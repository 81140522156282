/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React, {useContext, useState} from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonItem,
    IonBackButton,
    IonFooter,
    IonButton,
    IonInput,
    IonToast, IonLabel
} from '@ionic/react';
import NbioApi from "../../lib/api/NbioApi";

//components
import GradientDivider from "../../components/ui/GradientDivider";
import {RouteComponentProps} from "react-router";
import {AppContext} from "../../App";
import WebHeader from "../../components/ui/WebHeader";


interface ChangePasswordProps extends RouteComponentProps {
}

const ChangePassword: React.FC<ChangePasswordProps> = ({history}) => {
    const [password,setPassword] = useState('');
    const [password2,setPassword2] = useState('');
    const {user, setUser,isAuth,setIsAuth} = useContext(AppContext);
    const [isToastOpen,setIsToastOpen] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const onSubmit = () => {
        let i = history.location.state as any;
        const email = i.email;
        const code  = i.code;
        NbioApi.users.updatePassword(email,password,password2,code).then((res) =>{
            setUser(res.data.result.user);
            setIsAuth(true);
            history.push('/inicio')
        }).catch((ex) =>{
            let msg = 'Hubo un error al cambiar la contraseña';
            try {
                msg = ex.response.data.message_es;
            } catch (ex) {

            }
            setErrorMessage(msg);
            setIsToastOpen(true);
        })
    }
    return(
        <IonPage>
            <WebHeader title={'Escribe tu nueva contraseña'}></WebHeader>
            <IonContent fullscreen>
                <IonToast
                    isOpen={isToastOpen}
                    onDidDismiss={() => {
                        setIsToastOpen(false);
                    }}
                    message={errorMessage}
                    duration={5000}
                />
                <IonGrid>
                    <IonRow className={"ion-justify-content-center"}>
                        <IonCol size-md="6">
                            <IonItem>
                                <IonLabel position={'floating'}>
                                    Contraseña
                                </IonLabel>
                                <IonInput id="password"
                                          type={"password"}
                                          value={password}
                                          required
                                          placeholder="Contraseña"
                                          maxlength={20}
                                          onIonChange={(e: any) => setPassword(e.target.value)}>
                                </IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position={'floating'}>
                                    Repite tu contraseña
                                </IonLabel>
                                <IonInput id="password2"
                                          type={"password"}
                                          value={password2}
                                          required
                                          placeholder="Repite tu contraseña"
                                          maxlength={20}
                                          onIonChange={(e: any) => setPassword2(e.target.value)}>
                                </IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className="ion-no-border">
                <IonToolbar>
                    <IonGrid>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size-md="6">
                                <IonButton expand="block"
                                           fill="outline"
                                           onClick={() => onSubmit()}>
                                    Cambiar contraseña</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    )
}

export default ChangePassword;
