/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2022
 */

import React from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    withIonLifeCycle,
    IonButtons,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
} from '@ionic/react';
import {RouteComponentProps} from "react-router";
import {hourglass, receipt} from "ionicons/icons";
import {OrderType} from "../../lib/data_types/dataTypes";
import NbioApi from "../../lib/api/NbioApi";
import {Virtuoso} from "react-virtuoso";


//components
import GradientDivider from "../../components/ui/GradientDivider";
import BtnToolbarLogo from "../../components/ui/BtnToolbarLogo";
import OrderListItemOperator from "../../components/operator/OrderListItemOperator";


interface FinishedOrdersProps extends RouteComponentProps {};

interface FinishedOrdersState {
    pageFinished:number;
    finishedOrders: OrderType[];
    isLoaded: boolean;
    isLoading: boolean;
    renderComponent?: string;
    endFinishedOrdersReached:boolean;
    viewIsLoaded:boolean,
}



class FinishedOrders extends React.Component<FinishedOrdersProps, FinishedOrdersState> {
    constructor(props: FinishedOrdersProps) {
        super(props);
        this.state = {
            pageFinished:1,
            finishedOrders: [],
            isLoaded: false,
            isLoading:false,
            renderComponent: 'FINISHED',
            endFinishedOrdersReached:false,
            viewIsLoaded:false,
        }

        this.loadFinishedOrders = this.loadFinishedOrders.bind(this);
    }


    updateOrder(orderId:string){
        if(this.state.finishedOrders.map((o) => o._id).includes(orderId)){
            NbioApi.orders.getOrder(orderId).then((res) =>{
                // update order manually
                const orders = {...this.state.finishedOrders};
                const orderIndex = orders.findIndex((o) => o._id === orderId);
                orders[orderIndex] = res.data.order;
                this.setState({finishedOrders: orders});
            }).catch((ex) =>{

            })
        }
    }

    ionViewDidEnter() {
        this.loadFinishedOrders();
        this.setState({viewIsLoaded:true})
    }

    loadFinishedOrders = (showLoader=true) => {
        this.setState({isLoaded:false,isLoading:true})
        return new Promise((resolve,reject) => {
            if(this.state.endFinishedOrdersReached){
                return resolve(null);
            }
            return NbioApi.orders.assigned(this.state.pageFinished, 10, 'finished').then(res => {
                const newOrders = [...this.state.finishedOrders, ...res.data.orders];
                this.setState(
                    {
                        finishedOrders: newOrders,
                        pageFinished: this.state.pageFinished + 1,
                        isLoaded: true,
                        isLoading: false,
                        endFinishedOrdersReached:res.data.orders.length === 0
                    }, () =>{
                        resolve(null);
                    });
            }).catch(ex => {
                this.setState({
                    isLoaded: true,
                    isLoading: false
                })
                reject(null);
            })
        });
    }

    renderFinishedEmptyMessage() {
        if (this.state.finishedOrders.length === 0 ) {
            return (
                <div className={'ion-padding ion-text-center'}>
                    {this.state.isLoading ? 'Cargando...' : 'No tienes órdenes'}
                    <br/>
                    <br/>
                    <IonIcon icon={this.state.isLoading ? hourglass : receipt}
                             color={'medium'}
                             size={'large'}
                             slot={'icon-only'}>
                    </IonIcon>
                </div>
            )
        } else {
            return null;
        }

    }

    renderComponent() {
        switch (this.state.renderComponent) {
            case 'FINISHED': {
                return this.renderFinishedOrders();
            }
            case 'LOADING': {
                return this.renderLoader();
            }
            default: {
                return this.renderLoader();
            }
        }
    }

    renderFinishedOrders() {
        return (
            <>
                <IonRefresher slot="fixed" onIonRefresh={(ev: any) => {
                    this.setState(
                        {
                            pageFinished: 1,
                            finishedOrders: [],
                            endFinishedOrdersReached:false,
                        }, () => {
                        this.loadFinishedOrders().then((res) => {
                            ev.detail.complete();
                        })
                    })
                }}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                {
                    this.renderFinishedEmptyMessage()
                }
                <br/>
                <Virtuoso
                    style={{height: '100%'}}
                    data={this.state.finishedOrders}
                    totalCount={this.state.finishedOrders.length}
                    endReached={() => this.loadFinishedOrders()}

                    footer={ () => {
                        return (
                            <div
                                style={{
                                    padding: '1rem',
                                    textAlign: 'center',
                                }}
                            >
                                {this.state.endFinishedOrdersReached ? 'No tienes más órdenes' : 'Cargando...' }
                            </div>
                            )
                        }
                    }
                    itemContent={(index, order: OrderType) => {
                        return (
                            <OrderListItemOperator key={order._id} order={order} index={index}
                                                   onOrderUpdated={(order:OrderType) => this.updateOrder(order._id)}>
                            </OrderListItemOperator>
                        )}
                    }>
                </Virtuoso>

            </>
        )
    }

    renderLoader() {
        return null
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar className={"toolbar-nbio"}>
                        <IonButtons slot="start"><BtnToolbarLogo></BtnToolbarLogo></IonButtons>
                        <IonTitle>Órdenes Finalizadas</IonTitle>
                    </IonToolbar>
                    <GradientDivider></GradientDivider>
                </IonHeader>
                <IonContent>
                    {this.renderComponent()}
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(FinishedOrders);
