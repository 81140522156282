/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

import {
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput, IonToggle, IonToast
} from "@ionic/react";
import type {ProductType} from "../../lib/data_types/dataTypes";
import React from "react";
import NbioApi from "../../lib/api/NbioApi";
const {optimalChange} = require('../../lib/money/optimal-change');

interface CashPaymentProps{
    product?: ProductType;
    onClick?: any;
    onConfirmPayment:any;
    onFailedPayment:any;
    total?:number,
    checkoutId:string
}
interface CashPaymentState{
    isChangeRequired:boolean,
    amount?:number,
    hasPreloaded:boolean,
    isToastOpen:boolean,
    errorMessage:string
}
class CashPayment extends React.Component<CashPaymentProps,CashPaymentState> {
    constructor(props:CashPaymentProps) {
        super(props);
        this.state  = {
            isChangeRequired:false,
            amount: 0,
            hasPreloaded:false,
            isToastOpen:false,
            errorMessage:''
        }
    }
    static getDerivedStateFromProps(props:CashPaymentProps, state:CashPaymentState) {

        if (props.total !== 0 && !state.hasPreloaded) {
            return {
                amount: optimalChange(props.total),
                hasPreloaded: true
            }
        }
        return null
    }
    pay = () =>{
        let data = {};
        if(this.state.isChangeRequired){
            data = {
                cash_needs_change:this.state.isChangeRequired,
                cash_change_amount:this.state.amount
            }
            if((this.state.amount || 0) <= (this.props.total || 0)){
                this.setState({
                    isToastOpen:true,
                    errorMessage:`La cantidad debe de ser mayor a $${this.props.total}`
                })
                return this.props.onFailedPayment();

            }
        }

        NbioApi.checkout.generatePaymentIntent(this.props.checkoutId,data).then((res) =>{
            this.props.onConfirmPayment(res.data.orderId);
        }).catch((ex) =>{
            this.props.onFailedPayment();
        })
    }
    render(){
        return (
            <IonContent fullscreen>
                <IonGrid className={'ion-no-padding'}>
                    <IonToast
                        isOpen={this.state.isToastOpen}
                        onDidDismiss={() => {
                            this.setState({isToastOpen:false})
                        }}
                        message={this.state.errorMessage}
                        duration={5000}
                    />
                    <IonRow className="ion-justify-content-center">
                        <IonCol size-md="6">
                            <IonItem lines={"none"}>
                                <IonLabel slot="start">¿Necesitas cambio?</IonLabel>
                                <IonToggle slot="end" checked={this.state.isChangeRequired}
                                           onIonChange={(e) => this.setState({isChangeRequired:e.detail.checked})}/>
                            </IonItem>

                            <IonItem>
                                <IonLabel position={"floating"}>¿Con cuánto vas a pagar?</IonLabel>
                                <IonInput type={"number"}
                                          value={this.state.amount}
                                          disabled={!this.state.isChangeRequired}
                                          inputMode={'numeric'}
                                          onIonChange={(e) => {
                                              this.setState({
                                                  amount: parseFloat(e.detail.value || "0")
                                              })
                                          }}></IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        );
    }

};

export default CashPayment;
