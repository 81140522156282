/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2022
 */


import React from "react";
import {
    IonBackButton,
    IonButton, IonButtons,
    IonCol,
    IonContent, IonFooter,
    IonGrid, IonHeader,
    IonPage,
    IonRow,
    IonTitle, IonToolbar, withIonLifeCycle
} from "@ionic/react";
import API from "../../lib/api/NbioApi";
import PaymentMethods from "../../components/checkout/PaymentMethods";
import {PaymentMethod} from "../../lib/data_types/dataTypes";
import {RouteComponentProps} from "react-router";
import GradientDivider from "../../components/ui/GradientDivider";
import WebHeader from "../../components/ui/WebHeader";
import { emmitEvent,EVENTS } from "../../lib/events/events";

interface PaymentProps extends RouteComponentProps {
}
interface PaymentState{
    selectedCard?:PaymentMethod | null,
}
class CardPicker extends React.Component<PaymentProps, PaymentState> {
    constructor(e:any) {
        super(e);
        this.state ={
            selectedCard:null
        }
        this.selectCard   = this.selectCard.bind(this);
        this.onCardPicked = this.onCardPicked.bind(this);


    }
    selectCard(){
        if(this.state.selectedCard){
            // Make Default and go Back
            API.paymentMethods.makeDefault(this.state.selectedCard.id).then((res) =>{
                emmitEvent(EVENTS.RELOAD_DEFAULT_CARD,{});

                this.props.history.goBack();
            }).catch((ex) =>{

            })
        }


    }
    onCardPicked(card:PaymentMethod){
        this.setState({selectedCard:card});
    }
    render() {
        return (
            <IonPage>
                <WebHeader title={'Selecciona una tarjeta'} showSearchButton={false} showMenuButton={false}
                           showCartButton={false}></WebHeader>

                <IonContent fullscreen>
                    <IonGrid className={'ion-no-padding h-100'}>
                        <IonRow className="ion-justify-content-center h-100">
                            <IonCol className={'h-100'}>
                                <PaymentMethods
                                    isPicker={true}
                                    onCardPicked={(card: PaymentMethod) => this.onCardPicked(card)}
                                ></PaymentMethods>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
                <IonFooter className="ion-no-border">
                    <IonToolbar>
                        <IonGrid>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size-md="6">
                                    <IonButton expand="block"  onClick={this.selectCard}>SELECCIONAR</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonFooter>
            </IonPage>

        );
    }

}

export default withIonLifeCycle(CardPicker);
