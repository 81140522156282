/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2022
 */
import React, {useContext, useEffect, useState} from 'react';
import {
    IonAvatar,
    IonButton, IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal, IonProgressBar,
    IonRow, IonSelect, IonSelectOption, IonSpinner,
    IonText,
    IonTitle, IonToast,
    IonToolbar,
} from "@ionic/react";
import NbioApi from '../../lib/api/NbioApi';
import {useHistory} from "react-router";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {camera, person} from "ionicons/icons";
import {Camera, CameraResultType} from "@capacitor/camera";

//style
import "../../css/components/profileDataPopup.css";

//components
import NPDatePicker from "../ui/NPDatePicker";
import {AppContext} from "../../App";
dayjs.extend(customParseFormat);


const ProfileDataPopup: React.FC<{}> = ({}) => {
    const history = useHistory();

    const {user, setUser, isAuth, setIsAuth} = useContext(AppContext);
    const [isOpen, setIsOpen] = useState(true);
    const [step, setStep] = useState('NAMES'); //NAMES,BDAY,GENDER,IMG
    const [name, setName] = useState('');
    const [last_name, setLast_name] = useState('');
    const [dateOfBirth,setDateOfBirth] = useState(dayjs().format('MM-DD-YYYY'));
    const [gender,setGender] = useState('');
    const [profileUrl,setProfileUrl] = useState('');
    const [showToast,setShowToast] = useState(false);
    const [toastMsg,setToastMsg] = useState('');
    const [updatingProfile,setUpdatingProfile] = useState(false);
    const [updatingImage,setUpdatingImage] = useState(false);
    const [alreadyHasImage,setAlreadyHasImage] = useState(false);
    const [showCameraErrorMessage, setShowCameraErrorMessage] = useState(false);
    const [isUploadingPhoto,setIsUploadingPhoto] = useState(false);
    const updateProfile = () => {
        const data = {
            dateOfBirth:dateOfBirth,
            name:name,
            last_name:last_name,
            gender: gender
        }

        NbioApi.users.editMe(data).then((res) =>{
            setUser(res.data.user);
            setUpdatingProfile(false);
            setStep('IMG');
        }).catch((ex) =>{
            displayErrorMsg('Hubo un error al cambiar tu perfil.');
        });
    }
    useEffect(() =>{
        NbioApi.users.getMe().then((res) =>{
            setName(res.data.user.name);
            setLast_name(res.data.user.last_name);
            setGender(res.data.user.gender);
            setProfileUrl(res.data.user.profileUrl);
            setAlreadyHasImage(!!res.data.user.profileUrl);
        }).catch((ex) =>{

        });
    },[])
    const renderFormName = () => {
        return(
            <>
                <div className={'pdp-form ion-margin-top'}>
                    <IonLabel><h1>¿Cuál es tu nombre?</h1></IonLabel>

                    <IonItem lines={'full'} className={'ion-margin-top bg-transparent'}>
                        <IonInput id="name"
                                  type="text"
                                  placeholder={"Nombre"}
                                  value={name}
                                  onIonChange={(e: any) => setName(e.detail.value)}>
                        </IonInput>
                    </IonItem>
                </div>

                <div className={'pdp-form'}>
                    <IonLabel><h1>¿Cuál es tu apellido?</h1></IonLabel>

                    <IonItem lines={'full'} className={'ion-margin-top bg-transparent'}>
                        <IonInput id="last_name"
                                  type="text"
                                  placeholder={"Apellido"}
                                  value={last_name}
                                  onIonChange={(e: any) => setLast_name(e.detail.value)}>
                        </IonInput>
                    </IonItem>
                </div>

                <IonButton className='ion-margin-top'
                           color='primary'
                           expand='block'
                           onClick={() => {
                               if(validateNames()){
                                   setStep('BDAY');
                               }
                           }}
                           routerDirection={"forward"}>Siguiente</IonButton>
            </>
        )
    }

    const displayErrorMsg = (msg: string) => {
        setToastMsg(msg);
        setShowToast(true);
    }

    const validateNames = () => {
        if(name.trim() === ''){
            displayErrorMsg('El nombre no puede ser vacío');
            return false
        }

        if(last_name.trim() === ''){
            displayErrorMsg('El apellido no puede ser vacío');
            return false
        }

        return true
    }
    const isOver17YearsOld = (v:string) =>{
        const minAge = dayjs().subtract(17,'year');
        const userAge = dayjs(v,'MM-DD-YYYY');
        if(userAge.isValid()){
            return userAge.isBefore(minAge) ||  userAge.isSame(minAge);
        }else{
            return false;
        }
    }
    const validateBDay = () => {
        if(dateOfBirth.trim() === ''){
            displayErrorMsg('Debes de proporcionar una fecha de nacimiento');
            return false
        }
        if (!isOver17YearsOld(dateOfBirth)){
            displayErrorMsg('Debes de tener al menos 17 años para crear una cuenta');
            return false;
        }
        return true

    }

    const renderFormBDay = () => {
        return(
            <>
                <div className={'pdp-form ion-margin-top'}>
                    <IonLabel className={'ion-margin-bottom'}><h1>¿Cuál es tu fecha de nacimiento?</h1></IonLabel>


                    <NPDatePicker onDateChanged={(date:any) => {
                                    setDateOfBirth(date.format('MM-DD-YYYY'))}}
                    >
                    </NPDatePicker>
                </div>

                <IonButton className='ion-margin-top'
                           color='primary'
                           expand='block'
                           onClick={() => {
                               if(validateBDay()){
                                   setStep('GENDER');
                               }
                           }}
                           routerDirection={"forward"}>Siguiente</IonButton>
            </>
        )
    }

    const renderFormGender = () => {
        return(
            <>
                <div className={'pdp-form ion-margin-top'}>
                    <IonLabel><h1>¿Con cuál género te identificas?</h1></IonLabel>

                    <IonItem lines={'full'} className={'ion-margin-top bg-transparent'}>
                        <IonSelect interface="popover"
                                   value={gender}
                                   placeholder="Elige una opción"
                                   onIonChange={(e) => setGender(e.detail.value)}>
                            <IonSelectOption value="female">Femenino</IonSelectOption>
                            <IonSelectOption value="male">Masculino</IonSelectOption>
                            <IonSelectOption value="other">Otro</IonSelectOption>
                            <IonSelectOption value="">No proporcionar</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                </div>

                <IonButton className='ion-margin-top'
                           color='primary'
                           expand='block'
                           disabled={updatingProfile}
                           onClick={() => {
                               setUpdatingProfile(true);
                               updateProfile();
                           }}
                           routerDirection={"forward"}>Siguiente</IonButton>
            </>
        )
    }

    const renderUploadImg = () => {
        return (
            <>
                <div className={'pdp-form ion-margin-top'}>
                    <IonLabel><h1>Sube tu imagen de perfil</h1></IonLabel>

                    <div className={'flex-row ion-justify-content-center ion-margin-top'}>
                        <IonAvatar className={'p-relative'}>
                            {renderProfileImage(profileUrl)}
                            <IonButton className={"fab-camera-btn"}
                                       size="small" fill={'clear'}
                                       onClick={_takePicture}>
                                <IonIcon slot={"icon-only"} icon={camera}></IonIcon>
                            </IonButton>
                        </IonAvatar>
                    </div>
                </div>

                <IonButton color='primary'
                           disabled={updatingImage}
                           expand={'full'}
                           onClick={_takePicture}
                           routerDirection={"forward"}>Subir imagen
                </IonButton>
                <IonButton color={alreadyHasImage ? 'dark': 'danger'} className={'ion-margin-top'}
                           expand={'block'}
                           fill={'clear'}
                           disabled={updatingImage}
                           onClick={() => {
                               setIsOpen(false);
                           }}
                           routerDirection={"forward"}>
                                {alreadyHasImage ?  'Continuar' :'Subir en otro momento'}
                </IonButton>



            </>
        )
    }

    const renderProfileImage = (imgUrl: string) => {
        if(isUploadingPhoto){
            return (<IonSpinner/>);
        }
        if (imgUrl) {
            return (
                <img src={imgUrl} alt={'user-image'}></img>
            )
        } else {
            return (
                <IonIcon className={"w-100 h-100"} icon={person}></IonIcon>
            )
        }
    }

    const _takePicture = async () => {
        setUpdatingImage(true);
        try{
            const image = await Camera.getPhoto({
                quality: 90,
                resultType: CameraResultType.Base64,
                promptLabelHeader: 'Agregar foto de perfil',
                promptLabelPhoto: 'Seleccionar de la galería',
                promptLabelPicture: 'Tomar una fotografía'
            });
            // image.webPath will contain a path that can be set as an image src.
            // You can access the original file using image.path, which can be
            // passed to the Filesystem API to read the raw data of the image,
            // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
            const imageBase64 = image.base64String;
            setIsUploadingPhoto(true);
            if (imageBase64) {
                try{
                    await NbioApi.users.updateProfilePicture(imageBase64);
                    try{
                        let userRes = await NbioApi.users.getMe()
                        if (userRes) {
                            setUser(userRes.data.user);
                            setUpdatingImage(false);
                            setIsUploadingPhoto(false);
                            setIsOpen(false);
                        }
                    }catch (e) {
                        setUpdatingImage(false);
                        setIsUploadingPhoto(false);
                        return null;
                    }
                }catch(ex){
                    displayErrorMsg('Hubo un error al subir tu imagen de perfil');
                    setUpdatingImage(false);
                    setIsUploadingPhoto(false);
                }
            }
        }catch (e) {
            displayErrorMsg('No pudimos abrir la cámara. Por favor, vuelve a intentarlo');
        }



    }

    const renderComponent = () => {
        switch (step){
            case 'NAMES':
                return renderFormName();
            case 'BDAY':
                return renderFormBDay();
            case 'GENDER':
                return renderFormGender();
            case 'IMG':
                return renderUploadImg();
            default:
                return null;
        }
    }
    const getBarProgress = (barStep:string) =>{
        // NAMES,BDAY,GENDER,IMG
        if(barStep === 'BDAY'){
            return ['BDAY','GENDER','IMG'].includes(step) ? 100 :0
        }else if(barStep === 'GENDER'){
            return ['GENDER','IMG'].includes(step) ? 100 :0
        }else if(barStep === 'IMG'){
            return step === barStep ? 100 :0
        }else{
            return 0;
        }
    }
    return (
            <IonModal isOpen={isOpen}
                      backdropDismiss={false}>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={toastMsg}
                    duration={5000}
                />
                <IonHeader translucent>
                    <IonToolbar className={'pdp-toolbar'}>
                        <IonTitle>
                            Completa tu perfil
                        </IonTitle>
                    </IonToolbar>
                    <div className={'flex-row'}>
                        <IonProgressBar value={100}
                                        type="determinate"
                                        color={'primary'}>
                        </IonProgressBar>
                        <IonProgressBar value={getBarProgress('BDAY')}
                                        className="ion-margin-start"
                                        type="determinate"
                                        color={'primary'}>
                        </IonProgressBar>
                        <IonProgressBar value={getBarProgress('GENDER')}
                                        className="ion-margin-start"
                                        type="determinate"
                                        color={'primary'}>
                        </IonProgressBar>
                        <IonProgressBar value={getBarProgress('IMG')}
                                        className="ion-margin-start"
                                        type="determinate"
                                        color={'primary'}>
                        </IonProgressBar>
                    </div>
                </IonHeader>
                <IonContent fullscreen>
                    <IonGrid>
                        <IonRow className={'ion-justify-content-center'}>
                            <IonCol size-md="6">
                                <IonText className="ion-text-center">
                                    <span>Completa tu perfil para darte un mejor servicio</span>
                                </IonText>
                                {renderComponent()}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonModal>
    )
}

export default ProfileDataPopup;
