/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2023
 */

import {
    IonButton,
    IonIcon,
    IonLabel,
    IonToast,
    IonSpinner,
} from "@ionic/react";
import {ProductType} from "../../lib/data_types/dataTypes";
import Cart from "../../lib/cart/Cart";
import React, {useState} from "react";
import {addCircle, cart, removeCircle} from "ionicons/icons";
import {useHistory} from "react-router-dom";
import {emmitEvent, EVENTS} from "../../lib/events/events";





interface AddAmountToCartProps{
    product: ProductType;
    adId?:string;
    style?: string;
}

const AddAmountToCart: React.FC<AddAmountToCartProps> = ({
                                                             product,
                                                             adId = '',
                                                             style= '',
                                                         }) => {

    const [quantity,setQuantity] = useState(1);
    const [qty,setQty] = useState(1);

    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState('');
    const [isAddingToCart,setIsAddingToCart] = useState(false);
    const history = useHistory();
    const classWrapper = style === 'row' ? 'flex-row' : '';
    const classBtn = style === 'row' ? 'w-100' : '';


    const addToCart = (e : any) => {
        e.stopPropagation();
        setIsAddingToCart(true);
        Cart.addProduct(product._id,quantity,true,adId).then(res => {
            setToastMsg("Producto agregado")
            setShowToast(true);

            try{
                // @ts-ignore
                fbq('track', 'AddToCart');
            }catch(ex){
            }
            //google tracking
            try{
                // @ts-ignore
                gtag("event", "add_to_cart", {
                    currency: "MXN",
                    value: product.price,
                    items: [
                        {
                            item_id: product._id,
                            item_name: product.name,
                            item_brand: product.brandName || '',
                            item_category: product.categories ? product.categories[0] : '',
                            price: product.price,
                            quantity: 1
                        }
                    ]
                });
            }catch(ex){
            }
        }).catch((ex) =>{
        }).finally(() =>{
            cleanStates();
            setIsAddingToCart(false);
        });
    }

    const increaseQty =  (e: any) => {
        e.stopPropagation();

        let newQty = qty + 1 >= 20 ? 20 : qty + 1;
        setQty(newQty)
        setQuantity(newQty);
    }

    const decreaseQty = (e: any) =>{
        e.stopPropagation();

        let newQty = qty - 1 <= 1 ? 1 : qty - 1;
        setQty(newQty)
        setQuantity(newQty);
    }

    const cleanStates = () => {
        setQty(1)
        setQuantity(1);
        setToastMsg("");
    }

    const goToCart = () =>{
        history.push('/carrito');
    }
    const showCart = () =>{
        emmitEvent(EVENTS.OPEN_POPUP_CART,{});
    }

    return (

        <>
            {/*Ion Toast Not Mobile opens popup */}
            <IonToast isOpen={showToast} cssClass={'ion-hide-lg-down'}
                      onDidDismiss={() => setShowToast(false)}
                      message={toastMsg}
                      buttons={[
                          {
                              text: 'Ver el carrito',
                              role: 'info',
                              handler: () => {
                                  showCart();
                              }
                          }
                      ]}
                      duration={5000} />
            {/*Ion Toast Mobile calls /carrito */}
            <IonToast isOpen={showToast} cssClass={'ion-hide-lg-up'}
                      onDidDismiss={() => setShowToast(false)}
                      message={toastMsg}
                      buttons={[
                          {
                              text: 'Ir al carrito',
                              role: 'info',
                              handler: () => {
                                  goToCart();
                              }
                          }
                      ]}
                      duration={5000} />

            <div className={`${classWrapper}`}>
                <div className={'flex-row ion-align-items-center'}>
                    <IonButton onClick={decreaseQty}
                               className="no-padding-start btn-on-dark-primary-clear"
                               size="small"
                               fill="clear">
                        <IonIcon slot="icon-only" icon={removeCircle} ></IonIcon>
                    </IonButton>
                    <IonLabel>{quantity}</IonLabel>
                    <IonButton onClick={increaseQty} className="btn-on-dark-primary-clear" size="small" fill="clear">
                        <IonIcon slot="icon-only" icon={addCircle}></IonIcon>
                    </IonButton>
                    {style !== "row" ? <IonLabel>Unidades</IonLabel> : null}
                </div>
                <IonButton className={`${classBtn} btn-on-dark-primary ion-text-uppercase`}
                           expand="block"
                           fill="solid"
                           size={"small"}
                           onClick={addToCart}>
                    {
                        isAddingToCart ? <IonSpinner name={'dots'}></IonSpinner> : <>Agregar al carrito</>
                    }
                </IonButton>
            </div>
        </>
    );
};

export default AddAmountToCart;
