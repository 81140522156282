/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import React, {useState} from "react";
import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonModal,
    IonNote, IonText,
    IonTitle, IonToast,
    IonToolbar
} from "@ionic/react";
import {close, location} from "ionicons/icons";
import GradientDivider from "./GradientDivider";
import {useHistory} from "react-router";
import NbioApi from "../../lib/api/NbioApi";

interface locationModalProps {
    isVisible: boolean,
    onCloseModal: any
}

interface actionSheetProps {
}

export const LocationModal: React.FC<locationModalProps> = ({isVisible, onCloseModal}) => {
    const [zipCode,setZipCode] = useState('');
    const [isToastOpen,setIsToastOpen] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const history = useHistory();
    const verifyZipCode = () => {
        setIsToastOpen(false);
        NbioApi.zipCode.verifyZipCode(zipCode).then((res) =>{
            const isValid = res.data.isValid;
            if(isValid){
                // GO AWAY
                setErrorMessage('¡Felicidades! Estás dentro de la cobertura de Nbio.');
                setIsToastOpen(true);
                setTimeout(() =>{
                    onCloseModal();
                    localStorage.setItem('nbio-zipcode-verified', 'true');
                },2500);
            }else{
                // ERROR
                setErrorMessage('Lo sentimos, tu código postal no se encuentra dentro de la cobertura de Nbio.');
                setIsToastOpen(true);
            }
        }).catch((ex) =>{
                // ERROR
                setErrorMessage('Lo sentimos, hubo un error al verificar tu código postal.');
                setIsToastOpen(true);
        });
    }
    const skip = () =>{
        localStorage.setItem('nbio-zipcode-verified', 'true');
        onCloseModal();
    }

    return (
        <IonModal isOpen={isVisible} showBackdrop={false} backdropDismiss={false}>
            <IonHeader>
                <IonToolbar className={"toolbar-nbio"}>
                    <IonTitle>
                        {/*Ingresa tu C.P.*/}
                        Verifica tu cobertura

                    </IonTitle>
                    <IonButtons slot="end">
                        {/*<IonButton onClick={(ev) => {*/}
                        {/*    ev.stopPropagation();*/}
                        {/*    onCloseModal()}}>*/}
                        {/*    <IonIcon icon={close}></IonIcon>*/}
                        {/*</IonButton>*/}
                    </IonButtons>
                </IonToolbar>
                <GradientDivider></GradientDivider>
            </IonHeader>
            <IonContent>
                <IonCard className={'ion-no-border'}>
                    <IonCardHeader>
                        <IonCardTitle>
                            {/*Ingresa tu C.P.*/}
                            Verifica tu cobertura
                        </IonCardTitle>
                        <div className={'ion-margin-top'}>
                            Ayudanos a brindarte un mejor servicio. Ingresa tu código postal para verificar la cobertura en tu zona.
                        </div>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonInput placeholder={'Ingresa tu Código Postal'}
                                  onIonChange={(e:any) => setZipCode(e.target.value)}>
                        </IonInput>
                        <div className={'flex-row ion-justify-content-end ion-align-items-center ion-margin-top'}>
                            <IonButton fill={'clear'} onClick={(ev) => {
                                ev.stopPropagation();
                                skip();
                               }}>
                                Omitir
                            </IonButton>
                            <IonButton onClick={(ev) => {
                                ev.stopPropagation();
                                localStorage.setItem('NB_zip_code',zipCode)
                                verifyZipCode()
                            }}>
                                Verificar
                            </IonButton>
                        </div>
                    </IonCardContent>
                </IonCard>
                <div className={'ion-padding'}>
                    <IonNote className={'ion-text-wrap'}>
                        Los precios y horarios de entrega pueden variar según la zona. &nbsp;
                        <span onClick={(ev) => {
                            ev.stopPropagation();
                            skip();
                            history.push('/perfil')
                        }}>
                            <IonText color={'primary'} className={'cursor-pointer line-underline ion-text-wrap'}>
                                Agrega tu dirección para mayor información.
                            </IonText>
                        </span>
                    </IonNote>
                </div>
                <IonToast isOpen={isToastOpen} message={errorMessage} duration={5000}/>
            </IonContent>
        </IonModal>
    );
};
const LocationActionSheet: React.FC<actionSheetProps> = ({}) => {
    const [showModal, setShowModal] = useState(false);
    const [zipCode,setZipCode] = useState(localStorage.getItem('NB_zip_code'));
    // Wait 10 second
    // setTimeout(() =>{
    //     NbioApi.zipCode.shouldAskForZipCode().then((res) =>{
    //         if(localStorage.getItem('nbio-zipcode-verified') !== 'true'){
    //             if(res.data.shouldAskForZipCode){
    //                 setShowModal(true);
    //             }
    //         }
    //     }).catch((ex) =>{
    //
    //     });
    // }, 10 * 1000)
    return (
            <LocationModal isVisible={showModal} onCloseModal={() => {
                setZipCode(localStorage.getItem('NB_zip_code'))
                setShowModal(false)
            }}></LocationModal>
    )
}
export default LocationActionSheet;
