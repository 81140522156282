/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2021
 */

import {IonBadge} from "@ionic/react";
import React from "react";

interface OrderPaymentStatusProps {
  status: string;
  slot?: string;
}

const OrderPaymentStatus: React.FC<OrderPaymentStatusProps> = ({ status,slot='' }) => {
    let color, label;

    switch (status) {
        case 'paid': {
            color = "success";
            label = "pagado";
        }
            break;
        case 'pending': {
            color = "warning";
            label = "Pago pendiente"
        }
            break;
        default: {
            color = "medium";
            label = "-----";
        }
            break;
    }

    return <IonBadge slot={slot} color={color} className={'ion-text-capitalize'}>{label}</IonBadge>
};

export default OrderPaymentStatus;
