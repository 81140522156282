/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import {
    IonHeader,
    IonButton,
    IonIcon,
    IonGrid, IonRow, isPlatform
} from "@ionic/react";
import React, {useState,useEffect} from "react";
import {closeOutline} from "ionicons/icons";

//style
import "../../css/components/webHeader.css";
import '../../css/style.css';

//image
import bannerApp from '../../assets/nbio-banner-app.png';

interface webHeaderProps {
    title? :string
    customButton?: any,
    showSearchButton?: boolean
    showCartButton?: boolean
    showMenuButton?: boolean
}

const AppBannerHeader: React.FC<webHeaderProps> = ({
                                                 showSearchButton = true,
                                             }) => {
    const [isOpen,setIsOpen] = useState(false);
    const [isUnsupportedWebView,setIsUnsupportedWebView] = useState(false);

    const goToStore = () =>{
        const isIos = isPlatform('ios');
        window.open(isIos ? 'https://apps.apple.com/us/app/nbio/id1605823846' : 'https://play.google.com/store/apps/details?id=mx.nbio&hl=es_MX&gl=US');
    }
    useEffect(() =>{
        // Check if this is an unsupported webview (instagram, facebook in-app browser)
        const userAgent = window.navigator.userAgent.toLowerCase();
        if( userAgent.includes('fb_iab') ||
            userAgent.includes('fb4a') ||
            userAgent.includes('fbav') ||
            userAgent.includes('instagram') ||
            userAgent.includes('musical_ly')
        ){
            setIsUnsupportedWebView(true);
            setIsOpen(true);
        }
    },[]);
    return (
        <IonHeader className={"app-banner-header"}>
            {/*Top Header*/}
            {
                isOpen ?

                    <IonGrid >
                        <IonRow>
                            <div className={'ion-no-padding flex-row justify-content-between'} style={{
                                justifyContent: 'space-between',
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%'
                            }}>
                                <div style={{display:'flex'}}>
                                    <div style={{display:'flex'}}
                                         className={'flex-column ion-align-items-center cursor-pointer'}>

                                        <IonIcon icon={closeOutline} onClick={() => setIsOpen(false)}>

                                        </IonIcon>
                                    </div>

                                    <img src={bannerApp} height={37} className={'ml-1'}>
                                    </img>
                                    <div className={'ml-1'}>
                                        <div>
                                            <b>Farmacia Nbio</b>
                                        </div>
                                        <div>
                                            Envíos a todo México
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <IonButton onClick={() => goToStore()}>Obtener</IonButton>
                                </div>
                            </div>

                        </IonRow>
                    </IonGrid> : null
            }

        </IonHeader>
    )
}
export default AppBannerHeader;
