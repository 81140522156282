/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * September 2022
 */
import React from "react";
import {useHistory} from "react-router";
import {IonButton, IonCol, IonContent, IonGrid, IonRow, IonText} from "@ionic/react";

import '../../css/style.css';

interface UserLoginInvitationProps{
    onNavigateToRoute?: any
}

const UserLoginInvitation: React.FC<UserLoginInvitationProps> = ({onNavigateToRoute = () => {}}) => {
    const history = useHistory();
    const goToStart = () =>{
        window.location.href = '/inicio';
    }
    return (
        <IonContent fullscreen>
            <IonGrid className={'h-100'}>
                <IonRow className="h-100 ion-justify-content-center ion-align-items-center">
                    <IonCol size-md="6" className="ion-text-center">
                        <IonText className="ion-text-center">
                            <h5>Aún no has iniciado sesión.<br/> No te pierdas de la experiencia NBIO</h5>
                        </IonText>
                        <div className={'logo-icon-invitation'}></div>
                        <IonButton color={'primary'}
                                   routerDirection={'forward'}
                                   onClick={() => {
                                       onNavigateToRoute();
                                       setTimeout(() =>{
                                           history.push('/iniciar-sesion');
                                       },1000);
                                   }}>Iniciar sesión
                        </IonButton>
                        <IonButton color={'primary'}
                                   routerDirection={'forward'}
                                   fill={'outline'}
                                   onClick={() => {
                                       onNavigateToRoute();
                                       setTimeout(() => {
                                           history.push('/crear-cuenta');
                                       },1000);
                                   }}>Crear cuenta
                        </IonButton>
                        <br/>
                        <IonButton fill="clear"
                                   size="small"
                                   className={'ion-margin-top'}
                                   onClick={() => {
                                       onNavigateToRoute();
                                       setTimeout(() => {
                                           goToStart();
                                       },1000);
                                   }}
                                   routerDirection={"forward"}>Seguir comprando</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    );
};

export default UserLoginInvitation;
