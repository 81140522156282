/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2021
 */

import React, {useState} from 'react';
import {
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonContent,
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonLabel,
    withIonLifeCycle,
    IonItem,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonDatetime,
    IonToast,
    IonPopover,
    IonText
} from '@ionic/react';
import { RouteComponentProps} from "react-router";
import {User} from "../../lib/data_types/dataTypes";
import NbioApi from "../../lib/api/NbioApi";
import dayjs from "dayjs";
import utc   from "dayjs/plugin/utc";
import 'dayjs/locale/es'
import customParseFormat from 'dayjs/plugin/customParseFormat';

//components
import GradientDivider from "../../components/ui/GradientDivider";
import WebHeader from "../../components/ui/WebHeader";
import WebBreadcrumbs from "../../components/ui/WebBreadcrumbs";
import NPDatePicker from "../../components/ui/NPDatePicker";
dayjs.extend(customParseFormat);

//interface
interface EditUserProps extends RouteComponentProps{
};

interface EditUserState  {
    user: User;
    name: string;
    last_name: string;
    gender: string;
    dateOfBirth: string;
    showMessage:boolean;
    errorMessage:string;
    loaded:boolean;
}

//dates
dayjs.extend(utc);

class EditUser extends React.Component<EditUserProps,EditUserState>{
    constructor(props: EditUserProps) {
        super(props);
        this.state = {
            user: {
                name: '',
                last_name: '',
                source: '',
                uid: '',
                email: '',
                profileUrlThumbnail: '',
                profileUrl: '',
                roles: [],
                isDisabled: false
            },
            name: '',
            last_name: '',
            gender: '',
            dateOfBirth:'',
            showMessage:false,
            errorMessage:'',
            loaded:false
        }
    }

    ionViewDidEnter = () => {
        NbioApi.users.getMe().then(res => {
            const user = res.data.user;
            this.setState({
                user: user,
                name: user.name,
                last_name: user.last_name,
                gender: user.gender,
                dateOfBirth: dayjs(user.dateOfBirth).format('MM-DD-YYYY'),
                loaded:true
            });
        }).catch((ex) => {
            this.setState({loaded:true});
        });
    }

    setGender = (v: any) => {
        this.setState({
            gender:v
        })
    }

    setSelectedDate = (v: any) =>{
        const dateFormat = dayjs(v).format('DD MMMM YYYY');
        this.setState({
            dateOfBirth:dateFormat
        })
    }
    setDateOfBirth(dateOfBirth:string){
        this.setState({dateOfBirth:dateOfBirth})
    }
    isEmpty   = (v:string) => {
        if (v) {
            return v.trim() === '';
        } else {
            return true;
        }
    }
    setUserValue = ({id, value}: any) => {
        this.setState({[id]: value} as Pick<EditUserState, keyof EditUserState>);
    }
    isValidForm(){
        if (this.isEmpty(this.state.name)) {
            return {
                message: 'El nombre no puede estar vacío',
                isValid: false
            }
        }
        if (this.isEmpty(this.state.last_name)) {
            return {
                message: 'El apellido no puede estar vacío',
                isValid: false
            }
        }
        if (this.isEmpty(this.state.dateOfBirth)) {
            return {
                message: 'Debes de proporcionar una fecha de nacimiento',
                isValid: false
            }
        }
        if (!this.isOver17YearsOld(this.state.dateOfBirth)){
            return {
                message: 'Debes de tener al menos 17 años para crear una cuenta',
                isValid: false
            }
        }
        return {
            message:'',
            isValid:true
        }
    }
    isOver17YearsOld = (v:string) =>{
        const minAge = dayjs().subtract(17,'year');
        const userAge = dayjs(v,'MM-DD-YYYY');
        if(userAge.isValid()){
            return userAge.isBefore(minAge) ||  userAge.isSame(minAge);
        }else{
            return false;
        }
    }

    saveProfile(){
        const {isValid,message} = this.isValidForm();
        if(!isValid){
            this.setState({
                showMessage:true,
                errorMessage:message
            })
        }else{
            const data = {
                dateOfBirth:this.state.dateOfBirth,
                name:this.state.name,
                last_name:this.state.last_name,
                gender:this.state.gender
            }
            NbioApi.users.editMe(data).then((res) =>{
                this.setState({
                    showMessage:true,
                    errorMessage:'Perfil actualizado'
                })
            }).catch((ex) =>{
                this.setState({
                    showMessage:true,
                    errorMessage:'Hubo un error al cambiar tu perfil.'
                })
            })
        }
    }

    renderDate = (date: any) => {
        const dateValue = date ? dayjs(date).locale('es').utc().format('DD MMMM YYYY') : 'Elije tu fecha de nacimiento';
        const colorFormat = date ? '' : 'medium';

        return  <IonText color={colorFormat}>{dateValue}</IonText>

    }

    render(){
        return (
            <IonPage>
                <WebHeader title={'Editar mis datos'} showSearchButton={true}></WebHeader>
                <IonContent>
                    <IonToast
                        isOpen={this.state.showMessage}
                        onDidDismiss={() => {
                            this.setState({showMessage:false})
                        }}
                        message={this.state.errorMessage}
                        duration={5000}
                    />
                    <IonGrid>
                        <IonRow className={'ion-justify-content-center'} >
                            <IonCol size-md={6}>
                                <WebBreadcrumbs links={[{name:'Perfil',link:'/perfil'}]}></WebBreadcrumbs>

                                <IonItem lines={'full'}>
                                    <IonLabel position={'floating'}>Nombre: </IonLabel>
                                    <IonInput id="name"
                                              type="text"
                                              placeholder={"Nombre"}
                                              value={this.state.name}
                                              enterkeyhint="next"
                                              onIonChange={(e: any) => this.setUserValue(e.target)}>
                                    </IonInput>
                                </IonItem>
                                <IonItem lines={'full'}>
                                    <IonLabel position={'floating'}>Apellido: </IonLabel>
                                    <IonInput id="last_name"
                                              type="text"
                                              placeholder={"Apellido"}
                                              value={this.state.last_name}
                                              onIonChange={(e: any) => this.setUserValue(e.target)}>
                                    </IonInput>
                                </IonItem>
                                <div className={'ion-item-dupe'}>
                                    <IonLabel position={'stacked'}>Fecha de nacimiento:</IonLabel>
                                    {
                                        this.state.loaded ?
                                            <NPDatePicker defaultValue={this.state.dateOfBirth} onDateChanged={(date:any) => {
                                                this.setDateOfBirth(date.format('MM-DD-YYYY'));
                                            }}></NPDatePicker>
                                            :null
                                    }

                                </div>
                                <IonItem lines={'full'}>
                                    <IonLabel slot={''}>Género: </IonLabel>
                                    <IonSelect interface="popover" slot={'end'} value={this.state.gender}
                                               placeholder="Elige una opción"
                                               onIonChange={(e) => this.setGender(e.detail.value)}>
                                        <IonSelectOption value="female">Femenino</IonSelectOption>
                                        <IonSelectOption value="male">Masculino</IonSelectOption>
                                        <IonSelectOption value="other">Otro</IonSelectOption>
                                        <IonSelectOption value="">No proporcionar</IonSelectOption>

                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                        </IonRow>

                        <IonRow className={'ion-justify-content-center'} >
                            <IonCol size-md={6}>
                                <IonButton expand={"block"}
                                           className={'ion-text-uppercase'}
                                           onClick={() => {this.saveProfile()}}
                                >
                                    Guardar Cambios
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(EditUser);
