/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonImg,
    IonSkeletonText,
    IonText,
    IonButton,
    IonIcon,
    IonContent,
    IonPopover,
    IonList,
    IonItem, IonToast, IonNote, IonChip,
} from "@ionic/react";
import type {BrandType, ETAType, ProductType} from "../../lib/data_types/dataTypes";
import {useHistory} from "react-router";
import React, {useRef, useState} from "react";
import {AppLauncher} from "@capacitor/app-launcher";
import NbioApi from "../../lib/api/NbioApi";
import { money } from "../../lib/money/money";
import { isPlatform } from '@ionic/react';
import {
    linkOutline,
    logoFacebook,
    logoTwitter,
    mailOutline,
    shareSocialOutline,
    starOutline
} from "ionicons/icons";
import { Link } from "react-router-dom";
import {Share} from "@capacitor/share";

//style
import "../../css/components/product.css";

//components
import BuyItNowButton from "./BuyItNowButton";
import AddToCartButton from "./AddToCartButton";
import AddAmountToCart from "./AddAmountToCart";


//interface & const
interface ProductProps{
    product: ProductType;
    onClick?: any;
    classCard?: string;
    adId?:string,
    isRecommended? : boolean;
    hideImage?: boolean;
    hideInfo?: boolean;
    isWeb?: boolean;
    isInList?: boolean;
    brand?: BrandType;
    eta?: ETAType;
    isMobileView? : boolean;
}
const validUrl = require('valid-url');


const Product: React.FC<ProductProps> = ({ product ,
                                             onClick = () => {},
                                             classCard= 'ion-no-margin',
                                             adId='',
                                             isRecommended=false,
                                             hideImage=false,
                                             hideInfo=false,
                                             isWeb = false,
                                             isInList = false,
                                             brand= {_id:'',name:'',handleUrl:''},
                                             eta= {eta:'',formattedEta:''},
                                             isMobileView= false
                                         }) => {
    const history = useHistory();
    const isMobile = isPlatform('ios') || isPlatform('android') || isPlatform('mobile');
    const discountTag = product.discount?.type === 'percentage' ?
        `- ${product?.discount?.value}%` :
        `- ${money(product?.discount?.value)}`

    const [isShareModalOpen,setIsShareModalOpen] = useState(false);
    const [isShareToastOpen,setIsShareToastOpen] = useState(false);

    const [isPopover1Open,setIsPopover1Open] = useState(false);
    const [isPopover2Open,setIsPopover2Open] = useState(false);
    const [isPopover3Open,setIsPopover3Open] = useState(false);

    const popover = useRef<HTMLIonPopoverElement>(null);
    const popover2 = useRef<HTMLIonPopoverElement>(null);
    const popover3 = useRef<HTMLIonPopoverElement>(null);
    const sharePopover = useRef<HTMLIonPopoverElement>(null);

    const onClickCard = () => {
        if(product._id){
            onClick(product);
        }
    }

    const onAdClick = async (product: ProductType) =>{
        // REPORT TO ANALYTICS
        try{
            await NbioApi.ads.reportClick(product._id);
        }catch(ex){

        }
        if(validUrl.isWebUri(product.link)){
            AppLauncher.openUrl({url: product.link!});
        }else{
            history.push({
                pathname:`${product.link!}`,
                search:`AdId=${product._id}`
            });

        }
    }

    const share = async (product: ProductType,channel:string) =>{
        const productUrl = `${process.env.REACT_APP_APP_URL}/productos/${product.handleUrl}`;
        if(isMobile){
            try{
                await Share.share({
                    title: 'Farmacia Nbio',
                    text: `Farmacia Nbio | ${product.name}`,
                    url: `${process.env.REACT_APP_APP_URL}/productos/${product.handleUrl}`,
                    dialogTitle: 'Comparte este producto de Nbio.',
                });
            }catch (e) {
                console.log(e);
            }
        }
        else if(channel === 'facebook') {
            const url = `https://www.facebook.com/dialog/share?app_id=1011759540268928&display=popup&href=${productUrl}`;
            AppLauncher.openUrl({url: url});
        }else if( channel === 'x') {
            const url = `https://twitter.com/intent/tweet?text=${process.env.REACT_APP_APP_URL}/productos/${productUrl}`
            AppLauncher.openUrl({url: url});
        }else if( channel === 'email'){
            const url = `mailto:?body=${productUrl}`;
            AppLauncher.openUrl({url: url});
        }
        setIsShareModalOpen(false);


    }
    const copyLink = (id:string) => {
        // this.setState({tooltip:'Copiado!'})
        const copyText = document.getElementById(id);
        // @ts-ignore
        copyText.select();
        // @ts-ignore
        navigator.clipboard.writeText(copyText.value);
        setIsShareModalOpen(false);
        setIsShareToastOpen(true);
    }

    const handleShareButton = (product:ProductType,e:any) =>{
        if(!isMobile){
            openSharePopover(e);
        }else{
            share(product,'');
        }
    }

    const renderShareButton = () => {
        return(
            <>
                <div className={'p-share'}>
                    <IonButton id={'share-menu'} color={'black'} fill={'clear'} onClick={(e) => handleShareButton(product,e)}>
                        <IonIcon icon={shareSocialOutline} slot={'icon-only'}></IonIcon>
                    </IonButton>
                </div>
                { !isMobile ?
                    <IonPopover onDidDismiss={() => setIsShareModalOpen(false)} ref={sharePopover}
                                isOpen={isShareModalOpen}>

                        <IonContent>
                            <IonList style={{cursor:'pointer'}}>
                                <IonItem onClick={() => share(product,'facebook')}>
                                    <IonIcon icon={logoFacebook}>
                                    </IonIcon>
                                    &nbsp;Facebook
                                </IonItem>
                                <IonItem onClick={() => share(product,'x')}>
                                    <IonIcon icon={logoTwitter}>
                                    </IonIcon>
                                    &nbsp;Twitter / X
                                </IonItem>
                                <IonItem onClick={() => share(product,'email')}>
                                    <IonIcon icon={mailOutline}>
                                    </IonIcon>
                                    &nbsp;E-mail
                                </IonItem>
                                <IonItem onClick={() => copyLink('product_share_link')}>
                                    <IonIcon icon={linkOutline}>
                                    </IonIcon>
                                    &nbsp;Copiar enlace
                                </IonItem>
                                <input type={'hidden'} value={`${process.env.REACT_APP_APP_URL}/productos/${product?.handleUrl}`} id={'product_share_link'}></input>
                            </IonList>
                        </IonContent>
                    </IonPopover> : null
                }
            </>
        )
    }

    const renderAddToCartBtn = () => {
        return (
            <div className={'wrapper-btn-add-to-cart'}
                 onClick={(ev) => {
                     return false;
                 }}>
                <AddToCartButton product={product} adId={adId}/>
            </div>
        )
    }

    const renderDiscountTag = () => {
        return (
            <div className={'p-discount-tag'}>{discountTag}</div>
        )
    }

    const renderImage = (image:string | undefined,
                         isAd = false,
                         showAddToCartButton = false,
                         showShareButton: boolean = false) => {
        if(image){
            if(isAd){
                return <IonImg src={image}/>
            }else{
                return(
                    <div className={'wrapper-img-product'}>
                        <Link to={`/productos/${product?.handleUrl}`}>
                            <IonImg src={image}/>
                        </Link>
                        {/*show product discount*/}
                        { product.discount ? renderDiscountTag() : null}
                        {/*show the button add to cart, the button on the bottom right*/}
                        { showAddToCartButton ? renderAddToCartBtn() : null }
                        {/*show the button share, the button on the top right*/}
                        { showShareButton ? renderShareButton() : null}
                    </div>
                )
            }
        } else{
            return <IonSkeletonText className={"product-skeleton"} animated={true} />
        }
    }

    const renderDiscount = () => {
        return(
            <IonNote color={'danger'} className={'ion-margin-start'}>{discountTag} OFF</IonNote>
        )
    }

    const renderPrice = () => {
        const  hasDiscount = product.discount;

        return(
            <div className={'product-price'}>
                <IonCardTitle className={'product-price-color'}>
                    {money(product.price)}
                    { hasDiscount ? renderDiscount() : null}
                </IonCardTitle>
            </div>
        )
    }

    const renderOldPrice = () => {
        const  hasDiscount = product.discount;
        if( hasDiscount ){
            return(
                <div className={'ion-margin-top ion-margin-bottom'}>Precio Anterior: {money(product.originalPrice)}</div>
            )
        }else{
            return(
                <div className={'product-dummy-div ion-margin-top ion-margin-bottom'}></div>
            )
        }
    }

    const renderCategoryLabel = () => {
        const category = product.categories ? product.categories[0] : '';
        const listName = product?.list?.name;
        return (
            <IonChip color={'danger'}
                        className={'ion-justify-content-start no-margin-left ion-text-uppercase'}>
            {listName || category}
            </IonChip>
        );
    }

    const renderStars = () => {
        return (
            <div className={'product-stars'}>
                <IonIcon icon={starOutline}>
                </IonIcon>
                <IonIcon icon={starOutline}>
                </IonIcon>
                <IonIcon icon={starOutline}>
                </IonIcon>
                <IonIcon icon={starOutline}>
                </IonIcon>
                <IonIcon icon={starOutline}>
                </IonIcon>
            </div>
        )
    }

    const renderSKU = () => {
        return <div>SKU: {product.sku}</div>
    }

    const renderBrand = () => {
        if(brand?.name){
            return(
                <Link className={''}
                      to={`/marcas/${brand.handleUrl}`}>Visita todos los productos de &nbsp;
                    <IonText className={'product-brand-label'}>{brand.name}</IonText>
                </Link>
            )
        }else{
            return(
                <div className={'product-dummy-div'}></div>
            )
        }
    }

    const renderEta = () => {
        if(eta?.formattedEta){
            return(
                <div className={'ion-text-capitalize ion-margin-top ion-margin-bottom'}>
                    <IonText color={'primary'}><b>Llega: {eta.formattedEta}</b></IonText>
                </div>
            )
        }else{
            return(
                <div className={'product-dummy-div ion-margin-top ion-margin-bottom'}></div>
            )
        }
    }
    const openPopover = (e: any) => {
        popover.current!.event = e;
        setIsPopover1Open(true);
    };
    const openPopover2 = (e: any) => {
        popover2.current!.event = e;
        setIsPopover2Open(true);
    };
    const openPopover3 = (e: any) => {
        popover3.current!.event = e;
        setIsPopover3Open(true);
    };
    const openSharePopover = (e: any) => {
        sharePopover.current!.event = e;
        setIsShareModalOpen(true);
    };

    const renderInfo = () => {
        return(
            <IonCardHeader>
                <Link to={`/productos/${product?.handleUrl}`}>
                    <div className={`ion-text-wrap  ${isWeb?  'product-title-web' : 'product-title'}`} >
                        {product.name}
                    </div>
                </Link>
                {renderBrand()}
                {renderSKU()}
                {renderStars()}

                <div className={'hr-custom'}></div>

                {renderCategoryLabel()}
                {renderOldPrice()}
                {renderPrice()}
                {renderEta()}

                <AddAmountToCart product={product} adId={adId}/>

                <BuyItNowButton product={product}  classBtn={'ion-margin-top ion-margin-bottom' } adId={adId} />
                {/*Start Popovers*/}
                <IonPopover ref={popover} isOpen={isPopover1Open}
                            onDidDismiss={() =>setIsPopover1Open(false)}>
                    <IonContent class="ion-padding">
                        <p className={'ion-no-margin'}><b>Garantía de Entrega:</b></p>
                        <p className={'no-margin-bottom'}>
                            Nos preocupamos por llegar a tiempo, sin embargo, a veces suceden cosas que nos impiden lograrlo,
                            por eso todas las entregas al interior de la República Mexicana van garantizadas con devolución
                            del 100% del monto de la compra si exceden los 15 días naturales desde su solicitud.
                        </p>
                    </IonContent>
                </IonPopover>
                <IonPopover  ref={popover2} isOpen={isPopover2Open}
                             onDidDismiss={() =>setIsPopover2Open(false)}>
                    <IonContent class="ion-padding">
                        <p className={'ion-no-margin'}><b>Pagos Seguros:</b></p>
                        <p  className={'no-margin-bottom'}>
                            Para garantizar tu tranquilidad, procesamos nuestros pagos a través de MercadoPago.
                            <br/>
                            Además habilitamos diversos Métodos de pago para tu comodidad.
                        </p>
                    </IonContent>
                </IonPopover>
                <IonPopover  ref={popover3} isOpen={isPopover3Open}
                             onDidDismiss={() =>setIsPopover3Open(false)}>
                    <IonContent class="ion-padding">
                        <p className={'ion-no-margin'}><b>Atención a Clientes:</b></p>
                        <p  className={'no-margin-bottom'}>
                            ¿Necesitas ayuda con algo?
                            <br/>
                            Estamos disponibles 24/7 para ti
                            <br/>
                            <a href={'tel:+525641674170'} target={'_blank'}>
                                Tel. 56-4167-4170
                            </a>
                        </p>
                    </IonContent>
                </IonPopover>
                {/*Ends Popovers*/}

                <div className={'wrapper-triggers-popovers'}>
                    <IonButton id={`sp-one`} size={'small'} color={'dark'} className={'no-margin-left'}
                               fill={'clear'} onClick={openPopover}>
                        <small>
                            Garantía de entrega
                        </small>
                    </IonButton>

                    <IonButton id={`sp-two`} size={'small'} color={'dark'} className={'no-margin-left'}
                               fill={'clear'} onClick={openPopover2}>
                        <small>
                            Pagos Seguros y Flexibles
                        </small>
                    </IonButton>

                    <IonButton id={`sp-three`} size={'small'} color={'dark'} className={'no-margin-left'}
                               fill={'clear'} onClick={openPopover3}>
                        <small>
                            Atención a Clientes
                        </small>
                    </IonButton>
                </div>
            </IonCardHeader>
        )
    }

    const renderInfoMobile = () => {
        return(
            <IonCardHeader>
                {product.discount ? renderOldPrice() : null}
                {renderPrice()}
                {renderEta()}
                <AddAmountToCart product={product} adId={adId} style={'row'}/>
            </IonCardHeader>
        )
    }

    const renderInfoInList = () => {
        return(
            <IonCardHeader className={'padding-half'}>
                <Link to={`/productos/${product?.handleUrl}`}>
                    <div className={`ion-text-wrap  ${isWeb?  'product-title-web' : 'product-title'}`} >
                        {product.name}
                    </div>
                    <div className={'product-category-start-wrapper'}>
                        {renderCategoryLabel()}
                        {renderStars()}
                    </div>
                    {renderPrice()}
                </Link>
            </IonCardHeader>
        )
    }

    //-----------------------------------------------------------------------------------------------------------------
    //Render if the item is recommended product
    if(isRecommended){
        return(
            <IonCard button className={classCard}>
                {renderImage(product.images[0])}
                <IonCardHeader className={'padding-half'}>
                    <IonButton onClick={() => onClickCard()}
                               size={'small'}
                               fill={'outline'}
                               expand={'block'}
                               class={'ion-text-uppercase btn-on-dark-primary-outline'}
                               routerLink={`/productos/${product?.handleUrl}`}
                    >
                        Ver más
                    </IonButton>
                </IonCardHeader>
            </IonCard>
        )
    }

    //-----------------------------------------------------------------------------------------------------------------
    //Render if the item is an Ad
    if(product.isAd){
        const  title = product.title || 'Promocionado';
        return (
            <IonCard button className={classCard} onClick={() => onAdClick(product)}>
                {renderImage(product.image, true)}
                <IonCardHeader className={'padding-half'}>
                    <div className={"ion-text-wrap product-title"} >
                        {title}
                    </div>
                    <div className={"product-category-start-wrapper"} >
                    {/*NOT REMOVE*/}
                    </div>
                    <IonCardSubtitle color="medium" className={'product-price ion-no-margin'}>
                        Promocionado
                    </IonCardSubtitle>
                </IonCardHeader>
            </IonCard>
        )
    }

    //-----------------------------------------------------------------------------------------------------------------
    //Render if the product is in a list
    if (isInList) {
        return(
            <IonCard button className={classCard} onClick={() => onClickCard()}>
                { renderImage(product.images[0],false, true) }
                { renderInfoInList() }
            </IonCard>
        )
    }

    const renderInfoContent = () => {
        if(hideInfo){
            return null
        }else if(isMobileView){
            return renderInfoMobile()
        }else{
            return renderInfo()
        }
    }

    return(
        <IonCard button className={classCard} onClick={() => onClickCard()}>
            { !hideImage ? renderImage(product.images[0],false, false, true) : null }
            { renderInfoContent()}
            <IonToast isOpen={isShareToastOpen}
                      message={'Enlace copiado al portapapeles'}
                      animated={true}
                      duration={2000}
                      onDidDismiss={() => setIsShareToastOpen(false)}
            />
        </IonCard>
    )
}

export default Product;
