/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * July 2022
 */
import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonCard,
    IonItem,
    IonLabel,
    IonIcon,
    IonCol,
    IonProgressBar, IonToast, IonSelect, IonSelectOption
} from '@ionic/react';
import {Camera, CameraResultType} from '@capacitor/camera';
import {useHistory, useLocation} from "react-router";
import {camera, close} from "ionicons/icons";
import API from "../../lib/api/NbioApi";
import Cart from "../../lib/cart/Cart";
import axios from "axios";

//style
import "../../css/pages/prescription.css"

//components
import ImageSelector from "../ui/ImageSelector";

interface IdUploadProps {
    onFrontImageSelected: any,
    onBackImageSelected: any,
    onDocumentTypeSelected: any,
    cartItem: any
};


const IdUpload: React.FC<IdUploadProps> = (
    {
        onFrontImageSelected = () => {
        },
        onBackImageSelected = () => {
        },
        onDocumentTypeSelected = () => {
        },
        cartItem = {}
    }
) => {
    const [imageFrontUrl, setImageFrontUrl] = useState('');
    const [imageBackUrl, setImageBackUrl] = useState('');
    const [documentType, setDocumentType] = useState('INE');
    const [loadSavedImage, setLoadSavedImage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState('');
    const [photoIds, setPhotoIds] = useState([]);
    const [photoIdsBack, setPhotoIdsBack] = useState([]);

    const [showFrontImageSelector, setShowFrontImageSelector] = useState(false);
    const [showBackImageSelector, setShowBackImageSelector] = useState(false);
    const loadSavedImages = async () =>{
        try{
            let imageFrontUrl   = await axios.get(cartItem.photoId.imageFrontUrl, {responseType: 'arraybuffer'});
            const imageFrontUrlBase64 = Buffer.from(imageFrontUrl.data).toString('base64');
            let imageBackUrl   = await axios.get(cartItem.photoId.imageBackUrl, {responseType: 'arraybuffer'});
            const imageBackUrlBase64 = Buffer.from(imageBackUrl.data).toString('base64');
            setImageFrontUrl(imageFrontUrlBase64);
            setImageBackUrl(imageBackUrlBase64);
        }catch(ex){

        }


    }
    useEffect(() => {
        if(cartItem){
            if(cartItem.photoId){
                loadSavedImages();
            }
        }
        loadPhotoIds();
    }, [])
    const validate = () => {
        if (documentType === 'INE') {
            if (!imageFrontUrl) {
                return {
                    isValid: false,
                    error: 'Agrega la imagen frontal de tu identificación'
                }
            }
            if (!imageBackUrl) {
                return {
                    isValid: false,
                    error: 'Agrega el reverso de tu identificación'
                }
            }
        } else if (documentType === 'pasaporte') {
            if (!imageFrontUrl) {
                return {
                    isValid: false,
                    error: 'Agrega la imagen frontal de tu identificación'
                }
            }
        } else {
            return {
                isValid: false,
                error: 'Elige un tipo de identificación'
            }
        }

        return {
            isValid: true,
            error: ''
        }
    }

    const saveIdImages = () => {
        //validate
        const _v = validate();

    }

    const _takePicture = async (id: any) => {
        const image = await Camera.getPhoto({
            quality: 90,
            resultType: CameraResultType.Base64,
            promptLabelHeader: 'Agregar identificación',
            promptLabelPhoto: 'Seleccionar de la galería',
            promptLabelPicture: 'Tomar una fotografía'
        });
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        const imageBase64 = image.base64String;
        if (imageBase64) {
            if (id === 'imageFrontUrl') {
                setImageFrontUrl(imageBase64);
                onFrontImageSelected({
                    image:imageBase64,
                    documentType:documentType,
                    sourcePhotoId:null
                });
            }
            if (id === 'imageBackUrl') {
                setImageBackUrl(imageBase64);
                onBackImageSelected({
                    image:imageBase64,
                    documentType:documentType,
                    sourcePhotoId:null
                });
            }
        }
    }

    const removePicture = (image: any) => {
        setLoadSavedImage(false);
        if (image === 'imageFrontUrl') {
            setImageFrontUrl('');
        }
        if (image === 'imageBackUrl') {
            setImageBackUrl('');
        }
    }

    const renderAddButton = () => {
        if (isLoading) {
            return (
                <IonProgressBar type="indeterminate"></IonProgressBar>
            )
        } else {
            return (
                <IonButton className="ion-margin-horizontal w-100 ion-text-uppercase"
                           onClick={saveIdImages}
                           color="primary"
                           expand="block"
                           disabled={loadSavedImage}
                           fill='solid'>SUBIR IDENTIFICACIÓN</IonButton>
            )
        }
    }

    const renderId = (img: string, id: any) => {
        if (id === 'imageBackUrl' && documentType === 'pasaporte') {
            return null
        } else {
            if (img) {
                let imgSrc = `data:image/jpeg;base64,${img}`
                return (
                    <div className={'wrapper-image-prescription'}>
                        <img className="w-100" src={imgSrc} alt={'prescription-image'}>
                        </img>
                        <IonButton className="fab-camera-prescription-btn"
                                   fill="clear"
                                   shape="round"
                                   size="large"
                                   color={"danger"}
                                   strong
                                   onClick={() => removePicture(id)}>
                            <IonIcon slot={"icon-only"} icon={close}></IonIcon>
                        </IonButton>
                    </div>
                )
            }
        }
    }
    const onSelectedPhotoId = async (photoId: any,side = 'front') => {
        let imageUrl   = await axios.get(side === 'front' ? photoId.imageFrontUrl : photoId.imageBackUrl, {responseType: 'arraybuffer'});
        const imageBase64 = Buffer.from(imageUrl.data).toString('base64');
        if(side === 'front'){
            setImageFrontUrl(imageBase64);
            onFrontImageSelected({
                image:imageBase64,
                documentType:documentType,
                sourcePhotoId:photoId
            });
            if(photoId.imageBackUrl) {
                let imageBack = await axios.get(photoId.imageBackUrl, {responseType: 'arraybuffer'});
                const imageBackBase64 = Buffer.from(imageBack.data).toString('base64');
                setImageBackUrl(imageBackBase64);
                onBackImageSelected({
                    image: imageBackBase64,
                    documentType: documentType,
                    sourcePhotoId: photoId
                });
            }
        }else{
            setImageBackUrl(imageBase64);
            onBackImageSelected({
                image:imageBase64,
                documentType:documentType,
                sourcePhotoId:photoId
            })
        }

    }
    const renderImageSelector = (id: any) =>{
        return(
            <ImageSelector images={photoIds}
                           title={'Selecciona parte frontal'}
                           onSelect={(photoId: any) => onSelectedPhotoId(photoId,'front')}
                           onSelectUploadImage={async () => {
                               return _takePicture(id);
                           }}>
            </ImageSelector>
        )
    }
    const renderUploadBtn = (id: any) => {
        if (!imageFrontUrl) {
            if(showFrontImageSelector){
                return renderImageSelector(id);
            }
            return (
                    <IonButton expand={'block'}
                               fill={"outline"}
                               className={'upload-image-btn ion-margin-top ion-margin-bottom'}
                               onClick={() => {
                                   if (photoIds.length === 0) {
                                       return _takePicture(id);
                                   } else {
                                       setShowFrontImageSelector(true);
                                   }
                               }
                               }
                    >
                        <IonIcon slot={"icon-only"} icon={camera}></IonIcon>
                        Agregar parte frontal
                    </IonButton>


            )
        }
    }
    const renderUploadBtnBack = (id: any) => {
        if (imageFrontUrl && !imageBackUrl) {
            if(showBackImageSelector){
                return renderUploadBackImage(id);
            }
            return (
                <IonButton expand={'block'}
                           fill={"outline"}
                           className={'upload-image-btn ion-margin-top ion-margin-bottom'}
                           onClick={() => {
                               if (photoIds.length === 0) {
                                   return _takePicture(id);
                               } else {
                                   setShowBackImageSelector(true);
                               }
                           }
                           }
                >
                    <IonIcon slot={"icon-only"} icon={camera}></IonIcon>
                    Agregar reverso
                </IonButton>


            )
        }
    }
    const renderUploadBackImage = (id: any) => {
        if (!imageBackUrl && imageFrontUrl && documentType === 'INE') {
            return (
                <ImageSelector images={photoIdsBack}
                               title={'Selecciona reverso'}
                               onSelect={(photoId: any) => onSelectedPhotoId(photoId, 'back')}
                               onSelectUploadImage={async () => {
                                   return _takePicture(id);
                               }}>
                </ImageSelector>
            )
        }
    }
    const loadPhotoIds = () => {
        const cartId = Cart.getCartId() || '';
        API.cart.getPhotoIds(cartId).then((res) => {
            let photoIds:any = [];
            let photoIdsBack:any = [];
            res.data.photoIds.forEach((item:any) =>{
                const i = {...item};
                i.imageThumbnailUrl = item.imageFrontUrl;
                photoIds.push(i);
                const ii = {...item};
                ii.imageThumbnailUrl = item.imageBackUrl;
                photoIdsBack.push(ii);
            });
            setPhotoIds(photoIds);
            setPhotoIdsBack(photoIdsBack);
        }).catch((ex) => {
        })
    }
    return (
        <IonCol size-md="6">
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toastMsg}
                duration={1000}/>

            <IonCard className={'ion-padding ion-no-margin ion-margin-bottom'}>
                <IonItem lines={'none'} className={'bg-transparent no-padding-inner-horizontal'}>
                    <IonLabel>Identificación: </IonLabel>
                    <IonSelect interface="popover"
                               value={documentType}
                               onIonChange={(ev) => {
                                   setDocumentType(ev.detail.value);
                                   onDocumentTypeSelected(ev.detail.value);
                               }}
                               placeholder="Selecciona el tipo de identificación">
                        <IonSelectOption value="INE">INE</IonSelectOption>
                        <IonSelectOption value="pasaporte">Pasaporte</IonSelectOption>
                    </IonSelect>
                </IonItem>
            </IonCard>
            <IonCard className={'ion-padding ion-no-margin ion-margin-bottom'}>
                {renderId(imageFrontUrl, 'imageFrontUrl')}
                {renderId(imageBackUrl, 'imageBackUrl')}
                {renderUploadBtn('imageFrontUrl')}
                {renderUploadBtnBack('imageBackUrl')}
            </IonCard>
        </IonCol>
    )
}

export default IdUpload;
