/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2023
 */

import {
    IonButton,
    IonIcon,
    IonLabel, IonSpinner,
    IonToast,
} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router";
import Cart from "../../lib/cart/Cart";
import {CartItemType} from "../../lib/data_types/dataTypes";
import {cart as cartIcon, closeOutline} from "ionicons/icons";
import {AppContext} from "../../App";
import {EVENTS} from "../../lib/events/events";

//style
import "../../css/components/cartPopup.css";

//components
import CartProduct from "./CartProduct";
import FreeDeliveryProgressBar from '../checkout/FreeDeliveryProgressBar';
import buyNowForwarder from "../../lib/buy-now-forwarder/buy-now-forwarder";



interface CartPopupProps {
    showPopup:boolean;
    onDismiss?: any;
    referenceElement?: string;
    onClickClosePopup?: any;
    isLoading?: boolean
}

const CartPopup: React.FC<CartPopupProps > = ({
                                                showPopup =false,
                                                onDismiss = () =>{},
                                                referenceElement = 'icon-cart',
                                                onClickClosePopup = () => {},
                                                  isLoading = false

}) => {
    const history = useHistory();
    const {user, setUser, isAuth, setIsAuth} = useContext(AppContext);


    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState('');
    const [isAddingToCart,setIsAddingToCart] = useState(false);
    const [left,setLeft] = useState(0);
    const [cart,setCart] = useState({id:'', photoId:{}, items:[]});
    const [countItems,setCountItems] = useState(0);
    const [subTotal,setSubTotal] = useState(0);
    const [itemAddedToCart,setItemAddedToCart] = useState({
        _id: null
    });

    useEffect(() =>{
        if(showPopup){
            positionCartIcon();
            loadCart();
        }
    },[showPopup]);

    useEffect(() =>{
        onResize();
        loadCart();
    },[]);

    useEffect(() =>{
        window.addEventListener(EVENTS.UPDATE_CART, loadCart);
        window.addEventListener(EVENTS.ADD_TO_CART, loadCart);
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener(EVENTS.UPDATE_CART, loadCart);
            window.removeEventListener(EVENTS.ADD_TO_CART, loadCart);
            window.removeEventListener('resize', onResize);
        }
    },[]);
    const onResize = () =>{
        // 991 hide
        if(window.innerWidth <= 991){
            onClickClosePopup();
        }
        positionCartIcon();
    }
    const loadCart = () =>{
        Cart.getCart().then(res => {
            setCart(res.data.cart);
            setCountItems(res.data.countItems);
            setSubTotal(res.data.totalItems);
        }).catch((ex) => {
            setCart({
                id:'',
                photoId:{},
                items:[]
            });
            setCountItems(0);
            setToastMsg('');
            setShowToast(false);
        })
    }


    const waitTime = async(time:number) =>{
        return new Promise((resolve) =>{
            resolve(true);
        })
    }

    const onClickBuy = async () => {
        const cart = await Cart.getCart();
        const cartI = cart.data?.cart?.items;
        if(cartI?.length <= 0 ){
            // do nothing
            setToastMsg( 'Agrega productos al carrito para continuar.');
            setShowToast(true);
            return null;
        }else{
            //Redirect to checkout, if the cart doesn't contain a controlled product
            onClickClosePopup();
            const buyNowUrl = await buyNowForwarder.buyNowForwarder();
            if (buyNowUrl) {
                history.push(buyNowUrl);
            } else {
                // TODO: Handle error
                setToastMsg( 'Hubo un problema al procesar la compra');
                setShowToast(true);
            }
        }

        // Cart.getCart().then(res => {
        //     const cartI = res.data.cart.items;
        //     let isPrescriptionRequired = true;
        //
        //     isPrescriptionRequired = cartI.some( (item: any) => {
        //         if(item.isControlled){
        //             return !item.prescription;
        //         }
        //         return false
        //     })
        //     console.log('CAN_BUY_WITHOUT_ACCOUNT',process.env.REACT_APP_CAN_BUY_WITHOUT_ACCOUNT);
        //     if(isPrescriptionRequired) {
        //         history.push('/carrito');
        //     }else if(process.env.REACT_APP_CAN_BUY_WITHOUT_ACCOUNT){
        //         history.push('/confirmar-pedido');
        //     }else if(!process.env.REACT_APP_CAN_BUY_WITHOUT_ACCOUNT){
        //         history.push('/carrito');
        //     }
        // }).catch((ex) => {
        // })
    }
    const onClickGoToCart = () => {
        //Redirect to checkout, if the cart doesn't contain a controlled product
        onClickClosePopup();
        history.push('/carrito');
    }

    const onDeleteItem = (product:CartItemType) =>{
        Cart.removeProduct(product.cartItemId).then((res) =>{
            loadCart();
            setToastMsg('Producto eliminado');
            setShowToast(true);
        }).catch((ex) =>{
        })
    }

    const onDecreaseQty = (product:CartItemType) =>{
        let qty = product.quantity - 1 <= 1 ? 1 : product.quantity - 1;
        Cart.updateProduct(product.cartItemId,qty).then((res) =>{
            loadCart();
            setToastMsg('Producto eliminado');
            setShowToast(true);
        }).catch((ex) =>{
        })
    }

    const onIncreaseQty = (product:CartItemType) => {
        let qty = product.quantity + 1 >= 20 ? 20 : product.quantity + 1;
        Cart.updateProduct(product.cartItemId,qty).then((res) =>{
            loadCart();
            setToastMsg('Producto agregado');
            setShowToast(true);
        }).catch((ex) =>{
        })
    }

    const onAddPrescription = (product:CartItemType) =>{
        history.push({
            pathname:'/receta',
            state:{cartItem:product}
        })
    }

    const positionCartIcon = () => {
        const cartIcons = document.getElementsByClassName(referenceElement);
        if (cartIcons.length > 0) {
            // @ts-ignore
            const bBox = cartIcons[cartIcons.length - 1].getBoundingClientRect();
            if (bBox) {
                // @ts-ignore
                const bLeft = bBox.right - 400;
                // console.log('cartIcon', cartIcon);
                setLeft(bLeft);
            }
        }

    }

    const cartItems = cart.items ? cart.items : [];
    const renderEmptyCartMessage = (products:any) =>{
        if(isLoading){
            return (
                <div className={'ion-padding ion-text-center'}>
                    <IonSpinner style={{width:'200px',height:'200px'}} name={'dots'}></IonSpinner>
                </div>
            )
        }
        if(products.length === 0){
            return (
                <div className={'ion-padding ion-text-center'}>
                    Tu carrito está vacío.
                    <br/>
                    <br/>

                    <IonIcon icon={cartIcon} color={'medium'} size={'large'} slot={'icon-only'}></IonIcon>
                </div>
            )
        }
    }
    return (
        <div className={showPopup ? 'cart-popup-wrapper-open' : 'cart-popup-wrapper-closed'}
             onClick={() => onClickClosePopup()}>
            <IonToast isOpen={showToast}
                      onDidDismiss={() => {setShowToast(false); setToastMsg('')}}
                      message={toastMsg}
                      duration={2000} />
            {
                showPopup ?
                    <div className={"cart-popup"} style={{left:left}}
                         onClick={(ev) => (ev.stopPropagation())}>
                        <div className={'cart-popup-header'}>
                            <IonLabel><b>Productos </b>{countItems ? `(${countItems})` : '(0)'}</IonLabel>
                            <IonButton fill={'clear'}
                                       color={'medium'}
                                       size={'small'}
                                       onClick={() => onClickClosePopup()}>
                                <IonIcon icon={closeOutline}></IonIcon>
                            </IonButton>
                        </div>
                        <FreeDeliveryProgressBar total={subTotal} naked={true}></FreeDeliveryProgressBar>
                        <div className={"cart-popup-scroll"}>
                            {renderEmptyCartMessage(cartItems)}
                            {
                                cartItems.slice().reverse().map((item: any, index) => {
                                    return (<CartProduct key={`${item._id}`}
                                                         product={item}
                                                         onDelete={onDeleteItem}
                                                         onIncreaseQty={onIncreaseQty}
                                                         onDecreaseQty={onDecreaseQty}
                                                         onAddPrescription={(item:any) => onAddPrescription(item)}>
                                    </CartProduct>)
                                })
                            }
                        </div>
                        <div className={'cart-popup-footer'}>
                            <IonButton className="flex-1"
                                       color={'primary'}
                                       size={'small'}
                                       onClick={() => onClickBuy()}>
                                Comprar
                            </IonButton>
                        </div>
                    </div>
                :null
            }
        </div>
    )
}
export default CartPopup;

