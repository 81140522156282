/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * Nov 2024
 */
import React from 'react';
import {
    IonRow,
    IonCol,
    IonCard,
    IonCardContent
} from '@ionic/react';
const PrivacyPolicyContent: React.FC<{ sizeMd: string }> = ({ sizeMd = '6' }) =>{
        return (
            <IonRow className={"h-100 ion-justify-content-center"}>
                <IonCol size-md={sizeMd} className={"flex-col ion-justify-content-between"}>

                    <IonCard className={'ion-no-margin'}>
                        <IonCardContent>
                            <h1>Aviso de Privacidad (Política de privacidad)</h1>
                            <br/>
                            <p className={'ion-text-justify'}>
                                De conformidad con lo dispuesto por la Ley Federal de Protección de Datos Personales
                                en Posesión de los Particulares, su Reglamento y diversa normativa vigente y
                                relacionada (la “Legislación de Datos Personales”), se emite el presente Aviso de Privacidad.
                            </p>
                            <br/>
                            <br/>
                            {/*---------------------------------------------------------------------------------*/}
                            <h1> 1. Identidad y domicilio de los responsables.</h1>
                            <br/>
                            <p className={'ion-text-justify'}>
                                NBIO Healthcare Logistics ® como marca registrada de ENVIALO MEXICO, S.A. De C.V., a
                                quien identificaremos en este aviso de privacidad como “NBIO” (“El responsable”), obtiene, usa
                                y almacena (en lo sucesivo el “Tratamiento”) los datos personales de usted como
                                titular de los mismos (“Usted”, el “Titular” o “Usuario”), mismo que cuenta con
                                domicilio para efectos del presente Aviso de Privacidad en Rancho El Palmar 53. Col. Campestre
                                Coyoacán, Delegación Coyoacán, CDMX.
                            </p>
                            <br/>
                            <br/>
                            {/*---------------------------------------------------------------------------------*/}
                            {/*---------------------------------------------------------------------------------*/}
                            <h1>2. Tratamiento de los Datos Personales.</h1>
                            <br/>
                            <p className={'ion-text-justify'}>
                                NBIO será responsable de los datos personales que recabe a través de la aplicación
                                denominada “NBIO” o “NBIO Healthcare Logistics”, dentro del App NBIO, (el “App NBIO”), su
                                página de internet <a href={'https://www.nbio.mx'}>https://www.nbio.mx </a> (el “Sitio NBIO”) y sus
                                redes sociales y blogs (las “Redes Sociales NBIO”), también podrá recabar sus
                                datos personales a través de cualquier otro contacto con Usted, de terceros y
                                de otras fuentes permitidas por ley, tales como terceros, autoridades y sociedades
                                de información crediticia y cada uno será responsable del Tratamiento de los mismos.
                                En caso de que haya proporcionado datos de contacto de terceros como
                                referencias, autorizados, cotitulares, cónyuges, beneficiarios, coacreditados, referencias, garantes, obligados solidarios, avales, fiadores, tutores, proveedores, beneficiarios y demás figuras legales relacionadas con productos y/o servicios del Responsable, Usted garantiza que cuenta con su autorización para proporcionar dichos datos, incluyendo en su caso los datos personales aplicables, y es responsable de comunicar a dichas personas sobre los términos y razones por las cuales los contactaremos y, en su caso, el Tratamiento de sus datos personales conforme al presente Aviso de Privacidad, así como los medios para conocer el contenido íntegro del mismo. Adicionalmente, mediante su consentimiento al presente Aviso de Privacidad, usted ratifica su autorización para que el Responsable contacte a dichas personas.
                            </p>
                            <br/>
                            <br/>
                            {/*---------------------------------------------------------------------------------*/}
                            {/*---------------------------------------------------------------------------------*/}
                            <h1>3. Datos personales Tratados por el Responsable.</h1>
                            <br/>
                            <p className={'ion-text-justify'}>
                                Para las finalidades indicadas en el presente Aviso de Privacidad, trataremos
                                las siguientes categorías de datos personales y datos personales específicos:
                                <br/>
                                <ul>
                                    <li>Datos de identificación y contacto (Nombre completo, dirección, teléfono
                                        de contacto, código postal, ciudad, número de identificación oficial, CURP).</li>
                                    <li>Productos solicitados.</li>
                                    <li>Datos para facturación (Dirección fiscal, Registro Federal de contribuyentes RFC).</li>
                                    <li>Datos financieros y patrimoniales.</li>
                                    <li>Identificadores en línea.</li>
                                    <li>Intereses del Usuario.</li>
                                    <li>Datos laborales.</li>
                                    <li>Datos migratorios.</li>
                                    <li>Interacciones con perfiles y comportamientos en las Redes Sociales, los
                                        Sitios y la App.</li>
                                    <li>Datos de localización.</li>
                                    <li>Llamadas y mensajes en nuestros centros de atención a clientes (call
                                        centers o chats en línea) o mediante correo electrónico.</li>
                                </ul>

                                Con la finalidad de cumplir con la legislación en materia de salud, recabaremos
                                información de carácter sensible, dicha información será recabada al procesar
                                los pedidos con receta médica, la cual deberá cumplir de manera estricta y debida
                                con los elementos establecidos en la Ley General de Salud y el Reglamento
                                de Insumos para la Salud, en virtud del control de medicamentos que requieren
                                prescripción médica y fundamentado en la Ley General de Salud Art. 226.
                                Dicha información se enlista a continuación:

                                <ul>
                                    <li>Padecimientos.</li>
                                    <li>Estudios clínicos.</li>
                                    <li>Padecimientos previos.</li>
                                    <li>Dosis del medicamento.</li>
                                    <li>Vía de administración.</li>
                                    <li>Frecuencia de consumo.</li>
                                    <li>Tiempo de administración del medicamento.</li>
                                    <li>Médico que prescribe la receta y/o brinda la atención.</li>
                                    <li>Estatura.</li>
                                    <li>Peso.</li>
                                    <li>Edad.</li>
                                    <li>Receta médica en cualquier modalidad (especial u ordinaria) y en cualquier formato.</li>
                                </ul>

                                Asimismo, hacemos de su conocimiento que dicha información sensible cuenta
                                con mayores medidas de seguridad, técnicas, físicas y administrativas para
                                su debido tratamiento y confidencialidad.
                            </p>
                            <br/>
                            <br/>
                            {/*---------------------------------------------------------------------------------*/}
                            {/*---------------------------------------------------------------------------------*/}
                            <h1>4. Finalidades del Tratamiento.</h1>
                            <br/>
                            <p className={'ion-text-justify'}>
                                Trataremos los datos personales para las siguientes finalidades primarias y necesarias:
                                <ul>
                                    <li>Mantener, desarrollar, administrar, controlar y cumplir con la relación
                                        contractual derivada de la solicitud y/o contratación de nuestros
                                        productos y/o servicios, incluyendo cualquier operación o servicio financiero.</li>
                                    <li>Exhibir y/o comercializar productos y/o servicios personalizados en
                                        nuestros Sitios y App conforme a los intereses y necesidades particulares del Usuario.</li>
                                    <li>Crear, ordenar, catalogar, clasificar, dividir, separar y almacenar
                                        datos y expedientes de los Usuarios.</li>
                                    <li>Creación y administración de la cuenta de los Usuarios en nuestros
                                        Sitios y App y verificar, validar y confirmar la identidad de los
                                        Usuarios y la información proporcionada por los mismos, internamente
                                        o a través de cualquier autoridad o tercero.</li>
                                    <li>Proporcionar al Usuario información, comunicaciones y notificaciones
                                        sobre nuestros Sitios y App, nuestros productos y/o servicios y los
                                        productos y servicios de terceros exhibidos en nuestros Sitios y Apps
                                        y los términos y condiciones aplicables a los mismos.</li>
                                    <li>Administrar y procesar pagos, operaciones y transacciones de los
                                        Usuarios que utilicen nuestros Sitios y App.</li>
                                    <li>Administrar, desarrollar, dar mantenimiento, personalizar y mejorar
                                        nuestros Sitios y App, nuestros productos y/o servicios y sistemas
                                        operativos y realizar las actividades necesarias para que éstos
                                        funcionen de manera óptima y correcta, así como prevenir o detectar
                                        fraudes o abusos.</li>
                                    <li>Realizar estrategias de mercado y elaboración de segmentación de mercados.</li>
                                    <li>Realizar encuestas de satisfacción de nuestros productos y/o servicios.</li>
                                    <li>El ofrecimiento o reconocimiento de ofertas, promociones y beneficios
                                        de programas de lealtad y beneficios del Responsable y sus afiliados
                                        y/o terceros.</li>
                                    <li>Aplicar procesos de disociación a los datos personales y los de
                                        carácter sensible previo a su tratamiento para su almacenamiento
                                        en bases de datos estadísticas con fines comerciales.</li>
                                    <li>Resguardo de los datos personales de carácter sensible por el periodo
                                        requerido por la legislación en materia de salud.</li>
                                    <li>Proporcionarle asesoría, aclaraciones, atención de quejas, servicio
                                        al cliente y servicio postventa, así como monitorear la calidad de
                                        los mismos mediante la grabación de llamadas y almacenamiento de
                                        las conversaciones correspondientes.</li>
                                    <li>Cumplir con cualesquiera disposiciones y obligaciones legales, nacionales
                                        o internacionales, aplicables al Responsable, sus subsidiarias
                                        y/o afiliadas, así como para gestionar cualquier proceso judicial o
                                        tramitar y responder cualesquier requerimientos o solicitudes de las autoridades.</li>
                                    <li>Tener acceso a centrales de riesgo o burós de créditos para conocer
                                        su capacidad y comportamiento de pago, así como los estados
                                        financieros de los Usuarios.</li>
                                    <li>Realizar procesos relacionados con restructuras corporativas del
                                        Responsable, tales como la fusión, consolidación, venta, liquidación
                                        o transferencia de activos.</li>
                                    <li>Realizar actividades de cobranza.</li>
                                    <li>Actividades complementarias necesarias para la realización de los fines anteriores.</li>
                                </ul>
                                Trataremos los datos personales para las siguientes finalidades secundarias o accesorias:
                                <ol type={'a'}>
                                    <li>Mercadotecnia, publicidad, promoción, prospección comercial, procesos
                                        de referencias, la elaboración, valoración y análisis de perfiles de
                                        los Usuarios para el desarrollo y ofrecimiento de nuevos productos y
                                        servicios, realización de encuestas ajenas a nuestros productos o
                                        servicios, fines analíticos y estadísticos, todos ellos relacionados
                                        con los productos y servicios del Responsable, sus
                                        afiliadas, subsidiarias, aliados y otros terceros; y</li>
                                    <li>Para contactarlo sobre o enviarle información de novedades, noticias, ofertas
                                        del Responsable o terceros, boletines, foros de educación, publicidad
                                        o marketing, ventas a distancia, haciendo uso de medios tales
                                        como, pero sin limitar, correo electrónico, notificaciones PUSH, mensajes
                                        de texto (SMS), mensajería instantánea, llamadas y ventanas de diálogo (pop-ups).
                                    </li>
                                </ol>
                                En caso de que no desee que sus datos personales sean tratados para estas
                                finalidades secundarias o accesorias, Usted puede (i) enviar desde este momento
                                un correo electrónico a <a href={'mailto:datospersonales@nbio.mx'}>
                                datospersonales@nbio.mx</a>, siguiendo el procedimiento
                                a que se refiere el numeral 7 del presente Aviso de Privacidad, o (ii) en el
                                caso del párrafo b. antes mencionado, a través de nuestras Apps.
                            </p>
                            <br/>
                            <br/>
                            {/*---------------------------------------------------------------------------------*/}
                            {/*---------------------------------------------------------------------------------*/}
                            <h1>5. Opciones y medios que le ofrecemos para limitar el uso o divulgación
                                de sus datos personales.</h1>
                            <br/>
                            <p className={'ion-text-justify'}>
                                En todos aquellos casos legalmente procedentes, Usted podrá limitar el uso o
                                divulgación de sus datos personales, presentando una solicitud conforme al
                                procedimiento establecido en el numeral 7 del presente Aviso de Privacidad.
                            </p>
                            <br/>
                            <br/>
                            {/*---------------------------------------------------------------------------------*/}
                            {/*---------------------------------------------------------------------------------*/}
                            <h1>6. Transferencia de datos personales.</h1>
                            <br/>
                            <p className={'ion-text-justify'}>
                                Sus datos personales podrán ser transferidos dentro del territorio nacional
                                o hacia el extranjero (en cumplimiento con la regulación aplicable), conforme a lo siguiente:

                                <ol type={'a'}>
                                    <li>A autoridades, como puede ser de forma enunciativa más no limitativa
                                        el Instituto Nacional Electoral (“INE”), organismos o entidades
                                        gubernamentales, en cumplimiento a o en relación con las obligaciones
                                        contempladas en la legislación aplicable al Responsable, sus subsidiarias
                                        y/o afiliadas, así como en cumplimiento de requerimientos efectuados a las mismas.</li>
                                    <li>A autoridades, como puede ser de forma enunciativa más no limitativa
                                        el INE, organismos o entidades gubernamentales, cuando la transferencia
                                        sea requerida oficialmente y/o necesaria para el reconocimiento, ejercicio
                                        o defensa de un derecho del Responsable, sus subsidiarias y/o afiliadas
                                        en un proceso judicial.</li>
                                    <li>A compañías controladoras y/o afiliadas y/o subsidiarias y/o empresas
                                        relacionadas o pertenecientes al mismo grupo empresarial del
                                        Responsable, que operen bajo los mismos procesos y políticas
                                        internas, con finalidades de mercadotecnia, publicidad, promoción, prospección
                                        comercial, de procesos de referencias, de elaboración, valoración
                                        y análisis de perfiles de los Usuarios, de resguardo centralizado
                                        de la información, de fines analíticos y estadísticos y de registro
                                        histórico de usuarios y de la realización de encuestas referentes
                                        al Responsable, sus subsidiarias y/o afiliadas.</li>
                                    <li>A terceros adquirientes, derivado de una reestructura corporativa
                                        del Responsable, incluyendo, la fusión, consolidación, venta, liquidación
                                        o transferencia de activos, cuando la transferencia sea necesaria para
                                        el mantenimiento o cumplimiento de una relación entre dicho Responsable
                                        y el Titular.</li>
                                    <li>A terceros que exhiben y comercializan productos y/o servicios o
                                        aceptan realizar la gestión de un encargo a través de los Sitios y
                                        de la App, así como a prestadores de servicios y aliados comerciales
                                        incluyendo instituciones financieras, cuando la transferencia sea
                                        necesaria para el mantenimiento o cumplimiento de una relación entre
                                        uno de los Responsables y el Titular o para administrar, desarrollar, dar
                                        mantenimiento, personalizar y mejorar nuestros Sitios y App, nuestros
                                        productos y/o servicios y sistemas operativos y realizar las actividades
                                        necesarias para que éstos funcionen de manera óptima y correcta, así
                                        como prevenir o detectar fraudes o abusos y/o el cumplimiento de
                                        cualquiera de las finalidades descritas en el apartado 4 del
                                        presente Aviso de Privacidad incluyendo.</li>
                                    <li>A terceros que exhiben y comercializan productos y/o servicios o
                                        aceptan realizar la gestión de un encargo a través de los Sitios y
                                        de la App, así como a aliados comerciales incluyendo instituciones
                                        financieras, cuando la transferencia sea necesaria por virtud de un
                                        contrato celebrado o por celebrar en interés del Titular, por el
                                        Responsable y dichos terceros.</li>
                                    <li>A terceros y/o prestadores de servicios para la atención del propio
                                        Titular respecto de los servicios contratados y las actividades
                                        relacionadas con el seguimiento y cumplimiento de las obligaciones
                                        derivadas de los servicios contratados.</li>
                                    m                                             <li>A terceros que exhiben y comercializan servicios y/o productos o
                                    aceptan realizar la gestión de un encargo a través de los Sitios y de la
                                    App a través de los Sitios y de la App, con la finalidad de dar
                                    seguimiento a o realizar actividades relacionadas con cualquier adquisición
                                    y entrega de productos y/o servicios a través de los Sitios y de la
                                    App, así como dar cumplimiento a las obligaciones derivadas de la
                                    relación jurídica entre el Titular y dichos terceros.
                                </li>
                                    <li>Con base en los demás supuestos establecidos en la Legislación de
                                        Datos Personales, que no requieren de su consentimiento.</li>
                                </ol>
                                Con excepción de aquellas mencionadas en el inciso (g) del presente numeral, las
                                transferencias antes mencionadas no requieren de su consentimiento conforme a
                                la Legislación de Datos Personales. Si no manifiesta su negativa para dicha
                                transferencia, lo cual puede realizar desde este momento enviando un correo
                                electrónico al correo electrónico datospersonales@nbio.mx, entenderemos que
                                nos ha otorgado su consentimiento.
                                <br/>
                                <br/>
                                Adicionalmente, en caso que así lo autorice el Usuario mediante los Sitios, la
                                App o cualquier otro medio aplicable, el responsable podrá transferir sus datos
                                personales a terceros que exhiben y comercializan productos y/o servicios a
                                través de los Sitios y de la App, aliados comerciales y otros aliados
                                estratégicos del responsable, para fines mercadológicos, de publicidad o de
                                prospección comercial.
                                <br/>
                                <br/>
                                En cualquier caso de los señalados anteriormente, comunicaremos a los terceros
                                receptores el presente Aviso de Privacidad, con el fin de asegurar que su
                                información se utilice en los términos del mismo.
                            </p>
                            <br/>
                            <br/>
                            {/*---------------------------------------------------------------------------------*/}
                            {/*---------------------------------------------------------------------------------*/}
                            <h1>7. Medios para el ejercicio sus derechos.</h1>
                            <br/>
                            <p className={'ion-text-justify'}>
                                En todos aquellos casos legalmente procedentes, Usted podrá en todo momento
                                y a través del procedimiento establecido en el presente numeral, ejercer
                                ante el Responsable los siguientes derechos referentes a sus datos
                                personales: (i) derechos de Acceso, Rectificación, Cancelación y
                                Oposición (“Derechos ARCO”); (ii) revocar el consentimiento otorgado al
                                Responsable para el tratamiento de sus datos personales; (iii) limitar el
                                uso o divulgación de sus datos personales; y (iv) manifestar su negativa
                                para el tratamiento de sus datos personales con respecto a las finalidades
                                secundarias y accesorias antes mencionadas. Para el ejercicio de dichos
                                derechos referentes a sus datos personales deberá presentar la solicitud
                                correspondiente, mediante el debido llenado del formulario electrónico que
                                ponemos a su disposición y debe de descargar aquí,
                                <br/>
                                enviándolo a la dirección de correo electrónico
                                <a href={'mailto:datospersonales@nbio.mx'}>datospersonales@nbio.mx</a> en
                                formato electrónico y con firma autógrafa al calce del mismo, estableciendo
                                como asunto “Ejercicio de derechos sobre datos personales” y acompañando lo siguiente:
                                <ul>
                                    <li>copia de los documentos que acrediten su identidad (identificación
                                        oficial vigente con fotografía) o, en su caso, la representación
                                        legal, conforme a los términos establecidos en la Legislación de Datos Personales; y</li>
                                    <li>cualquier otro elemento o documento que facilite la localización de sus datos personales.</li>
                                </ul>
                                Le daremos trámite a las solicitudes de los Titulares para el ejercicio de
                                sus derechos referentes a datos personales en un plazo no mayor a 20 (veinte)
                                días hábiles contados a partir de su recepción y realizar requerimiento o
                                subsanar conforme a los tiempos de la Legislación de Datos Personales; el
                                Responsable podrá ampliar este plazo hasta por 20 (veinte) días hábiles más,
                                cuando el caso lo amerite, previa notificación. En caso de que su solicitud
                                resulte procedente, la misma se hará efectiva dentro de los 15 (quince) días
                                hábiles siguientes de nuestra respuesta.
                                <br/>
                                <br/>
                                Usted podrá obtener la información o datos personales solicitados a través
                                de documentos electrónicos en formatos convencionales o a través de cualquier
                                otro medio legítimo que garantice y acredite el ejercicio efectivo del derecho
                                solicitado.
                                Según se establece anteriormente, el Departamento de Datos Personales del
                                Responsable está a cargo de tramitar cualesquier solicitudes de los titulares
                                para el ejercicio de sus derechos referentes a datos personales y puede
                                ponerse en contacto con los mismos a través del correo
                                electrónico <a href={'mailto:datospersonales@nbio.mx'}>datospersonales@nbio.mx</a>
                                <br/>
                                <br/>
                                El Responsable desea mantener actualizados sus datos personales en todo
                                momento. Por lo anterior, solicitamos su cooperación para comunicarnos
                                oportunamente cualquier cambio que debamos tener en cuenta, para incorporarlo
                                a nuestras bases de datos. Usted garantiza y responde, en cualquier caso, de
                                la veracidad, exactitud, vigencia y autenticidad de sus datos personales o
                                los de cualquier tercero bajo su custodia o representación legal, en el
                                entendido de que el Responsable cumplirá con lo establecido en la legislación
                                aplicable en relación con los datos de dichos terceros recibidos por el Responsable.
                            </p>
                            <br/>
                            <br/>
                            {/*---------------------------------------------------------------------------------*/}
                            {/*---------------------------------------------------------------------------------*/}
                            <h1>8. Medios que permiten recabar datos personales de manera automática.</h1>
                            <br/>
                            <p className={'ion-text-justify'}>
                                Le informamos que los Sitios, la App y las Redes Sociales hacen uso de
                                cookies y otras tecnologías, a través de las cuales es posible monitorear
                                automáticamente su comportamiento, brindarle nuestros productos y/o servicios
                                y otorgarle una experiencia optima y personalizada durante el uso de los
                                mismos, así como ofrecerle nuevos productos y servicios basados en sus
                                preferencias. Los datos personales que se recaban a través de estas
                                tecnologías son: dirección IP, sitios web y secciones de los mismos que
                                usted visita desde los Sitios, antes de los Sitios o en páginas relacionadas
                                con los Sitios y la App, características de navegador, características de
                                dispositivos, sistema operativo, preferencias de idiomas, URL a las que se
                                hace referencia, información sobre conductas y acciones realizadas en los
                                Sitios y la App, información sobre conductas y acciones realizadas mientras
                                nuestra App está abierta, fechas y horas de las visitas a los Sitios o
                                acceso a la App, secciones o contenido consultadas en los mismos y datos de
                                ubicación y localización del Usuario mientras nuestra App esté abierta.
                                <br/>
                                <br/>
                                Estas tecnologías pueden deshabilitarse accediendo a la(s) opción(es) de
                                Privacidad y/o Seguridad ubicada(s) en la(s) sección(es) de
                                Opciones, Herramientas, Preferencias de Internet o funciones similares del
                                navegador de Internet que esté utilizando o a través del ícono de
                                configuración, herramientas o similares del dispositivo en el cual esté
                                instalada la App; sin embargo, al desactivarlas debe tomar en cuenta que
                                dicha acción podría provocar que no sea capaz de obtener el funcionamiento
                                total que los Sitios y la App pudiera ofrecerle. Si usted desea obtener
                                información adicional acerca de las cookies mencionadas en esta
                                sección, puede visitar <a href={'www.allaboutcookies.org/es'}>
                                www.allaboutcookies.org/es.</a>
                            </p>
                            <br/>
                            <br/>
                            {/*---------------------------------------------------------------------------------*/}
                            {/*---------------------------------------------------------------------------------*/}
                            <h1>9. Soporte</h1>
                            <br/>
                            <p className={'ion-text-justify'}>Para soporte técnico de tu cuenta, retroalimentación, dudas o compras en la aplicación escríbenos a <a href={'mailto:comercio@nbio.mx'}>comercio@nbio.mx</a> </p>
                            <p className={'ion-text-justify'}>o si lo prefieres visita nuestra página de <a href={'https://www.facebook.com/NBIO-Cuidado-de-la-Salud-107444454919635/'}>Facebook</a> para soporte por chat.</p>
                            <p className={'ion-text-justify'}>Para soporte por teléfono marca al 55 7095 5437</p>
                            <br/>
                            <h1>¿Cómo eliminar mi cuenta?</h1>
                            <br/>
                            <p className={'ion-text-justify'}>Si deseas eliminar tu cuenta sigue estas instrucciones:</p>
                            <ul>
                                <li>
                                    Ve a perfil, luego presiona sobre 'Eliminar mi cuenta'.
                                </li>
                                <li>
                                    Confirma tu elección.
                                </li>
                                <li>
                                    Tu historial de compras y de navegación será eliminado con tu usuario. Toma en cuenta que para volver a usar Nbio necesitarás hacer un nuevo usuario.
                                </li>
                            </ul>
                            <br/>
                            {/*---------------------------------------------------------------------------------*/}
                            {/*---------------------------------------------------------------------------------*/}
                            <h1>10. Cambios al Aviso de Privacidad.</h1>
                            <br/>
                            <p className={'ion-text-justify'}>
                                El presente Aviso de Privacidad puede sufrir modificaciones, cambios o
                                actualizaciones derivadas de nuevos requerimientos legales, de nuestras
                                propias necesidades por los productos o servicios que ofrecemos, de nuestras
                                prácticas, programas o políticas de privacidad, de cambios en nuestro modelo
                                de negocio o por otras causas.
                                <br/>
                                <br/>
                                Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir
                                el presente Aviso de Privacidad, a través de los Sitios y de la App, en la
                                sección correspondiente de Aviso de Privacidad.
                            </p>
                            <br/>
                            <br/>
                            {/*---------------------------------------------------------------------------------*/}
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        )

}
export default PrivacyPolicyContent;
