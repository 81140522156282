/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    withIonLifeCycle,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonItem,
    IonBackButton,
    IonFooter,
    IonButton,
    IonInput,
    IonToast
} from '@ionic/react';
import NbioApi from "../../lib/api/NbioApi";

//components
import GradientDivider from "../../components/ui/GradientDivider";
import {RouteComponentProps} from "react-router";
import dayjs from "dayjs";
import WebHeader from "../../components/ui/WebHeader";

interface ValidatePasswordCodeState {
    email: string;
    isToastOpen: boolean;
    errorMessage: string;
    digit1: string;
    digit2: string;
    digit3: string;
    digit4: string;
    canRequestCode: boolean;
    countDown: string;
}

interface ValidatePasswordCodeProps extends RouteComponentProps {

}

class ValidatePasswordCode extends React.Component<ValidatePasswordCodeProps, ValidatePasswordCodeState> {
    private digit1: any;
    private digit2: any;
    private digit3: any;
    private digit4: any;
    private lastRequestCodeTimestamp: number | null;


    constructor(props: ValidatePasswordCodeProps) {
        super(props);
        this.state = {
            email: '',
            isToastOpen: false,
            errorMessage: '',
            digit1: '',
            digit2: '',
            digit3: '',
            digit4: '',
            canRequestCode: true,
            countDown: '00:00'
        }
        this.lastRequestCodeTimestamp = Date.now();
    }

    componentDidMount() {
        // alert('enter')
        setTimeout(() => {
            try {
                this.digit1.setFocus();
            } catch (ex) {

            }
        }, 500)
    }

    onChange = ({id, value}: any) => {
        if (id === 'digit1' && value.length >= 1) {
            this.digit2.setFocus();
        }
        if (id === 'digit2' && value.length === 1) {
            this.digit3.setFocus();
        }
        if (id === 'digit3' && value.length === 1) {
            this.digit4.setFocus();
        }

        this.setState({[id]: value} as Pick<ValidatePasswordCodeState, keyof ValidatePasswordCodeState>, () => {
            if (this.validateDigits(false)) {
                this.doValidateCode();
            }
        });
    }
    formatCountDown(diff:number){
        const d = 300 - diff;
        const minutes = Math.floor(d  / 60);
        const seconds = d - minutes * 60;
        return `${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`
    }
    onClickRequestResetPwd = () => {
        if(!this.state.canRequestCode){
            return false;
        }
        let i = this.props.location.state as any;
        const email = i.email;
        NbioApi.users.requestResetPwd(email).then(res => {
            this.setState({
                isToastOpen: true,
                canRequestCode: false,
                errorMessage: `Código reenviado a ${email}. Revisa tu bandeja de entrada.`
            }, () => {
                setTimeout(() => {
                    this.lastRequestCodeTimestamp = Date.now();
                    this.setState({
                        canRequestCode: true
                    })
                }, 5 * 60 * 1000);
                setInterval(() => {
                    const future = dayjs();
                    const past = dayjs(this.lastRequestCodeTimestamp);
                    const diff = future.diff(past, 'seconds');
                    this.setState({
                        countDown:this.formatCountDown(diff)
                    })

                }, 1000)
            })
        }).catch(ex => {
            let msg = 'Hubo un error al solicitar el código';
            try {
                msg = ex.response.data.message_es;
            } catch (ex) {

            }
            this.setState({
                isToastOpen: true,
                errorMessage: msg
            })
        });
    }
    doValidateCode = () => {
        let i = this.props.location.state as any;
        const email = i.email;
        const code = `${this.state.digit1}${this.state.digit2}${this.state.digit3}${this.state.digit4}`;
        NbioApi.users.validatePasswordResetCode(email, code).then((res) => {
            this.props.history.push({
                pathname:'/cambiar-contrasena',
                state:{
                    email:email,
                    code:code
                }
            });
        }).catch((ex) => {
            let msg = 'Hubo un error al validar el código';
            try {
                msg = ex.response.data.message_es;
            } catch (ex) {

            }
            this.setState({
                errorMessage: msg,
                isToastOpen: true
            }, () => {
            })
        })
    }
    onClickValidateCode = () => {
        const isCodeValid = this.validateDigits();

        if (isCodeValid) {
            this.doValidateCode();
        }
    }

    validateDigits = (showMessage = true) => {
        const digit1 = this.state.digit1.trim();
        const digit2 = this.state.digit2.trim();
        const digit3 = this.state.digit3.trim();
        const digit4 = this.state.digit4.trim();

        if (digit1 !== '' && digit2 !== '' && digit3 !== '' && digit4 !== '') {
            return true;
        }
        if (showMessage) {
            this.setState({errorMessage: 'El código debe contener 4 dígitos', isToastOpen: true});
        }
        return false;
    }

    render() {
        return (
            <IonPage>
                <WebHeader title={'Ingresa el código de validación'} showCartButton={false} showSearchButton={false} showMenuButton={false}></WebHeader>
                <IonContent fullscreen>
                    <IonToast
                        isOpen={this.state.isToastOpen}
                        onDidDismiss={() => {
                            this.setState({isToastOpen: false});
                        }}
                        message={this.state.errorMessage}
                        duration={5000}
                    />
                    <IonGrid>
                        <IonRow className={"ion-justify-content-center"}>
                            <IonCol size-md="6">
                                <IonRow>
                                    <IonCol>
                                        <IonItem>
                                            <IonInput id="digit1"
                                                      className={'ion-text-center'}
                                                      type={"text"}
                                                      ref={(ref) => this.digit1 = ref}
                                                      value={this.state.digit1}
                                                      required
                                                      placeholder="-"
                                                      inputmode={"numeric"}
                                                      maxlength={1}
                                                      onKeyPress={(ev) => {
                                                          if (this.state.digit1.length >= 1) {
                                                              this.digit2.setFocus();
                                                          }
                                                      }}
                                                      onIonChange={(e: any) => this.onChange(e.target)}>
                                            </IonInput>
                                        </IonItem>
                                    </IonCol>

                                    <IonCol>
                                        <IonItem>
                                            <IonInput id="digit2"
                                                      className={'ion-text-center'}
                                                      type={"text"}
                                                      ref={(ref) => this.digit2 = ref}
                                                      value={this.state.digit2}
                                                      required
                                                      placeholder="-"
                                                      inputmode={"numeric"}
                                                      maxlength={1}
                                                      onKeyDown={(ev) => {
                                                          if (this.state.digit2.length === 0) {
                                                              if (ev.keyCode === 8 || ev.key === 'Backspace') {
                                                                  this.digit1.setFocus();
                                                              }
                                                          }
                                                      }}
                                                      onKeyPress={(ev) => {
                                                          if (this.state.digit2.length >= 1) {
                                                              this.digit3.setFocus();
                                                          }
                                                      }}
                                                      onIonChange={(e: any) => this.onChange(e.target)}>
                                            </IonInput>
                                        </IonItem>
                                    </IonCol>

                                    <IonCol>
                                        <IonItem>
                                            <IonInput id="digit3"
                                                      className={'ion-text-center'}
                                                      type={"text"}
                                                      ref={(ref) => this.digit3 = ref}
                                                      value={this.state.digit3}
                                                      required
                                                      placeholder="-"
                                                      inputmode={"numeric"}
                                                      maxlength={1}
                                                      onKeyDown={(ev) => {
                                                          if (this.state.digit3.length === 0) {
                                                              if (ev.keyCode === 8 || ev.key === 'Backspace') {
                                                                  this.digit2.setFocus();
                                                              }
                                                          }
                                                      }}
                                                      onKeyPress={(ev) => {
                                                          if (this.state.digit3.length >= 1) {
                                                              this.digit4.setFocus();
                                                          }
                                                      }}
                                                      onIonChange={(e: any) => this.onChange(e.target)}>
                                            </IonInput>
                                        </IonItem>
                                    </IonCol>

                                    <IonCol>
                                        <IonItem>
                                            <IonInput id="digit4"
                                                      className={'ion-text-center'}
                                                      type={"text"}
                                                      ref={(ref) => this.digit4 = ref}
                                                      value={this.state.digit4}
                                                      required
                                                      placeholder="-"
                                                      inputmode={"numeric"}
                                                      maxlength={1}
                                                      onKeyDown={(ev) => {
                                                          if (this.state.digit4.length === 0) {
                                                              if (ev.keyCode === 8 || ev.key === 'Backspace') {
                                                                  this.digit3.setFocus();
                                                              }
                                                          }
                                                      }}
                                                      onIonChange={(e: any) => this.onChange(e.target)}>
                                            </IonInput>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>

                                <IonButton expand="block"
                                           size={"small"}
                                           fill={"clear"}
                                           onClick={() => this.onClickRequestResetPwd()}>
                                    {
                                        this.state.canRequestCode ? 'No recibí el código de validación' :
                                            `Puedes pedir otro código en: ${this.state.countDown} minutos`
                                    }

                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
                <IonFooter className="ion-no-border">
                    <IonToolbar>
                        <IonGrid>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size-md="6">
                                    <IonButton expand="block"
                                               fill="outline"
                                               onClick={() => this.onClickValidateCode()}>
                                        Validar código</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(ValidatePasswordCode);
