/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * June 2023
 */

import {
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonLabel, IonButton, IonIcon
} from "@ionic/react";
import Product from "./Product";
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';
import {ProductType} from "../../lib/data_types/dataTypes";


import "../../css/components/productListHomeWeb.css";
import {chevronBack, chevronForward} from "ionicons/icons";


interface ProductListHomeWebProps {
    lists: [{name: string, products: ProductType[], _id: string}],
    selectedList: {name: string, products: ProductType[]},
    onSelectList?: any,
    onClick?: any,
}

const ProductListHomeWeb: React.FC<ProductListHomeWebProps> = ({lists,
                                                                selectedList,
                                                                onSelectList = () => {},
                                                                onClick = () => {}}) => {
    
    const renderHeader = () => {
        if(lists.length > 1){
            return(
                <IonRow className={'ion-justify-content-center'}>
                    <IonCol size-md={6} >
                        <IonSegment mode={'ios'}
                                    value={selectedList.name}
                                    swipeGesture={false}
                                    onIonChange={(e) => onSelectList(e.detail.value) }>
                            {
                                lists.map((list,index) => {
                                    return(
                                        <IonSegmentButton key={`${list._id}_${index}`} value={list.name}
                                                          className={'segment-main-page'}>
                                            <IonText className={'ion-text-capitalize ion-text-wrap'} >
                                                <h3>
                                                    {list.name}
                                                </h3>
                                            </IonText>
                                        </IonSegmentButton>
                                    )
                                })
                            }
                        </IonSegment>
                        <div className={'ion-margin-top'}>
                        </div>
                    </IonCol>
                </IonRow>
            )
        }else{
            return(
                <IonRow>
                    <IonCol>
                        <IonText className="ion-text-capitalize">
                            <h3>{selectedList.name}</h3>
                        </IonText>
                    </IonCol>
                </IonRow>
            )
        }
    }

    const buttonStyle = {
        backgroundColor:'white',
        borderRadius:'100%',
        fontSize:'1.9rem',
        boxShadow:"1px 1px 1px 1px #8e8e8e78",
    }
    const date = Date.now();
    return (
        <IonGrid className={'ion-hide-md-down margin-top-lg'}>
            {renderHeader()}
            <IonRow className={"ion-align-items-center"}>
                <Swiper modules={[Pagination,Navigation]}
                        slidesPerView={4}
                        autoplay={true}
                        pagination={false}
                        navigation={{
                            prevEl: `.nbio-pl-swiper-prev-${date}`,
                            nextEl: `.nbio-pl-swiper-next-${date}`,
                        }}
                >

                    {
                        selectedList.products.map((product: any,index:number) => {
                            return (
                                <SwiperSlide key={`${product._id}_${index}`}>
                                    <IonCol>
                                        <Product product={product}
                                                 onClick={(p: any) => onClick(p)}
                                                 isInList={true}>
                                        </Product>
                                    </IonCol>
                                </SwiperSlide>
                            )
                        })
                    }
                    <div className={'nbio-pl-swiper-navigation'}>
                        <IonButton className={`nbio-pl-swiper-prev-${date}`} fill={'clear'} color={'tertiary'}>
                            <IonIcon icon={chevronBack} color={'white'} style={buttonStyle}>
                            </IonIcon>
                        </IonButton>
                        <IonButton className={`nbio-pl-swiper-next-${date}`}  fill={'clear'} color={'tertiary'}>
                            <IonIcon icon={chevronForward} color={'white'} style={buttonStyle}>
                            </IonIcon>
                        </IonButton>
                    </div>
                </Swiper>
            </IonRow>
        </IonGrid>
    )
};

export default ProductListHomeWeb;
