/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * February 2022
 */

import React from 'react';
import {
    IonItem,
    IonLabel,
    IonThumbnail,
} from '@ionic/react';
import {OrderType} from "../../lib/data_types/dataTypes";

//components
import OrderPaymentStatus from "../../components/orders/OrderPaymentStatus";
import OrderStatus from "../../components/orders/OrderStatus";
import OrderProductThumbnail from "./OrderProductThumbnail";


interface OrderListItem {
    order: OrderType;
}


const OrderListItem: React.FC<OrderListItem> = ({order}) => {
    return (
        <IonItem key={order._id}
                 lines={"full"}
                 className={'ion-no-padding no-padding-inline-end'}
                 detail
                 routerDirection="forward"
                 routerLink={`/ordenes/${order._id}`}>
            <div className={'w-100'}>
                <IonItem  lines={"none"}>
                    <IonLabel slot={"start"}><small>Orden <b>#{order._id.slice(-6)}</b></small></IonLabel>
                    <OrderStatus status={order.status}
                                 slot={'end'}
                                 className={'ion-text-wrap'}>
                    </OrderStatus>
                </IonItem>
                {/*Todo: make scrollable*/}
                <IonItem lines="none" className={'scroll-x-auto'}>
                    <OrderProductThumbnail items={order.items}/>
                </IonItem>
                <IonItem lines={"none"}>
                    <OrderPaymentStatus status={order.paymentStatus} slot={""}></OrderPaymentStatus>
                    <IonLabel slot={"end"}><small>Total:<b> ${order.displayTotals.parsedTotal}</b></small></IonLabel>
                </IonItem>
            </div>
        </IonItem>
    );
};

export default OrderListItem;
