/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2023
 */


import React from "react";
import {
    IonBackButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid, IonHeader,
    IonPage,
    IonRow,
    IonTitle, IonToolbar, withIonLifeCycle
} from "@ionic/react";
import PaymentMethods from "../../components/checkout/PaymentMethods";
import {RouteComponentProps} from "react-router";
import GradientDivider from "../../components/ui/GradientDivider";
import WebHeader from "../../components/ui/WebHeader";

interface PaymentMethodsListProps extends RouteComponentProps {
}

interface PaymentMethodsListState {}

class PaymentMethodsList extends React.Component<PaymentMethodsListProps,PaymentMethodsListState> {
    render() {
        return (
            <IonPage>
                <WebHeader showSearchButton={true}></WebHeader>
                <IonContent>
                    <IonGrid className={'ion-no-padding h-100'}>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size={'12'} sizeLg={'6'}>
                                <IonHeader className={"ion-margin-top ion-no-border"}>
                                    <IonTitle className="ion-text-capitalize">Métodos de pago</IonTitle>
                                </IonHeader>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center h-100">
                            <IonCol className={'h-100'}>
                                <PaymentMethods></PaymentMethods>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>

        );
    }

}

export default withIonLifeCycle(PaymentMethodsList);
