/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

import React from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonItem,
    IonLabel,
    IonInput,
    IonContent,
    IonPage,
    withIonLifeCycle,
    IonToast,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    IonToggle,
    IonProgressBar,
    IonFooter,
    IonSkeletonText, IonSelect, IonSelectOption, IonTextarea
} from '@ionic/react';
import {RouteComponentProps} from "react-router";
import {InvoiceType} from "../../lib/data_types/dataTypes";
import API from "../../lib/api/NbioApi";
import {CfdiUsages,FiscalRegimes} from "../../lib/data/invoicing";
import {Capacitor} from "@capacitor/core";
import { Keyboard } from '@capacitor/keyboard';

//style
import "../../css/pages/address.css";

//components
import GradientDivider from "../../components/ui/GradientDivider";
import WebHeader from "../../components/ui/WebHeader";
// validate email
const validator                        = require("email-validator");
//Interfaces
interface InvoicingState {
    rfc: string;
    name: string;
    email: string;
    address: string;
    cfdiUsage: string;
    fiscalRegime: string;
    isDefault: boolean
    errorMessage: string;
    isToastOpen: boolean;
    isNewInvoicing:boolean;
    isLoading:boolean;
    renderContent: string;
    isKeyboardVisible:boolean;
}

interface InvoicingProps extends RouteComponentProps {

}

interface InvoicingData {
    invoiceId?: string,
    onSavePath?:string
}

class Invoicing extends React.Component<InvoicingProps, InvoicingState> {
    private ionInputAddress: any;
    private ionInputEmail: any;
    private ionInputRFC: any;
    private ionInputName: any;
    constructor(props: InvoicingProps) {
        super(props);
        this.state = {
            rfc: '',
            name: '',
            email: '',
            address: '',
            cfdiUsage: '',
            fiscalRegime: '',
            isDefault: false,
            errorMessage: '',
            isToastOpen: false,
            isNewInvoicing:true,
            isLoading:false,
            renderContent: 'LOADING',  //type LOADING, FORM
            isKeyboardVisible:false
        }
    }

    ionViewDidEnter() {
        let i = this.props.location.state as InvoicingData;

        if(i){
            if(i.invoiceId){
                this.setState({isNewInvoicing:false,renderContent:'FORM'})
                API.invoices.getInvoice(i.invoiceId).then((res) =>{
                    let invoice = res.data.invoice as InvoiceType;
                    this.setState({
                        rfc:invoice.rfc,
                        name:invoice.name,
                        email:invoice.email,
                        address:invoice.address,
                        cfdiUsage:invoice.cfdiUsage,
                        fiscalRegime:invoice.fiscalRegime,
                        isDefault:invoice.isDefault || false,
                    })
                }).catch((ex) =>{

                })
            }else{
                this.setState({isNewInvoicing:true,renderContent:'FORM'});
            }
        }
        //    Keyboard events
        const isKeyboardAvailable = Capacitor.isPluginAvailable('Keyboard');

        if(isKeyboardAvailable){
            Keyboard.addListener('keyboardDidShow', info => {
                this.setState({isKeyboardVisible:true});
            });
            Keyboard.addListener('keyboardDidHide', () => {
                this.setState({isKeyboardVisible:false});
            });
        }
    }
    async goNext(currentField:string ){
        const isKeyboardAvailable = Capacitor.isPluginAvailable('Keyboard');
        if(isKeyboardAvailable){
            await Keyboard.hide();
        }
        if(currentField === 'rfc'){
            try{
                this.ionInputName.setFocus();
            }catch(ex){
            }
        }
        if(currentField === 'name'){
            try{
                this.ionInputEmail.setFocus();
            }catch(ex){
            }
        }
        if(currentField === 'email'){
            try{
                this.ionInputAddress.setFocus();
            }catch(ex){
            }
        }

    }
    handleInput = ({id, value}: any) => {
        this.setState({[id]: value} as Pick<InvoicingState, keyof InvoicingState>);
    }

    saveInvoicing = () => {
        const {name} = this.state;
        const {rfc} = this.state;
        const {email} = this.state;
        const {address} = this.state;
        const {cfdiUsage} = this.state;
        const {fiscalRegime} = this.state;
        const {isDefault} = this.state;


        this.setState({isLoading:true});
        let _invoice = {
            id:'',
            invoiceId: '',
            rfc: rfc.toUpperCase(),
            name: name,
            email: email,
            address: address,
            cfdiUsage: cfdiUsage,
            fiscalRegime: fiscalRegime,
            isDefault: isDefault
        } as InvoiceType;

        // WHERE SHOULD I GO AFTER I FINISH?
        let i = this.props.location.state as InvoicingData;
        if(this.state.isNewInvoicing){
            API.invoices.create(_invoice).then((res) =>{
                let pathName = i.onSavePath ? i.onSavePath : '/datos-de-facturacion';
                let invoiceId = res.data.invoice._id || '';
                this.props.history.replace({
                    pathname:pathName,
                    state:{
                        invoiceId:invoiceId
                    }
                });
            })
        }else{
            let i = this.props.location.state as InvoicingData;
            if(i) {
                if (i.invoiceId) {
                    API.invoices.edit(i.invoiceId, _invoice).then((res) => {
                        let pathName = i.onSavePath ? i.onSavePath : '/datos-de-facturacion';
                        let invoiceId = res.data.invoice._id || '';
                        this.props.history.replace({
                            pathname: pathName,
                            state: {
                                invoiceId: invoiceId
                            }
                        });
                    })
                }
            }
        }
    }

    renderSaveButton(){
        if(this.state.isLoading){
            return(
                <IonFooter>
                    <IonToolbar>
                        <IonGrid>
                            <IonRow className={'ion-justify-content-center'}>
                                <IonCol size-md={"6"} size-lg={'6'}>
                                        <IonButtons>
                                            <IonProgressBar type="indeterminate" color={'primary'}></IonProgressBar>
                                        </IonButtons>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonFooter>
            )
        }else{
            return (
                <IonFooter >
                    <IonToolbar>
                        <IonGrid>
                            <IonRow className={'ion-justify-content-center'}>
                                <IonCol size-md={"6"} size-lg={'6'}>
                                        <IonButtons>
                                            <IonButton className="ion-margin-horizontal w-100"
                                                       color="primary"
                                                       expand="block"
                                                       fill='solid'
                                                       onClick={() => this.onClickSaveInvoicing()}>Guardar datos de facturación</IonButton>
                                        </IonButtons>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonFooter>

            )
        }
    }

    validateInvoicing = () => {
        const {name} = this.state;
        const {rfc} = this.state;
        const {email} = this.state;
        const {address} = this.state;
        const {cfdiUsage} = this.state;
        const {fiscalRegime} = this.state;
        if(!validateRFC(rfc)){
            this.showErrorMsg('El RFC no es válido');
            return false;
        }

        if(name.trim() === ''){
            this.showErrorMsg('El nombre no puede estar vacío');
            return false;
        }
        if(address.trim() === ''){
            this.showErrorMsg('La calle no puede estar vacía');
            return false;
        }
        if(!cfdiUsage){
            this.showErrorMsg('Selecciona un uso de CFDI');
            return false;
        }
        if(!fiscalRegime){
            this.showErrorMsg('Selecciona un régimen fiscal');
            return false;
        }
        const isValidEmail = validator.validate(email);
        if (!isValidEmail) {
            this.showErrorMsg('Escribe un correo electrónico válido');
            return false;
        }

        return true;
    }

    showErrorMsg = (msg: string) => {
        this.setState({isToastOpen:true, errorMessage:msg});
    }

    onClickSaveInvoicing = () => {
        const isInvoicingValid = this.validateInvoicing();
        if(isInvoicingValid){
            this.saveInvoicing();
        }
    }
    showIntoView = () =>{
        if(this.state.isKeyboardVisible){
            try{
                // @ts-ignore
                document.activeElement.parentElement.parentElement.scrollIntoView();
            }catch(ex){
                console.log('ex',ex);
            }
        }
    }

    renderInvoicingForm = () => {
        const {name} = this.state;
        const {rfc} = this.state;
        const {email} = this.state;
        const {address} = this.state;
        const {cfdiUsage} = this.state;
        const {fiscalRegime} = this.state;
        const {isDefault} = this.state;

        return(
            <IonCol size-md={"6"} size-lg={"6"} className={'ion-margin-bottom'}>
                <IonItem className={'ion-margin-top'}>
                    <IonLabel position={"floating"}>RFC</IonLabel>
                    <IonInput id="rfc"
                              type="text"
                              value={rfc}
                              required={true}
                              minlength={1}
                              maxlength={13}
                              onIonChange={(e: any) => this.handleInput(e.target)}
                              onFocus={() => this.showIntoView()}
                              enterkeyhint="next"
                              ref={(ref) => this.ionInputRFC = ref}
                              onKeyPress={(ev) => {
                                  if(ev.key === 'Enter'){
                                      this.goNext('rfc');
                                  }
                              }}
                              placeholder="RFC">
                    </IonInput>
                </IonItem>

                <IonItem className={'ion-margin-top'}>
                    <IonLabel position={"floating"}>Nombre</IonLabel>
                    <IonInput id="name"
                              type="text"
                              value={name}
                              required={true}
                              minlength={1}
                              onIonChange={(e: any) => this.handleInput(e.target)}
                              onFocus={() => this.showIntoView()}
                              enterkeyhint="next"
                              ref={(ref) => this.ionInputName = ref}
                              onKeyPress={(ev) => {
                                  if(ev.key === 'Enter'){
                                      this.goNext('name');
                                  }
                              }}
                              placeholder="Nombre">
                    </IonInput>
                </IonItem>

                <IonItem className={'ion-margin-top'}>
                    <IonLabel position={"floating"}>Correo electrónico</IonLabel>
                    <IonInput id="email"
                              type="text"
                              value={email}
                              required={true}
                              minlength={1}
                              onIonChange={(e: any) => this.handleInput(e.target)}
                              onFocus={() => this.showIntoView()}
                              enterkeyhint="next"
                              ref={(ref) => this.ionInputEmail = ref}
                              onKeyPress={(ev) => {
                                  if(ev.key === 'Enter'){
                                      this.goNext('email');
                                  }
                              }}
                              placeholder="Correo electrónico">
                    </IonInput>
                </IonItem>

                <IonItem className={'ion-margin-top'}>
                    <IonLabel position={"floating"}>Dirección fiscal</IonLabel>
                    <IonTextarea id="address"
                              rows={4}
                              value={address}
                              required={true}
                              minlength={1}
                              onIonChange={(e: any) => this.handleInput(e.target)}
                              onFocus={() => this.showIntoView()}
                              enterkeyhint="next"
                              ref={(ref) => this.ionInputAddress = ref}
                              onKeyPress={(ev) => {
                                 if(ev.key === 'Enter'){
                                     this.goNext('address');
                                 }
                              }}
                              placeholder="Dirección fiscal">
                    </IonTextarea>
                </IonItem>

                <IonItem className={'ion-margin-top'}>
                    <IonLabel position={"floating"}>Regimen fiscal</IonLabel>
                    <IonSelect id="fiscalRegime"
                               cancelText={'Cancelar'}
                               interface="action-sheet"
                               value={fiscalRegime}
                               placeholder="Elige una opción"
                               onIonChange={(e) => this.handleInput(e.target)}>
                        <IonSelectOption value="">Elige una opción</IonSelectOption>
                        {
                            FiscalRegimes.map((regimes,index) => {
                                return(
                                    <IonSelectOption value={regimes} key={index}>{regimes}</IonSelectOption>
                                )
                            })
                        }
                    </IonSelect>
                </IonItem>

                <IonItem className={'ion-margin-top'}>
                    <IonLabel position={"floating"}>Uso del CDFI</IonLabel>
                    <IonSelect id="cfdiUsage"
                               value={cfdiUsage}
                               cancelText={'Cancelar'}
                               interface="action-sheet"
                               placeholder="Elige una opción"
                               onIonChange={(e) => this.handleInput(e.target)}>
                        <IonSelectOption value="">Elige una opción</IonSelectOption>
                        {
                            CfdiUsages.map((usage, index) => {
                                return(
                                    <IonSelectOption value={usage} key={index}>{usage}</IonSelectOption>
                                )
                            })
                        }
                    </IonSelect>
                </IonItem>

                <IonItem className={'ion-margin-top'}>
                    <IonLabel >Hacer predeterminado</IonLabel>
                    <IonToggle color={"primary"}
                               checked={isDefault}
                               onIonChange={e => this.setState({isDefault: e.detail.checked})}>
                    </IonToggle>
                </IonItem>
            </IonCol>
        )
    }



    renderLoading = () => {
        return(
            <IonCol  size-lg={"6"}  className={'h-100'}>
                <IonSkeletonText animated className={'h-100'}/>
            </IonCol>
        )
    }


    renderContent = () => {
        switch (this.state.renderContent){
            case 'LOADING': {
                return this.renderLoading();
            }
            case 'FORM':{
                return this.renderInvoicingForm();
            }
            default: {
                return this.renderLoading();
            }
        }
    }

    render() {

        return (
            <IonPage>
                <WebHeader title={'Datos de facturación'} showSearchButton={false} showCartButton={false} showMenuButton={false}></WebHeader>
                <IonContent>
                    <IonGrid className={`ion-no-padding`}>
                        <IonToast
                            isOpen={this.state.isToastOpen}
                            onDidDismiss={() => {
                                this.setState({isToastOpen: false, errorMessage:''});
                            }}
                            message={this.state.errorMessage}
                            duration={500}
                        />
                        <IonRow className={`ion-justify-content-center`}>
                            {this.renderContent()}
                        </IonRow>
                    </IonGrid>
                </IonContent>

                {this.renderSaveButton()}
            </IonPage>
        )
    }
}
const validateRFC = function(rfc:string){
    rfc = rfc.toUpperCase();
    const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    return re.test(rfc);
}
export default withIonLifeCycle(Invoicing);
