/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * February 2022
 */

import React from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    withIonLifeCycle,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonItem,
    IonNote,
    IonThumbnail,
    IonBackButton, IonFooter, IonButton, IonCard, IonLabel, IonText, IonTextarea
} from '@ionic/react';
import GradientDivider from "../../components/ui/GradientDivider";
import {RouteComponentProps} from "react-router";
import {OrderType, ReviewProductType, ReviewType} from "../../lib/data_types/dataTypes";
import NbioApi from "../../lib/api/NbioApi";
import StarRatings from 'react-star-ratings';
import WebHeader from "../../components/ui/WebHeader";
// react-star-ratings

// const ratingType = {
//     id:string;
//     rating:number;
// }
interface ReviewState {
    review: ReviewType | null;
    ratings: any[];
    rating: number;
    reviewTxt: string;
}

interface ReviewProps extends RouteComponentProps<{
    id: string;
}> {
};


class Review extends React.Component<ReviewProps, ReviewState> {
    constructor(props: ReviewProps) {
        super(props);
        this.state = {
            review: null,
            ratings: [],
            rating: 0,
            reviewTxt:''
        }
        this.changeRating = this.changeRating.bind(this);
    }

    ionViewDidEnter() {
        const orderId = this.props.match.params.id;
        this.getReview(orderId);
    }

    getReview = (orderId: string) => {
        NbioApi.reviews.getByOrderId(orderId).then(res => {
            this.setState({
                review: res.data.review,
                rating:res.data.review.rating || 0,
                reviewTxt:res.data.review.review ||''
            });
        }).catch(ex => {
        })
    }
    completeReview = () =>{
        const reviewId = this.state.review?._id || '';
        const answers = {
            review:'',
            rating:this.state.rating,
            answers: this.state.ratings.map((r) =>{
                return {
                    productId: r.id,
                    review: '',
                    rating: r.rating
                }

            })
        }
        NbioApi.reviews.completeReview(reviewId,answers).then((res) =>{
            this.props.history.goBack();
        }).catch((ex) =>{

        })
    }
    render() {
        const products = this.state.review?.products || [];
        const ratingOperator = this.state.rating;
        return (
            <IonPage>
                <WebHeader title={'Reseña'}></WebHeader>
                <IonContent fullscreen>
                    <IonGrid>
                        <IonRow className={"ion-justify-content-center"}>
                            <IonCol size-md="6">
                                <IonLabel className="ion-text-uppercase">Califica al operador</IonLabel>
                                <IonCard className={"ion-no-margin ion-margin-bottom"}>
                                    <IonItem lines={"none"}>
                                        <StarRatings starDimension="20px"
                                                     starSpacing="1px"
                                                     rating={ratingOperator}
                                                     starHoverColor="#E0BB2E"
                                                     starRatedColor="#ffd534"
                                                     changeRating={(v) => this.setState({rating: v})}
                                                     numberOfStars={5}
                                                     name={'rating-operator'}></StarRatings>
                                    </IonItem>
                                </IonCard>
                                <IonTextarea placeholder={'Escribe un comentario'} value={this.state.reviewTxt}
                                             onIonChange={(e) => {
                                                 this.setState({reviewTxt:e.detail.value!})
                                             }}
                                >

                                </IonTextarea>
                                <IonLabel className="ion-text-uppercase">Califica nuestros productos</IonLabel>
                                {products.map(product => {
                                    const rating = this.findRating(product);
                                    return (
                                        <IonCard key={product._id} className={"ion-no-margin ion-margin-bottom"}>
                                            <IonItem lines={"none"}>
                                                <IonThumbnail slot={'start'}>
                                                    <img src={product.imageUrlThumbnail} width={"100%"}/>
                                                </IonThumbnail>
                                                <div className={"flex-col"}>
                                                    <IonNote>{product.description}</IonNote>
                                                    <IonNote><small> Te gusto el producto?</small></IonNote>

                                                    <StarRatings starDimension="20px"
                                                                 starSpacing="1px"
                                                                 rating={rating}
                                                                 starHoverColor="#E0BB2E"
                                                                 starRatedColor="#ffd534"
                                                                 changeRating={(v) => this.changeRating(product.id, v)}
                                                                 numberOfStars={5}
                                                                 name={product.id}></StarRatings>
                                                </div>
                                            </IonItem>

                                        </IonCard>
                                    )
                                })
                                }

                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButtons>
                            <IonButton className="ion-margin-horizontal w-100"
                                       color="primary"
                                       expand="block"
                                       fill='solid'
                                       onClick={() => this.completeReview()}
                            >Completar reseña</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        )
    }

    private changeRating(id: string, rating: number) {
        const ratings = [...this.state.ratings];
        const index = ratings.findIndex((r) => r.id === id);
        if (index >= 0) {
            ratings[index] = {
                id: id,
                rating: rating
            }
        } else {
            ratings.push({
                id: id,
                rating: rating
            })
        }
        this.setState({
            ratings: ratings
        })
    }

    private findRating(product: ReviewProductType) {
        const foundRating = this.state.ratings.find((p) => p.id === product.id);
        return foundRating ? foundRating.rating : 0;
    }
}

export default withIonLifeCycle(Review);
