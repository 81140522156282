/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * May 2023
 */

import {
    IonButton,
    IonIcon,
    IonLabel,
    IonModal,
    IonCardTitle,
    IonCardSubtitle,
    IonCard,
    IonCardContent,
    IonText, IonToast, IonSpinner, isPlatform, useIonAlert
} from "@ionic/react";
import {ProductType} from "../../lib/data_types/dataTypes";
import Cart from "../../lib/cart/Cart";
import React, {useState} from "react";
import {addCircle, cart, removeCircle} from "ionicons/icons";
import {money} from "../../lib/money/money";
import {useHistory} from "react-router-dom";

//style
import "../../css/components/addToCartButton.css";
import {emmitEvent, EVENTS } from "../../lib/events/events";


interface AddToCartButtonProps{
    product: ProductType;
    onButtonClick?: any;
    adId?:string
}

const AddToCartButton: React.FC<AddToCartButtonProps> = ({
                                                           product,onButtonClick = () =>{},
                                                           adId=''
                                                           },) => {
    const [quantity,setQuantity] = useState(1);
    const [qty,setQty] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState('');
    const [isAddingToCart,setIsAddingToCart] = useState(false);
    const [presentAlert] = useIonAlert();
    const history = useHistory();
    const goToStore = () =>{
        const isIos = isPlatform('ios');
        window.open(isIos ? 'https://apps.apple.com/us/app/nbio/id1605823846' : 'https://play.google.com/store/apps/details?id=mx.nbio&hl=es_MX&gl=US');
    }
    const askDownloadApp = () =>{
        const isIos = isPlatform('ios');
        presentAlert({
            header: 'Descarga nuestra app',
            message:
                `Para una mejor experiencia, descarga nuestra app`,
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                },
                {
                    text: isIos ? 'Ir a la App Store' : 'Ir a Google Play',
                    role: 'confirm',
                    handler: () => {
                        goToStore();
                    },
                },
            ],
        })
    }
    const addToCart = (e : any) => {
        e.stopPropagation();
        setIsAddingToCart(true);
        Cart.addProduct(product._id,quantity,true,adId).then(res => {
            setToastMsg("Producto agregado")
            setShowToast(true);
            cleanStates();

            try{
                // @ts-ignore
                fbq('track', 'AddToCart');
            }catch(ex){

            }
            //google tracking
            try{
                // @ts-ignore
                gtag("event", "add_to_cart", {
                    currency: "MXN",
                    value: product.price,
                    items: [
                        {
                            item_id: product._id,
                            item_name: product.name,
                            item_brand: product.brandName || '',
                            item_category: product.categories ? product.categories[0] : '',
                            price: product.price,
                            quantity: 1
                        }
                    ]
                });
            }catch(ex){

            }
        }).catch((ex) =>{
            if(ex === 'Unsupported web view'){
                askDownloadApp();
            }
        }).finally(() =>{
            setIsAddingToCart(false);
        });
    }

    const increaseQty =  (e: any) => {
        e.stopPropagation();

        let newQty = qty + 1 >= 20 ? 20 : qty + 1;
        setQty(newQty)
        setQuantity(newQty);
    }

    const decreaseQty = (e: any) =>{
        e.stopPropagation();

        let newQty = qty - 1 <= 1 ? 1 : qty - 1;
        setQty(newQty)
        setQuantity(newQty);
    }

    const renderPrice = () => {
        return(
            <IonCardTitle color="danger" className={'product-price'}>
                {
                    product.discount ?
                        <IonText color={'medium'} className={'line-through ion-margin-end'}>
                            <small>{money(product.originalPrice)}</small>
                        </IonText> : null
                }
                {money(product.price)}
            </IonCardTitle>
        )
    }

    const cleanStates = () => {
        setQty(1)
        setQuantity(1);
        setToastMsg("");
    }
    const goToCart = () =>{
        history.push('/carrito');
    }
    const showCart = () =>{
        emmitEvent(EVENTS.OPEN_POPUP_CART,{});
    }
    return (
        <>
            <IonButton fill="solid"
                       size="small"
                       className="btn-on-dark-primary"
                       disabled={!product._id}
                       onClick={(e) => {
                        e.stopPropagation();
                        setIsOpen(true);}}
            >
                <IonIcon icon={cart}></IonIcon>
            </IonButton>
            {/*Ion Toast Not Mobile opens popup */}
            <IonToast isOpen={showToast} cssClass={'ion-hide-lg-down'}
                      onDidDismiss={() => {setShowToast(false); setIsOpen(false)}}
                      message={toastMsg}
                      buttons={[
                          {
                              text: 'Ver el carrito',
                              role: 'info',
                              handler: () => {
                                  showCart();
                              }
                          }
                      ]}
                      duration={5000} />
            {/*Ion Toast Mobile calls /carrito */}
            <IonToast isOpen={showToast} cssClass={'ion-hide-lg-up'}
                      onDidDismiss={() => {setShowToast(false); setIsOpen(false)}}
                      message={toastMsg}
                      buttons={[
                          {
                              text: 'Ir al carrito',
                              role: 'info',
                              handler: () => {
                                  goToCart();
                              }
                          }
                      ]}
                      duration={5000} />
            <IonModal isOpen={isOpen}
                      animated={true}
                      initialBreakpoint={1}
                      breakpoints={[0, 1]}
                      className={'ion-modal-add-to-cart'}
                      onDidDismiss={() => {
                          cleanStates();
                          setIsOpen(false);}}
            >
                    <div className={'modal-auto-h'}>
                        <IonCard className={'ion-no-margin'}>
                            <img src={product.images[0]} width={'100%'}/>
                        </IonCard>
                        <IonCard className={'card-content'}>
                            <IonCardContent>
                                {renderPrice()}

                                <IonCardSubtitle>
                                    {product.name}
                                </IonCardSubtitle>
                            </IonCardContent>
                        </IonCard>
                        <IonCard className={'card-footer ion-padding'}>
                            <div className={'flex-row ion-align-items-center'}>
                                <IonButton onClick={decreaseQty}
                                           className="no-padding-start btn-on-dark-primary-clear"
                                           size="small"
                                           fill="clear">
                                    <IonIcon slot="icon-only" icon={removeCircle} ></IonIcon>
                                </IonButton>
                                <IonLabel>{quantity}</IonLabel>
                                <IonButton onClick={increaseQty} className="btn-on-dark-primary-clear" size="small" fill="clear">
                                    <IonIcon slot="icon-only" icon={addCircle}></IonIcon>
                                </IonButton>
                            </div>
                            <IonButton className="w-100 btn-on-dark-primary"
                                       expand="block"
                                       fill="solid"
                                       size={"small"}
                                       onClick={addToCart}>
                                {
                                    isAddingToCart ?
                                        <IonSpinner name={'dots'}></IonSpinner>
                                    :
                                        <div className={'flex-row'}>Agregar &nbsp;<IonIcon icon={cart}></IonIcon></div>
                                }
                            </IonButton>
                        </IonCard>
                    </div>
            </IonModal>
        </>
    );
};

export default AddToCartButton;
