/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * February 2022
 */
import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonContent,
    IonIcon,
    IonModal,
} from "@ionic/react";
import {close} from "ionicons/icons";
import {AdType} from "../../lib/data_types/dataTypes";
import NbioApi from '../../lib/api/NbioApi';

//style
import "../../css/components/adPopup.css";
import {AppLauncher} from "@capacitor/app-launcher";
import {useHistory} from "react-router";
// url validator
const validUrl = require('valid-url');


const AdPopup: React.FC<{}> = ({}) => {
    const history = useHistory();
    const initialAdState: AdType = {
        _id: '',
        image: '',
        image2: '',
        link: ''
    }
    const [isOpen, setIsOpen] = useState(false);
    const [ad, setAd] = useState(initialAdState);
    const onAdClick = async (ad: AdType) => {
        // REPORT TO ANALYTICS
        try{
            await NbioApi.ads.reportClick(ad._id);
        }catch(ex){

        }
        setIsOpen(false);
        if (validUrl.isWebUri(ad.link)) {
            AppLauncher.openUrl({url: ad.link!});
        } else {
            history.push({
                pathname:`${ad.link!}`,
                search:`AdId=${ad._id}`
            });
        }
    }
    useEffect(() => {
        NbioApi.ads.getAdPopup().then((res) => {
            const data = res.data;
            if (data.content) {
                setAd(res.data.content);
                setIsOpen(true);
            }
        }).catch((ex) => {

        });
    },[]);
    return (
        <div>
            <IonModal isOpen={isOpen}
                      showBackdrop={false}>
                <IonContent className={'ad-overflow-hidden'}>
                    <IonButton className={'ad-popup-close-btn'}
                               fill={'clear'}
                               color="dark"
                               onClick={() => {
                                   setIsOpen(false)
                               }}>
                        Cerrar
                        <IonIcon icon={close}></IonIcon>
                    </IonButton>
                    <div className={'ad-popup-image'} style={{
                        backgroundImage: `url("${ad.image2}")`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition:'center center'
                    }} >
                    </div>

                    <IonButton className={'ad-popup-btn'}
                               color={"light"}
                               routerDirection="forward"
                               onClick={() => onAdClick(ad)}
                    >Ver más</IonButton>
                </IonContent>
            </IonModal>
        </div>
    )
}

export default AdPopup;
