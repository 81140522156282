/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * February 2021
 */

import React, {useContext, useRef, useState} from "react";
import {
    IonItem,
    IonLabel,
    IonIcon,
    IonItemOption,
    IonItemOptions,
    IonItemSliding, IonSpinner
} from "@ionic/react";
import type {OrderType} from "../../lib/data_types/dataTypes";
import {ellipse, stopwatch} from "ionicons/icons";
import NbioApi from "../../lib/api/NbioApi";
import {AppContext} from "../../App";
import { money } from "../../lib/money/money";
import {AppLauncher} from "@capacitor/app-launcher";

//component
import OrderStatus from "../orders/OrderStatus";
import OrderPaymentMethod from "../orders/OrderPaymentMethod";


//interface
interface OrderListItemOperator {
    order: OrderType;
    onOrderUpdated: any;
    index: number;
}

const OrderListItemOperator: React.FC<OrderListItemOperator> = ({order,
                                                                 index,
                                                                 onOrderUpdated = () => {}}) => {
    //declarations
    let slidingRef = useRef<HTMLIonItemSlidingElement>(null);
    const [showSpinner,setShowSpinner] = useState(false);
    const shipping = order.shipping || {};
    const {user} = useContext(AppContext);
    const deliveryBlock = order.deliveryBlock;
    const colorItem = index % 2 === 0 ? 'light' : '';

    const onMarkAsWorking = (order: OrderType) =>  {
        NbioApi.operator.markOrderAsWorkingOnOrder(order._id).then((res) => {
            onOrderUpdated(order);
            slidingRef.current?.close();
        }).catch((ex) => {

        })
    }

    const goToPharmacyLocation = () => {
        setShowSpinner(true);
        const  regionId = order.region ? order.region._id : "";
        if(regionId){
            NbioApi.regions.getRegion(regionId).then((res) => {
                const r = res.data.region;
                if(r){
                    const sRegions = r.subRegions ? r.subRegions : [];
                    const pharmacy = sRegions.find((sr: any) => sr.featureGeoJson.geometry.type === 'Point');
                    const location = pharmacy ? pharmacy.featureGeoJson.geometry.coordinates : [0,0];
                    setShowSpinner(false);
                    slidingRef.current?.close();
                    // AppLauncher.openUrl({url: `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`});
                    AppLauncher.openUrl({url: `https://www.google.com/maps/search/?api=1&query=${location[1]},${location[0]}` });
                }else{
                    handleErrorPharmacyLocation();
                }
            }).catch((ex) => {
                handleErrorPharmacyLocation();
            });
        }else{
            handleErrorPharmacyLocation();
        }
    }

    const handleErrorPharmacyLocation = () => {
        setShowSpinner(false);
        slidingRef.current?.close();
    }

    return (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        <IonItemSliding onClick={(ev) => {
        }} ref={slidingRef}>

            <IonItem className='ion-no-padding no-padding-inline-end'
                     key={order._id}
                     lines={"full"}
                     color={colorItem}
                     detail routerLink={`/ordenes/${order._id}`}>
                <div className={'w-100'}>
                    <IonItem className='no-padding-inline-end' lines={'none'} color={colorItem}>
                        <IonLabel className={'ion-text-wrap'} slot={""}>
                            {
                                order.isViewedByUser ? null :
                                    <IonIcon icon={ellipse} color={'warning'}></IonIcon>
                            }
                            {
                                order.userWorkingOnThisOrder === user.uid ?
                                    <IonIcon icon={stopwatch} color={'primary'}></IonIcon>
                                    : null
                            }
                            Orden #: <small>{order._id}</small>
                        </IonLabel>

                        <OrderStatus status={order.status}
                                     slot={'end'}
                                     className={'ion-text-wrap'}>
                        </OrderStatus>
                    </IonItem>

                    <IonItem className='no-padding-inline-end' lines={'none'} color={colorItem}>
                        <IonLabel className='no-margin-top' slot={""}>Dirección de entrega:<br/>
                            <small>{shipping.address}, {shipping.borough}, {shipping.zipCode}<br/>
                                Referencia: {shipping.reference}
                            </small>
                        </IonLabel>
                    </IonItem>

                    <IonItem className='no-padding-inline-end' lines={'none'} color={colorItem}>
                        <IonLabel slot={"start"} className={'ion-text-wrap'}>
                            Método de pago:<br/>
                            <small><OrderPaymentMethod paymentMethod={order.paymentMethod}/></small>
                        </IonLabel>
                        <IonLabel slot={"end"} className={'ion-text-wrap'}>
                            Total:<br/>
                            <small>
                                {money(order.displayTotals.parsedTotal)}
                            </small>
                        </IonLabel>
                    </IonItem>
                    {
                        order.shippingMethod.name === 'Programada' ?
                            <IonItem className={'no-padding-inline-end'} lines={'none'} color={colorItem}>
                                <IonLabel>
                                    Fecha de entrega:
                                    <br/>
                                    <small>
                                        {deliveryBlock?.dayLabel} / {deliveryBlock?.hoursLabel}
                                    </small>
                                </IonLabel>
                            </IonItem>
                            : null

                    }
                </div>

            </IonItem>
            <IonItemOptions>
                <IonItemOption color="tertiary" onClick={(ev) => {goToPharmacyLocation()}}>
                    {
                        showSpinner ? <IonSpinner></IonSpinner> : 'Ver ubicación de farmacia'
                    }
                </IonItemOption>
                <IonItemOption color="primary" onClick={(ev) => onMarkAsWorking(order)}>
                    {
                        order.userWorkingOnThisOrder === user.uid ? 'Desmarcar como trabajando' : 'Marcar como trabajando'
                    }
                </IonItemOption>
            </IonItemOptions>
        </IonItemSliding>
    )

};

export default OrderListItemOperator;
