/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * July 2022
 */
import React from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    withIonLifeCycle,
    IonButtons,
    IonButton,
    IonFooter,
    IonCard,
    IonItem,
    IonLabel,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonBackButton,
    IonProgressBar, IonToast, IonSelect, IonSelectOption
} from '@ionic/react';
import {Camera, CameraResultType} from '@capacitor/camera';
import {RouteComponentProps} from "react-router";
import {camera, close} from "ionicons/icons";
import {CartItemType} from "../../lib/data_types/dataTypes";


//style
import "../../css/pages/prescription.css"


//components
import GradientDivider from "../../components/ui/GradientDivider";
import WebHeader from "../../components/ui/WebHeader";


interface IdUploadProps extends RouteComponentProps {
};

interface IdUploadState {
    imagesUrl: any;
    isLoading: boolean;
    loadSavedImage: boolean;
    toastMsg: string;
    showToast: boolean;
    savedCartItem?: CartItemType;
    imageFrontUrl: string,
    imageBackUrl: string,
    documentType: string
}


class IdUpload extends React.Component<IdUploadProps, IdUploadState> {
    constructor(props: IdUploadProps) {
        super(props);
        this.state = {
            imagesUrl: [],
            imageFrontUrl: '',
            imageBackUrl: '',
            documentType: 'INE',
            isLoading: false,
            loadSavedImage: false,
            toastMsg: '',
            showToast: false,
            savedCartItem: undefined,
        }
    }

    ionViewDidEnter() {
        let i = this.props.location.state as any;
        let cartId = i.cartId;
    }

    validate = () => {
        const documentType = this.state.documentType;
        const imageFrontUrl = this.state.imageFrontUrl;
        const imageBackUrl = this.state.imageBackUrl;

        if (documentType === 'INE') {
            if (!imageFrontUrl) {
                return {
                    isValid: false,
                    error: 'Agrega la imagen frontal de tu identificación'
                }
            }
            if (!imageBackUrl) {
                return {
                    isValid: false,
                    error: 'Agrega el reverso de tu identificación'
                }
            }
        } else if (documentType === 'pasaporte') {
            if (!imageFrontUrl) {
                return {
                    isValid: false,
                    error: 'Agrega la imagen frontal de tu identificación'
                }
            }
        } else {
            return {
                isValid: false,
                error: 'Elige un tipo de identificación'
            }
        }

        return {
            isValid: true,
            error: ''
        }
    }

    saveIdImages = () => {
        //validate
        const _v = this.validate();

        if (_v.isValid) {
            let i = this.props.location.state as any;
            const cartId = i.cartId;
            const documentType = this.state.documentType;
            let id = {};

            if (documentType === 'INE') {
                id = {
                    documentType: this.state.documentType,
                    imageFrontUrl: this.state.imageFrontUrl,
                    imageBackUrl: this.state.imageBackUrl
                }
            } else {
                id = {
                    documentType: this.state.documentType,
                    imageFrontUrl: this.state.imageFrontUrl,
                }
            }

        } else {
            this.setState({toastMsg: _v.error, showToast: true});
        }
    }

    _takePicture = async (id: any) => {
        try {
            const image = await Camera.getPhoto({
                quality: 90,
                resultType: CameraResultType.Base64,
                promptLabelHeader: 'Agregar identificación',
                promptLabelPhoto: 'Seleccionar de la galería',
                promptLabelPicture: 'Tomar una fotografía'
            });
            // image.webPath will contain a path that can be set as an image src.
            // You can access the original file using image.path, which can be
            // passed to the Filesystem API to read the raw data of the image,
            // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
            const imageBase64 = image.base64String;
            if (imageBase64) {
                this.setState({
                    [id]: imageBase64,
                } as Pick<IdUploadState, keyof IdUploadState>);
            }
        } catch (e) {
            this.setState(
                {
                    toastMsg: 'No pudimos abrir la cámara. Por favor, vuelve a intentarlo',
                    showToast: true
                });

        }

    }

    removePicture = (image: any) => {
        this.setState({
            [image]: '',
            loadSavedImage: false
        } as Pick<IdUploadState, keyof IdUploadState>);
    }

    renderAddButton() {
        if (this.state.isLoading) {
            return (
                <IonProgressBar type="indeterminate"></IonProgressBar>
            )
        } else {
            return (
                <IonButton className="ion-margin-horizontal w-100 ion-text-uppercase"
                           onClick={this.saveIdImages}
                           color="primary"
                           expand="block"
                           disabled={this.state.loadSavedImage}
                           fill='solid'>SUBIR IDENTIFICACIÓN</IonButton>
            )
        }
    }

    renderId = (img: string, id: any) => {
        if (id === 'imageBackUrl' && this.state.documentType === 'pasaporte') {
            return null
        } else {
            if (img) {
                let imgSrc = `data:image/jpeg;base64,${img}`
                return (
                    <div className={'wrapper-image-prescription'}>
                        <img className="w-100" src={imgSrc} alt={'prescription-image'}>
                        </img>
                        <IonButton className="fab-camera-prescription-btn"
                                   fill="clear"
                                   shape="round"
                                   size="large"
                                   color={"danger"}
                                   strong
                                   onClick={() => this.removePicture(id)}>
                            <IonIcon slot={"icon-only"} icon={close}></IonIcon>
                        </IonButton>
                    </div>
                )
            }
        }
    }

    renderUploadBtn = (id: any) => {
        if (!this.state.imageFrontUrl) {
            return (
                <IonButton expand={'block'}
                           fill={"outline"}
                           className={'ion-text-wrap'}
                           onClick={() => this._takePicture(id)}>
                    <IonIcon slot={"icon-only"} icon={camera}></IonIcon>
                    Agregar parte frontal
                </IonButton>
            )
        }
    }

    renderUploadBackImage = (id: any) => {
        if (!this.state.imageBackUrl && this.state.imageFrontUrl && this.state.documentType === 'INE') {
            return (
                <IonButton expand={'block'}
                           fill={"outline"}
                           color={'secondary'}
                           className={'ion-text-wrap'}
                           onClick={() => this._takePicture(id)}>
                    <IonIcon slot={"icon-only"} icon={camera}></IonIcon>
                    Agregar reverso
                </IonButton>
            )
        }
    }

    render() {
        return (
            <IonPage>
                <IonToast
                    isOpen={this.state.showToast}
                    onDidDismiss={() => this.setState({showToast: false})}
                    message={this.state.toastMsg}
                    duration={1000}/>
                <WebHeader title={'Subir Identificación'} showSearchButton={false} showCartButton={false} showMenuButton={false}></WebHeader>
                <IonContent fullscreen className="ion-padding-vertical">
                    <IonGrid>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size-md="6">
                                <IonCard className={'ion-padding ion-no-margin ion-margin-bottom'}>
                                    <IonItem lines={'none'} className={'bg-transparent no-padding-inner-horizontal'}>
                                        <IonLabel>Identificación: </IonLabel>
                                        <IonSelect interface="popover"
                                                   value={this.state.documentType}
                                                   onIonChange={(ev) => {
                                                       this.setState({documentType: ev.detail.value})
                                                   }}
                                                   placeholder="Selecciona el tipo de identificación">
                                            <IonSelectOption value="INE">INE</IonSelectOption>
                                            <IonSelectOption value="pasaporte">Pasaporte</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonCard>
                                <IonCard className={'ion-padding ion-no-margin ion-margin-bottom'}>
                                    {this.renderId(this.state.imageFrontUrl, 'imageFrontUrl')}
                                    {this.renderId(this.state.imageBackUrl, 'imageBackUrl')}
                                    {this.renderUploadBtn('imageFrontUrl')}
                                    {this.renderUploadBackImage('imageBackUrl')}
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButtons>
                            {this.renderAddButton()}
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(IdUpload);
