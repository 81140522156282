/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2021
 */

import {IonBadge} from "@ionic/react";
import React from "react";
interface OrderStatusProps {
  status: string;
  className?: string;
  slot?: string;
}

const OrderStatus: React.FC<OrderStatusProps> = ({ status,className="", slot="" }) => {
    let color = '';
    let label = '';
    
    switch (status){
        case 'processing':
            color  = 'tertiary';
            label = 'Procesando';
            break;
        case 'received':
            color = 'tertiary';
            label = 'Recibida';
            break;
        case 'amount_validated':
            color  = 'tertiary';
            label = 'Monto validado';
            break;
        case 'on_route':
            color = 'tertiary';
            label = 'En camino';
            break;
        case 'delivering':
            color = 'tertiary';
            label = 'Entregando';
            break;
        case 'delivered':
            color  = 'tertiary';
            label = 'Entregado';
            break;
        case 'finished':
            color  = 'tertiary';
            label = 'Terminado';
            break;
        case 'cancelled':
            color  = 'danger';
            label = 'Cancelado';
            break;
        default:
            color = 'tertiary';
            label = '---'
            break;
    }

    return <IonBadge className={className} slot={slot} color={color}>Estado: {label}</IonBadge>
};

export default OrderStatus;
