import React, {useContext, useEffect, useRef, useState} from "react";
import {
    IonButton, IonButtons,
    IonContent,
    IonHeader, IonIcon,
    IonItem,
    IonMenu, IonNote,
    IonRippleEffect, IonSkeletonText,
    IonText,
    IonThumbnail,
    IonTitle,
    IonToolbar
} from "@ionic/react";

import '../../css/components/Menu.css'
import {AppContext} from "../../App";
import {useHistory} from "react-router";
import API from "../../lib/api/NbioApi";
import {personCircle, personCircleOutline, refreshOutline} from "ionicons/icons";
import BtnToolbarLogo from "../ui/BtnToolbarLogo";
import {EVENTS} from "../../lib/events/events";
import {Link} from "react-router-dom";

interface MenuProps {

}

export const Menu: React.FC<MenuProps> = ({}) => {
    const {user, setUser, isAuth, setIsAuth} = useContext(AppContext);
    const history = useHistory();
    const [categories, setCategories] = useState([]);
    const [errored, setErrored] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const menuRef: any = useRef();
    const closeMenu = () => {
        menuRef.current.close();
    }

    const goToProfile = () => {
        history.push('/perfil');
    }
    const openMenu = () => {
        menuRef.current.open();
    }
    useEffect(() => {
        loadCategories();
        window.addEventListener(EVENTS.OPEN_MENU, openMenu);
    }, []);
    const loadCategories = () => {
        setLoading(true);
        API.categories.get(1, 10).then((res) => {
            setCategories(res.data.categories);
            setLoaded(true);
            setLoading(false);
            setErrored(false);
        }).catch((ex) => {
            setErrored(true);
            setLoading(false);
        });
    }
    const renderCategories = () => {
        return categories.map((category: any) => {
            return (

                <Link to={`/categorias/${category.handleUrl}`}>
                    <div className={'nbio-menu-category-name ion-activatable ripple-parent'}
                         onClick={() => closeMenu()} key={category._id}>
                        <IonRippleEffect>

                        </IonRippleEffect>
                        <IonText>
                            {category.name}
                        </IonText>
                    </div>
                </Link>

            )
        })
    }
    const renderProfile = () => {
        if (!isAuth) {
            return (
                <div className={'nbio-menu-profile'}>
                    <Link to={'/perfil'} onClick={() => {
                        closeMenu()
                    }}>
                        <div
                            className={'ion-activatable ripple-parent border-radius-100 nbio-menu-thumbnail-wrapper cursor-pointer'}
                        >
                            <div className={'nbio-menu-thumbnail'}
                                 style={{
                                     backgroundImage: `url(https://nbio.blob.core.windows.net/cdn/nbio-default-profile.png)`,
                                     backgroundSize: 'cover',
                                     backgroundColor: 'white'
                                 }}>

                            </div>
                            <IonRippleEffect></IonRippleEffect>
                        </div>
                    </Link>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: '10px',
                        marginLeft: '1rem'
                    }}>
                        <IonButton fill={'outline'} size={'small'} routerLink={'/iniciar-sesion'} onClick={() => {
                            closeMenu();
                        }}>
                            &nbsp;INICIAR SESIÓN
                        </IonButton>
                    </div>
                </div>
            )
        }
        const profileUrl = user.profileUrl || 'https://nbio.blob.core.windows.net/cdn/nbio-default-profile.png';
        return (
            <div className={'nbio-menu-profile'}>
                <div
                    className={'ion-activatable ripple-parent border-radius-100 nbio-menu-thumbnail-wrapper cursor-pointer'}
                    onClick={() => {
                        goToProfile()
                    }}
                >
                    <div className={'nbio-menu-thumbnail'}
                         style={{
                             backgroundImage: `url(${profileUrl})`,
                             backgroundSize: 'cover'
                         }}>

                    </div>
                    <IonRippleEffect></IonRippleEffect>
                </div>
                <div className={'nbio-menu-profile-content'}>
                    <div className={'ion-text-capitalize ion-text-wrap'}>
                        <b>{user.name} {user.last_name}</b>
                    </div>
                    <div>
                            <span className={'cursor-pointer ion-activatable ripple-parent'}>
                                <Link to={'/perfil'} onClick={() => {
                                    closeMenu()
                                }}>
                                    <IonRippleEffect>
                                    </IonRippleEffect>
                                    Perfil
                                </Link>
                            </span>
                        &nbsp;|&nbsp;
                        <span className={'cursor-pointer ion-activatable ripple-parent'}>
                            <Link to={'/ordenes'} onClick={() => {
                                closeMenu()
                            }}>
                                <IonRippleEffect>
                                </IonRippleEffect>
                                Órdenes
                            </Link>
                            </span>
                    </div>

                </div>
            </div>

        )
    }
    return (
        <IonMenu ref={menuRef}>
            <IonHeader>
                <IonToolbar className={'nbio-menu-header'}>
                    <IonButtons slot={"start"}>
                        <BtnToolbarLogo/>
                        <IonButton fill="clear"
                                   className="web-header-nbio"
                                   routerDirection="root" routerLink="/inicio">
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding nbio-menu-content">
                {/*Profile*/}

                {
                    renderProfile()
                }
                {/* Categories   */}
                <IonText className={'m-t-20 nbio-menu-subtitle'}>
                    <h5 className={'ion-text-capitalize'}>
                        Categorías
                    </h5>
                </IonText>

                {
                    loading ?
                        [1, 2, 3, 4, 5].map((v) => {
                            return (
                                <IonSkeletonText className={'nbio-menu-category-name ion-activatable ripple-parent'}
                                                 key={v} animated={true}>
                                </IonSkeletonText>
                            )
                        }) : null
                }
                {
                    !errored && loaded ? renderCategories() : null
                }
                {
                    !errored && loaded ?
                        <div>
                            <div className={'nbio-menu-category-name ion-activatable ripple-parent'}
                                 onClick={() => closeMenu()}>
                                <Link to={'/descuentos'}>
                                    <IonRippleEffect>

                                    </IonRippleEffect>
                                    <IonText>
                                        Descuentos
                                    </IonText>
                                </Link>
                            </div>
                            <div className={'nbio-menu-category-name ion-activatable ripple-parent'}
                                 onClick={() => closeMenu()}>
                                <Link to={'/categorias'}>
                                    <IonRippleEffect>

                                    </IonRippleEffect>
                                    <IonText>
                                        <b>Todas las categorías</b>
                                    </IonText>
                                </Link>
                            </div>
                        </div>  : null
                }
                {
                    errored ?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginTop: '10px'
                        }} onClick={() => {
                            loadCategories();
                        }}>
                            <IonNote>¡Algo salió mal!</IonNote>
                            <IonButton fill={'outline'} size={'small'}>
                                <IonIcon icon={refreshOutline}></IonIcon>
                                Reintentar
                            </IonButton>
                        </div> : null
                }


                {/*    Need Help?*/}
                <IonText className={'nbio-menu-subtitle'}>
                    <h5 className={'ion-text-capitalize'}>
                        ¿Necesitas Ayuda?
                    </h5>
                </IonText>
                <IonText className={'display-block nbio-menu-need-help-text'}>
                    <a href={'tel:+525641674170'} target={'_blank'}>
                        ¿No encontraste algún producto? ¿Tuviste algún problema? Llama a nuestra línea de atención a
                        clientes <b>Aquí.</b>
                    </a>

                </IonText>
            </IonContent>
        </IonMenu>
    )
}
