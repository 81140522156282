/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2021
 */

import React, {useContext, useState, useEffect} from 'react';
import {
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
} from '@ionic/react';
import {AppContext} from "../../App";

import {informationCircle} from "ionicons/icons";

//components
import GradientDivider from "../../components/ui/GradientDivider";
import BtnToolbarLogo from "../../components/ui/BtnToolbarLogo";
import Profile from "../../components/Profile";
import WebHeader from "../../components/ui/WebHeader";
import UserLoginInvitation from "../../components/ui/UserLoginInvitation";


const TabProfile: React.FC = () => {
    const [renderComponent, setRenderComponent] = useState('');
    const {user, setUser, isAuth, setIsAuth} = useContext(AppContext);

    useEffect(() =>{
        if(!isAuth){
            setRenderComponent('login-invitation');
        }else{
            setRenderComponent('profile');
        }
    },[isAuth])

    const displayComponent = () => {
        switch (renderComponent) {
            case 'profile':{
                return <Profile></Profile>
            }
            case 'login-invitation':{
                return <UserLoginInvitation></UserLoginInvitation>
            }
            default:{
                return null
            }
        }
    }

    const renderInfoBtn = () => {
        if(renderComponent === 'profile'){
            return(
                <IonButtons slot="end">
                    <IonButton routerDirection={'forward'} routerLink={'/informacion'}>
                        <IonIcon icon={informationCircle}></IonIcon>
                    </IonButton>
                </IonButtons>
            )
        }else{
            return null
        }
    }

    return (
        <IonPage>
            {
                user.roles.includes('operator') ? null :
                    <WebHeader title={'Perfil'} showSearchButton={true}></WebHeader>
            }
            {displayComponent()}
        </IonPage>
    )
}

export default TabProfile;

