/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2022
 */


import React, {useState} from "react";
import {
    IonRippleEffect
} from "@ionic/react";
import {CouponType} from "../../lib/data_types/dataTypes";
import dayjs from "dayjs";
import utc   from "dayjs/plugin/utc";
import "dayjs/locale/es";

//style
import "../../css/components/coupon.css"

//components
import CouponTermsAndConditionsModal from "./CouponTermsAndConditionsModal";

interface CouponProps{
    coupon: CouponType;
    onClickCoupon?: any
}

//dates
dayjs.extend(utc);


const Coupon: React.FC<CouponProps> = ({coupon, onClickCoupon = () => {}}) => {
    const [isModalOpen,setIsModalOpen] = useState(false);


    const renderTitle = () => {
        if(coupon.type === 'free_shipping'){
            return 'Envio gratis'
        }

        return `${coupon.value} ${coupon.discountType === 'fixed_amount' ? 'MXN' : '%'} OFF`;
    }

    const renderAppliesTo = () => {
        let txt = 'Aplica a todos los productos';

        if(coupon.products.length > 0 || coupon.categories.length > 0){
            return 'Aplica a productos seleccionados'
        }
        if(coupon.appliesToAllProducts || coupon.appliesToAllCategories){
            return txt
        }

        return txt;
    }

    const renderMinMaxPurchase = () => {
        let txt = 'No hay mínimo de compra'
        let txtLimit =`Con límite de ${coupon.maximumPurchase} mxn.`
        if(coupon.hasMinimumPurchase){
            return `Mínimo de compra ${coupon.minimumPurchase} mxn. ${coupon.hasMaximumPurchase ? txtLimit : ''}`
        }
        if(coupon.hasMaximumPurchase){
            return  txtLimit
        }
        return txt
    }

    const expirationDate = coupon.endDate ? dayjs(coupon.endDate).format('DD/MM/YYYY') : 'agotar existencias.';

    return (
        <div className={'coupon ion-activatable ion-margin-bottom'} onClick={() => onClickCoupon(coupon._id)}>
            <IonRippleEffect></IonRippleEffect>
            <div className={'coupon-body'}>
                <div className={'coupon-title'}>{renderTitle()}</div>
                <div className={'coupon-subtitle'}>{coupon.code}</div>
                <div className={'coupon-subtitle'}><small>{renderMinMaxPurchase()}</small></div>
                <div className={'coupon-subtitle'}><small>{renderAppliesTo()}</small></div>
            </div>
            <div className={'coupon-footer'}>
                <div><small>Válido hasta {expirationDate}</small></div>
                <div className={'coupon-tiny-btn'} onClick={(e) => {
                    e.stopPropagation();
                    setIsModalOpen(true);
                }}>
                    T&C
                </div>
            </div>
            <div className="circle1"></div>
            <div className="circle2"></div>

            <CouponTermsAndConditionsModal coupon={coupon} isModalOpen={isModalOpen} onClickCloseModal={() => setIsModalOpen(false)}/>

        </div>
    );
};

export default Coupon;
