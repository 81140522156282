/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * April 2022
 */

import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonModal,
    IonPage,
    IonToolbar
} from "@ionic/react";
import '../../css/style.css';
import React, {useRef, useState} from "react";
import set = Reflect.set;
import {add, close, refresh, reload, remove, star} from "ionicons/icons";

const NPLightBox: React.FC<{ isOpen: boolean, url: string, onClose: any }> = ({isOpen, url, onClose}) => {
    const [translateX, setTranslateX] = useState(0);
    const [translateY, setTranslateY] = useState(0);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [currentX, setCurrentX] = useState(0);
    const [currentY, setCurrentY] = useState(0);
    const [rotation, setRotation] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [zoom, setZoom] = useState(1)
    const imgEl = useRef(null);
    const getTransformStr = () => {
        return `translate(${translateX}px, ${translateY}px) scale(${zoom}) rotate(${rotation}deg)`
    }
    const increaseZoom = () => {
        if (zoom >= 5) {
            setZoom(5);
        } else {
            setZoom(zoom + 1)
        }
    }
    const decreaseZoom = () => {
        if (zoom <= 1) {
            setZoom(1);
        } else {
            setZoom(zoom - 1)
        }
    }
    const rotate = () => {
        if (rotation === 0) {
            setRotation(90);
        } else if (rotation === 90) {
            setRotation(180);
        } else if (rotation === 180) {
            setRotation(270);
        } else {
            setRotation(0);
        }
    }
    return (
        <IonModal isOpen={isOpen}>
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                    <IonButtons>
                        <IonButton onClick={() => rotate()}>
                            <IonIcon icon={reload}>

                            </IonIcon>
                        </IonButton>
                        <IonButton onClick={() => increaseZoom()}>
                            <IonIcon icon={add}>

                            </IonIcon>
                        </IonButton>
                        <IonButton onClick={() => decreaseZoom()}>
                            <IonIcon icon={remove}>

                            </IonIcon>
                        </IonButton>

                    </IonButtons>
                    <IonButtons slot={'end'}>
                        <IonButton onClick={() => onClose()}>
                            <IonIcon icon={close}>

                            </IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div
                        style={{
                            backgroundColor: 'gray',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                        }}
                        onTouchStart={(ev) => {
                            // console.log(ev)
                            // console.log('start',ev.touches[0].clientX,ev.touches[0].clientY)
                            setStartX(ev.touches[0].clientX);
                            setStartY(ev.touches[0].clientY);
                            setIsDragging(true);
                        }}
                        onTouchMove={(ev) => {
                            const img = document.getElementsByClassName('np-lb-img')[0];
                            const imgWidth = img.getBoundingClientRect().width;
                            const imgHeight = img.getBoundingClientRect().height;
                            const x = img.getBoundingClientRect().x;
                            const y = img.getBoundingClientRect().y;

                            // const w = imgEl.current().getBBox().width
                            // get pinch
                            if (ev.changedTouches.length > 1) {
                                const x1 = ev.changedTouches[0].clientX;
                                const x2 = ev.changedTouches[1].clientX;

                                const y1 = ev.changedTouches[0].clientX;
                                const y2 = ev.changedTouches[1].clientY;
                                let distance = Math.sqrt(Math.pow((x2 - x1), 2) + Math.pow((y2 - y1), 2));
                                if (distance < 200) {
                                    // setZoom(1);
                                } else if (distance > 400) {
                                    // setZoom(5);
                                } else {

                                }
                                const newZoom = distance / 1000 * 5;

                                // if((distance - prevDistance) >= 50){
                                //     setZoom(newZoom)
                                // console.log(distance)
                                // setPrevDistance(distance - prevDistance);

                                // }

                                // Min: 100 = 1
                                // Max: 400 = 10
                            } else {
                                // diff
                                const diffX = ev.changedTouches[0].clientX - imgWidth
                                const diffY = ev.changedTouches[0].clientY - imgHeight;

                                const distX = ev.changedTouches[0].clientX - startX;
                                const distY = ev.changedTouches[0].clientY - startY;
                                const moveX = (distX) + 0;
                                const moveY = (distY) + 0;
                                setTranslateX(moveX + currentX);
                                setTranslateY(moveY + currentY);
                            }
                        }}
                        onTouchEnd={(ev) => {
                            // save new position
                            setCurrentX(translateX);
                            setCurrentY(translateY);
                            setIsDragging(false);
                        }}

                    >
                        <img
                            className={'w-100 h-auto np-lb-img'}
                            ref={imgEl}
                            style={{
                                'transform': getTransformStr(),
                                'transition': isDragging ? '' : '0.3s all'
                            }}
                            src={url}
                        ></img>
                    </div>

                </IonContent>

            </IonPage>


        </IonModal>
    );
};

export default NPLightBox;
