/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */
import React from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonItem,
    IonLabel,
    IonContent,
    IonPage,
    withIonLifeCycle,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    IonFooter, IonCard, IonCardContent, IonBadge, IonCheckbox, IonIcon, IonProgressBar,
} from '@ionic/react';
import {RouteComponentProps} from "react-router";
import {AddressType} from "../../lib/data_types/dataTypes"

//components
import GradientDivider from "../../components/ui/GradientDivider";
import API from "../../lib/api/NbioApi";
import {navigateCircle} from "ionicons/icons";
import WebHeader from "../../components/ui/WebHeader";

//Interfaces
interface AddressListState {
    addressList: AddressType[];
    isLoading:boolean;
}

interface AddressListProps extends RouteComponentProps {
}

interface AddressListData {
    goBackTo?: string
}

class AddressList extends React.Component<AddressListProps, AddressListState> {
    constructor(props: AddressListProps) {
        super(props);
        this.state = {
            addressList: [],
            isLoading:true
        }
    }

    ionViewDidEnter() {
        this.loadAddresses();
    }

    renderIsDefault = (isDefault: boolean) => {
        if(isDefault){
            return <IonBadge color={"secondary"} className={"ion-text-start ion-text-wrap"}>Dirección predeterminada</IonBadge>
        }
    }

    loadAddresses(){
        this.setState({addressList:[],isLoading:true})
        API.addresses.get().then((res:any) =>{
            let addressList = res.data.addresses;
            this.setState({addressList:addressList,isLoading:false})
        }).catch((ex) =>{
            this.setState({addressList:[],isLoading:false})
        })
    }

    onSelectAddress = (_id: string) => {
        let i = this.props.location.state as AddressListData;
        if(i.goBackTo === '/confirmar-pedido'){
            this.props.history.replace({
                pathname:'/confirmar-pedido',
                state:{
                    addressId:_id
                }
            });
        }
    }

    onDeleteAddress(addressId:string){
        API.addresses.delete(addressId).then((res) =>{
            this.loadAddresses();
        }).catch((ex) =>{

        })
    }

    editAddress(addressId:string){
        let i = this.props.location.state as AddressListData;
        if(i){
            this.props.history.replace({
                pathname:`/direcciones/${addressId}`,
                state:{
                    addressId:addressId,
                    onSavePath:i.goBackTo
                }
            });
        }
    }
    
    renderAddressesList(){
        const addresses = this.state.addressList;
        if(this.state.isLoading){
            return (
                <div className={'ion-padding ion-text-center'}>
                    <br/>
                    <br/>
                    <IonProgressBar type="indeterminate" color={'primary'}></IonProgressBar>
                </div>
            )
        }
        if(addresses.length === 0){
            return(
                <div className={'ion-padding ion-text-center'}>
                    No tienes direcciones guardadas.
                    <br/>
                    <br/>

                    <IonIcon icon={navigateCircle} color={'medium'} size={'large'} slot={'icon-only'}></IonIcon>
                </div>
            )
        }else{
            {
                return (addresses.map(address => {
                    return(
                        <IonCard key={address._id} className={'ion-margin-top'}>
                            <IonItem lines="none" button={true} onClick={() => this.onSelectAddress(address._id)}>
                                <IonLabel className={"ion-text-capitalize"}>{address.name} {address.lastName}</IonLabel>
                                <IonCheckbox checked={address.isDefault}></IonCheckbox>
                            </IonItem>
                            <IonCardContent className={"no-padding-vertical"}>
                                <small>{address.address}, {address.borough}, {address.zipCode}<br/>
                                    {address.reference}
                                </small>
                            </IonCardContent>
                            <IonItem>
                                {this.renderIsDefault(address.isDefault || false)}
                                <div slot={'end'}>
                                    <IonButton slot={"start"}
                                               size={"small"}
                                               fill={"outline"}
                                               color={'danger'}
                                               onClick={() => this.onDeleteAddress(address._id)}
                                    >Eliminar</IonButton>
                                    <IonButton slot={"end"}
                                               size={"small"}
                                               fill={"outline"}
                                               onClick={() => this.editAddress(address._id)}
                                    >Editar</IonButton>
                                </div>

                            </IonItem>
                        </IonCard>
                    )
                }))
            }
        }
    }

    renderBtnFooter = () => {
        return(
            <IonRow className="ion-justify-content-center ion-hide-lg-down">
                <IonCol size-md="6" size-lg={"6"}>
                    <IonButton color="primary"
                               expand="block"
                               fill='solid'
                               onClick={() => this.onCreateNewAddress()}>
                        Agregar dirección
                    </IonButton>
                </IonCol>
            </IonRow>
        )
    }

    onCreateNewAddress = () => {
        let i = this.props.location.state as AddressListData;
        if(i){
            this.props.history.replace({
                pathname:`/direcciones/agregar`,
                state:{
                    onSavePath:i.goBackTo
                }
            });
        }
    }

    render() {
        return (
            <IonPage>
                <WebHeader title={'Mis direcciones'} showSearchButton={false}></WebHeader>
                <IonContent fullscreen>
                    <IonGrid className={'ion-no-padding'}>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size-md="6">
                                {this.renderAddressesList()}
                            </IonCol>
                        </IonRow>
                        {/*Todo: uncommentedFooter btn for web*/}
                        {/*{this.renderBtnFooter()}*/}
                    </IonGrid>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonGrid>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size-md="6">
                                    <IonButtons>
                                        <IonButton className="w-100"
                                                   color="primary"
                                                   expand="block"
                                                   fill='solid'
                                                   onClick={() => this.onCreateNewAddress()}>
                                            Agregar dirección
                                        </IonButton>
                                    </IonButtons>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(AddressList);
