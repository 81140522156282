const parseMoney =  (m) =>{
    if(!m){
        return '$0.00';
    }
    return `$${(Math.trunc(m*100) / 100).toFixed(2)}`;
}
const money = (amount) =>{
    if(!amount){
        return '$0.00'
    }else{
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
    }
}
module.exports = {
    parseMoney:parseMoney,
    money:money
}
