import React, {useEffect, useState} from "react";
import {
    IonAccordion,
    IonAccordionGroup,
    IonAlert, IonBackButton, IonBadge, IonBreadcrumb, IonBreadcrumbs,
    IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonChip,
    IonCol, IonContent, IonGrid, IonHeader, IonIcon,
    IonInput,
    IonItem, IonItemDivider, IonItemGroup,
    IonLabel, IonModal, IonNavLink, IonNote, IonPage,
    IonRadio, IonRadioGroup, IonRow,
    IonSelect,
    IonSelectOption, IonSpinner, IonTextarea, IonTitle, IonToast,
    IonToggle, IonToolbar
} from "@ionic/react";
import StaticMapNbio from "../../components/map/StaticMapNbio";
import NbioApi from "../../lib/api/NbioApi";
import Cart from "../../lib/cart/Cart";
import API from "../../lib/api/NbioApi";
import {AddressType, CartItemType, ProgrammedHourType, ShippingMethodType} from "../../lib/data_types/dataTypes";
import {Swiper, SwiperSlide} from "swiper/react";
import {money} from "../../lib/money/money";
import {
    add,
    calculator,
    card, cardOutline,
    cash, chevronDown, close, cubeOutline, locateOutline,
    lockClosed,
    lockClosedOutline,
    lockClosedSharp, logoWhatsapp,
    pencil,
    reload, remove
} from "ionicons/icons";
import CartList from "../../components/cart/CartList";
import BtnToolbarLogo from "../../components/ui/BtnToolbarLogo";
import AppBannerHeader from "../../components/ui/AppBannerHeader";
import {useHistory} from "react-router";
import {emmitEvent, EVENTS} from "../../lib/events/events";
import PrivacyPolicy from "../info/PrivacyPolicy";
import PrivacyPolicyContent from "../info/PrivacyPolicyContent";
import FAQContent from "../info/FAQContent";
import TOSContent from "../info/TOSContent";
import {CfdiUsages, FiscalRegimes} from "../../lib/data/invoicing";

const validateEmail = require("email-validator");
const mpKey = process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY || ''
const addressStates = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de México",
    "Coahuila",
    "Colima",
    "Durango",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "México",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas"
];
let cardPaymentBrickController: any = null;
const ConfirmOrderGuest: React.FC = () => {
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [borough, setBorough] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [state, setState] = useState('');
    const [reference, setReference] = useState('');
    const [email, setEmail] = useState('');
    const [checkoutId, setCheckoutId] = useState('');
    const [shippingMethods, setShippingMethods] = useState([]);
    const [selectedShippingMethodId, setSelectedShippingMethodId] = useState('');
    const [selectedShippingMethod, setSelectedShippingMethod] = useState({
        _id: '',
        shippingType: '',
        price: 0,
        oldPrice: 0,
        name: ''
    });
    const [programmedHours, setProgrammedHours] = useState([]);
    const [selectedProgrammedHour, setSelectedProgrammedHour] = useState({id: '', startTime: undefined});
    const [shipping, setShipping] = useState({});
    const [checkout, setCheckout] = useState({
        region: {
            paymentMethods: undefined
        },
        freeShipping: {
            hasFreeShipping: undefined
        },
        totals: {
            subTotal: undefined,
            totalTax: undefined,
            totalShipping: undefined,
            totalDiscount: undefined,
            gratuityTotal: undefined,
            total: undefined
        },
        selectedShippingMethod: {
            oldPrice: undefined,
            name: ''
        },
        items: [],
        shippingMethod: {
            name: '',
            price: 0
        },
        requiresInvoice: false
    });
    const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [gratuityToastIsOpen, setGratuityToastIsOpen] = useState(false);
    const [gratuityAlertIsOpen, setGratuityAlertIsOpen] = useState(false);
    const [customGratuityValue, setCustomGratuityValue] = useState(0.0);
    const [selectedGratuityValue, setSelectedGratuityValue] = useState('');
    const [recommendedGratuityValues, setRecommendedGratuityValues] = useState([
        0,
        30,
        40
    ]);
    const [step, setStep] = useState('info');
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
    const [isCreatingAddress, setIsCreatingAddress] = useState(false);
    const [isChangeRequired, setIsChangeRequired] = useState(false);
    const [changeAmount, setChangeAmount] = useState(0);
    const [showBricks, setShowBricks] = useState(false);
    const [canGoToShipping, setCanGoToShipping] = useState(false);
    const [canGoToPayment, setCanGoToPayment] = useState(false);
    const [mercadoPagoPaymentIntentId, setMercadoPagoPaymentIntentId] = useState('');
    const [isFormReady, setIsFormReady] = useState(false);
    const [isPaying, setIsPaying] = useState(false);
    const [isTCPopupOpen, setIsTCPopupOpen] = useState(false);
    const [isFAQPopupOpen, setIsFAQPopupOpen] = useState(false);
    const [isPPPopupOpen, setIsPPPopupContent] = useState(false);
    const [isPlacingPOSOrder, setIsPlacingPOSOrder] = useState(false);
    const [isPlacingCashOrder, setIsPlacingCashOrder] = useState(false);
    const [isCheckingMPOrder, setIsCheckingMPOrder] = useState(false);
    // CFDI Invoicing
    const [requiresInvoice, setRequiresInvoice] = useState(false);
    const history = useHistory();

    let mp = null;
    const loadCheckout = async () => {
        let cartId = Cart.getCartId() || '';
        try {
            const res = await NbioApi.checkout.createCheckout(cartId);
            setCheckoutId(res.data.checkout.checkoutId);
            setCheckout(res.data.checkout);
            const c = await Cart.getCart();
            const items = c?.data?.cart?.items;

            setItems(items);
        } catch (ex) {
            console.log(ex);
        }

    }
    const updateCart = async (values = {}) => {
        let cartId = Cart.getCartId() || '';
        try {
            const res = await NbioApi.checkout.updateCheckout(values, checkoutId);
            loadShippingMethods();
            setCheckout({...res.data.checkout});
            console.log(checkout, res.data.checkout);
            return res;
        } catch (ex) {
            console.log(ex);
        }
    }
    const createAddress = async (newStep = 'shipping') => {
        setIsCreatingAddress(true);
        const validateContactRes = validateContact();
        if (!validateContactRes.isValid) {
            setErrorMessage(validateContactRes.message);
            setIsErrorMessageOpen(true);
            setIsCreatingAddress(false);
            return;
        }
        const validateAddressRes = validateAddress();
        if (!validateAddressRes.isValid) {
            setErrorMessage(validateAddressRes.message);
            setIsErrorMessageOpen(true);
            setIsCreatingAddress(false);
            return;
        }
        let _address = {
            name: name,
            lastName: lastName,
            phone: phone,
            address: address,
            borough: borough,
            zipCode: zipCode,
            reference: reference,
            isDefault: false,
            address2: '',
            addressId: '',
            state: state,
            latitude: 0,
            longitude: 0
        } as AddressType;
        try {
            const addressCreate = await API.addresses.create(_address);
            setShipping(addressCreate.data.address);
            await updateCart({
                contact: {
                    email: email
                },
                shipping: addressCreate.data.address,
                requiresInvoice:requiresInvoice
            });
            setCanGoToShipping(true);
            setStep(newStep);
        } catch (ex) {
            console.log(ex);
        } finally {
            setIsCreatingAddress(false);
        }

    };
    useEffect(() => {
        loadCheckout();
        loadProgrammedHours();
        loadAvailablePaymentMethods();
    }, []);
    const validateContact = () => {
        const isEmailValid = validateEmail.validate(email);
        if (isEmailValid) {
            return {
                isValid: true,
                message: ''
            }
        } else {
            return {
                isValid: false,
                message: 'Ingresa un e-mail válido'
            }
        }
    }
    const validateZipCode = (txt: string) => {
        let re = /\d/;
        if (txt.length !== 5) {
            return {
                isValid: false,
                message: 'El código postal debe de ser de 5 caracteres'
            }
        } else if (!re.test(txt)) {
            return {
                isValid: false,
                message: 'El código postal es incorrecto'
            }
        } else {
            return {
                isValid: true,
                message: ''
            }
        }
    }
    const validateAddress = () => {
        //
        // if(latitude === 0){
        //     this.showErrorMsg('Selecciona una ubicación en el mapa');
        //     return false;
        // }
        // if(longitude === 0){
        //     this.showErrorMsg('Selecciona una ubicación en el mapa');
        //     return false;
        // }
        if (name.trim() === '') {
            return {
                isValid: false,
                message: 'El nombre no puede estar vacío'
            }
        }
        if (lastName.trim() === '') {
            return {
                isValid: false,
                message: 'El apellido no puede estar vacío'
            }
        }
        if (phone.trim() === '') {
            return {
                isValid: false,
                message: 'El número telefónico no puede estar vacío'
            }
        }
        if (address.trim() === '') {
            return {
                isValid: false,
                message: 'La calle no puede estar vacía'
            }
        }
        if (borough.trim() === '') {
            return {
                isValid: false,
                message: 'La delegación no puede estar vacía'
            }
        }
        const zipCodeValidation = validateZipCode(zipCode);
        // const zipCodeRes = this.validateZipCode(zipCode);
        if (!zipCodeValidation.isValid) {
            return {
                isValid: false,
                message: zipCodeValidation.message
            }
        }
        if (!state) {
            return {
                isValid: false,
                message: 'Selecciona un estado'
            }
        }
        if (reference?.trim() === '') {
            return {
                isValid: false,
                message: 'La referecia no puede estar vacía'
            }
        }
        return {
            isValid: true,
            message: ''
        }
    }
    const validateShippingMethod = () => {
        if (!selectedShippingMethod?.name) {
            return {
                isValid: false,
                message: 'Selecciona un método de envío'
            }
        }
        console.log('sph', selectedProgrammedHour);
        if (selectedShippingMethod?.shippingType === 'schedule') {
            if (!selectedProgrammedHour?.startTime) {
                return {
                    isValid: false,
                    message: 'Selecciona un horario para recibir tu pedido'
                }
            }
        }
        return {
            isValid: true,
            message: ''
        }
    }
    const renderContact = () => {
        return (
            <IonCol size-md={6} size-lg={6} className={'ion-margin-bottom'}>
                <h4>Contacto</h4>
                <IonItem className={'ion-margin-top'}>
                    <IonLabel position={"floating"}>Email</IonLabel>
                    <IonInput id="email"
                              type="text"
                              value={email}
                              required={true}
                              minlength={1}
                              onIonChange={(e: any) => setEmail(e.target.value)}
                              enterkeyhint="next"
                              onKeyPress={(ev) => {
                                  // if(ev.key === 'Enter'){
                                  //     this.goNext('name');
                                  // }
                              }}
                              placeholder="Email">
                    </IonInput>
                </IonItem>
                <IonButton size={'small'} fill={'clear'} style={{textTransform: 'inherit'}} onClick={() => {
                    history.push('/iniciar-sesion');
                }}>
                    Crea una cuenta o inicia sesión para agilizar tu compra
                </IonButton>
            </IonCol>
        )
    }
    //Address
    const renderAddress = () => {
        return (

            <IonCol size-md={"6"} size-lg={"6"} className={'ion-margin-bottom'}>
                <h4>Dirección de envío</h4>
                {/*<IonItem lines="none" onClick={() => this.setState({renderContent:'MAP'})}>*/}
                {/*    <div className={'wrapper-static-map-address ion-padding-vertical'}>*/}
                {/*        <StaticMapNbio*/}
                {/*            longitude={this.state.longitude}*/}
                {/*            latitude={this.state.latitude}*/}
                {/*            zoom={20}*/}
                {/*        ></StaticMapNbio>*/}
                {/*    </div>*/}
                {/*</IonItem>*/}
                <IonItem className={'ion-margin-top'}>
                    <IonLabel position={"floating"}>Nombre</IonLabel>
                    <IonInput id="name"
                              type="text"
                              value={name}
                              required={true}
                              minlength={1}
                              onIonChange={(e: any) => setName(e.target.value)}
                              enterkeyhint="next"
                              onKeyPress={(ev) => {
                                  // if(ev.key === 'Enter'){
                                  //     this.goNext('name');
                                  // }
                              }}
                              placeholder="Nombre">
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position={"floating"}>Apellido</IonLabel>
                    <IonInput id="lastName"
                              type="text"
                              value={lastName}
                              required={true}
                              minlength={1}
                              onIonChange={(e: any) => setLastName(e.target.value)}
                              enterkeyhint="next"
                        // onKeyPress={(ev) => {
                        //     if(ev.key === 'Enter'){
                        //         this.goNext('lastName');
                        //     }
                        // }}
                              placeholder="Apellido">
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position={"floating"}>Teléfono</IonLabel>
                    <IonInput id="phone"
                              type="tel"
                              value={phone}
                              required={true}
                              minlength={1}
                              onIonChange={(e: any) => setPhone(e.target.value)}
                        // onFocus={() => this.showIntoView()}
                        // ref={(ref) => this.ionInputPhone = ref}
                              onKeyPress={(ev) => {
                                  // if(ev.key === 'Enter'){
                                  //     this.goNext('phone');
                                  // }
                              }}
                              enterkeyhint="next"
                              placeholder="Teléfono">
                    </IonInput>
                </IonItem>


                <IonItem className={'ion-margin-top'}>
                    <IonLabel position={"floating"}>Calle y número</IonLabel>
                    <IonInput id="address"
                              type="text"
                              value={address}
                              required={true}
                              minlength={1}
                              onIonChange={(e: any) => setAddress(e.target.value)}
                              enterkeyhint="next"
                              placeholder="Calle y número">
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position={"floating"}>Delegación o colonia</IonLabel>
                    <IonInput id="borough"
                              type="text"
                              required={true}
                              value={borough}
                              minlength={1}
                              onIonChange={(e: any) => setBorough(e.target.value)}
                              enterkeyhint="next"
                              placeholder="Delegación o colonia">
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position={"floating"}>Código postal</IonLabel>
                    <IonInput id="zipCode"
                              type="number"
                              value={zipCode}
                              required={true}
                              minlength={5}
                              onIonChange={(e: any) => setZipCode(e.target.value)}
                              enterkeyhint="next"
                              placeholder="Código postal">
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position={"floating"}>Estado</IonLabel>
                    <IonSelect id="state"
                               cancelText={'Cerrar'}
                               interface="action-sheet"
                               value={state}
                               placeholder="Selecciona un estado."
                               onIonChange={(e) => setState(e.detail.value)}>
                        <IonSelectOption value="">Selecciona un estado</IonSelectOption>
                        {
                            addressStates.map((state: string, index: number) => {
                                return (
                                    <IonSelectOption value={state} key={index}>{state}</IonSelectOption>
                                )
                            })
                        }
                    </IonSelect>
                </IonItem>
                <IonItem className={'ion-margin-top'}>
                    <IonLabel position={"floating"}>Referencia</IonLabel>
                    <IonInput id="reference"
                              type="text"
                              value={reference}
                              required={true}
                              minlength={1}
                              onIonChange={(e: any) => setReference(e.target.value)}
                              enterkeyhint="go"
                              placeholder="Referencia">
                    </IonInput>
                </IonItem>
                <IonItemGroup className={'ion-margin-top'}>
                    <IonItemDivider>
                        <IonLabel>Facturación</IonLabel>
                    </IonItemDivider>
                    <IonItem lines={"none"}>
                        <IonLabel slot="start">¿Necesitas factura?</IonLabel>
                        <IonToggle slot="end" checked={requiresInvoice}
                                   onIonChange={(e) => {
                                       setRequiresInvoice(e.detail.checked);
                                       // this.setState({checkout: checkout}, () => {
                                       //     this.updateCheckout();
                                       // })
                                   }}/>
                    </IonItem>
                    {
                        requiresInvoice ?
                            <IonItemGroup>
                                <IonItem>
                                    <IonLabel>
                                        Serás contactado por teléfono o whatsapp para pedirte tus datos de facturación.
                                    </IonLabel>
                                </IonItem>

                                <IonButton size={'small'} fill={'clear'} style={{textTransform: 'inherit'}} onClick={() => {
                                    history.push('/iniciar-sesion');
                                }}>
                                    ¿Quieres agilizar este proceso? Crea una cuenta o inicia sesión
                                </IonButton>

                            </IonItemGroup> : null
                    }
                    {/*{*/}
                    {/*    this.state.checkout.requiresInvoice ?*/}
                    {/*        <IonItem lines={"none"} onClick={() => this.goToInvoices()}*/}
                    {/*                 detail>*/}
                    {/*            {this.renderInvoice(this.state.checkout.invoiceData)}*/}
                    {/*        </IonItem> : null*/}
                    {/*}*/}

                </IonItemGroup>
                <div className={'div-100'}>

                </div>
                <IonRow className={'flex-row ion-justify-content-end'}>
                    <IonButton onClick={() => createAddress()} disabled={isCreatingAddress}>
                        {isCreatingAddress ? <IonSpinner name={'dots'}></IonSpinner> : 'Continuar con envío'}
                    </IonButton>
                </IonRow>


            </IonCol>
        )
    }

    const loadShippingMethods = async () => {
        // Fetch shipping methods
        try {
            const res = await NbioApi.shippingMethods.getShippingMethods(checkoutId);
            const sm = res.data?.shippingMethods || []
            setShippingMethods(sm);
        } catch (ex) {
            console.log(ex);
        }
    }
    const onShippingMethodChanged = async (e: any) => {
        const selectedShippingMethodId = e.detail.value;
        const selectedShippingMethod =
            shippingMethods.find(
                (sm: any) =>
                    sm._id.toString() === e.detail.value
            );
        if (selectedShippingMethod) {
            setSelectedShippingMethodId(selectedShippingMethodId);
            setSelectedShippingMethod(selectedShippingMethod);
            await updateCart({
                shipping: shipping,
                shippingMethod: selectedShippingMethodId,
                shippingMethodId: e.detail.value,
                requiresInvoice:requiresInvoice
            });
        }
    }
    const renderShippingMethodPrice = (shippingMethod: ShippingMethodType) => {
        if (shippingMethod.oldPrice) {
            return <div className={'flex-row'}>
                <IonLabel color={'danger'} className={'mr-2'}>
                    <b>GRATIS</b>
                </IonLabel>
                <s>{money(shippingMethod.price)}</s>
            </div>
        } else {
            return `${money(shippingMethod.price)}`;
        }
    }
    const loadProgrammedHours = async () => {
        try {
            const res = await NbioApi.shippingMethods.getDeliveryBlocks();
            let blocks = res.data.blocks.map((b: any) => b.blocks);
            blocks = [].concat.apply([], blocks);
            setProgrammedHours(blocks);
        } catch (ex) {

        }


    }
    const goToPayment = (newStep: string = 'payment') => {
        const validateShippingMethodRes = validateShippingMethod();
        if (validateShippingMethodRes.isValid) {
            setCanGoToPayment(true);
            setStep(newStep);
        } else {
            setIsErrorMessageOpen(true);
            setErrorMessage(validateShippingMethodRes.message);
        }
    }
    const renderShippingMethods = () => {
        return (
            <IonCol size-md={6} size-lg={6} className={'ion-margin-bottom'}>
                <h4>Método de envío</h4>
                <IonRadioGroup value={selectedShippingMethodId}
                               name={'shipping-method-radio-group'}
                               onIonChange={e => onShippingMethodChanged(e)}>
                    {
                        shippingMethods.map((shippingMethod: any) => {
                            return (
                                <IonItem lines={"none"} key={shippingMethod._id}
                                         className={
                                             shippingMethod._id === selectedShippingMethodId ?
                                                 'selected-item-border' : ''
                                         }>
                                    <IonRadio slot="start" value={shippingMethod._id}></IonRadio>
                                    <div>
                                        <div className={'flex-row ion-justify-content-between'}>
                                            <IonLabel>
                                                {shippingMethod.name}
                                            </IonLabel>

                                        </div>
                                    </div>
                                    <IonLabel className={''} slot={'end'}>
                                        {renderShippingMethodPrice(shippingMethod)}
                                    </IonLabel>
                                </IonItem>
                            )
                        })
                    }
                    {
                        selectedShippingMethod?.shippingType === 'schedule' ?
                            <>
                                <IonItemDivider>
                                    <IonLabel class={'ion-text-wrap'}>Selecciona el horario en el que quieres recibir tu
                                        pedido:</IonLabel>
                                </IonItemDivider>
                                <IonItemGroup>
                                    {
                                        programmedHours.map((ph, i) => {
                                            return (
                                                renderTimeBlocks(ph, i)
                                            )
                                        })
                                    }

                                </IonItemGroup>
                            </>
                            : null
                    }
                </IonRadioGroup>
                <div className={'div-100'}>

                </div>
                <IonRow className={'flex-row ion-justify-content-between'}>
                    <IonButton onClick={() => setStep('info')} fill={"clear"}>
                        Volver a la dirección
                    </IonButton>
                    <IonButton onClick={() => goToPayment()}>
                        Continuar con el pago
                    </IonButton>
                </IonRow>
            </IonCol>

        )
    }
    const onRenderTimeBlockSelected = async (selectedProgrammedHour: any) => {
        // await this.setPutOrderButton(true);
        // await this.doSetState({selectedProgrammedHour});
        setSelectedProgrammedHour(selectedProgrammedHour);
        await updateCart({
            shipping: shipping,
            shippingMethod: selectedShippingMethodId,
            deliveryBlock: selectedProgrammedHour,
            requiresInvoice:requiresInvoice
        });
        // await this.setPutOrderButton(false);
    }
    const renderTimeBlocks = (ph: ProgrammedHourType, key: number) => {
        return (
            <IonItem color={selectedProgrammedHour?.id === ph.id ? 'primary' : ''} key={key}
                     onClick={() => {
                         onRenderTimeBlockSelected(ph);
                     }}>
                <IonLabel>
                    <b>{ph.shortDayLabel}</b>
                </IonLabel>
                <IonLabel>
                    {ph.hoursLabel}
                </IonLabel>
            </IonItem>
        )
    }
    const loadAvailablePaymentMethods = async () => {
        try {
            const res = await API.store.getAvailablePaymentMethods();
            // Compare regions payment methods with general payment methods
            let availablePaymentMethods = res.data.paymentMethods;
            if (checkout?.region?.paymentMethods) {
                const rpm: any = checkout?.region?.paymentMethods;
                availablePaymentMethods = availablePaymentMethods.map((apm: any) => {
                    apm.isActive = apm.isActive && (rpm.find((pm: any) => pm.paymentMethod === apm._id))?.isActive;
                    return apm;
                });
            }
            setAvailablePaymentMethods(availablePaymentMethods);
            console.log('res', res);
        } catch (e) {
            console.log('ex', e);
        }
    }
    const onPaymentMethodChanged = async (e: any) => {
        const value = e.detail.value;
        // await this.setPutOrderButton(true);
        if (value === paymentMethod) {
            // Avoid multiple calls
            return true;
        }
        if (value !== 'mercado-pago') {
            const data = {
                customGratuityValue: 0,
                selectedGratuityValue: 0,
                paymentMethod: value
            }
            // await this.doSetState(data);
            // await this.updateGratuity();
        } else {
            const paymentMethod = {
                paymentMethod: value
            };
            // await this.doSetState(paymentMethod);
            // await this.calcGratuity(true);
            // await this.updateGratuity();
        }
        setPaymentMethod(value);
        await updateCart({
            shipping: shipping,
            shippingMethod: selectedShippingMethodId,
            deliveryBlock: selectedProgrammedHour,
            paymentMethod: value,
            requiresInvoice:requiresInvoice
        });
        // await this.setPutOrderButton(false);
    }
    const isPaymentMethodDisabled = (code: string) => {
        const paymentMethod: any = availablePaymentMethods.find((pm: any) => pm.code === code);
        return !paymentMethod?.isActive;

    }
    const renderNotePOS = (paymentMethod: string) => {
        if (paymentMethod === 'pos') {
            return <IonNote>&nbsp;Realizarás tu pago a través de una terminal punto de venta con el
                repartidor.</IonNote>
        } else {
            return null
        }
    }

    const renderNoteCash = (paymentMethod: string) => {
        if (paymentMethod === 'cash') {
            return <IonNote>&nbsp;Realizarás tu pago con el repartidor.</IonNote>
        } else {
            return null
        }
    }
    const renderPaymentMethods = () => {
        return (
            <IonItemGroup className={"ion-margin-top"}>
                <IonItemDivider>
                    <IonLabel>Método de pago</IonLabel>
                </IonItemDivider>

                <IonRadioGroup value={paymentMethod}
                               name={'payment-method-radio-group'}
                               onIonChange={e => {
                                   onPaymentMethodChanged(e);
                               }}>
                    <IonItem lines={"none"}>
                        <IonRadio slot="start" value={'mercado-pago'}
                                  disabled={isPaymentMethodDisabled('mercado-pago')}></IonRadio>
                        <IonIcon icon={card} color={'primary'}></IonIcon>
                        <IonLabel>
                            &nbsp;Tarjeta de crédito / débito
                        </IonLabel>
                    </IonItem>
                    <IonItem lines={"none"}>
                        <IonRadio slot="start" value={'pos'} disabled={isPaymentMethodDisabled('pos')}></IonRadio>
                        <IonIcon icon={calculator} color={'primary'}></IonIcon>
                        <div>
                            <IonLabel>&nbsp;Pago con Terminal</IonLabel>
                            {renderNotePOS(paymentMethod)}
                        </div>
                    </IonItem>
                    <IonItem lines={"none"}>
                        <IonRadio slot="start" value={'cash'} disabled={isPaymentMethodDisabled('cash')}></IonRadio>
                        <IonIcon icon={cash} color={'primary'}></IonIcon>
                        <div>
                            <IonLabel>&nbsp;Efectivo</IonLabel>
                            {renderNoteCash(paymentMethod)}
                        </div>
                    </IonItem>
                </IonRadioGroup>
                {renderChange()}
                {renderPOS()}
                {renderGratuity()}
                <div className={'div-100'}>
                </div>
                <IonRow className={'flex-row ion-justify-content-between'}>
                    <IonButton onClick={() => setStep('shipping')} fill={"clear"}>
                        Volver al método de envío
                    </IonButton>
                    {
                        paymentMethod === 'cash' ?
                            <IonButton onClick={() => placeCashOrder()} disabled={isPlacingCashOrder}>
                                {
                                    isPlacingCashOrder ? <IonSpinner name={'dots'}></IonSpinner> : 'Ordenar'
                                }

                            </IonButton> : null
                    }
                    {
                        paymentMethod === 'pos' ?
                            <IonButton onClick={() => placePOSOrder()} disabled={isPlacingPOSOrder}>
                                {
                                    isPlacingPOSOrder ? <IonSpinner name={'dots'}></IonSpinner> : 'Ordenar'
                                }
                            </IonButton> : null
                    }
                    {
                        paymentMethod === 'mercado-pago' ?
                            <IonButton onClick={() => goToMPPayment()} disabled={isCheckingMPOrder}>
                                Pagar
                            </IonButton> : null
                    }
                </IonRow>
            </IonItemGroup>
        )
    }
    const goToMPPayment = async () => {
        const {isValid, errorMsg} = await checkCheckout();
        setIsCheckingMPOrder(true);
        if (!isValid) {
            setErrorMessage(errorMsg);
            setIsErrorMessageOpen(true);
            setIsCheckingMPOrder(false);
            return null;
        } else {
            loadBricks();
            setStep('mercado-pago')
            setIsCheckingMPOrder(false);
        }
    }
    const onGratuitySelected = async (grv: any) => {
        // await this.setPutOrderButton(true);
        setSelectedGratuityValue(grv);
        // await this.updateGratuity();
        // await this.setPutOrderButton(false);
    }
    const updateGratuity = async () => {
        try {
            const amount = selectedGratuityValue === 'other' ?
                customGratuityValue : parseFloat(selectedGratuityValue.toString());
            await NbioApi.checkout.setGratuity(checkoutId, 'fixed', amount);
            loadCheckout();
        } catch (ex) {
        }
    }
    useEffect(() => {
        updateGratuity();
    }, [customGratuityValue, selectedGratuityValue])
    const renderGratuity = () => {
        if (paymentMethod !== 'mercado-pago') {
            return null
        } else {
            return (<IonItemGroup className={"ion-margin-top"}>
                <IonItemDivider>
                    <IonLabel>Propina</IonLabel>
                </IonItemDivider>
                <div className={'ion-padding'}>
                    <Swiper slidesPerView={4}>

                        {
                            recommendedGratuityValues.map((rgv: any, index: number) => {
                                const isSelected = rgv === selectedGratuityValue;
                                return (
                                    <SwiperSlide>
                                        <IonChip color={isSelected ? 'primary' : ''}
                                                 onClick={() => {
                                                     onGratuitySelected(rgv);
                                                 }}>
                                            <IonLabel>
                                                {money(rgv)}
                                            </IonLabel>
                                        </IonChip>
                                    </SwiperSlide>
                                )
                            })
                        }
                        <SwiperSlide>
                            <IonChip color={selectedGratuityValue === 'other' ? 'primary' : ''} onClick={() => {
                                setGratuityAlertIsOpen(true);
                            }}>
                                <IonIcon icon={pencil} color={'secondary'}>

                                </IonIcon>
                                <IonLabel>
                                    {selectedGratuityValue === 'other' ? money(customGratuityValue) : 'Editar'}
                                </IonLabel>
                            </IonChip>
                        </SwiperSlide>
                    </Swiper>


                </div>


            </IonItemGroup>)

        }
    }
    const renderStep = () => {
        if (step === 'info') {
            return <>
                {renderContact()}
                {renderAddress()}
            </>
        }
        if (step === 'shipping') {
            return renderShippingMethods();
        }
        if (step === 'payment') {
            return renderPaymentMethods();
        }
        if (step === 'mercado-pago') {
            return renderMPForm();
        }
        if (step === 'after-sale') {
            return renderAfterSale();
        }
    }
    const loadBricks = async () => {
        unloadBricks();
        setIsFormReady(false);
        setShowBricks(true);
        await initBricks();
        setIsFormReady(true);
    }
    const unloadBricks = () => {
        try {
            cardPaymentBrickController.unmount();
        } catch (ex) {
            console.log(ex);
        }
    }
    const renderMPForm = () => {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        const backgroundImage = prefersDark.matches ? `url("https://nbio.blob.core.windows.net/cdn/mp-alpha-dark-mode.png")` :
            `url("https://nbio.blob.core.windows.net/cdn/mp-alpha.png")`;
        return (
            <>
                <div className={'flex-row ion-align-items-center ion-justify-content-center'}>
                    <IonLabel color={'primary'}>
                        <b>PAGO SEGURO CON</b>
                    </IonLabel>
                    <div className={'mp-logo'} style={
                        {
                            backgroundImage: backgroundImage,
                            height: 50,
                            width: 90,
                            backgroundSize: 'contain',
                            marginLeft: 5,
                            backgroundRepeat: 'no-repeat',
                        }}>
                    </div>
                </div>

                <div id="cardPaymentBrick_container" style={{marginTop: 40}}></div>
                <IonRow className={'flex-row ion-justify-content-end'}>
                    <IonButton onClick={() => {
                        payWithMP();
                    }} disabled={!isFormReady || isPaying} className={'ion-margin'}>
                        <IonIcon slot={'start'} icon={lockClosed} size={'small'}></IonIcon>
                        {
                            isPaying ? <IonSpinner name={'dots'}></IonSpinner> :
                                'Pagar'
                        }
                    </IonButton>
                </IonRow>

            </>
        )
    }
    const goBackToStore = () => {
        Cart.saveCartId('');
        emmitEvent(EVENTS.ADD_TO_CART, {});
        history.replace({
            pathname: `/`
        });
    }

    const renderAfterSale = () => {

        return (
            <>
                <div id="statusScreenBrick_container"></div>
                <IonButton onClick={() => goBackToStore()}>Volver a la tienda</IonButton>
            </>
        )
    }
    useEffect(() => {
        if (step === 'after-sale' && mercadoPagoPaymentIntentId) {
            // @ts-ignore
            mp = new MercadoPago(mpKey, {locale: 'es-MX'});
            const bricksBuilder = mp.bricks({});
            renderStatusScreenBrick(bricksBuilder, mercadoPagoPaymentIntentId);
        }
    }, [step, mercadoPagoPaymentIntentId])
    const loadInitData = async () => {
        try {
            const resUser = await API.users.getMe();
            const user = resUser.data.user;
            const checkout = await NbioApi.checkout.getCheckout(checkoutId);
            // setState
            // this.setState({
            //     userEmail: user.email,
            //     amount:checkout.data.checkout.totals.total
            // },() =>{
            //     return Promise.resolve(true);
            // })
        } catch (ex) {
            return Promise.reject('Error loading init data');
        }

    }
    const initBricks = async () => {
        // wait for initData
        try {
            await loadInitData();
        } catch (ex) {
        }
        // @ts-ignore
        mp = new MercadoPago(mpKey, {locale: 'es-MX'});
        const settings = {}
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        let formBackgroundColor = '#1E1E1E';
        let textPrimaryColor = '#418CF9';
        if (!prefersDark.matches) {
            formBackgroundColor = '#FFFFFF';
            textPrimaryColor = '#000000';
        }
        const bricksBuilder = mp.bricks({});
        // @ts-ignore
        // window.bricksBuilder = bricksBuilder;
        const renderCardPaymentBrick = async (bricksBuilder: any) => {

            const settings = {
                locale: 'es',
                initialization: {
                    amount: checkout.totals.total,
                    payer: {
                        email: email
                    },
                },
                customization: {
                    paymentMethods: {
                        minInstallments: 1,
                        maxInstallments: 1,
                    },
                    visual: {
                        hidePaymentButton: true,
                        style: {
                            customVariables: {
                                formBackgroundColor: formBackgroundColor,
                                textPrimaryColor: textPrimaryColor,
                                inputBackgroundColor: '#FFFFFF',
                                borderRadiusSmall: 0,
                                borderRadiusMedium: 0,
                                borderRadiusLarge: '5px',
                                borderRadius: 0
                            }
                        }
                    }
                },
                callbacks: {

                    onReady: () => {
                        // Avoid submit on MP Form
                        const form = document.getElementsByTagName('form')[0]
                        form.addEventListener('submit', (ev) => ev.preventDefault())
                        // Keyboard fix
                        try {
                            // @ts-ignore
                            document.getElementById('cardPaymentBrick_container').addEventListener('click', () => {
                                // this.showIntoView();
                            });
                        } catch (e) {

                        }

                    },
                    onSubmit: (cardFormData: any) => {
                        return createPaymentIntent(cardFormData);
                    },
                    onError: (error: any) => {
                    },
                },
            };
            return await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings);
        };
        cardPaymentBrickController = await renderCardPaymentBrick(bricksBuilder);
        console.log('cardPaymentBrickController', cardPaymentBrickController);
    }
    const createPaymentIntent = async (cardFormData: object) => {
        return NbioApi.checkout.generatePaymentIntent(checkoutId, {mercadoPagoData: cardFormData}).then((res) => {
            return res;
        }).catch((ex) => {
            return Promise.reject(ex);
        })
    }
    const payWithNewCard = async () => {
        // @ts-ignore
        const cardFormData = await cardPaymentBrickController.getFormData();
        if (cardFormData) {
            return createPaymentIntent(cardFormData);
        } else if (cardFormData === null) {
            return Promise.reject({local_error_es: 'Debes de ingresar tus datos'});
        }
    }
    const payWithMP = async () => {
        setIsPaying(true);
        const {isValid, errorMsg} = await checkCheckout();
        if (!isValid) {
            console.log(errorMsg);
            setErrorMessage(errorMsg);
            setIsErrorMessageOpen(true);
            setIsPaying(false);
            return null;
        } else {
            payWithNewCard().then((res) => {
                setMercadoPagoPaymentIntentId(res?.data?.mercadoPagoPaymentIntentId);
                setStep('after-sale');
            }).catch((ex) => {
                console.log(ex);
                if (ex.local_error_es) {
                    setErrorMessage(ex?.local_error_es);
                    setIsErrorMessageOpen(true);
                } else if (ex?.response?.data?.reason_es) {
                    setErrorMessage(ex.response.data.reason_es);
                    setIsErrorMessageOpen(true);
                }
            }).finally(() => {
                setIsPaying(false);
            });
        }

    }
    const renderNameAndAddress = () => {
        return [address, borough, zipCode, state, 'México'].join(' , ');
    }
    const shortcutInfo = () => {
        setStep('info');
    }
    const shortcutShippingMethod = () => {
        setStep('shipping');
    }
    const renderStatusScreenBrick = async (bricksBuilder: any, paymentId: string) => {
        const settings = {
            initialization: {
                paymentId: paymentId, // payment id to show
            },
            callbacks: {
                onReady: () => {
                    /*
                      Callback called when Brick is ready.
                      Here you can hide loadings from your site, for example.
                    */
                },
                onError: (error: any) => {
                    // callback called for all Brick error cases
                    console.error(error);
                },
            },
        };
        // @ts-ignore
        window.statusScreenBrickController = await bricksBuilder.create(
            'statusScreen',
            'statusScreenBrick_container',
            settings,
        );
    };
    const renderPrevSteps = () => {
        // steps: info,shipping, payment
        return (
            <div>
                <IonCard>
                    {
                        ['shipping', 'payment'].includes(step) ?
                            <IonItem>
                                <IonLabel slot={'start'} style={{flex: .2}}>
                                    Contacto
                                </IonLabel>
                                <IonLabel style={{flex: 1}}>
                                    {email}
                                </IonLabel>
                                <IonButton fill={'clear'} onClick={() => shortcutInfo()}>
                                    Cambiar
                                </IonButton>
                            </IonItem> : null
                    }
                    {
                        ['shipping', 'payment'].includes(step) ?
                            <IonItem>
                                <IonLabel slot={'start'} style={{flex: .2}}>
                                    Enviar a
                                </IonLabel>
                                <IonLabel style={{flex: 1}}>
                                    {renderNameAndAddress()}
                                </IonLabel>
                                <IonButton fill={'clear'} onClick={() => shortcutInfo()}>
                                    Cambiar
                                </IonButton>
                            </IonItem>
                            : null
                    }
                    {
                        ['payment'].includes(step) ?
                            <IonItem>
                                <IonLabel slot={'start'} style={{flex: .2}}>
                                    Método de envío
                                </IonLabel>
                                <IonLabel style={{flex: 1}}>
                                    <IonLabel>
                                        {checkout?.shippingMethod?.name}
                                    </IonLabel>
                                    {
                                        checkout?.freeShipping?.hasFreeShipping ?
                                            <div className={'flex-row'}>
                                                <IonLabel color={'danger'} className={'mr-2'}>
                                                    <b>GRATIS</b>
                                                </IonLabel>
                                                <s>{money(checkout?.selectedShippingMethod?.oldPrice)}</s>
                                            </div> :
                                            <IonLabel
                                                slot={"end"}>{money(checkout.totals.totalShipping)}</IonLabel>
                                    }
                                </IonLabel>
                                <IonButton fill={'clear'} onClick={() => shortcutShippingMethod()}>
                                    Cambiar
                                </IonButton>
                            </IonItem>
                            : null
                    }

                </IonCard>
            </div>
        )
    }
    const renderTotals = () => {
        return (
            <IonItemGroup className={"ion-margin-top"}>
                <IonItemDivider>
                    <IonLabel>Resumen</IonLabel>
                </IonItemDivider>
                <IonItem lines={"none"}>
                    <IonLabel className={'ion-text-uppercase'}>SUBTOTAL</IonLabel>
                    <IonLabel slot={"end"}>{money(checkout?.totals?.subTotal)}</IonLabel>
                </IonItem>
                {/*<IonItem lines={"none"}>*/}
                {/*    <IonLabel className={'ion-text-uppercase'}>IMPUESTOS</IonLabel>*/}
                {/*    <IonLabel slot={"end"}>{money(checkout?.totals?.totalTax)}</IonLabel>*/}
                {/*</IonItem>*/}
                <IonItem lines={"none"}>
                    <IonLabel className={'ion-text-uppercase'}>ENVÍO</IonLabel>

                    {
                        checkout?.freeShipping?.hasFreeShipping ?
                            <div className={'flex-row'}>
                                <IonLabel color={'danger'} className={'mr-2'}>
                                    <b>GRATIS</b>
                                </IonLabel>
                                <s>{money(checkout?.selectedShippingMethod?.oldPrice)}</s>
                            </div> :
                            <IonLabel
                                slot={"end"}>{money(checkout.totals.totalShipping)}</IonLabel>
                    }
                </IonItem>
                {
                    checkout.totals.totalDiscount ?
                        <IonItem lines={"none"}>
                            <IonLabel className={'ion-text-uppercase'}>DESCUENTOS</IonLabel>
                            <IonLabel color={'danger'}
                                      slot={"end"}>-{money(checkout.totals.totalDiscount)}</IonLabel>
                        </IonItem> : null
                }
                <IonItem lines={"none"}>
                    <IonLabel className={'ion-text-uppercase'}>PROPINA</IonLabel>
                    <IonLabel
                        slot={"end"}>{money(checkout.totals.gratuityTotal)}</IonLabel>
                </IonItem>
                <IonItem lines={"none"}>
                    <IonLabel className={'ion-text-uppercase'}>TOTAL</IonLabel>
                    <IonLabel
                        slot={"end"}>{money(checkout.totals.total)}</IonLabel>
                </IonItem>
            </IonItemGroup>
        )
    }
    const renderPOS = () => {
        if (paymentMethod !== 'pos') {
            return null;
        }
        return (
            <IonRow className="ion-justify-content-center">
                <IonCol size-sm="12">
                    <IonItem lines={"none"}>
                        <IonLabel className={"ion-text-wrap"}>
                            Realizarás tu pago a través de una terminal punto de venta con el repartidor.
                            <br/>
                            Toca en ORDENAR para continuar.
                        </IonLabel>
                    </IonItem>
                </IonCol>
            </IonRow>
        )
    }
    const renderChange = () => {
        if (paymentMethod !== 'cash') {
            return null;
        }
        return (
            <IonRow className="ion-justify-content-center">
                <IonCol size-sm="12">
                    <IonItem lines={"none"}>
                        <IonLabel slot="start">¿Necesitas cambio?</IonLabel>
                        <IonToggle slot="end" checked={isChangeRequired}
                                   onIonChange={(e) => setIsChangeRequired(e.detail.checked)}/>
                    </IonItem>

                    <IonItem>
                        <IonLabel position={"floating"}>¿Con cuánto vas a pagar?</IonLabel>
                        <IonInput type={"number"}
                                  value={changeAmount}
                                  disabled={!isChangeRequired}
                                  inputMode={'numeric'}
                                  onIonChange={(e) => {
                                      setChangeAmount(
                                          parseFloat(e.detail.value || "0")
                                      )
                                  }}></IonInput>
                    </IonItem>
                </IonCol>
            </IonRow>)
    }
    const goToCheckoutPage = (orderId: string) => {
        history.replace({
            pathname: `/checkouts/${orderId}`,
            state: {
                orderId: orderId,
                // paymentMethod:this.state.paymentType
            }
        });
    }
    const checkCheckout = async () => {
        return await NbioApi.checkout.check(checkoutId).then((res) => {
            // CHECKOUT CHECK
            return Promise.resolve({isValid: true, errorMsg: ''});
        }).catch((ex) => {
            let errorMsg = 'Hubo un problema validando tu orden.';
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {

            }
            return Promise.resolve({isValid: false, errorMsg: errorMsg});
        })
    }
    const placePOSOrder = async () => {
        setIsPlacingPOSOrder(true);
        const {isValid, errorMsg} = await checkCheckout();
        if (!isValid) {
            setErrorMessage(errorMsg);
            setIsErrorMessageOpen(true);
            setIsPlacingPOSOrder(false);
            return null;
        } else {
            NbioApi.checkout.generatePaymentIntent(checkoutId).then((res) => {
                // this.props.onConfirmPayment(res.data.orderId);
                goToCheckoutPage(res.data.orderId);

            }).catch((ex) => {

                // this.props.onFailedPayment();
            }).finally(() => {
                setIsPlacingPOSOrder(false);
            })
        }


    }
    const placeCashOrder = async () => {
        let data = {};
        setIsPlacingCashOrder(true);
        if (isChangeRequired) {
            data = {
                cash_needs_change: isChangeRequired,
                cash_change_amount: changeAmount
            }
            if ((+changeAmount || 0) <= (checkout?.totals?.total || 0)) {
                setIsErrorMessageOpen(true);
                setErrorMessage(`La cantidad debe de ser mayor a ${money(checkout?.totals?.total)}`);
                setIsPlacingCashOrder(false);
                return;
            }
        }
        const {isValid, errorMsg} = await checkCheckout();
        if (!isValid) {
            console.log(errorMsg);
            setErrorMessage(errorMsg);
            setIsErrorMessageOpen(true);
            setIsPlacingCashOrder(false);
            return null;
        } else {
            NbioApi.checkout.generatePaymentIntent(checkoutId, data).then((res) => {
                // this.props.onConfirmPayment(res.data.orderId);
                console.log(res);
                goToCheckoutPage(res.data.orderId);
            }).catch((ex) => {
                console.log(ex);
                // this.props.onFailedPayment();
            }).finally(() => {
                setIsPlacingCashOrder(false);
            });
        }


    }
    const goToStep = (newStep: string) => {
        if (step === 'info') {
            // validate info
            createAddress(newStep);

        } else if (step === 'shipping') {
            //validate shipping
            goToPayment(newStep);
        } else if (step === 'payment') {
            // No need for validation
            setStep(newStep);
        }
    }
    const renderSideContent = (sizeSm = 12, sizeMd = 6, className = '') => {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        const backgroundImage = prefersDark.matches ? `url("https://nbio.blob.core.windows.net/cdn/mp-alpha-dark-mode.png")` :
            `url("https://nbio.blob.core.windows.net/cdn/mp-alpha.png")`;
        return (
            <IonCol size-sm={sizeSm} size-md={sizeMd}
                    className={`contrast-bg ${className}`}>
                <IonRow className={'ion-justify-content-center'}>
                    <IonCol size-sm={12} size-md={12}>
                        <div style={{height: 250, overflowY: 'auto'}}>
                            <CartList products={items} onDelete={() => {
                            }}
                                      onDecreaseQty={() => {
                                      }} onIncreaseQty={() => {
                            }}
                                      onAddPrescription={() => {
                                      }} isLoading={isLoading}
                                      viewOnly={true}
                            >
                            </CartList>
                        </div>

                        {
                            renderTotals()
                        }
                    </IonCol>

                </IonRow>
                <IonRow className={'web-footer-section-top-small'}>
                    <IonCol>
                        <IonCard>
                            <IonCardContent className={'ion-text-center'}>
                                <IonIcon className={'web-footer-sp-icon-small'} icon={locateOutline}
                                ></IonIcon>
                                <div>
                                    <h6 className={''}>Seguimiento en tiempo real</h6>
                                </div>
                            </IonCardContent>

                        </IonCard>

                    </IonCol>
                    <IonCol>
                        <IonCard>
                            <IonCardContent className={'ion-text-center'}>
                                <IonIcon className={'web-footer-sp-icon-small'} icon={cardOutline}></IonIcon>
                                <div>
                                    <h6 className={''}>Pagos seguros con Mercado Pago</h6>
                                </div>
                            </IonCardContent>
                        </IonCard>

                    </IonCol>
                </IonRow>
                <IonRow className={'web-footer-section-top-small'}>
                    <IonCol>
                        <IonCard>
                            <IonCardContent className={'ion-text-center'}>
                                <IonIcon className={'web-footer-sp-icon-small'} icon={cubeOutline}></IonIcon>
                                <div>
                                    <h6 className={''}>Enviamos a toda la República Mexicana.</h6>
                                </div>
                            </IonCardContent>
                        </IonCard>

                    </IonCol>

                    <IonCol>
                        <IonCard>
                            <IonCardContent className={'ion-text-center'}>
                                <IonIcon className={'web-footer-sp-icon-small'} icon={logoWhatsapp}></IonIcon>
                                <h6 className={''}>Soporte por Teléfono al &nbsp;
                                    <a href={'tel:+525641674170'} target={'_blank'}>
                                        (564) 167-4170
                                    </a>
                                </h6>
                            </IonCardContent>
                        </IonCard>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <div className={'flex-row ion-align-items-center ion-justify-content-center'}>
                            <IonLabel color={'primary'}>
                                <b>PAGO SEGURO CON</b>
                            </IonLabel>
                            <div className={'mp-logo'} style={
                                {
                                    backgroundImage: backgroundImage,
                                    height: 50,
                                    width: 90,
                                    backgroundSize: 'contain',
                                    marginLeft: 5,
                                    backgroundRepeat: 'no-repeat',
                                }}>
                            </div>
                        </div>
                        <div className={'flex-row ion-justify-content-center'}>
                            <div style={
                                {
                                    backgroundImage: "url('https://nbio.blob.core.windows.net/cdn/visa.png')",
                                    backgroundColor: 'white',
                                    height: 27,
                                    width: 76,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    marginRight: 5,
                                    border: '1px solid #a4a4a4'
                                }}>
                            </div>
                            <div style={
                                {
                                    backgroundImage: "url('https://nbio.blob.core.windows.net/cdn/mc.png')",
                                    backgroundColor: 'white',
                                    height: 27,
                                    width: 76,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    marginRight: 5,
                                    border: '1px solid #a4a4a4'

                                }}>
                            </div>
                            <div style={
                                {
                                    backgroundImage: "url('https://nbio.blob.core.windows.net/cdn/ae.png')",
                                    backgroundColor: 'white',
                                    height: 27,
                                    width: 76,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    border: '1px solid #a4a4a4'
                                }}>
                            </div>
                        </div>


                    </IonCol>

                </IonRow>

            </IonCol>
        )
    }
    return (
        <IonPage>
            <IonContent>

                <IonGrid style={{overflow: 'hidden', height: '100vh'}}>
                    <IonRow>
                        <IonCol size-sm={12} size-md={step !== 'after-sale' ? 6 : 12}
                                style={{overflow: 'auto', height: '100vh'}}>
                            <IonAccordionGroup className={'ion-hide-md-up'}>
                                <IonAccordion value="first" toggleIcon={chevronDown} toggleIconSlot="start">
                                    <IonItem slot="header" color="light">
                                        <IonLabel>
                                            <IonLabel>
                                                <b>Total {money(checkout?.totals?.total)}</b>

                                            </IonLabel>
                                            Ver Pedido
                                        </IonLabel>
                                    </IonItem>
                                    <IonItem slot={'end'}>
                                    </IonItem>

                                    <div slot="content">
                                        <IonRow>
                                            {renderSideContent(12, 12)}
                                        </IonRow>
                                    </div>
                                </IonAccordion>
                            </IonAccordionGroup>
                            <IonRow className={'ion-justify-content-center'}>
                                <IonCol size-sm={12} size-md={10}>
                                    <a href={'/'}>
                                        <div className={'main-app-logo-sm'}></div>
                                    </a>
                                    {
                                        step !== 'after-sale' ?
                                            <IonBreadcrumbs className={'ion-justify-content-center'}>
                                                <IonBreadcrumb color={step === 'info' ? 'primary' : ''}
                                                               className={'cursor-pointer'}
                                                               onClick={() => goToStep('info')}>
                                                    Información
                                                </IonBreadcrumb>
                                                <IonBreadcrumb className={'cursor-pointer'}
                                                               color={step === 'shipping' ? 'primary' : ''}
                                                               disabled={!canGoToShipping}
                                                               onClick={() => setStep('shipping')}>
                                                    Envío
                                                </IonBreadcrumb>
                                                <IonBreadcrumb className={'cursor-pointer'}
                                                               color={step === 'payment' ? 'primary' : ''}
                                                               disabled={!canGoToPayment}
                                                               onClick={() => goToStep('payment')}>
                                                    Método de pago
                                                </IonBreadcrumb>
                                            </IonBreadcrumbs> : null
                                    }

                                    {renderPrevSteps()}
                                    {renderStep()}
                                    <IonRow className={'ion-justify-content-center'}>
                                        <IonButton fill={'clear'} style={{textTransform: 'initial'}} size={'small'}
                                                   onClick={() => setIsTCPopupOpen(true)}>
                                            Términos y Condiciones
                                        </IonButton>
                                        <IonButton fill={'clear'} style={{textTransform: 'initial'}} size={'small'}
                                                   onClick={() => setIsFAQPopupOpen(true)}>
                                            Preguntas Frecuentes
                                        </IonButton>
                                        <IonButton fill={'clear'} style={{textTransform: 'initial'}} size={'small'}
                                                   onClick={() => setIsPPPopupContent(true)}>
                                            Política de Privacidad
                                        </IonButton>
                                    </IonRow>
                                </IonCol>
                            </IonRow>


                        </IonCol>

                        {/*#f4f4f4/*/}
                        {
                            step !== 'after-sale' ?
                                <>
                                    {renderSideContent(12, 6, 'ion-hide-md-down')}
                                </>

                                : null
                        }

                    </IonRow>
                    <IonToast
                        message={errorMessage}
                        isOpen={isErrorMessageOpen}
                        duration={2000}
                        onDidDismiss={() => setIsErrorMessageOpen(false)}
                    />
                    <IonToast
                        message={'Ingresa una cantidad válida para la propina'}
                        isOpen={gratuityToastIsOpen}
                        duration={1000}
                        onDidDismiss={() => setGratuityToastIsOpen(true)}
                    />
                    <IonAlert
                        isOpen={gratuityAlertIsOpen}
                        onDidDismiss={() => {
                        }}
                        cssClass='my-custom-class'
                        header={'Propina'}
                        message={'Ingresa una cantidad'}
                        buttons={[
                            {
                                text: 'Cancelar',
                                role: 'cancel',
                                cssClass: 'secondary',
                                id: 'cancel-button',
                                handler: blah => {
                                    setGratuityToastIsOpen(false);
                                }
                            },
                            {
                                text: 'Aceptar',
                                id: 'confirm-button',
                                handler: (v) => {
                                    if (v.amount) {
                                        if (v.amount >= 0 && v.amount <= 100000) {
                                            setCustomGratuityValue(parseFloat(v.amount));
                                            setSelectedGratuityValue('other');
                                            // updateGratuity();
                                            // selectedGratuityValue:'other'
                                            // this.setState({
                                            //     customGratuityValue:parseFloat(v.amount),
                                            //     selectedGratuityValue:'other'
                                            // }, async() =>{
                                            // await this.setPutOrderButton(true);
                                            // await this.updateGratuity();
                                            // await this.setPutOrderButton(false);

                                            // })
                                        } else {
                                            setGratuityToastIsOpen(true);
                                        }
                                    } else {
                                        setGratuityToastIsOpen(true);
                                    }
                                    setGratuityToastIsOpen(false)
                                }
                            }
                        ]}
                        inputs={[
                            {
                                name: 'amount',
                                type: 'number',
                                placeholder: 'Cantidad'
                            }
                        ]
                        }
                    />
                </IonGrid>
            </IonContent>
            <IonModal isOpen={isTCPopupOpen} onDidDismiss={() => setIsTCPopupOpen(false)}>
                <IonContent>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>
                                Términos y Condiciones
                            </IonTitle>
                            <IonButtons slot={'end'}>
                                <IonButton onClick={() => setIsTCPopupOpen(false)}>
                                    <IonIcon icon={close}>

                                    </IonIcon>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <TOSContent sizeMd={'12'}></TOSContent>
                </IonContent>

            </IonModal>
            <IonModal isOpen={isFAQPopupOpen} onDidDismiss={() => setIsFAQPopupOpen(false)}>
                <IonContent>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>
                                Preguntas Frecuentes
                            </IonTitle>
                            <IonButtons slot={'end'}>
                                <IonButton onClick={() => setIsFAQPopupOpen(false)}>
                                    <IonIcon icon={close}>

                                    </IonIcon>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <FAQContent size={'12'} sizeLg={'12'} sizeMd={'12'}></FAQContent>
                </IonContent>

            </IonModal>
            <IonModal isOpen={isPPPopupOpen} onDidDismiss={() => setIsPPPopupContent(false)}>
                <IonContent>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>
                                Política de Privacidad
                            </IonTitle>
                            <IonButtons slot={'end'}>
                                <IonButton onClick={() => setIsPPPopupContent(false)}>
                                    <IonIcon icon={close}>

                                    </IonIcon>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <PrivacyPolicyContent sizeMd={'12'}></PrivacyPolicyContent>
                </IonContent>
            </IonModal>
        </IonPage>

    )
}
export default ConfirmOrderGuest;