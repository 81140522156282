/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2023
 */

import {
    IonLabel,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
} from "@ionic/react";
import React from "react";

//style
import '../../css/components/sellingPoints.css'


interface SellingPointsProps{
    sellingPoints: string[] | undefined;
    classNameCard?: string
}

const SellingPoints: React.FC<SellingPointsProps> = ({sellingPoints = [],
                                                      classNameCard=''}) => {

    const rowPoints = (sp: string) => {
        return(
            <div className={'sp-row'}>
                <span className={'sp-icon'}>&#10687;</span>
                <IonLabel className={'text-wrap'}>{sp}</IonLabel>
            </div>
        )
    }

    return (
        <IonCard className={`${classNameCard} ion-no-margin ion-margin-top`}>
            <IonCardContent className={'no-margin-horizontal'}>
                <IonGrid className={'ion-no-padding'}>
                    <IonRow>
                        <IonCol size-lg={6} size-md={12} size-sm={12}>
                            {rowPoints(sellingPoints[0])}
                        </IonCol>
                        <IonCol size-lg={6} size-md={12} size-sm={12}>
                            {rowPoints(sellingPoints[1])}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size-lg={6} size-md={12} size-sm={12}>
                            {rowPoints(sellingPoints[2])}
                        </IonCol>
                        <IonCol size-lg={6} size-md={12} size-sm={12}>
                            {rowPoints(sellingPoints[3])}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size-lg={6} size-md={12} size-sm={12}>
                            {rowPoints(sellingPoints[4])}
                        </IonCol>
                        <IonCol size-lg={6} size-md={12} size-sm={12}>
                            {rowPoints(sellingPoints[5])}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    );
};

export default SellingPoints;
