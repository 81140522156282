/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2022
 */

import { IonItem, IonSelect, IonSelectOption} from "@ionic/react";
import '../../css/style.css';
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {isValid} from "ionicons/dist/types/components/icon/validate";


const currentYear = dayjs().year();
const years = Array.from(Array(100).keys()).map((y) => currentYear - y);
const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
];
const monthStrToInt = (month:string) =>{
    switch(month){
        case 'Enero':
            return 1;
        case 'Febrero':
            return 2;
        case 'Marzo':
            return 3;
        case 'Abril':
            return 4;
        case 'Mayo':
            return 5;
        case 'Junio':
            return 6;
        case 'Julio':
            return 7;
        case 'Agosto':
            return 8;
        case 'Septiembre':
            return 9;
        case 'Octubre':
            return 10;
        case 'Noviembre':
            return 11;
        case 'Diciembre':
            return 12;
    }
}
const monthIntToStr = (month:number) =>{
    switch(month){
        case 1:
            return 'Enero';
        case 2:
            return 'Febrero';
        case 3:
            return 'Marzo';
        case 4:
            return 'Abril';
        case 5:
            return 'Mayo';
        case 6:
            return 'Junio';
        case 7:
            return 'Julio';
        case 8:
            return 'Agosto';
        case 9:
            return 'Septiembre';
        case 10:
            return 'Octubre';
        case 11:
            return 'Noviembre';
        case 12:
            return 'Diciembre';
    }
}
interface NPDatePickerProps{
    onDateChanged: any;
    defaultValue?: any;
}
const NPDatePicker: React.FC<NPDatePickerProps> = ({onDateChanged = () => {},defaultValue = null}) => {
    const [daysInMonth,setDaysInMonth] = useState([1]);
    const [day,setDay] = useState(1);
    const [month,setMonth] = useState('Enero');
    const [year,setYear] = useState(currentYear);
    const calcDaysInMonth = () =>{
        // if no year or month is selected
        let currentDate = dayjs();
        try{
            currentDate = dayjs(`${year}-${monthStrToInt(month)}-${day}`)
        }catch(ex){

        }
        const _daysInMonth = currentDate.daysInMonth();
        const daysInMonthArr = Array.from(Array(_daysInMonth).keys()).map((d) => d + 1);
        setDaysInMonth(daysInMonthArr);
    }
    // on mount
    useEffect(() => {
        // set default date
        if(defaultValue){
            const defaultDayJs = dayjs(defaultValue,'MM-DD-YYYY');
            if(defaultDayJs.isValid()){
                const day = defaultDayJs.format('DD');
                const month = monthIntToStr(parseInt(defaultDayJs.format('MM')));
                const year = defaultDayJs.format('YYYY');
                setDay(parseInt(day));
                setMonth(month || 'Enero');
                setYear(parseInt(year));
            }
        }
        calcDaysInMonth();
        try{
            onDateChanged(dayjs(`${year}-${monthStrToInt(month)}-${day}`));
        }catch (e) {
        }
    },[]);
    // on day changed
    useEffect(() =>{
        try{
            onDateChanged(dayjs(`${year}-${monthStrToInt(month)}-${day}`));
        }catch (e) {
        }
    },[day]);
    // on month changed
    useEffect(() => {
        calcDaysInMonth();
        try{
            onDateChanged(dayjs(`${year}-${monthStrToInt(month)}-${day}`));
        }catch (e) {
        }
    },[month]);
    // on year changed
    useEffect(() => {
        calcDaysInMonth();
        try{
            onDateChanged(dayjs(`${year}-${monthStrToInt(month)}-${day}`));
        }catch (e) {
        }
    },[year]);
    return (
        <div className={'flex-row ion-justify-content-between'}>
            <IonSelect interface="action-sheet" slot={'end'} value={day}
                       defaultValue={1}
                       cancelText={'Cancelar'}
                       placeholder="Elige un día"
                       onIonChange={(e) => {
                           setDay(e.detail.value)
                       }}>
                {
                    daysInMonth.map((d) =>{
                        return <IonSelectOption value={d} key={d}>{d}</IonSelectOption>
                    })

                }
            </IonSelect>
            <IonSelect interface="action-sheet" slot={'end'} value={month}
                       defaultValue={'Enero'}
                       cancelText={'Cancelar'}
                       placeholder="Elige un mes"
                       onIonChange={(e) => {
                           setMonth(e.detail.value)
                       }}>
                {
                    months.map((m) =>{
                        return <IonSelectOption value={m} key={m}>{m}</IonSelectOption>
                    })

                }
            </IonSelect>
            <IonSelect interface="action-sheet" slot={'end'} value={year}
                       defaultValue={year}
                       cancelText={'Cancelar'}
                       placeholder="Elige un año"
                       onIonChange={(e) => {
                           setYear(e.detail.value)
                       }}>
                {
                    years.map((y) =>{
                        return <IonSelectOption value={y} key={y}>{y}</IonSelectOption>
                    })

                }
            </IonSelect>
        </div>
    );
};

export default NPDatePicker;
