/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2022
 */

import React, {useEffect, useState} from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonItem,
    IonLabel,
    IonContent,
    IonCard,
    IonCardContent,
    IonBadge,
    IonIcon,
    IonSkeletonText,
    IonNote,
    IonRadio,
    IonRadioGroup, IonAlert, IonSpinner,
} from '@ionic/react';
import {useHistory} from "react-router-dom";
import { PaymentMethod} from "../../lib/data_types/dataTypes"

//components
import API from "../../lib/api/NbioApi";
import {card} from "ionicons/icons";

interface PaymentMethodsProps {
    isPicker?: boolean,
    onCardPicked?: any,
    checkoutId?:string
}


const PaymentMethods: React.FC<PaymentMethodsProps> = (
    {
        isPicker = false,
        checkoutId = null,
        onCardPicked = () => {
        }
    }) => {
    const [paymentList, setPaymentList] = useState<[PaymentMethod] | []>([]);
    const [renderStep, setRenderStep] = useState('LIST'); //Values   LOADING, LIST
    const [selectedCard, setSelectedCard] = useState<string | null>(null);
    const history = useHistory();
    const [showDeleteToast,setShowDeleteToast] = useState(false);
    const [paymentMethodToDelete,setPaymentMethodToDelete] = useState<PaymentMethod| null>(null);


    useEffect(() => {
        loadPaymentMethods();
    }, []);

    const loadPaymentMethods = () => {
        API.paymentMethods.get().then((res) => {
            setPaymentMethodToDelete(null);
            setPaymentList(res.data.paymentMethods)
        }).catch((ex) => {
        })
    }

    const renderLoading = () => {
        return (
            <IonCol className={'h-100'}>
                <IonSkeletonText animated className={'h-100'}/>
            </IonCol>
        )
    }
    const goToPayWithCard = () => {
        history.push({
            pathname: `/pagar`,
            state: {
                paymentType: 'force-card',
                checkoutId: checkoutId,
                action: 'PAGAR'
            }
        })
    }

    const renderAddCard = () => {
        if (!isPicker) {
            return null
        }
        return (
            <>
                <IonCard button={true} onClick={() => goToPayWithCard()}>
                    <IonItem lines="none">
                        <IonLabel className={"ion-text-capitalize"}>
                            Agregar una tarjeta
                        </IonLabel>
                    </IonItem>
                </IonCard>
            </>


        )
    }
    const onDeletePaymentMethod = (payment:PaymentMethod) =>{
        setShowDeleteToast(true);
        setPaymentMethodToDelete(payment);
    }
    const renderList = () => {


        if (paymentList.length === 0) {
            return (
                <IonCol size-md="6">
                    <div className={'ion-padding ion-text-center'}>
                        No tienes tarjetas guardadas
                        <br/>
                        <br/>
                        <IonIcon icon={card} color={'medium'} size={'large'} slot={'icon-only'}></IonIcon>
                    </div>
                </IonCol>
            )
        } else {
            return (
                <IonCol size-md="6">
                    <IonRadioGroup value={selectedCard} onIonChange={(e) => {
                        const selectedCard = paymentList.find((c) => c.id === e.detail.value);
                        setSelectedCard(e.detail.value)
                        onCardPicked(selectedCard);
                    }}>

                        {
                            paymentList.map((payment: PaymentMethod) => {
                                const paymentMethodToDeleteId = paymentMethodToDelete ? paymentMethodToDelete.id : '';
                                return (
                                    <IonCard key={payment.id} className={'ion-margin-top'}>
                                        <IonItem lines="none">
                                            {
                                                isPicker ? <IonRadio value={payment.id}/> : null
                                            }
                                            <IonLabel className={"ion-text-capitalize"}>
                                                <b>&nbsp;{payment.brand} **** **** **** {payment.last4}</b>
                                            </IonLabel>
                                        </IonItem>
                                        <IonCardContent className={"no-padding-vertical"}>
                                            <IonLabel>
                                                {payment.name}
                                            </IonLabel>
                                            <br/>
                                            <IonNote>
                                                Vence {payment.exp_month}/{payment.exp_year}
                                            </IonNote>
                                        </IonCardContent>
                                        <IonItem>
                                            {/*TODO: Remove when no default card is approved*/}
                                            {/*{renderIsDefault(payment.isDefault, payment)}*/}
                                            <div slot={'end'}>
                                                <IonButton slot={"start"}
                                                           size={"small"}
                                                           fill={"outline"}
                                                           color={'danger'}
                                                           onClick={() => {
                                                               onDeletePaymentMethod(payment)
                                                           }}
                                                >
                                                    { payment.id !== paymentMethodToDeleteId ? <span>Eliminar</span> : <IonSpinner color={'danger'} name={'dots'}/>}
                                                </IonButton>
                                            </div>
                                        </IonItem>
                                    </IonCard>
                                )
                            })
                        }

                    </IonRadioGroup>
                </IonCol>
            )
        }
    }
    const makeDefault = (id: string) => {
        API.paymentMethods.makeDefault(id).then((res) => {
            loadPaymentMethods();
        }).catch((ex) => {

        })
    }
    const renderIsDefault = (isDefault: boolean, payment: PaymentMethod) => {
        if (isPicker) {
            return null
        }
        if (isDefault) {
            return <IonBadge color={"secondary"} className={"ion-text-start ion-text-wrap"}>Predeterminada</IonBadge>
        } else {
            return <IonButton onClick={() => makeDefault(payment.id)}>Hacer predeterminada</IonButton>
        }
    }

    const renderComponent = () => {
        switch (renderStep) {
            case 'LOADING':
                return renderLoading();
            case 'LIST':
                return renderList();
            default:
                return renderLoading();
        }
    }

    const deletePaymentMethod = () =>{
        if(paymentMethodToDelete){

            API.paymentMethods.delete(paymentMethodToDelete?.id).then((res) =>{
                setTimeout(() =>{
                    // wait for the card to be deleted on MP server
                    loadPaymentMethods();
                },1000)
            }).catch((ex)=>{
                setPaymentMethodToDelete(null);
                // TODO:
            })
        }
    }

    return (
        <IonContent>
            <IonAlert isOpen={showDeleteToast}
                      onDidDismiss={() => setShowDeleteToast(false)}
                      header={'¿Estás seguro/a de borrar tu tarjeta?'}
                      message={'Tendrás que volver a ingresar los datos en tu siguiente compra'}
                      buttons={[
                          {
                              text: 'No',
                              role: 'cancel',
                              handler: () => setPaymentMethodToDelete(null)
                          },
                          {
                              text: 'Si',
                              handler: () => {deletePaymentMethod()},
                          },
                      ]}/>
            <IonGrid className={'ion-no-padding h-100'}>
                <IonRow className="ion-justify-content-center h-100">
                    {renderComponent()}
                </IonRow>
            </IonGrid>
        </IonContent>
    )
}

export default PaymentMethods;
