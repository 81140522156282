/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2023
 */


import React from "react";
import {
    IonBackButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon,
    IonPage,
    IonRow, IonSkeletonText,
    IonTitle,
    IonToolbar,
    withIonLifeCycle
} from "@ionic/react";
import {RouteComponentProps} from "react-router";
import NbioApi from "../../lib/api/NbioApi";


//components
import GradientDivider from "../../components/ui/GradientDivider";
import WebHeader from "../../components/ui/WebHeader";
import Coupon from "../../components/coupons/Coupon";
import {ticket} from "ionicons/icons";


interface CouponsListProps extends RouteComponentProps {
}


interface CouponsListState {
    coupons: any[],
    isLoading: boolean
}

class CouponsList extends React.Component<CouponsListProps,CouponsListState> {
    constructor(props: CouponsListProps) {
        super(props);
        this.state = {
            coupons: [],
            isLoading: true
        }
    }

    ionViewDidEnter() {
        NbioApi.coupons.getMyCoupons().then((res) =>{
            this.setState({coupons:res.data.coupons.map((c:any) => c.coupon), isLoading:false});
        }).catch((ex) =>{
            this.setState({isLoading:false});
            console.log(ex);
        })
    }

    renderCoupons = () => {
        const coupons = this.state.coupons;
        if(coupons.length > 0){
            return(
                coupons.map((coupon) => {
                    return <Coupon key={coupon._id} coupon={coupon}/>
                })
            )
        }else{
            return(
                <div className={'ion-text-center h-100 flex-col ion-justify-content-center ion-align-items-center'}>
                    <div className={'ion-margin-bottom'}> No tienes cupones en este momento. <br/>
                        ¡Descubre cupones en farmacias participantes!
                    </div>
                    <IonIcon icon={ticket} size={'large'} slot={'icon-only'}></IonIcon>
                </div>
            )
        }
    }

    render() {
        return (
            <IonPage>
                <WebHeader title={'Mis cupones'} showSearchButton={true} showMenuButton={true} showCartButton={true}></WebHeader>
                <IonContent fullscreen>
                    <IonGrid className={'h-100'}>
                        <IonRow className="ion-justify-content-center ion-hide-lg-down">
                            <IonCol size={'12'} sizeLg={'6'}>
                                <IonHeader className={"ion-no-border"}>
                                    <IonTitle className="ion-text-capitalize">Mis cupones</IonTitle>
                                </IonHeader>
                            </IonCol>
                        </IonRow>
                        <IonRow className="h-100 ion-justify-content-center">
                            <IonCol size-md="6">
                                {this.state.isLoading ? <IonSkeletonText animated={true} className={'h-100 w-100'}/> : this.renderCoupons()}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        );
    }

}

export default withIonLifeCycle(CouponsList);
