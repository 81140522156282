/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * May 2022
 */

import {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
    IonCardTitle,
    IonCardContent,
} from "@ionic/react";
import React from "react";
import {OrderType, ProgrammedHourType, ShippingMethodType} from "../../lib/data_types/dataTypes";

//style
import "../../css/components/orderWidget.css";
import OrderProductThumbnail from "./OrderProductThumbnail";

//interface
interface OrderWidgetProps {
    order?: OrderType
}

const OrderWidget: React.FC<OrderWidgetProps> = ({ order }) => {

    // const renderTitle = () =>{
    //     const items = order?.items ? order.items : [];
    //     const orderStatus = order?.status || '';
    //     const itemRequiresPrescription =
    //         items.find(
    //             (item) => item.isPrescriptionValidated && item.isPrescriptionValid === false);
    //
    //     if(itemRequiresPrescription && orderStatus !== 'cancelled'){
    //         return 'Vuelve a subir tu receta';
    //     }
    //     else if(['received', 'processing'].includes(orderStatus)){
    //         if(order?.assignedUser){
    //             // Cuando se asigne operador cambiar a Pedido aceptado
    //             return 'Pedido aceptado';
    //         }else{
    //             return 'Preparando tu orden';
    //         }
    //     }else if(['amount_validated'].includes(orderStatus)){
    //         // Cuando validen el monto, mostrar 'entregando a repartidor'
    //         return 'Entregando a repartidor';
    //     }
    //     else if(['on_route', 'delivering'].includes(orderStatus)){
    //         return 'Tu orden está en camino';
    //     }else if(['delivered','finished','archived'].includes(orderStatus)){
    //         return 'Orden entregada';
    //     }else if(['cancelled'].includes(orderStatus)){
    //         return 'Orden cancelada';
    //     }
    // }

    const renderDeliveryTime = (shippingMethod: ShippingMethodType, deliveryBlock: ProgrammedHourType | undefined) => {
        if (shippingMethod.name === 'Inmediata') {
            return 'Hoy'
        }

        if (shippingMethod.name === 'Programada') {
            if (deliveryBlock?.dayLabel) {
                return `${deliveryBlock.dayLabel}`
            }
        }
    }

   if (order !== undefined){
       const items = order?.items ? order.items : [];

       return (
           <IonGrid>
               <IonRow>
                   <IonCol>
                       <IonText>
                           <h5 className={'ion-hide-lg-up'}>
                               Pedido en curso
                           </h5>
                           <h3 className={'ion-hide-lg-down'}>
                               Pedido en curso
                           </h3>
                       </IonText>
                   </IonCol>
               </IonRow>
               <IonRow className={"ion-align-items-center"}>
                   <IonCol size={'12'}>
                       <IonCard button
                                className={'ion-no-margin o-widget'}
                                routerDirection="forward"
                                routerLink={`/ordenes/${order._id}`}>
                           <div className={'o-wrapper'}>
                                   <IonText className={'ion-margin-end'}>
                                       <h5>
                                           Lo recibes: {renderDeliveryTime(order.shippingMethod, order.deliveryBlock)}
                                       </h5>
                                   </IonText>
                               <OrderProductThumbnail items={items}></OrderProductThumbnail>
                           </div>

                       </IonCard>
                   </IonCol>
               </IonRow>
           </IonGrid>
       )
   }else{
       return null
   }
};

export default OrderWidget;
