/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonItem,
    IonLabel,
    IonInput,
    IonContent,
    IonPage,
    IonToast,
    IonSelect,
    IonSelectOption,
    IonText,
    IonIcon,
    getPlatforms,
    IonCheckbox,
    useIonAlert, isPlatform
} from '@ionic/react';
import {useHistory} from "react-router";
import {Keyboard} from "@capacitor/keyboard";
import NbioApi from "../../lib/api/NbioApi";
import {AppContext} from "../../App";
import {Capacitor} from "@capacitor/core";
import dayjs from "dayjs";
import utc   from "dayjs/plugin/utc";
import 'dayjs/locale/es'
import customParseFormat from 'dayjs/plugin/customParseFormat';

//style
import '../../css/pages/Login.css';
import {logoFacebook, logoGoogle} from "ionicons/icons";
import {FacebookLogin} from "@capacitor-community/facebook-login";
import {SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse} from "@capacitor-community/apple-sign-in";
import { GoogleAuth } from '@reslear/capacitor-google-auth';
import axios from "axios";
import NPDatePicker from "../../components/ui/NPDatePicker";

//dates
dayjs.extend(utc);
dayjs.extend(customParseFormat);



const SignIn: React.FC = () => {
    const history = useHistory();
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const {user, setUser, isAuth, setIsAuth} = useContext(AppContext);
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
    const [dateOfBirth,setDateOfBirth] = useState(dayjs().format('MM-DD-YYYY'));
    const [gender,setGender] = useState('');
    const [presentAlert] = useIonAlert();

    const ionInputName = useRef(null);
    const ionInputLastName = useRef(null);
    const ionInputEmail = useRef(null);
    const ionInputPassword = useRef(null);
    const ionInputConfirmPassword = useRef(null);
    const [isUnsupportedWebView,setIsUnsupportedWebView] = useState(false);

    const isKeyboardAvailable = Capacitor.isPluginAvailable('Keyboard');
    if (isKeyboardAvailable) {
        Keyboard.addListener('keyboardWillShow', info => {
            setIsKeyboardOpen(true);
        });

        Keyboard.addListener('keyboardDidShow', info => {
            setIsKeyboardOpen(true);
        });

        Keyboard.addListener('keyboardWillHide', () => {
            setIsKeyboardOpen(false);
        });

        Keyboard.addListener('keyboardDidHide', () => {
            setIsKeyboardOpen(false);
        });
    }
    const goToLogin = () => {
        history.push('/iniciar-sesion');
    }
    useEffect(() =>{
        // Check if this is an unsupported webview (instagram, facebook in-app browser)
        const userAgent = window.navigator.userAgent.toLowerCase();
        if( userAgent.includes('fb_iab') ||
            userAgent.includes('fb4a') ||
            userAgent.includes('fbav') ||
            userAgent.includes('instagram') ||
            userAgent.includes('musical_ly')
        ){
            // setIsUnsupportedWebView(true); // TODO:REVERT THIS WHEN NEEDED
        }
    },[]);
    const askDownloadApp = () =>{
        const isIos = isPlatform('ios');
        presentAlert({
            header: 'Descarga nuestra app',
            message:
                `Para una mejor experiencia, descarga nuestra app`,
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                },
                {
                    text: isIos ? 'Ir a la App Store' : 'Ir a Google Play',
                    role: 'confirm',
                    handler: () => {
                        goToStore();
                    },
                },
            ],
        })
    }
    const goToStore = () =>{
        const isIos = isPlatform('ios');
        window.open(isIos ? 'https://apps.apple.com/us/app/nbio/id1605823846' : 'https://play.google.com/store/apps/details?id=mx.nbio&hl=es_MX&gl=US');
    }
    const isEmpty   = (v:string) => {
        if (v) {
            return v.trim() === '';
        } else {
            return true;
        }
    }
    const isOver17YearsOld = (v:string) =>{
        const minAge = dayjs().subtract(17,'year');
        const userAge = dayjs(v,'MM-DD-YYYY');
        if(userAge.isValid()){
            return userAge.isBefore(minAge) ||  userAge.isSame(minAge);
        }else{
            return false;
        }
    }

    const isValidForm = () =>{
        if (isEmpty(name)) {
            return {
                message: 'El nombre no puede estar vacío',
                isValid: false
            }
        }
        if (isEmpty(lastName)) {
            return {
                message: 'El apellido no puede estar vacío',
                isValid: false
            }
        }
        if (isEmpty(dateOfBirth)) {
            return {
                message: 'Debes de proporcionar una fecha de nacimiento',
                isValid: false
            }
        }
        if (!isOver17YearsOld(dateOfBirth)){
            return {
                message: 'Debes de tener al menos 17 años para crear una cuenta',
                isValid: false
            }
        }
        if (isEmpty(email)) {
            return {
                message: 'El email no puede estar vacío',
                isValid: false
            }
        }
        if (isEmpty(password)) {
            return {
                message: 'La contraseña no puede estar vacía',
                isValid: false
            }
        }
        if(password.trim().length < 6){
            return {
                message: 'La contraseña debe de tener al menos 6 caractéres',
                isValid: false
            }
        }
        if (isEmpty(confirmPassword)) {
            return {
                message: 'La contraseña no puede estar vacía',
                isValid: false
            }
        }
        if(password !== confirmPassword){
            return {
                message: 'Las contraseñas no coinciden',
                isValid: false
            }
        }
        return {
            message:'',
            isValid:true
        }
    }
    const goToStart = () =>{
        window.location.href = '/inicio';
    }
    const createAccount = (e: any) => {
        e.preventDefault();
        const {message,isValid} = isValidForm();
        if(!isValid){
            setErrorMessage(message);
            setIsToastOpen(true);

        }else{
            const newUser = {
                name: name,
                last_name: lastName,
                email: email,
                password: password,
                phone: phone,
                gender:gender,
                dateOfBirth:dateOfBirth,
            }
            NbioApi.users.create(newUser).then((res) => {
                try{
                    // @ts-ignore
                    gtag("event", "sign_up", {method: "nbio_mx"});
                }catch(ex){
                }
                setUser(res.data.result.user);
                setIsAuth(true);
                goToStart()
            }).catch((ex) => {
                let errorMessage = 'Hubo un error al crear tu cuenta';
                try{
                    errorMessage = ex.response.data.error_es;

                }catch(ex){

                }
                setErrorMessage(errorMessage);
                setIsToastOpen(true);
            })
        }

    }

    useEffect(() =>{
        GoogleAuth.initialize({
            clientId: '18257496481-e9k3e0ocq6mefenvdq6dgml13ufl7e94.apps.googleusercontent.com',
            scopes: ['profile', 'email'],
            grantOfflineAccess: true,
        })
    })
    const register = () =>{
        const GET_TOKEN_URL = 'https://oauth2.googleapis.com/token'
        GoogleAuth.signIn().then((res) =>{
            if(!res.authentication.accessToken){
                // GET TOKEN
                const body = {
                    client_id: '18257496481-hu6kfsu44mp5lger9hhb0lq0nlrnn5t3.apps.googleusercontent.com',
                    client_secret: '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',
                    code: res.serverAuthCode,
                    grant_type: 'authorization_code',
                    redirect_uri: 'urn:ietf:wg:oauth:2.0:oob'
                };
                const tokenRes = axios.post(GET_TOKEN_URL,body).then((res) =>{
                    // Login successful.
                    const token = res.data.access_token;
                    // Login successful.
                    NbioApi.users.loginWithSocial(token,'google').then((res) => {
                        // forget cart
                        // Cart.forgetCart();
                        try{
                            // @ts-ignore
                            gtag("event", "sign_up", {method: "google"});
                        }catch(ex){
                        }
                        setUser(res.data.result.user);
                        setIsAuth(true);
                        goToStart()
                    }).catch((ex) => {
                        let errorMessage = "Hubo un error al iniciar sesión";
                        try{
                            errorMessage = ex.response.data.result.reason_es;
                        }catch(ex){
                        }
                        setErrorMessage(errorMessage);
                        setIsToastOpen(true);
                    })
                }).catch((ex) =>{
                    // console.log('error',ex.response)
                })
            }else{
                // Login successful.
                NbioApi.users.loginWithSocial(res.authentication.accessToken,'google').then((res) => {
                    // forget cart
                    // Cart.forgetCart();
                    try{
                        // @ts-ignore
                        gtag("event", "sign_up", {method: "google"});
                    }catch(ex){
                    }
                    setUser(res.data.result.user);
                    setIsAuth(true);
                    goToStart()
                }).catch((ex) => {
                    let errorMessage = "Hubo un error al iniciar sesión";
                    try{
                        errorMessage = ex.response.data.result.reason_es;
                    }catch(ex){
                    }
                    setErrorMessage(errorMessage);
                    setIsToastOpen(true);
                })
            }

        }).catch((ex) =>{
        })
    }

    const loginWithFacebook = async () => {
        const FACEBOOK_PERMISSIONS = ['email', 'user_birthday', 'public_profile', 'user_gender'];
        await FacebookLogin.initialize({ appId: '1011759540268928' });
        const result = await FacebookLogin.login({permissions: FACEBOOK_PERMISSIONS});
        if (result.accessToken) {
            // Login successful.
            NbioApi.users.loginWithSocial(result.accessToken.token,'meta').then((res) => {
                // forget cart
                // Cart.forgetCart();
                try{
                    // @ts-ignore
                    gtag("event", "sign_up", {method: "meta"});
                }catch(ex){
                }
                setUser(res.data.result.user);
                setIsAuth(true);
                goToStart()
            }).catch((ex) => {
                let errorMessage = "Hubo un error al iniciar sesión";
                try{
                    errorMessage = ex.response.data.result.reason_es;
                }catch(ex){
                }
                setErrorMessage(errorMessage);
                setIsToastOpen(true);
            })
        }
    }
    const platforms = getPlatforms();
    const showAppleSignIn = platforms.includes('ios') || platforms.includes('iphone') || platforms.includes('ipad');

    const loginWithApple = async () =>{

        let options: SignInWithAppleOptions = {
            clientId: 'mx.nbio.signin',
            redirectURI: 'https://api.nbio.mx/users/login/social',
            scopes: 'email name',
            state: '12345',
            nonce: 'nonce',
        };

        SignInWithApple.authorize(options)
            .then((result: SignInWithAppleResponse) => {
                // Handle user information
                // Validate token with server and create new session
                // Login successful.
                const extraData = {
                    givenName: result.response?.givenName || null,
                    familyName: result.response?.familyName || null
                }
                NbioApi.users.loginWithSocial(result.response.identityToken,'apple',extraData).then((res) => {
                    // forget cart
                    // Cart.forgetCart();
                    try{
                        // @ts-ignore
                        gtag("event", "sign_up", {method: "apple"});
                    }catch(ex){
                    }
                    setUser(res.data.result.user);
                    setIsAuth(true);
                    goToStart()
                }).catch((ex) => {
                    let errorMessage = "Hubo un error al iniciar sesión";
                    try{
                        errorMessage = ex.response.data.result.reason_es;
                    }catch(ex){
                        // console.log(ex)
                    }
                    setErrorMessage(errorMessage);
                    setIsToastOpen(true);
                })
            })
            .catch(error => {
                // console.log(error)
                // Handle error
            });
    }

    const goNext = async (currentField:string ) =>
    {
        if (currentField === 'name') {
            try {
                // @ts-ignore
                ionInputLastName.current.setFocus();
            } catch (ex) {
            }
        }
        if (currentField === 'lastName') {
            try {
                // @ts-ignore
                ionInputEmail.current.setFocus();
            } catch (ex) {
            }
        }
        if (currentField === 'email') {
            try {
                // @ts-ignore
                ionInputPassword.current.setFocus();
            } catch (ex) {
            }
        }
        if (currentField === 'password') {
            try {
                // @ts-ignore
                ionInputConfirmPassword.current.setFocus();
            } catch (ex) {
            }
        }
    }
    return (
        <IonPage>
            <IonContent fullscreen>
                <IonGrid>
                    <IonToast
                        isOpen={isToastOpen}
                        onDidDismiss={() => {
                            setIsToastOpen(false)
                        }}
                        message={errorMessage}
                        duration={8000}
                    />
                    <IonRow className="ion-justify-content-center">
                        <IonCol size-md="6">
                            {
                                isKeyboardOpen ? null :
                                    <div className={'main-app-logo'}></div>
                            }
                            <div className={'ion-padding-top'}>
                                <IonText className="ion-text-center">
                                    <h5>Unirse con </h5>
                                </IonText>
                                <div className={'social-sign-buttons'}>
                                    <IonButton class={'social-button'}
                                               // onClick={() => register()}
                                               onClick={() => {
                                                   if(isUnsupportedWebView){
                                                       askDownloadApp();
                                                   }else{
                                                       register();
                                                   }

                                               }}
                                    >
                                        <IonIcon slot="start" icon={logoGoogle} className='social-icon'></IonIcon>
                                    </IonButton>
                                    <IonButton class={'social-button'}
                                               // onClick={() => loginWithFacebook()}
                                               onClick={() => {
                                                   if(isUnsupportedWebView){
                                                       askDownloadApp();
                                                   }else{
                                                       loginWithFacebook();
                                                   }
                                               }}
                                    >
                                        <IonIcon slot="start" icon={logoFacebook} className='social-icon'></IonIcon>
                                    </IonButton>
                                    {
                                        showAppleSignIn ?
                                            <IonButton class={'social-button'}
                                                       // onClick={() => loginWithApple()}
                                                       onClick={() => {
                                                           if(isUnsupportedWebView){
                                                               askDownloadApp();
                                                           }else {
                                                               loginWithApple();
                                                           }
                                                       }}
                                            >
                                                <div slot="start" className='social-icon apple-icon'>

                                                </div>
                                            </IonButton>
                                            : null
                                    }
                                </div>
                                <IonText className="ion-text-center">
                                    <h5>O con e-mail:</h5>
                                </IonText>
                            </div>
                            <IonItem className={'ion-margin-top'}>
                                <IonLabel position={"floating"}>Nombre(s)</IonLabel>
                                <IonInput id="name"
                                          type="text"
                                          value={name}
                                          ref={ionInputName}
                                          autoCapitalize={'words'}
                                          onIonChange={(e: any) => setName(e.target.value)}
                                          onKeyPress={(ev) => {
                                              if(ev.key === 'Enter'){
                                                  goNext('name');
                                              }
                                          }}
                                          enterkeyhint="next"
                                          placeholder="Nombre(s)">
                                </IonInput>
                            </IonItem>

                            <IonItem>
                                <IonLabel position={"floating"}>Apellidos</IonLabel>
                                <IonInput id="lastName"
                                          type="text"
                                          value={lastName}
                                          ref={ionInputLastName}
                                          autoCapitalize={'words'}
                                          onIonChange={(e: any) => setLastName(e.target.value)}
                                          onKeyPress={(ev) => {
                                              if(ev.key === 'Enter'){
                                                  goNext('lastName');
                                              }
                                          }}
                                          enterkeyhint="next"
                                          placeholder="Apellidos">
                                </IonInput>
                            </IonItem>
                            <div className={'ion-item-dupe'}>
                                <IonLabel position={'stacked'}>Fecha de nacimiento:</IonLabel>
                                <NPDatePicker onDateChanged={(date:any) => {
                                    setDateOfBirth(date.format('MM-DD-YYYY'));
                                }}></NPDatePicker>
                            </div>

                            {/*<IonItem lines={'inset'} className={'flex-column'} button={false} onClick={(ev) => ev.preventDefault()}>*/}
                            {/*    <IonLabel slot={''} position={'stacked'}>Fecha de nacimiento:</IonLabel>*/}
                            {/*    <NPDatePicker>*/}
                            {/*    </NPDatePicker>*/}
                            {/*</IonItem>*/}

                            <IonItem>
                                <IonLabel slot={''}>Género: </IonLabel>
                                <IonSelect interface="popover" slot={'end'} value={gender}
                                           placeholder="Elige una opción"
                                           onIonChange={(e) => setGender(e.detail.value)}>
                                    <IonSelectOption value="female">Femenino</IonSelectOption>
                                    <IonSelectOption value="male">Masculino</IonSelectOption>
                                    <IonSelectOption value="other">Otro</IonSelectOption>
                                    <IonSelectOption value="">No proporcionar</IonSelectOption>
                                </IonSelect>
                            </IonItem>

                            <IonItem className={'ion-margin-top'}>
                                <IonLabel position={"floating"}>Correo</IonLabel>
                                <IonInput id="email"
                                          type="text"
                                          value={email}
                                          ref={ionInputEmail}
                                          enterkeyhint="next"
                                          onIonChange={(e: any) => setEmail(e.target.value)}
                                          onKeyPress={(ev) => {
                                              if(ev.key === 'Enter'){
                                                  goNext('email');
                                              }
                                          }}
                                          placeholder="Correo">
                                </IonInput>
                            </IonItem>

                            <IonItem className={'ion-margin-top'}>
                                <IonLabel position={"floating"}>Contraseña</IonLabel>
                                <IonInput id="password"
                                          type="password"
                                          value={password}
                                          ref={ionInputPassword}
                                          enterkeyhint="next"
                                          onIonChange={(e: any) => setPassword(e.target.value)}
                                          onKeyPress={(ev) => {
                                              if(ev.key === 'Enter'){
                                                  goNext('password');
                                              }
                                          }}
                                          placeholder="Contraseña">
                                </IonInput>
                            </IonItem>

                            <IonItem>
                                <IonLabel position={"floating"}>Confirmar contraseña</IonLabel>
                                <IonInput id="confirmPassword"
                                          type="password"
                                          value={confirmPassword}
                                          ref={ionInputConfirmPassword}
                                          onIonChange={(e: any) => setConfirmPassword(e.target.value)}
                                          placeholder="Confirmar contraseña">
                                </IonInput>
                            </IonItem>

                            <IonItem lines={'none'} className={'ion-margin-top'}>
                                <IonLabel className={'ion-text-wrap'}>Al crear una cuenta, acepto las <a href={'/politicas-de-privacidad'}>Políticas de Privacidad</a> y <a href={'/terminos-y-condiciones'}>Términos y Condiciones</a> de Nbio.</IonLabel>
                            </IonItem>

                            <IonButton className={"ion-margin-vertical"}
                                       expand="block"
                                       onClick={(e) => createAccount(e)}>Crear cuenta</IonButton>

                            <div className={'flex-row ion-justify-content-between ion-wrap'}>
                                <IonButton fill="clear"
                                           size="small"
                                           routerLink={"/inicio"}
                                           routerDirection={"forward"}>Continuar sin cuenta</IonButton>
                                <IonButton fill="clear"
                                           size="small"
                                           routerLink={"/iniciar-sesion"}
                                           routerDirection={"forward"}>Ya tengo cuenta</IonButton>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default SignIn;
