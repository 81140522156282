/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2024
 */

import {
    IonProgressBar,
    IonIcon,
    IonCard,
    IonCardContent,
    IonLabel,
    IonGrid,
    IonCol,
    IonRow
} from "@ionic/react";
import React from "react";
import NbioApi from "../../lib/api/NbioApi";
import {cart} from "ionicons/icons";

//style
import "../../css/components/freeDeliveryProgressBar.css";
import { Link } from "react-router-dom";

const {money} = require('../../lib/money/money');

interface FreeDeliveryProgressBarProps{
    total:number,
    naked?: boolean
}
interface FreeDeliveryProgressBarState{
    isActive: boolean,
    minPurchaseAmount: number,
}
class FreeDeliveryProgressBar extends React.Component<FreeDeliveryProgressBarProps,FreeDeliveryProgressBarState> {
    constructor(props:FreeDeliveryProgressBarProps) {
        super(props);
        this.state  = {
            isActive: false,
            minPurchaseAmount: 999999
        }

        this.checkFreeShipping = this.checkFreeShipping.bind(this);
    }

    componentDidMount(){
        this.checkFreeShipping();
    }

    checkFreeShipping = () => {
        NbioApi.store.getFreeShipping().then((res) => {
            const freeShippingConfig = res.data.freeShippingConfig
            this.setState({isActive:freeShippingConfig.isActive,
                                 minPurchaseAmount:freeShippingConfig.minPurchaseAmount});
        }).catch((ex) => {
            console.log(ex);
        })
    }

    render(){
        const diff = this.state.minPurchaseAmount - this.props.total;
        const cartReachedMinPurchaseAmount = diff <= 0;
        const progress = this.props.total / this.state.minPurchaseAmount
        if (this.state.isActive){
            if(this.props.naked){
                return (
                                <IonCard className={"ion-no-margin"}>
                                    <IonCardContent>
                                        {
                                            cartReachedMinPurchaseAmount ?
                                                <IonLabel className='ion-margin-bottom'>
                                                    ¡Felicidades, tienes envío GRATIS!
                                                </IonLabel>
                                                :
                                                <IonLabel className='ion-margin-bottom'>
                                                    Te falta <b>{money(this.state.minPurchaseAmount - this.props.total)}</b> para <b>ENVÍO GRATIS</b>
                                                </IonLabel>
                                        }
                                        <div className={"fdpb-content"}>
                                            <IonProgressBar className={"fdpb"} value={progress}/>
                                            <div className={`fpdb-icon-wrapper ${cartReachedMinPurchaseAmount ? 'fpdb-icon' : null}`}>
                                                <IonIcon icon={cart}/>
                                            </div>
                                        </div>
                                        {
                                            ! cartReachedMinPurchaseAmount ?
                                                <div className={'ion-text-right ion-margin-top'}>
                                                    <IonLabel color={'primary'} className={'ion-text-right'}>
                                                        <Link to={'/categorias'}>
                                                            <b>&nbsp;Seguir comprando</b>
                                                        </Link>
                                                    </IonLabel>
                                                </div>
                                                : null
                                        }
                                    </IonCardContent>
                                </IonCard>
                );
            }else{
                return (
                    <IonGrid >
                        <IonRow className={"ion-justify-content-center"}>
                            <IonCol size-md="6">
                                <IonCard className={"ion-no-margin"}>
                                    <IonCardContent>
                                        {
                                            cartReachedMinPurchaseAmount ?
                                                <IonLabel className='ion-margin-bottom'>
                                                    ¡Felicidades, tienes envío GRATIS!
                                                </IonLabel>
                                                :
                                                <IonLabel className='ion-margin-bottom'>
                                                    Te falta <b>{money(this.state.minPurchaseAmount - this.props.total)}</b> para <b>ENVÍO GRATIS</b>
                                                </IonLabel>
                                        }
                                        <div className={"fdpb-content"}>
                                            <IonProgressBar className={"fdpb"} value={progress}/>
                                            <div className={`fpdb-icon-wrapper ${cartReachedMinPurchaseAmount ? 'fpdb-icon' : null}`}>
                                                <IonIcon icon={cart}/>
                                            </div>
                                        </div>
                                        {
                                            ! cartReachedMinPurchaseAmount ?
                                                <div className={'ion-text-right ion-margin-top'}>
                                                    <IonLabel color={'primary'} className={'ion-text-right'}>
                                                        <Link to={'/categorias'}>
                                                            <b>&nbsp;Seguir comprando</b>
                                                        </Link>
                                                    </IonLabel>
                                                </div>
                                                : null
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                );
            }

        }else{
            return null
        }
    }
};

export default FreeDeliveryProgressBar;
