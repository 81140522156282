/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * Febuary 2022
 */
import React from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    withIonLifeCycle,
    IonContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonCardHeader,
    IonCardTitle,
    IonNote,
    IonCardContent,
    IonBackButton, IonLabel, IonImg, IonSkeletonText,
} from '@ionic/react';
import {RouteComponentProps} from "react-router";

import {CartItemType} from "../../lib/data_types/dataTypes";
import NbioApi from "../../lib/api/NbioApi";
import { Swiper, SwiperSlide } from 'swiper/react';


//components
import GradientDivider from "../../components/ui/GradientDivider";
import dayjs from "dayjs";
// @ts-ignore
import Lightbox from "react-awesome-lightbox";
import NPLightBox from "../../components/ui/NPLightBox";


//Interfaces
interface OrderPrescriptionsOperatorState {
    controlledProducts: CartItemType[],
    isLoading: boolean;
    showLightBox:boolean;
    selectedImage:string
};


interface OrderPrescriptionsOperatorProps extends RouteComponentProps<{
    id: string; }> {
};


class OrderPrescriptionsOperator extends React.Component<OrderPrescriptionsOperatorProps, OrderPrescriptionsOperatorState> {
    constructor(props: OrderPrescriptionsOperatorProps) {
        super(props);
        this.state = {
            controlledProducts: [],
            isLoading: true,
            showLightBox:false,
            selectedImage:''
        }
        this.getOrder          = this.getOrder.bind(this);
    }

    ionViewDidEnter() {
        const orderId = this.props.match.params.id;
        this.getOrder(orderId);
    }

    getOrder = (id:string) => {
        NbioApi.orders.getOrder(id).then(res => {
            const products = res.data.order.items;
            const controlledProducts = products.filter((product: CartItemType) => product.isControlled);
            this.setState({controlledProducts:controlledProducts,isLoading:false});
        }).catch(ex => {
            console.log(ex);
        })
    }

    parseDate(d: Date | undefined) {
        if(!d){
            return '-'
        }else{
            return dayjs(d).format('DD-MM-YYYY HH:mm:ss')
        }
    }
    toggleLightBox(){
        this.setState({showLightBox:!this.state.showLightBox})
    }
    renderLightBox(){
        return (
            <NPLightBox
                url={this.state.selectedImage}
                isOpen={this.state.showLightBox}
                onClose={() => this.setState({showLightBox:false})}>

        </NPLightBox>)
    }
    render() {
        const orderId = this.props.match.params.id;
        const controlledProducts = this.state.controlledProducts;
        const isLoading = this.state.isLoading;

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar className={"toolbar-nbio"}>
                        <IonButtons slot="start"><IonBackButton></IonBackButton></IonButtons>
                        <IonTitle>Recetas #{orderId}</IonTitle>
                    </IonToolbar>
                    <GradientDivider></GradientDivider>
                </IonHeader>
                <IonContent fullscreen>
                    {this.renderLightBox()}
                    <IonGrid>
                        <IonRow className={"ion-justify-content-center"}>
                            <IonCol size-md="6">
                                <Swiper slidesPerView={1} loop={false}>
                                    {
                                        controlledProducts.map((product,index) => {
                                            const prescription = product.prescription;
                                            return(
                                                <SwiperSlide key={product._id}>
                                                    <div>
                                                        <IonNote>Receta {index+1} de {controlledProducts.length}</IonNote>
                                                        <IonCard className={'ion-no-margin ion-margin-top'}>
                                                            {
                                                                isLoading ? <IonSkeletonText animated style={{height: '250px'}}/> :
                                                                    <IonImg src={prescription?.imageUrl}
                                                                            className={'w-100'}
                                                                            onClick={() =>{
                                                                                this.setState({
                                                                                    'selectedImage':prescription?.imageUrl!,
                                                                                    showLightBox:true
                                                                                })
                                                                            }
                                                                    }></IonImg>
                                                            }
                                                            <IonCardHeader>
                                                                <IonCardTitle className={"ion-text-start"}>
                                                                    Información de la receta
                                                                </IonCardTitle>
                                                            </IonCardHeader>
                                                            <IonCardContent className={"ion-text-start"}>
                                                                <IonLabel><b>Cédula profesional: </b>{prescription?.documentId}</IonLabel><br/>
                                                                <IonLabel><b>Fecha: </b>{this.parseDate(prescription?.issueDate)}</IonLabel>
                                                            </IonCardContent>

                                                            <IonCardHeader>
                                                                <IonCardTitle className={"ion-text-start"}>
                                                                    Información del producto
                                                                </IonCardTitle>
                                                            </IonCardHeader>
                                                            <IonCardContent className={"ion-text-start"}>
                                                                <IonLabel><b>Producto: </b>{product.description}</IonLabel><br/>
                                                                <IonLabel><b>Cantidad: </b>{product.quantity}</IonLabel>
                                                            </IonCardContent>
                                                        </IonCard>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        )
    }
}



export default withIonLifeCycle(OrderPrescriptionsOperator);
