/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * May 2023
 */
import {useEffect, useState} from "react";
import {Pagination,Navigation} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';
import NbioApi from "../../lib/api/NbioApi";

//slide style
import 'swiper/swiper-bundle.min.css';
import '../../css/components/banner.css';

//components
import BannerItem from "./BannerItem";


const Banner: React.FC = () => {
    const [bannerItems, setBannerItems] = useState<any[]>([]);
    const [height,setHeight] = useState(0);
    const [width,setWidth] = useState(0);
    const LAPTOP_SIZE = 880;
    let observerWidth = 0;

    useEffect(() => {
        NbioApi.store.getCarousel().then((res) => {
            const data = res.data as any;
            if (data.carousel.pages) {
                const pages = data.carousel.pages;
                setBannerItems(pages);
            }
        }).catch((ex) => {
        });
    }, []);

    useEffect(() =>{
        startObserving();
    },[]);

    const startObserving = () =>{
        const resize_ob = new ResizeObserver(function(entries) {
            // ResizeObserver catches the swiper that is in the shadow root
            // but not the swiper that is in the DOM.
            // so we need to get all the rendered swipers and select the last one.
            try{
                const swipers = document.getElementsByClassName('banner_swipers');
                const swiper = swipers[swipers.length -1];
                // current width & height
                //@ts-ignore
                let _width = swiper.offsetWidth;

                if(Math.abs(_width - observerWidth) > 100){
                    setWidth(_width);
                    observerWidth = _width;
                    setHeight(calcHeight(_width,imageRatio));
                }
            }catch(ex){

            }
        });
        const selector = document.querySelector(`#banner_swiper_wrapper`);
        resize_ob.observe(selector as Element);
    }

    const calcScale = (w:number) =>{
        return w < LAPTOP_SIZE ? 3.15  : 5.795 ;
    }

    const calcHeight = (w:number,imageRatio:number) =>{
        return (w * imageRatio) / calcScale(w);
    }

    const imageRatio = width < LAPTOP_SIZE ? 1.777  : 2.742 ;

    const pagination = {
        clickable: true,
        renderBullet: function (index: any, className:string) {
            return `<span class='${className}'></span>`;
        },
    };

    return (
        <div id={'banner_swiper_wrapper'}>
            <Swiper
                modules={[Pagination,Navigation]}
                slidesPerView={1}
                loop={true}
                autoplay={true}
                navigation={false}
                pagination ={pagination}
                id={'banner_swiper'}
                className={'banner_swipers'}
            >
                {
                    bannerItems.map((item) => {
                        return (
                            <SwiperSlide key={`${item._id}`}>
                                <BannerItem item={item} width={width} height={height}></BannerItem>
                            </SwiperSlide>
                        )})
                }
            </Swiper>
        </div>

    );
};

export default Banner;
