/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2022
 */


import React, {forwardRef} from "react";
import {
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel, IonNote, IonRadio, IonRadioGroup,
    IonRow,
    IonSkeletonText, IonSpinner, IonToast, withIonLifeCycle,
} from "@ionic/react";
import API from "../../lib/api/NbioApi";
import {PaymentMethod} from "../../lib/data_types/dataTypes";
import {RouteComponentProps} from "react-router";
import NbioApi from "../../lib/api/NbioApi";
import  '../../css/components/MPPayment.css';
import {Capacitor} from "@capacitor/core";
import {Keyboard} from "@capacitor/keyboard";
import {EVENTS} from "../../lib/events/events";
const mpKey = process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY || ''
interface MPPaymentProps extends RouteComponentProps{
    onConfirmPayment:any;
    onFailedPayment:any;
    checkoutId:string;
    onChangePaymentType?:any;
}
interface MPPaymentState{
    paymentMethodsLength:number;
    isLoaded:boolean;
    isErrored:boolean;
    defaultPaymentMethod:PaymentMethod | null;
    isCardLoaded: boolean;
    isCardErrored:boolean;
    cvv: string;
    showToast: boolean;
    toastMsg: string;
    showBricks: boolean;
    amount:number;
    userEmail:string;
    isKeyboardVisible:boolean;
    renderCVC:boolean;
}

class MPPayment extends React.Component<MPPaymentProps,MPPaymentState> {
    private mp: any;
    private cardPaymentBrickController: any;
    private reloadEvent: any;
    constructor(s:any) {
        super(s);
        this.state = {
            paymentMethodsLength:0,
            isLoaded:false,
            isErrored:false,
            defaultPaymentMethod:null,
            isCardLoaded: false,
            isCardErrored:false,
            cvv:'',
            showToast:false,
            toastMsg:'',
            showBricks:false,
            amount:0,
            userEmail:'',
            isKeyboardVisible:false,
            renderCVC:false
        }
        this.loadCards   = this.loadCards.bind(this);
        this.reloadCards = this.reloadCards.bind(this);

    }
    ionViewDidEnter(){
        this.loadCards();
    }
    reloadCards(){
        // @ts-ignore
        const mp = new MercadoPago(mpKey,{locale:'es-MX'});
        this.mp = mp;
        this.setState({
            renderCVC:false
        }, () =>{
            setTimeout(() =>{

                this.setState({renderCVC:true},() =>{
                    this.loadCards();
                })
            },1000);
        })
    }
    componentWillUnmount() {
        window.removeEventListener(EVENTS.RELOAD_DEFAULT_CARD, this.reloadCards);
    }

    componentDidMount() {
        this.reloadEvent = window.addEventListener(EVENTS.RELOAD_DEFAULT_CARD, this.reloadCards);
        this.loadCards();
        const isKeyboardAvailable = Capacitor.isPluginAvailable('Keyboard');

        if(isKeyboardAvailable){
            Keyboard.addListener('keyboardDidShow', info => {
                this.setState({isKeyboardVisible: true},() => this.showIntoView());
            });

            Keyboard.addListener('keyboardDidHide', () => {
                this.setState({isKeyboardVisible: false});
            });
        }
        // @ts-ignore
        const mp = new MercadoPago(mpKey,{locale:'es-MX'});
        this.mp = mp;
    }
    showIntoView = () =>{
        if(this.state.isKeyboardVisible){
            try{
                // @ts-ignore
                document.activeElement.parentElement.scrollIntoView();
            }catch(ex){
            }
        }
    }
    async loadInitData(){
        try{
            const resUser = await API.users.getMe();
            const user = resUser.data.user;
            const checkout = await NbioApi.checkout.getCheckout(this.props.checkoutId);
            this.setState({
                userEmail: user.email,
                amount:checkout.data.checkout.totals.total
            },() =>{
                return Promise.resolve(true);
            })
        }catch(ex){
            return Promise.reject('Error loading init data');
        }

    }
    getPaymentMethods(){



    }
    async initBricks(){
        // wait for initData
        try{
            await this.loadInitData();
        }catch(ex){
        }
        // @ts-ignore
        const mp = new MercadoPago(mpKey,{locale:'es-MX'});
        this.mp = mp;
        const settings = {

        }
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        let formBackgroundColor  = '#1E1E1E';
        let textPrimaryColor = '#418CF9';
        if(!prefersDark.matches){
            formBackgroundColor = '#FFFFFF';
            textPrimaryColor = '#000000';
        }
        const bricksBuilder = mp.bricks({});
        const renderCardPaymentBrick = async (bricksBuilder:any) => {

            const settings = {
                locale: 'es',
                initialization: {
                    amount: this.state.amount,
                    payer: {
                        email: this.state.userEmail
                    },
                },
                customization: {
                    paymentMethods: {
                        minInstallments: 1,
                        maxInstallments: 1,
                    },
                    visual: {
                        hidePaymentButton: true,
                        style: {
                            customVariables: {
                                formBackgroundColor: formBackgroundColor,
                                textPrimaryColor: textPrimaryColor,
                                inputBackgroundColor:'#FFFFFF',
                                borderRadiusSmall:0,
                                borderRadiusMedium:0,
                                borderRadiusLarge:'5px',
                                borderRadius:0
                            }
                        }
                    }
                },
                callbacks: {

                    onReady: () => {
                        // Avoid submit on MP Form
                        const form = document.getElementsByTagName('form')[0]
                        form.addEventListener('submit',(ev) => ev.preventDefault())
                        // Keyboard fix
                        try{
                            // @ts-ignore
                            document.getElementById('cardPaymentBrick_container').addEventListener('click',() => {
                                this.showIntoView();
                            });
                        }catch (e) {

                        }

                    },
                    onSubmit: (cardFormData:any) => {
                        return this.createPaymentIntent(cardFormData);
                    },
                    onError: (error:any) => {
                    },
                },
            };
            this.cardPaymentBrickController = await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings);
        };
        renderCardPaymentBrick(bricksBuilder);
    }
    renderCVC(){
        if(this.state.renderCVC){
            return (
                <div>
                    {/*<IonInput placeholder={'Ingresa tu CVV para pagar'}  onIonChange={(e: any) => this.setState({cvv: e.target.value})}></IonInput>*/}
                    <form id="form-checkout" method="POST" action="/process_payment">
                        <select  id="form-checkout__cardId" hidden></select>
                        <div id="form-checkout__securityCode-container" className="container"></div>
                        <input name="token" id="token" hidden >
                        </input>
                        <button hidden>Enviar</button>
                    </form>
                </div>
            )
        }else{
            return <IonSkeletonText/>
        }

    }

    appendCardToSelect() {
        const selectElement = document.getElementById('form-checkout__cardId');
        const tmpFragment = document.createDocumentFragment();
        const optionElement = document.createElement('option');
        optionElement.setAttribute('value', this.state.defaultPaymentMethod?.id || '');
        optionElement.textContent = `something something`
        tmpFragment.appendChild(optionElement);
        // @ts-ignore
        selectElement.appendChild(tmpFragment)
        const formElement = document.getElementById('form-checkout');
        const securityCodeElement = this.mp.fields.create('securityCode', {
            placeholder: "CVV"
        }).mount('form-checkout__securityCode-container');
        // @ts-ignore
        formElement.addEventListener('submit', e => this.payWithSavedCard(e));
    }

    async payWithSavedCard(e:any){
        try{
            e.preventDefault();
        }catch (e) {
        }

        try{
            // @ts-ignore
            // const token = await createCardToken({
            //     cardId: this.state.defaultPaymentMethod?.id,
            //     securityCode: this.state.cvv
            // });
            // console.log('token',token);
            // return this.createPaymentIntent({token:token.id,chargeDefault:true,cardId:this.state.defaultPaymentMethod?.id});
            const tokenElement = document.getElementById('token');
            // @ts-ignore
            if (!tokenElement.value) {
                // @ts-ignore
                const token = await this.mp.fields.createCardToken({
                    // @ts-ignore
                    cardId: document.getElementById('form-checkout__cardId').value
                });
                // @ts-ignore
                tokenElement.value = token.id;
                return this.createPaymentIntent({token:token.id,chargeDefault:true,cardId:this.state.defaultPaymentMethod?.id});
            }
        }catch(ex:any){
            return Promise.reject(ex);
        }

    }
    async payWithNewCard(){
        const cardFormData = await this.cardPaymentBrickController.getFormData();
        if(cardFormData){
            return this.createPaymentIntent(cardFormData);
        }else if(cardFormData === null){
            return Promise.reject({local_error_es:'Debes de ingresar tus datos'});
        }
    }
    createPaymentIntent(cardFormData: object){
        return NbioApi.checkout.generatePaymentIntent(this.props.checkoutId, {mercadoPagoData:cardFormData}).then((res) => {
            return res;
        }).catch((ex) =>{
            return Promise.reject(ex);
        })
    }
    public pay(){
        if(this.state.showBricks || this.state.paymentMethodsLength === 0){
            this.payWithNewCard().then((res) =>{
                // @ts-ignore
                this.props.onConfirmPayment(res.data.orderId);
            }).catch((ex) =>{
                if(ex.local_error_es){
                    this.setState({
                        showToast:true,
                        toastMsg:ex.local_error_es
                    })
                }else if(ex.response.data.reason_es){
                    this.setState({
                        showToast:true,
                        toastMsg:ex.response.data.reason_es
                    })
                }
                this.props.onFailedPayment();
            })
        } else if(this.state.paymentMethodsLength > 0){
            this.payWithSavedCard(null).then((res:any) =>{
                this.props.onConfirmPayment(res.data.orderId);
            }).catch((ex) =>{
                if(ex?.response?.data?.reason_es) {
                    this.setState({
                        showToast: true,
                        toastMsg: ex?.response?.data?.reason_es
                    })
                }else if(Array.isArray(ex)){
                     //invalid_type, invalid_length, securityCode
                    const errorArray = ex;
                    if(errorArray[0]?.cause){
                        const cause = errorArray[0]?.cause;
                        let toastMsg = 'Hubo un problema inicializando tu tarjeta con Mercado Pago. Intente nuevamente.';
                        if(cause === 'invalid_type'){
                            toastMsg = 'El CVV debe de contener sólo números.';
                        }else if(cause === 'invalid_length'){
                            toastMsg = 'El CVV tiene una longitud incorrecta.';
                        }else if(cause === 'securityCode'){
                            toastMsg = 'El CVV no es válido.';
                        }
                        this.setState({
                            showToast:true,
                            toastMsg: toastMsg
                        });
                    }
                }else{
                    this.setState({
                        showToast:true,
                        toastMsg: 'Hubo un problema inicializando tu tarjeta con Mercado Pago. Intente nuevamente.'
                    });
                    this.reloadCards();
                }
                this.props.onFailedPayment();
            })
        }else{

        }
    }
    renderDefaultCard = (payment: PaymentMethod | null) => {
        if (!payment) {
            return <div></div>
        } else {
            return (
                <IonCard key={payment.last4} className={'ion-margin-top'}>
                    <IonItem lines="none">
                        <IonRadioGroup value={'1'}>
                            <IonRadio value={'1'}></IonRadio>
                        </IonRadioGroup>
                        <IonLabel className={"ion-text-capitalize"} >
                            <b>&nbsp; {payment.brand} **** **** **** {payment.last4}</b>
                        </IonLabel>
                    </IonItem>
                    <IonCardContent className={"no-padding-vertical"}>
                        <IonLabel>
                            {payment.name}
                        </IonLabel>
                        <br/>
                        <IonNote>
                            Vence {payment.exp_month}/{payment.exp_year}
                        </IonNote>
                        {this.renderCVC()}
                    </IonCardContent>
                </IonCard>
            )
        }

    }
    loadBricks(){
        this.setState({
            showBricks:true
        }, () =>{
            this.initBricks();
        })
    }
    loadCards(){
        API.paymentMethods.get().then((res) => {
            const paymentMethods = res.data.paymentMethods;
            const paymentMethodsLength = paymentMethods.length;

            this.setState({
                paymentMethodsLength:paymentMethodsLength,
                isLoaded:true
            }, () =>{
                if(paymentMethodsLength === 0){
                    this.initBricks();
                }else{
                    // this.initCVC();
                }
            })

        }).catch((ex) => {
            this.setState({
                isErrored:true,
                isLoaded:true
            })
        });
        API.paymentMethods.getDefault().then((res) => {
            this.setState({
                defaultPaymentMethod:res.data.defaultCard,
                isCardLoaded:true,
                renderCVC:true
            }, () =>{
                setTimeout(() =>{
                    // TODO: Find a better way to wait for the component to load
                    this.appendCardToSelect();
                },1000);
            })
        }).catch((ex) => {
            this.setState({
                isCardErrored:true
            })
        })
    }
    renderContent(){
        if (!this.state.isLoaded) {
            return (
                <IonSkeletonText animated className={'h-100'}/>
            )
        } else if (     (this.state.paymentMethodsLength === 0 && !this.state.isErrored) || this.state.showBricks ) {
            return (
                <div>
                    <div className={'ion-margin-top'}>
                        {this.renderCardForm()}
                    </div>
                </div>
            )
        } else if (this.state.paymentMethodsLength > 0 && !this.state.isErrored) {
            return (
                <IonCol>
                    {/*TIENES TARJETAS, CARGAR DEFAULT*/}
                    {
                        this.renderDefaultCard(
                            this.state.defaultPaymentMethod
                        )
                    }
                    <IonCard button={true} routerLink={'/seleccionar-tarjeta'}>
                        <IonItem lines="none" detail>
                            <IonLabel className={"ion-text-capitalize"}>
                                Seleccionar otra tarjeta
                            </IonLabel>
                        </IonItem>
                    </IonCard>
                    <IonCard button={true} onClick={()=> this.goToSelectCard()}>
                        <IonItem lines="none" detail>
                            <IonLabel className={"ion-text-capitalize"}>
                                Agregar una tarjeta
                            </IonLabel>
                        </IonItem>
                    </IonCard>
                </IonCol>
            )
        } else {
            return (
                <div>No pudimos cargar tus metodos de pago</div>
            )
        }
    }
    goToSelectCard(){
        this.loadBricks();
        // this.props.onChangePaymentType('force-card');
    }
    render(){
        return(
            <IonContent fullscreen className={'scroll-y-auto'}>
                <IonToast isOpen={this.state.showToast}
                          onDidDismiss={() => this.setState({showToast: false, toastMsg: ''})}
                          message={this.state.toastMsg}
                          duration={3000}/>
                <IonGrid className={'ion-no-padding h-100'}>
                    <IonRow className="ion-justify-content-center h-100">
                        <IonCol className={'h-100'}>
                            {this.renderContent()}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        )
    }
    renderCardForm(){
        return (
            <div id="cardPaymentBrick_container" className={'h-100'} ></div>
        )
    }
}
export default MPPayment;
