/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import { Swiper, SwiperSlide } from 'swiper/react';
import type {ProductType} from "../../lib/data_types/dataTypes";


//slide style
import 'swiper/swiper-bundle.min.css';
import '@ionic/react/css/ionic-swiper.css';

//components
import Product from "./Product";

interface RecommendedProductsProps{
    products: ProductType[],
    onClick:any,
    slidesPerView?:number
}

const RecommendedProducts: React.FC<RecommendedProductsProps> = ({products,onClick,slidesPerView = 2}) => {

  return (
      <Swiper slidesPerView={slidesPerView} loop={true}>
          {products.map(product => {
                return(
                    <SwiperSlide key={product._id}>
                        <Product product={product}
                                 onClick={(p:any) => onClick(p)}
                                 classCard={'ion-no-margin ion-margin-top ion-margin-end ion-margin-bottom'}
                                 isInList={true}
                                 isRecommended={true}
                        >
                        </Product>
                    </SwiperSlide>
                )
            })
          }
      </Swiper>
  );
};

export default RecommendedProducts;
