/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React, {useState} from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonItem,
    IonLabel,
    IonContent,
    IonToolbar,
    IonButtons,
    IonFooter,
    IonCard,
    IonCardContent,
    IonBadge,
    IonCheckbox,
    IonIcon,
    IonProgressBar,
    useIonViewDidEnter,
    IonPage,
    IonHeader, IonBackButton, IonTitle,
} from '@ionic/react';
import {useHistory,useLocation} from "react-router-dom";
import {InvoiceType} from "../../lib/data_types/dataTypes"

//components
import API from "../../lib/api/NbioApi";
import {documentText} from "ionicons/icons";
import WebHeader from "../../components/ui/WebHeader";
import GradientDivider from "../../components/ui/GradientDivider";
import {RouteComponentProps} from "react-router";
interface InvoicingProfileComponentProps extends RouteComponentProps{
    invoicingSelected?: string;
    showSelectionCB?: boolean;
}

interface InvoicingListData {
    invoiceId?: string,
}

const InvoicingList: React.FC<InvoicingProfileComponentProps> =
    ({invoicingSelected,  showSelectionCB= true}) => {
        const [invoicingList,setInvoicingList] = useState<InvoiceType[]>([]);
        const [isLoading,setIsLoading] = useState(true);
        let history = useHistory();
        let location = useLocation();
        useIonViewDidEnter(() => {
            loadInvoicing();
        });

        const loadInvoicing = () =>{
            setInvoicingList([]);
            setIsLoading(true);
            API.invoices.get().then((res:any) =>{
                let invoicingList = res.data.invoices;
                setInvoicingList(invoicingList);
                setIsLoading(false);
            }).catch((ex) =>{
                setInvoicingList([]);
                setIsLoading(false);
            })
        }
        const state:any = location.state;
        const onSavePath = state?.onSavePath || '/confirmar-pedido';
        const onCreateNewInvoicing = () => {
            history.push({
                pathname:`/datos-de-facturacion/agregar`,
                state:{
                    onSavePath: onSavePath
                }
            });
        }

        const renderIsDefault = (isDefault: boolean) => {
            if(isDefault){
                return <IonBadge color={"secondary"} className={"ion-text-start ion-text-wrap"}>Predeterminada</IonBadge>
            }
        }

        const onSelectedInvoice = (invoiceId: string) => {
            let i = location.state as InvoicingListData;
            if(i){
                history.replace({
                    pathname:'/confirmar-pedido',
                    state:{
                        invoiceId:invoiceId
                    }
                });
            }else{
                history.push({
                    pathname:`/datos-de-facturacion/${invoiceId}`,
                    state:{
                        invoiceId: invoiceId,
                        onSavePath: onSavePath
                    }
                });
            }
        }

        const onDeleteInvoice = (invoiceId:string) => {
            API.invoices.delete(invoiceId).then((res) =>{
                loadInvoicing();
            }).catch((ex) =>{

            })
        }

        const onEditInvoice = (invoiceId:string) => {
            history.push({
                pathname:`/datos-de-facturacion/${invoiceId}`,
                state:{
                    invoiceId: invoiceId,
                    onSavePath: onSavePath
                }
            });
        }

        const renderCheckbox = (isDefault: any) => {
            if(showSelectionCB){
                return <IonCheckbox checked={isDefault}></IonCheckbox>
            }else{
                return null
            }
        }

        const renderInvoicing = () =>{
            if(isLoading){
                return (
                    <div className={'ion-padding ion-text-center'}>
                        <br/>
                        <br/>
                        <IonProgressBar type="indeterminate" color={'primary'}></IonProgressBar>
                    </div>
                )
            }
            if(invoicingList.length === 0){
                return(
                    <div className={'ion-padding ion-text-center'}>
                        No tienes datos de facturación guardados.
                        <br/>
                        <br/>
                        <IonIcon icon={documentText} color={'medium'} size={'large'} slot={'icon-only'}></IonIcon>
                    </div>
                )
            }else{
                {
                    return(
                        invoicingList.map((invoice :InvoiceType) => {
                            return(
                                <IonCard key={invoice._id} className={'ion-margin-top'}>
                                    <IonItem lines="none" button={true} onClick={() => onSelectedInvoice(invoice._id)}>
                                        <IonLabel className={"ion-text-capitalize"}>{invoice.rfc} </IonLabel>
                                        {renderCheckbox(invoice.isDefault)}
                                    </IonItem>
                                    <IonCardContent className={"no-padding-vertical"}>
                                        <small>
                                            {invoice.name}<br/>
                                            {invoice.address}<br/>
                                            {invoice.fiscalRegime}<br/>
                                            {invoice.cfdiUsage}<br/>
                                        </small>
                                    </IonCardContent>
                                    <IonItem>
                                        {renderIsDefault(invoice.isDefault || false)}
                                        <div slot={'end'}>
                                            <IonButton slot={"start"}
                                                       size={"small"}
                                                       fill={"outline"}
                                                       color={'danger'}
                                                       onClick={() => onDeleteInvoice(invoice._id)}
                                            >Eliminar</IonButton>
                                            <IonButton slot={"end"}
                                                       size={"small"}
                                                       fill={"outline"}
                                                       onClick={() => onEditInvoice(invoice._id)}
                                            >Editar</IonButton>
                                        </div>
                                    </IonItem>
                                </IonCard>
                            )
                        })
                    )
                }
            }
        }

        return (
            <IonPage>
                <WebHeader title={'Datos de facturación'} showSearchButton={false} showCartButton={false} showMenuButton={false}></WebHeader>
                <IonContent>
                    <IonGrid className={'ion-no-padding'}>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size-md="6">
                                {renderInvoicing()}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonGrid>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size-md="6">
                                    <IonButtons>
                                        <IonButton className="w-100"
                                                   color="primary"
                                                   expand="block"
                                                   fill='solid'
                                                   onClick={onCreateNewInvoicing}>
                                            Agregar datos de facturación
                                        </IonButton>
                                    </IonButtons>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        )
    }

export default InvoicingList;
