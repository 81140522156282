/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

import React, {useState} from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    withIonLifeCycle,
    IonButtons,
    IonBackButton,
    IonSearchbar,
    IonButton,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonChip,
    IonList,
    IonItem,
    IonSkeletonText,
    IonMenu,
    IonLabel,
    IonCard,
    IonNote, IonNavLink, IonNav, IonRouterLink, IonBadge
} from '@ionic/react';
import {RouteComponentProps} from "react-router";
import {
    ActiveFilterType, CategoryType,
    filterTagDataType,
    filterTagObjectType,
    filterTagType,
    ProductType
} from "../../lib/data_types/dataTypes";

import {cart, filter, hourglass, logoAppleAppstore, logoGooglePlaystore, menuOutline, person} from "ionicons/icons";

//components
import GradientDivider from "../../components/ui/GradientDivider";
import ProductList from "../../components/products/ProductList";
import API from "../../lib/api/NbioApi";
import NbioApi from "../../lib/api/NbioApi";
import WebHeader from "../../components/ui/WebHeader";
import {Capacitor} from "@capacitor/core";
import {Keyboard} from "@capacitor/keyboard";
import {emmitEvent, EVENTS} from "../../lib/events/events";
import Cart from "../../lib/cart/Cart";
import BtnToolbarLogo from "../../components/ui/BtnToolbarLogo";
import {Link} from "react-router-dom";
import CartPopup from "../../components/cart/CartPopup";
import ProfilePopup from "../../components/profile/ProfilePopup";
import AppBannerHeader from "../../components/ui/AppBannerHeader";

interface SearchProductsProps
    extends RouteComponentProps<{
        id: string;
    }> {
}

interface SearchProductsState {
    query: string;
    prevQuery:string;
    searchHistory: string[];
    searchResults: ProductType[];
    autoCompleteResults: string[];
    isSearching:boolean,
    activeFilters:ActiveFilterType,
    searchFilters:filterTagType,
    endReached: boolean,
    finishedSearching:boolean,
    page:number,
    cartItems:number,
    userClickedProduct:boolean,
    categories:CategoryType[],
    showCartPopover:boolean,
    showProfilePopover:boolean
}

class SearchProducts extends React.Component<SearchProductsProps, SearchProductsState> {
    private searchMenu: any;
    private searchBarRef: any;
    constructor(props: SearchProductsProps) {
        super(props);
        this.state = {
            prevQuery:'',
            query: '',
            isSearching:false,
            finishedSearching:false,
            searchHistory: [],
            searchResults: [],
            searchFilters:{},
            autoCompleteResults: [],
            activeFilters:{},
            endReached:true,
            page:1,
            cartItems:0,
            userClickedProduct:false,
            categories:[],
            showCartPopover:false,
            showProfilePopover:false
        }

    }
    loadAll(){
        if(!this.state.userClickedProduct){
            const search = this.props.location.search;
            const urlParams = new URLSearchParams(search);
            const q = urlParams.get('q');
            if(q){
                this.setState({query:q});
                this.search(q);
            }
            try{
                this.searchBarRef.setFocus();
            }catch (e) {

            }
            NbioApi.products.searchHistory().then(res => {
                this.setState({
                    searchHistory: res.data.searchHistory
                });
            }).catch((ex) => {
                console.log(ex);
            })
            NbioApi.categories.get(1,5).then((res) =>{
                this.setState({categories:res.data.categories});
            }).catch((ex) =>{

            });
        }

        // Update cart
        this.updateCartItem();
        window.addEventListener(EVENTS.UPDATE_CART, this.updateCartItem);
        window.addEventListener(EVENTS.ADD_TO_CART, this.updateCartItem);
    }

    ionViewDidEnter() {
        this.loadAll();
    }
    ionViewDidLeave(){
        window.removeEventListener(EVENTS.UPDATE_CART, this.updateCartItem);
        window.removeEventListener(EVENTS.ADD_TO_CART, this.updateCartItem);
    }
    updateCartItem = () => {
        Cart.getCart().then((res) => {
            this.setState({cartItems:res.data.countItems});
        }).catch((ex) => {
            this.setState({cartItems:0});
        });
    }
    isSelected(filterName:string, filter:filterTagDataType){
        if(this.state.activeFilters.hasOwnProperty(filterName)){
            const isInArray = this.state.activeFilters[filterName].find((f) =>{
                return f.label === filter.label && f.value === filter.value
            })
            return !!isInArray;
        }else{
            return false;
        }
    }
    onFilterSelected(filterName:string, filterTag:filterTagObjectType, filter:filterTagDataType){
        let newFilters = {...this.state.activeFilters}
        if(this.state.activeFilters.hasOwnProperty(filterName)){
            // append it or remove it
            const foundFilter = this.state.activeFilters[filterName]
                .find((f) => f.label === filter.label && f.value === filter.value);
            if(foundFilter){
                // remove it
                newFilters[filterName] = this.state.activeFilters[filterName]
                    .filter((f) => f.label !== filter.label && f.value !== filter.value);
            }else{
                // append it
                newFilters[filterName].push(filter);
            }
        }else{
            // create it
            newFilters[filterName] = [
                filter
            ]
        }
        this.setState({activeFilters:newFilters}, () =>{
            this.search(this.state.query)
        })

    }
    renderFilterType(filter: filterTagObjectType,filterName:string){
        if(filter.type === 'string'){
            // return a list of strings
            return (
                <IonRow>
                    <IonCol>
                        {
                            filter.data.length === 0 ?
                                <IonChip color={'primary'}>Todo</IonChip> : null
                        }
                        {
                            filter.data.map((d,index) =>{
                                return (
                                    <IonChip  key={`${index}_${d.label}`} color={this.isSelected(filterName,d) ? 'primary' : ''}  onClick={() =>{
                                        this.onFilterSelected(filterName,filter,d);
                                    }}
                                             className={'ion-text-wrap ion-text-capitalize'}>{d.label}</IonChip>
                                )
                            })
                        }

                    </IonCol>
                </IonRow>
            )
        }
    }
    renderLoading() {
        if (this.state.isSearching ) {
            return (
                <div className={'ion-padding ion-text-center'}>
                    {'Cargando...'}
                    <br/>
                    <br/>
                    <IonIcon icon={hourglass } color={'medium'} size={'large'} slot={'icon-only'}></IonIcon>
                </div>
            )
        } else {
            return null;
        }

    }
    onSearch = async (query: string) => {
        await this.setSearchValue(query);
        if (query.trim() !== '') {
            this.search(query);
            try{
            }catch(ex){

            }
        } else {
            this.clearSearchResults();
        }
    }
    setSearchValue = async (query: string) => {
        return new Promise((resolve,reject) => {
            if(this.searchBarRef){
                this.searchBarRef.value = query;
                this.setState({
                    prevQuery:this.state.query,
                    query: query
                },() =>{
                    resolve(true);
                })
            }else{
                this.setState({
                    prevQuery:this.state.query,
                    query: query
                },() =>{
                    resolve(true);
                })
            }
        });
    }
    search = (query: string) => {
        const queryHasChanged = query !== this.state.prevQuery;
        if(query.trim() === ''){
            this.setState({
                query: '',
                isSearching:false,
                finishedSearching:true,
                searchResults: [],
                searchFilters:{},
                autoCompleteResults: [],
                activeFilters:{},
                endReached:true,
                page:1,
                cartItems:0
            });
            return true;
        }
        if(true){
            this.setState({
                activeFilters:this.state.activeFilters,
                isSearching:true,
                finishedSearching:false,
                endReached:false,
                page:1
            }, () =>{
                API.products.autocomplete(query).then((res) => {
                    this.setState({
                        autoCompleteResults: res.data.keywords.map((k: any) => k.keyword),
                    })
                }).catch((ex) => {
                    // console.log(ex);
                });
                API.products.search(query,this.state.page,10,this.state.activeFilters).then(res => {
                    if(queryHasChanged){
                        this.setState({
                            searchFilters:res.data.filters,
                        })
                    }
                    this.setState({
                        searchResults: res.data.products,
                        isSearching:false,
                        finishedSearching:true
                    });
                    try{
                        // @ts-ignore
                        fbq('track', 'Search');
                    }catch (e) {

                    }
                    //google tracking
                    try{
                        // @ts-ignore
                        gtag("event", "search", {search_term: query});
                    }catch (e) {

                    }
                }).catch((ex) => {
                    this.setState({isSearching:false});
                });
            })
        }

    }

    clearSearchResults = () => {
        this.setState({searchResults: [], query: ''});
    }

    renderSearchHistory = () => {
        if(this.state.isSearching){
            return null;
        }
        if (this.state.query === '') {
            const history = this.state.searchHistory;
            if(history.length === 0){
                return null;
            }
            return (
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonText>Búsquedas recientes</IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {
                                history.map((item) => {
                                    return (
                                        <IonChip color={"tertiary"}
                                                 onClick={() => this.onSearch(item)}>{item}</IonChip>
                                    )
                                })
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
            )
        } else {
            const autoCompleteResults = this.state.autoCompleteResults;
            if(autoCompleteResults.length === 0){
                return null;
            }
            return (
                <IonGrid>
                    <IonRow>
                        <IonCol className={'flex-row ion-align-items-center'}>
                            <IonText >
                                Sugerencias
                            </IonText>
                        </IonCol>
                        <IonCol className={'flex-row ion-align-items-center ion-justify-content-end'}>
                            <IonButton onClick={() => this.openMenu()} className={'ion-hide-sm-up ion-no-padding'} fill={'clear'}>
                                <IonIcon icon={filter}></IonIcon>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {
                                autoCompleteResults.map((item,index) => {
                                    return (
                                        <IonChip
                                            key={`${item}_${index}`}
                                            color={"tertiary"}
                                                 onClick={() => {
                                                     this.onSearch(item);
                                                 }}>{item}
                                        </IonChip>
                                    )
                                })
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
                // <IonList>
                //     <IonItem>Res</IonItem>
                //     <IonItem>Res</IonItem>
                //     <IonItem>Res</IonItem>
                //     <IonItem>Res</IonItem>
                // </IonList>
            )

        }
    }
    renderMenu(){
        const filterTags = this.state.searchFilters;
        const keys = Object.keys(filterTags!);

        return(
            <IonMenu side="start"  contentId={'page-search-products'} ref={(ref) => this.searchMenu = ref}>
                <IonHeader>
                    <IonToolbar className={'toolbar-nbio'}>
                        <h3 className={'ion-margin'}>Filtra tu búsqueda</h3>
                    </IonToolbar>

                </IonHeader>
                <IonContent>

                    <IonList>
                        {
                            keys.map((k) =>{
                                return (
                                    <div key={`k_${filterTags[k].locale_es}`} >
                                        <IonItem lines={'none'}>{filterTags[k].locale_es}</IonItem>
                                        {
                                            this.renderFilterType(filterTags[k],k)
                                        }
                                    </div>
                                )
                            })
                        }
                        {
                            keys.length === 0 ?
                                <div style={{height:100}} className={'d-flex justify-content-center align-items-center ion-margin'}>
                                    <IonNote >
                                        Haz una búsqueda para mostrar los filtros.
                                    </IonNote>
                                </div>
                                : null
                        }
                    </IonList>
                </IonContent>
            </IonMenu>

        )
    }
    handleSearchChange = async (e:any) =>{
        await this.setSearchValue(e.detail.value!);
    }
    onKeyChange = async (e:any) =>{
        const q = e.target.value;
        this.setState({query:q});
        await this.search(q);
    }
    renderBadge = () => {
        if(this.state.cartItems > 0){
            return <IonBadge className="web-header-badge" color={"danger"}>{this.state.cartItems}</IonBadge>
        }else{
            return null
        }
    }
    toggleCart = () =>{
        this.setState({
            showCartPopover:!this.state.showCartPopover,
            showProfilePopover:false
        });
    }
    toggleProfile = () =>{
        this.setState({
            showCartPopover:false,
            showProfilePopover: ! this.state.showProfilePopover
        });
    }

    render() {
        return (
            <IonPage id={'page-search-products'}>
                {this.renderMenu()}
                {/*<WebHeader></WebHeader>*/}
                <AppBannerHeader></AppBannerHeader>
                <IonHeader >
                    <IonGrid className={'top-header ion-hide-lg-down'}>
                        <IonRow className={'ion-hide-lg-down'}>
                        <IonCol size={'6'} className={'ion-no-padding border-right'}>
                            <a className={'flex-row ion-justify-content-end ion-margin-end'}
                               href={'https://apps.apple.com/us/app/nbio/id1605823846'}
                               target={'_blank'}>
                                <IonIcon className={'margin-right-half'}
                                         icon={logoAppleAppstore}
                                         size={'large'}>
                                </IonIcon>
                                <div className={'flex-col'}>
                                    <small>Descarga nuestra app</small><b>App Store</b>
                                </div>
                            </a>
                        </IonCol>
                        <IonCol size={'6'} className={'ion-no-padding'}>
                            <a className={'flex-row ion-margin-start'}
                               href={'https://play.google.com/store/apps/details?id=mx.nbio&hl=es_MX&gl=US'}
                               target={'_blank'}>
                                <IonIcon className={'margin-right-half'}
                                         icon={logoGooglePlaystore}
                                         size={'large'}>
                                </IonIcon>
                                <div className={'flex-col'}>
                                    <small>Descarga nuestra app</small>
                                    <b>Google Play</b>
                                </div>
                            </a>
                        </IonCol>
                    </IonRow>
                    </IonGrid>
                    <IonToolbar className={"toolbar-nbio"}>
                        <IonButtons slot="start">
                            <BtnToolbarLogo  className={'ion-hide-lg-down'}/>
                            <IonBackButton></IonBackButton>
                        </IonButtons>
                        <form action={'search'}
                              onKeyDown={ev =>{
                                  if (ev.keyCode === 13 || ev.key === 'Enter') {
                                      ev.preventDefault();
                                  }}}
                        >
                            <IonSearchbar animated
                                          color="dark"
                                          placeholder={'Buscar...'}
                                          onKeyDown={e =>{
                                              this.handleEnter(e);
                                          }}
                                          onIonInput={(ev) => {
                                              this.onKeyChange(ev);
                                            }
                                          }
                                          className={'nb_search_bar'}
                                          value={this.state.query}
                                          // ref={(ref) => this.searchBarRef = ref}
                            >
                            </IonSearchbar>
                        </form>

                        <IonButtons slot="end">
                            {/*Icon cart large devices (show cart popup)*/}
                            <IonButton
                                className={'ion-hide-lg-down icon-profile'}
                                size="large"
                                fill={'clear'}
                                onClick={() => this.toggleProfile()}>
                                <IonIcon slot="icon-only" icon={person}></IonIcon>
                            </IonButton>
                            <IonButton
                                className={'ion-hide-lg-down icon-cart'}
                                size="large"
                                fill={'clear'}
                                onClick={() => this.toggleCart()}>
                                <IonIcon slot="icon-only" icon={cart}></IonIcon>
                                {this.renderBadge()}
                            </IonButton>
                            <IonButton
                            className={'ion-hide-lg-up '}
                                size="large"
                                       fill={'clear'}
                                       routerLink={"/carrito"}
                                       routerDirection={"forward"}>
                                <IonIcon slot="icon-only"icon={cart}></IonIcon>
                                {this.renderBadge()}
                            </IonButton>
                            <IonButton onClick={() => this.openMenu()} className={'ion-hide-lg-down'}>
                                <IonIcon slot="icon-only" icon={filter}></IonIcon>
                            </IonButton>
                            <IonButton size="large"
                                       fill={'clear'}
                                       className={'ion-hide-lg-up'}
                                       onClick={() => this.openMainMenu()}
                            >
                                <IonIcon slot="icon-only"icon={menuOutline}></IonIcon>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <div className={'ion-hide-lg-down navigation-bar flex-row ion-justify-content-around'}>

                        {
                            this.state.categories.map((category:any) =>{
                                return (
                                    <Link  key={category._id}  to={`/categorias/${category.handleUrl}`} >
                                        {category.name}
                                    </Link>
                                )
                            })
                        }
                        <Link  to={`/descuentos`}>
                            Descuentos
                        </Link>
                        <Link  to={`/categorias`}>
                            Ver Categorías
                        </Link>
                    </div>
                    <GradientDivider></GradientDivider>
                    <CartPopup
                        showPopup={this.state.showCartPopover}
                        onClickClosePopup={() => this.setState({showCartPopover:false})}
                    />
                    <ProfilePopup showPopup={this.state.showProfilePopover}
                                  onClickClosePopup={() => this.setState({showProfilePopover: false})}
                    />

                </IonHeader>
                <IonContent>
                    {this.renderSearchHistory()}
                    {
                        false  ?
                            <IonSkeletonText animated={true} className={'h-100'}>

                            </IonSkeletonText>
                            :
                            <ProductList products={this.state.searchResults}
                                         onClick={(p: any) => this.goToProduct(p)}
                                         isInfiniteContentDisabled={this.state.endReached}
                                         onLoadNewPage={ async (pageNumber:number) => this.onLoadNewPage(pageNumber)}
                            > </ProductList>
                    }
                    {/* Empty results */}
                    {
                        this.state.searchResults.length === 0
                        && this.state.finishedSearching === true
                            && this.state.query.trim() !== ''
                            ?
                            <div className={'ion-margin-start ion-margin-end'}>
                                <h1 >
                                    ¡Oops! Parece que "<b>{this.state.query}</b>" no se encuentra por aquí.</h1>
                                <br/>
                                <h6>Pero no te preocupes ¡Estamos listos para ayudarte a encontrarlo!</h6>
                                <h6>Prueba otra búsqueda o da
                                    <IonText color={'primary'}>
                                        <a href={'https://forms.office.com/pages/responsepage.aspx?id=3JP0PNb2UEKZT6ltspsYuSLSd8cKmApPjEqSvmZxSP1UNVBKN1daQlRGWUw2TU9VUzA3VTlJMUg4Si4u'}
                                           target={'_blank'}> clic aquí </a>
                                    </IonText>
                                    y nos pondremos manos a la obra para conseguirlo.
                                </h6>
                                <h6>¡Gracias por tu comprensión, eres genial!</h6>
                            </div>
                            : null
                    }
                </IonContent>

            </IonPage>
        )
    }
    async onLoadNewPage(pageNumber: number) {
        return new Promise((resolve,reject) =>{
            if(!this.state.query){
                return reject(false); // Avoids loading empty searches
            }
            if(pageNumber === this.state.page){
                return reject(false); // Avoids loading same page twice
            }
            this.setState({
                page:pageNumber
            }, () =>{
                API.products.search(this.state.query,this.state.page,10,this.state.activeFilters).then(res => {
                    let newProducts = [...this.state.searchResults,...res.data.products]
                    this.setState({
                        searchResults:newProducts,
                        isSearching:false,
                        finishedSearching:true,
                        endReached:pageNumber === res.data.pages
                    },() =>{
                        resolve(res.data);
                    });
                    //facebook tracking
                    try{
                        // @ts-ignore
                        fbq('track', 'Search');
                    }catch (e) {

                    }
                    //google tracking
                    try{
                        // @ts-ignore
                        gtag("event", "search", {search_term: this.state.query});
                    }catch (e) {

                    }
                }).catch((ex) => {
                    this.setState({isSearching:false, endReached:true});
                    reject(false);
                });
            });
        })
    }
    goToProduct(p: any) {
        this.setState({
            userClickedProduct:true
        }, () =>{
            // this.props.history.push(`/productos/${p.handleUrl}`);
        });
    }

    // async onLoadNewPage(pageNumber: number) {
    //     return new Promise((resolve,reject) =>{
    //         let categoryId = this.props.match.params.id;
    //         return NbioApi.categories.getCategory(categoryId,pageNumber,20).then((res) =>{
    //             let newProducts = [...this.state.searchResults,...res.data.products]
    //             this.setState({searchResults:newProducts}, () =>{
    //                 resolve(res.data);
    //             });
    //
    //         })
    //     })
    // }
    private openMenu() {
        this.searchMenu.toggle();
    }
    private openMainMenu(){
        emmitEvent(EVENTS.OPEN_MENU,{});
    }

    private async handleEnter (ev:any) {
        if (ev.keyCode === 13 || ev.key === 'Enter') {
            const query = this.state.query;
            await this.onSearch(query); // store at
            this.search(this.state.query);
            const isKeyboardAvailable = Capacitor.isPluginAvailable('Keyboard');
            if(isKeyboardAvailable){
                Keyboard.hide();
            }else{
                // Hide it on web;
                ev.target.blur();
            }
        }


    }
    private async handleKeyChange (ev:any) {
            const query = this.searchBarRef.value;
            await this.onSearch(query); // store at
            this.search(this.state.query);
    }
    private goToCategories(ev:any) {
        ev.preventDefault();
        this.props.history.push(`/categorias/`);
    }
}

export default withIonLifeCycle(SearchProducts);
