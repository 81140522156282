/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React, {useContext, useState} from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonItem,
    IonBackButton,
    IonFooter,
    IonButton,
    IonInput,
    IonToast, IonLabel, IonText
} from '@ionic/react';
import NbioApi from "../../lib/api/NbioApi";

//components
import GradientDivider from "../../components/ui/GradientDivider";
import {RouteComponentProps} from "react-router";
import WebHeader from "../../components/ui/WebHeader";
import WebBreadcrumbs from "../../components/ui/WebBreadcrumbs";



interface UpdatePasswordProps extends RouteComponentProps {
}

const UpdatePassword: React.FC<UpdatePasswordProps> = ({history}) => {
    const [password,setPassword] = useState('');
    const [password2,setPassword2] = useState('');
    const [isToastOpen,setIsToastOpen] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');

    const onSubmit = () => {
        NbioApi.users.changeMyPassword(password,password2).then((res) =>{
            history.push('/perfil')
        }).catch((ex) =>{
            let msg = 'Hubo un error al cambiar la contraseña';
            try {
                msg = ex.response.data.message_es;
            } catch (ex) {

            }
            setErrorMessage(msg);
            setIsToastOpen(true);
        })
    }
    return(
        <IonPage>
            <WebHeader title={'Cambiar contraseña'} showSearchButton={false} showCartButton={false} showMenuButton={false}></WebHeader>
            <IonContent fullscreen>
                <IonToast
                    isOpen={isToastOpen}
                    onDidDismiss={() => {
                        setIsToastOpen(false);
                    }}
                    message={errorMessage}
                    duration={5000}
                />
                <IonGrid>
                    <IonRow className={"ion-justify-content-center"}>
                        <IonCol size-md="6">
                            <WebBreadcrumbs links={[{name:'Perfil',link:'/perfil'}]}></WebBreadcrumbs>
                            <IonItem>
                                <IonLabel position={'floating'}>
                                    Escribe tu nueva contraseña
                                </IonLabel>
                                <IonInput id="password"
                                          type={"password"}
                                          value={password}
                                          required
                                          placeholder="Escribe tu nueva contraseña"
                                          maxlength={20}
                                          onIonChange={(e: any) => setPassword(e.target.value)}>
                                </IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position={'floating'}>
                                    Repite tu nueva contraseña
                                </IonLabel>
                                <IonInput id="password2"
                                          type={"password"}
                                          value={password2}
                                          required
                                          placeholder="Repite tu nueva contraseña"
                                          maxlength={20}
                                          onIonChange={(e: any) => setPassword2(e.target.value)}>
                                </IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className="ion-no-border">
                <IonToolbar>
                    <IonGrid>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size-md="6">
                                <IonButton expand="block"
                                           fill="outline"
                                           onClick={() => onSubmit()}>
                                    Actualizar contraseña</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    )
}

export default UpdatePassword;
