/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import {
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    useIonViewDidEnter
} from "@ionic/react";
import type {ProductType} from "../../lib/data_types/dataTypes";
import Product from "./Product";
import {useState} from "react";

interface ProductListProps {
    products: ProductType[],
    title?: string,
    onClick?: any,
    onLoadNewPage?: any,
    isInfiniteContentDisabled?: boolean
    loadOnEnter?: boolean
}

const ProductList: React.FC<ProductListProps> = ({products, title
                                                     ,onClick,onLoadNewPage,isInfiniteContentDisabled},
                                                     loadOnEnter = true
                                                 ) => {
    const [currentPage,setCurrentPage] = useState(loadOnEnter ? 2 : 1); // starts on page 2 if the page 1 is pre loaded

    useIonViewDidEnter(() => {
        if(loadOnEnter){
            loadData(null);
        }
    });

    const loadData = (ev: any) => {
        if(onLoadNewPage ){
            onLoadNewPage(currentPage).then((res:any) =>{
                setCurrentPage(currentPage+1)
                if(ev){
                    ev.target.complete();
                }
            //     do something
            }).catch((ex:any) =>{
            })
        }else{
        }
    }

    return (
        <>
            <IonGrid>
                {
                    title !== "" ?
                        <IonRow>
                            <IonCol>
                                <IonText className="ion-text-capitalize">
                                    <h5>{title}</h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        : null
                }
                <IonRow className={"ion-align-items-center"}>
                    {
                        products.map((product,index) => {
                            return (
                                    <IonCol size-xs="6" size-sm="6" size-md="3" key={`${index}_${product._id}`}>
                                    <Product product={product}
                                             onClick={(p: any) => onClick(p)}
                                             isInList={true}>
                                    </Product>
                                </IonCol>
                            )
                        })
                    }
                </IonRow>
            </IonGrid>
            <IonInfiniteScroll
                onIonInfinite={(ev) => loadData(ev)}
                threshold="100px"
                disabled={isInfiniteContentDisabled}
            >
                <IonInfiniteScrollContent
                    loadingSpinner="bubbles"
                    loadingText="Cargando más productos..."
                ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
        </>
    )
}

export default ProductList;
