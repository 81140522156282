/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    withIonLifeCycle,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonItem,
    IonBackButton, IonFooter, IonButton, IonLabel, IonInput, IonText, IonToast
} from '@ionic/react';
import NbioApi from "../../lib/api/NbioApi";

//components
import GradientDivider from "../../components/ui/GradientDivider";
import {RouteComponentProps} from "react-router";
import WebHeader from "../../components/ui/WebHeader";

interface RequestResetPasswordState {
    email: string;
    isToastOpen: boolean;
    errorMessage: string;
}

interface RequestResetPasswordProps extends RouteComponentProps {

}

class RequestResetPassword extends React.Component<RequestResetPasswordProps, RequestResetPasswordState> {
    constructor(props: RequestResetPasswordProps) {
        super(props);
        this.state = {
            email: '',
            isToastOpen: false,
            errorMessage: ''
        }
    }

    onChange = (value: string) => {
        this.setState({email:value});
    }

    onClickRequestResetPwd = () => {
        const email = this.state.email;
        if(email.trim() !== ''){
            this.requestResetPwd(email);
        }else{
            this.setState({errorMessage:'El correo no puede ser vacio',isToastOpen:true});
        }
    }

    requestResetPwd = (email: string) => {
        NbioApi.users.requestResetPwd(email).then(res => {
            this.props.history.push({
                pathname:'/validar-codigo',
                state:{
                    email:email
                }
            });
        }).catch(e => {
            this.setState({errorMessage: "Error, intenta mas tarde",isToastOpen:true});
        });
    }

    render() {
        return (
            <IonPage>
                <WebHeader title={'Solicitar cambio de contraseña'} showSearchButton={false} showCartButton={false} showMenuButton={false}></WebHeader>
                <IonContent fullscreen>
                    <IonToast
                        isOpen={this.state.isToastOpen}
                        onDidDismiss={() => {this.setState({isToastOpen:false});}}
                        message={this.state.errorMessage}
                        duration={5000}
                    />
                    <IonGrid >
                        <IonRow className={"ion-justify-content-center"}>
                            <IonCol size-md="6">
                                <IonText className={'text-wrap'}>
                                    Ingresa el correo electrónico que utilizas para Nbio
                                </IonText>

                                <IonItem className={"ion-margin-vertical"}>
                                    <IonLabel position={"floating"}>Correo</IonLabel>
                                    <IonInput id="email"
                                              type={"email"}
                                              value={this.state.email}
                                              placeholder="Correo"
                                              onIonChange={(e: any) => this.onChange(e.target.value)}>
                                    </IonInput>
                                </IonItem>
                                <IonButton routerLink={"/iniciar-sesion"}
                                           // className={"ion-margin-vertical"}
                                           expand="block"
                                           size={"small"}
                                           fill={"clear"}
                                           routerDirection={"back"}>Volver
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
                <IonFooter className="ion-no-border">
                    <IonToolbar>
                        <IonGrid>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size-md="6">
                                    <IonButton expand="block"
                                               fill="outline"
                                               onClick={() => this.onClickRequestResetPwd()}
                                    >Solicitar cambio</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(RequestResetPassword);
