/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * February 2022
 */
import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonModal,
    IonTextarea,
    IonTitle,
    IonToast,
    IonToolbar,
} from "@ionic/react";
import {close} from "ionicons/icons";
import StarRatings from "react-star-ratings";
import NbioApi from '../../lib/api/NbioApi';

//components
import OrderProductThumbnail from "../orders/OrderProductThumbnail";

interface ReviewOperatorProps {

}

const ReviewOperator: React.FC<ReviewOperatorProps> = ({}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');
    const [reviewId,setReviewId] = useState('');
    const [showToast,setShowToast] = useState(false);
    const [toastText,setToastText] = useState('');
    const [orderId,setOrderId] = useState('');
    const [products,setProducts] = useState([]);


    useEffect(() =>{
        NbioApi.reviews.getUnfinishedReviews().then((res) =>{
            if(res.data.unfinishedReview){
                const data = res.data.unfinishedReview;
                setReviewId(data._id)
                setIsOpen(true)
                setOrderId(data.orderId);
                setProducts(data.products);
            }
        }).catch((ex) =>{

        })
    },[])
    const sendReview = () =>{
        if(rating > 0){
            const answers = {
                reviewOperatorOnly:true,
                review:review,
                rating:rating
            }
            NbioApi.reviews.completeReview(reviewId,answers).then((res) =>{
                setToastText('¡Gracias por enviarnos tus comentarios!')
                setShowToast(true);
                setIsOpen(false);
            }).catch((ex) =>{
                setToastText('Hubo un error al mandar tus comentarios')
                setShowToast(true);
                setIsOpen(false);
            })

        }else{
            setShowToast(true);
            setToastText('Califica al operador con las estrellas')
        }
    }

    const skipReview = () =>{
        NbioApi.reviews.skipReview(reviewId).then((res) =>{
            setToastText('Puedes dejar tus comentarios desde la pantalla de tu orden');
            setShowToast(true);
            setIsOpen(false);

        }).catch((ex) =>{
            setToastText('Hubo un error al omitir la reseña')
            setShowToast(true);
            setIsOpen(false);

        })
    }

    return (
        <>
            <IonToast         isOpen={showToast}
                              onDidDismiss={() => setShowToast(false)}
                              message={toastText}
                              duration={2000} />
            <IonModal isOpen={isOpen}>

                <IonHeader translucent>
                    <IonToolbar>
                        <IonTitle>
                            Califica al operador
                        </IonTitle>
                        <IonButtons slot={'end'}>
                            <IonButton onClick={() => setIsOpen(false)}>
                                <IonIcon icon={close}></IonIcon>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardSubtitle> ¿Cómo calificas al operador de tu última compra?</IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <StarRatings starDimension="20px"
                                         starSpacing="1px"
                                         rating={rating}
                                         starHoverColor="#E0BB2E"
                                         starRatedColor="#ffd534"
                                         changeRating={(v) => setRating(v)}
                                         numberOfStars={5}
                                         name={'rating-operator'}></StarRatings>
                            <IonTextarea placeholder={'Escribe un comentario'} value={review}
                                         onIonChange={(e) => {
                                             setReview(e.detail.value!)
                                         }}
                            >
                            </IonTextarea>

                        </IonCardContent>
                    </IonCard>

                    <IonCard>
                        <IonCardHeader>
                            <IonCardSubtitle> Detalles de la orden {orderId}</IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <OrderProductThumbnail items={products}/>
                        </IonCardContent>
                    </IonCard>

                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButtons>
                            <IonButton fill={'clear'} color={'primary'} onClick={() => skipReview()}>
                                Omitir
                            </IonButton>
                            <IonButton className="w-100"
                                       color="primary"
                                       expand="block"
                                       onClick={() => sendReview()}
                                       fill='solid'>Calificar</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            </IonModal>
        </>

    )
}
export default ReviewOperator;
