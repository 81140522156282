/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2023
 */

import React from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    withIonLifeCycle,
    IonButtons,
    IonBackButton,
    IonSearchbar, IonItem, IonButton, IonIcon, IonSkeletonText, IonRow, IonGrid, IonSpinner
} from '@ionic/react';
import { RouteComponentProps} from "react-router";
import { Virtuoso } from 'react-virtuoso';
import NbioApi from "../../lib/api/NbioApi";
import {CategoryType} from "../../lib/data_types/dataTypes";
//components
import GradientDivider from "../../components/ui/GradientDivider";
import WebHeader from "../../components/ui/WebHeader";
import WebFooter from "../../components/ui/WebFooter";
import {search} from "ionicons/icons";
import ProductList from "../../components/products/ProductList";

interface DiscountsProps extends RouteComponentProps{}

interface DiscountsState{
    discounts: any[] ;
    products: any[];
    page:number,
    endReached:boolean,
    isFirstLoad:boolean
}

class Discounts extends React.Component<DiscountsProps,DiscountsState>{
    constructor(props: DiscountsProps) {
        super(props);
        this.state = {
            discounts: [],
            products:[],
            page:1,
            endReached:false,
            isFirstLoad:true
        }
    }
    ionViewDidEnter() {
        this.loadPage(this.state.page);
    }
    loadPage(pageNumber:number){
        if(this.state.endReached){
            return null;
        }
        NbioApi.discounts.get(pageNumber,10).then(res => {
            this.setState(
                {
                    products: [...this.state.products,...res.data.products],
                    page:this.state.page+1,
                    endReached: this.state.page >= res.data.pages,
                    isFirstLoad:false
                });
        }).catch((ex) =>{
        })
    }
    goToCategory(category:any){
        // this.props.history.push(`/categorias/${category.handleUrl}`);
    }
    renderDiscount(discount:any,index:number){
        return (
            <div key={`${discount.index}_${discount._id}`}>
                <IonItem>{discount.name}</IonItem>
                <ProductList
                             products={discount.discountedProducts}
                             title={discount.name}
                             isInfiniteContentDisabled={true}>
                </ProductList>
            </div>
        )
    }
    render(){
        const categoryList = this.state.discounts;
        return (
            <IonPage>
                <WebHeader title={'Descuentos'}></WebHeader>
                <IonContent className={"ion-padding-top"}>
                    <IonHeader className={"ion-margin-bottom ion-no-border"}>
                        <IonTitle></IonTitle>
                    </IonHeader>
                    {
                        this.state.isFirstLoad ?
                            <div className={'ion-text-center'}>
                                <IonSpinner color={'primary'}></IonSpinner>
                            </div>
                        :
                            <ProductList
                                products={this.state.products}
                                title={''}
                                isInfiniteContentDisabled={false}
                                onLoadNewPage={ async (pageNumber:number) => {
                                    return this.loadPage(pageNumber)
                                }}
                                onClick={() =>{}}

                            >
                            </ProductList>
                    }

                    <WebFooter></WebFooter>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(Discounts);
