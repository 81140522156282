/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

import {
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonButton,
    IonItem,
    IonLabel,
} from "@ionic/react";
import type {ProductType} from "../../lib/data_types/dataTypes";
import React, {useState} from "react";
import NbioApi from "../../lib/api/NbioApi";
import {calculator} from "ionicons/icons";

interface PosPaymentProps{
    product?: ProductType;
    onClick?: any;
    onConfirmPayment:any;
    onFailedPayment:any;
    total?:number,
    checkoutId:string
}
interface PosPaymentState{
}
class PosPayment extends React.Component<PosPaymentProps,PosPaymentState> {
    constructor(props:PosPaymentProps) {
        super(props);
        this.state  = {
        }
    }
    pay = () =>{
        NbioApi.checkout.generatePaymentIntent(this.props.checkoutId).then((res) =>{
            this.props.onConfirmPayment(res.data.orderId);
        }).catch((ex) =>{
            this.props.onFailedPayment();
        })
    }
    render(){
        return (
            <IonContent fullscreen>
                <IonGrid className={'ion-no-padding'}>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size-md="6">
                            <IonItem lines={"none"}>
                                <IonLabel className={"ion-text-wrap"}>
                                    Realizarás tu pago a través de una terminal punto de venta con el repartidor.
                                    <br/>
                                    Toca en ORDENAR para continuar.
                                </IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        );
    }

};

export default PosPayment;
