/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import {IonButton} from "@ionic/react";
import '../../css/style.css';
import {CartItemType} from "../../lib/data_types/dataTypes";

//interface
interface BtnToolbarLogoProps {
  className?:string
}
const BtnToolbarLogo: React.FC<BtnToolbarLogoProps> = ({className = ''}) => {
  return (
      <IonButton fill="clear" className={`btn-toolbar-logo ${className}`} routerDirection="root" routerLink="/inicio"></IonButton>
  );
};

export default BtnToolbarLogo;
