/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * July 2022
 */

import { Swiper, SwiperSlide } from 'swiper/react';
import {IonCard, IonText} from "@ionic/react";
import cameraImage from '../../assets/camera-outline-dark.png';

//slide style
import 'swiper/swiper-bundle.min.css';
import '@ionic/react/css/ionic-swiper.css';
import '../../css/components/imageSelector.css';
import React from "react";

//components


interface ImageSelectorProps{
    images: any;
    onSelect: any;
    onSelectUploadImage: any;
    title?: string;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({  images,
                                                         onSelect,
                                                         onSelectUploadImage,
                                                         title= 'Selecciona una imagen'}) => {

  return (
      <>
          <IonText className={'ion-padding-top ion-text-uppercase'}><b>{title}</b></IonText>
          <Swiper slidesPerView={1.5} loop={false}>
              {images.map((image: any) => {
                  return(
                      <SwiperSlide key={image._id}>
                          <IonCard button
                                   onClick={() => onSelect(image)}
                                   className={'ion-no-margin ion-margin-top ion-margin-end ion-margin-bottom'}>
                              <img className={'image-selector-thumbnail'} src={image.imageThumbnailUrl}/>
                          </IonCard>
                      </SwiperSlide>
                  )
              })
              }
              <SwiperSlide key={'new'}>
                  <IonCard button
                           onClick={() => onSelectUploadImage()}
                           className={'image-selector-btn ion-no-margin ion-margin-top ion-margin-end ion-margin-bottom'}>
                      <img className={'image-selector-thumbnail'} src={cameraImage}/>
                  </IonCard>
              </SwiperSlide>
          </Swiper>
      </>

  );
};

export default ImageSelector;
