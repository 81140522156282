/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */
import React, {useEffect} from "react";
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon, IonLabel, IonNote,
    IonPage, IonSegment, IonSegmentButton,
    IonRow,
    IonToolbar,
    withIonLifeCycle, IonMenu, IonTitle, IonMenuToggle, IonMenuButton,
} from "@ionic/react";
import {CategoryType, OrderType, ProductType} from "../../lib/data_types/dataTypes";
import {RouteComponentProps} from "react-router";
import NbioApi from "../../lib/api/NbioApi";
import {getPlatforms} from '@ionic/react';
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {Toast} from "@capacitor/toast";
import {LocalNotifications, LocalNotificationSchema, ScheduleOptions} from "@capacitor/local-notifications";
import {Capacitor} from "@capacitor/core";
import {list, menuOutline, refreshOutline, search} from "ionicons/icons";

//style
import '../../css/style.css';

//components
import LocationActionSheet from "../../components/ui/LocationActionSheet";
import GradientDivider from '../../components/ui/GradientDivider';
import CategoryList from "../../components/categories/CategoryList";
import ProductList from "../../components/products/ProductList";
import ReviewOperator from "../../components/reviews/ReviewOperator";
import AdPopup from "../../components/ads/AdPopup";
import WebHeader from "../../components/ui/WebHeader";
import WebFooter from "../../components/ui/WebFooter";
import OrderWidget from "../../components/orders/OrderWidget";
import ProfileDataPopup from "../../components/user/ProfileDataPopup";
import BtnToolbarLogo from "../../components/ui/BtnToolbarLogo";
import Banner from "../../components/banner/Banner";
import ProductListHomeWeb from "../../components/products/ProductListHomeWeb";
import {Menu} from "../../components/menu/Menu";
import { setAppTitle } from "../../lib/app-utils/app-utils";
import Cart from "../../lib/cart/Cart";

interface TabHomeState {
    productList: ProductType[],
    mainCategories: CategoryType[],
    viewIsLoaded: boolean,
    currentOrder?: OrderType,
    displayProfilePopupComponent: boolean,
    areTopProductsErrored: boolean,
    areCategoriesErrored: boolean,
    areCategoriesLoaded: boolean,
    lists: any,
    selectedList: any;
}

interface TabHomeProps extends RouteComponentProps {
}

const showToast = async (msg: string) => {
    await Toast.show({
        text: msg
    })
}

const dummyProduct = {_id: '', images: [''], price: 0, name: '', isControlled: false, medicationGroup: ''};
const dummyProductList = Array.from({length: 4}, () => (dummyProduct));

class TabHome extends React.Component<TabHomeProps, TabHomeState> {
    appIsLoadedInterval: any;

    constructor(props: TabHomeProps) {
        super(props);
        this.state = {
            productList: [],
            mainCategories: [],
            currentOrder: undefined,
            viewIsLoaded: false,
            displayProfilePopupComponent: false,
            areTopProductsErrored: false,
            areCategoriesErrored: false,
            lists: [{name: '', products: []}],
            selectedList: {name: '', products: []},
            areCategoriesLoaded: false
        }
    }

    ionViewDidEnter() {
        const areNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
        this.setState({viewIsLoaded: true});

        if (areNotificationsAvailable) {
            // PUSH NOTIFICATIONS
            PushNotifications.checkPermissions().then((res) => {
                if (res.receive !== 'granted') {
                    PushNotifications.requestPermissions().then((res) => {
                        if (res.receive === 'denied') {
                            showToast('Push Notification permission denied');
                        } else {
                            showToast('Push Notification permission granted');
                            this.register();
                        }
                    });
                } else {
                    this.register();
                }
            });
        }
        setAppTitle('Farmacia Nbio | Envíos a todo México');
        this.loadContent();
        // Check if there is a redirection
        const hasRedirection = Cart.hasRedirectAfterLoginSuggestion();
        if (hasRedirection) {
            setTimeout(() =>{
                this.completeRedirect();
            },500);
        }
    }

    register() {
        // console.log('Initializing HomePage');

        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register()

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: Token) => {
                NbioApi.users.updateFcmToken(token.value).then((res) => {
                    // console.log('FcmToken uploaded')
                }).catch((ex) => {
                    // console.log(ex)
                })

                // console.log('Push registration success');
            }
        );


        const platforms = getPlatforms();
        if (platforms.includes('android') || platforms.includes('ios')) {
            // console.log(platforms)
            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError',
                (error: any) => {
                    // console.log('Error on registration: ' + JSON.stringify(error));
                }
            );
            // Show us the notification payload if the app-utils is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification: PushNotificationSchema) => {
                    // console.log('notification', notification)

                    let _notification = {
                        id: parseInt(notification.id),
                        title: notification.title,
                        body: notification.body,
                        extra: notification.data,
                        largeIcon: 'ic_launcher',
                        smallIcon: 'ic_launcher',
                    } as LocalNotificationSchema;
                    let options = {
                        notifications: [_notification]
                    } as ScheduleOptions;
                    LocalNotifications.schedule(options)
                })

            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification: ActionPerformed) => {
                    // console.log('!notification',notification)
                    // console.log('data',notification.notification.data)
                    const data = notification.notification.data;
                    // console.log('data',data)
                    // CHECK EVERY SECOND IF VIEW IS LOADED
                    this.appIsLoadedInterval = setInterval(() => {
                        // THEN PUSH
                        if (this.state.viewIsLoaded) {
                            clearInterval(this.appIsLoadedInterval);
                            if (data) {
                                if (data.action) {
                                    // console.log('action interval',data.action)
                                    if (data.action === 'ORDER_NEEDS_PRESCRIPTION') {
                                        this.props.history.push(`/ordenes/${data.orderId}`);
                                    } else if (data.action === 'ORDER_PAID') {
                                        this.props.history.push(`/ordenes/${data.orderId}`);
                                    } else if (data.action === 'ORDER_CREATED') {
                                        this.props.history.push(`/ordenes/${data.orderId}`);
                                    } else if (data.action === 'ORDER_AMOUNT_SAVED') {
                                        this.props.history.push(`/ordenes/${data.orderId}`);
                                    }  else if(data.action === 'ORDER_PARCEL_NUMBER_ADDED'){
                                        this.props.history.push(`/ordenes/${data.orderId}`);
                                    }
                                }
                            }
                        }

                    }, 2000)
                    // // TRY TO PUSH REGARDLESS
                    // if(data){
                    //     if(data.action){
                    //         console.log('action',data.action)
                    //         if(data.action === 'ORDER_NEEDS_PRESCRIPTION'){
                    //             this.props.history.push(`/ordenes/${data.orderId}`);
                    //         }else if(data.action === 'ORDER_PAID'){
                    //             this.props.history.push(`/ordenes/${data.orderId}`);
                    //         }else if(data.action === 'ORDER_AMOUNT_SAVED'){
                    //             this.props.history.push(`/ordenes/${data.orderId}`);
                    //         }
                    //     }
                    // }
                    // setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
                }
            );
            LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
                // CHECK EVERY SECOND IF VIEW IS LOADED
                this.appIsLoadedInterval = setInterval(() => {
                    // THEN PUSH
                    if (this.state.viewIsLoaded) {
                        clearInterval(this.appIsLoadedInterval);
                        if (notification.notification.extra) {
                            const extra = notification.notification.extra;
                            if (extra.action === 'ORDER_NEEDS_PRESCRIPTION') {
                                this.props.history.push(`/ordenes/${extra.orderId}`);
                            } else if (extra.action === 'ORDER_PAID') {
                                this.props.history.push(`/ordenes/${extra.orderId}`);
                            } else if (extra.action === 'ORDER_CREATED') {
                                this.props.history.push(`/ordenes/${extra.orderId}`);
                            } else if (extra.action === 'ORDER_NEEDS_PRESCRIPTION') {
                                this.props.history.push(`/ordenes/${extra.orderId}`);
                            } else if (extra.action === 'ORDER_AMOUNT_SAVED') {
                                this.props.history.push(`/ordenes/${extra.orderId}`);
                            } else if(extra.action === 'ORDER_PARCEL_NUMBER_ADDED'){
                                this.props.history.push(`/ordenes/${extra.orderId}`);
                            }
                        }
                    }
                }, 2000);
            })
        }


    }
    completeRedirect = async () =>{
        // And Validate Cart
        const res = await Cart.getCart();
        const cartData = res.data.cart;
        const cartItems = cartData.items;
        let isPrescriptionRequired = true;
        isPrescriptionRequired = cartData.items.some( (item: { isControlled: any; prescription: any; }) => {
            if(item.isControlled){
                return !item.prescription;
            }
            return false
        });
        NbioApi.users.getMe().then((res) =>{
            // TODO: Check if user is logged in
            if(cartItems.length > 0){
                if(isPrescriptionRequired){
                    this.props.history.push('/carrito');
                }else{
                    this.props.history.push('/confirmar-pedido');
                }
            }
        }).catch((ex) =>{

        })

        Cart.deleteRedirectAfterLoginSuggestion();
    }

    goToProduct(product: any) {
        // this.props.history.push(`/productos/${product.handleUrl}`);
    }

    goToCategory(category: any) {
        // this.props.history.push(`/categorias/${category.handleUrl}`);
    }

    async waitTime(time = 0) {
        return new Promise((resolve => {
            setTimeout(() => {
                resolve(true);
            }, time);
        }));
    }

    async loadContent(delay = 1000) {
        this.setState({productList: dummyProductList, areTopProductsErrored: false, areCategoriesErrored: false});
        // Add Delay for cinematic experience
        await this.waitTime(delay);
        //check if my profile is completed
        NbioApi.users.isMyProfileComplete().then((res) => {
            this.setState({displayProfilePopupComponent: !res.data.isProfileComplete});
        }).catch((ex) => {
            this.setState({displayProfilePopupComponent: false});
        });

        //gev products lists
        NbioApi.lists.get().then(res => {
            this.setState({lists: res.data.lists, selectedList: res.data.lists[0]});
        }).catch((ex) => {
        });

        //get products
        NbioApi.products.getTop().then(res => {
            this.setState({productList: []});
            this.setState({productList: res.data.products});
        }).catch((ex) => {
            this.setState({areTopProductsErrored: true})
            this.setState({productList: []});
        })

        //get main categories
        NbioApi.categories.get().then((res) => {
            let categories = res.data.categories;
            categories = categories.filter((c: CategoryType) => c.image);
            this.setState({mainCategories: categories, areCategoriesLoaded: true, areCategoriesErrored: false});
        }).catch((ex) => {
            this.setState({areCategoriesErrored: true, areCategoriesLoaded: false})

        })
        //Get my current order
        NbioApi.orders.getActiveOrder().then((res) => {
            this.setState({
                currentOrder: res.data.order
            })
        }).catch((ex) => {
        })
    }

    onSelectList = (listName: any) => {
        let sl = this.state.lists.find((list: any) => list.name === listName);
        this.setState({selectedList: sl});

    }

    render() {
        return (
            <>
                <IonPage id={'tab-home'}>
                    {/* Rich data */}
                    <div itemScope itemType="https://schema.org/WebSite">
                        <meta itemProp="url" content="https://www.nbio.mx/"/>
                        <meta itemProp="name" content="Farmacia Nbio"/>
                        <meta itemProp="description"
                              content="NBIO Farmacia te entrega a domicilio cualquier medicamento, fácil y rápido"/>
                        <meta itemProp="alternateName" content="Farmacia Nbio"/>
                    </div>
                    <ReviewOperator></ReviewOperator>
                    <AdPopup></AdPopup>
                    {this.state.displayProfilePopupComponent ? <ProfileDataPopup/> : null}
                    <WebHeader></WebHeader>
                    <LocationActionSheet></LocationActionSheet>
                    <IonContent className="">
                        <Banner/>
                        <div className={'main-content-margin'}>
                            <div className={'ion-hide-lg-up'}>
                                <OrderWidget order={this.state.currentOrder}></OrderWidget>
                            </div>


                            {/*Products Lists Starts*/}
                            <ProductListHomeWeb lists={this.state.lists}
                                                selectedList={this.state.selectedList}
                                                onSelectList={(list: any) => this.onSelectList(list)}
                                                onClick={(p: any) => this.goToProduct(p)}
                            />


                            <div className={'ion-hide-lg-up'}>
                                {
                                    this.state.lists.map((list: any,index:number) => {
                                        return (
                                            <ProductList key={`${list._id}_${index}`}
                                                         products={list.products.slice(0, 4)}
                                                         title={this.state.areTopProductsErrored ? "" : list.name}
                                                         onClick={(p: any) => this.goToProduct(p)}
                                                         isInfiniteContentDisabled={true}>
                                            </ProductList>
                                        )
                                    })
                                }
                            </div>
                            {/*Products Lists Ends*/}

                            <div className={'ion-hide-lg-down margin-top-lg'}>
                                <OrderWidget order={this.state.currentOrder}></OrderWidget>
                            </div>
                            {
                                // Hides when is loading, when there is an order. It is replaced by <OrderWidget>
                                !this.state.areCategoriesErrored && this.state.areCategoriesLoaded  && !this.state.currentOrder?
                                    //*Start Features on medium screen and up*/
                                    <IonGrid className={'ion-hide-lg-down  margin-top-lg'}>
                                        <IonRow>
                                            <IonCol className={'feature-col ion-padding-start'}>
                                                <h3 className={'ion-no-margin'}>Pague cómodo y seguro</h3>
                                                <h5 className={'ion-no-margin'}>Con Mercado Pago</h5>
                                            </IonCol>

                                            <IonCol className={'feature-col ion-padding-start'}>
                                                <h3 className={'ion-no-margin'}>Recíbalos hoy</h3>
                                                <h5 className={'ion-no-margin'}>Con entrega inmediata</h5>
                                            </IonCol>

                                            <IonCol className={'feature-col ion-padding-start'}>
                                                <h3 className={'ion-no-margin'}>Envíos a todo el país</h3>
                                                <h5 className={'ion-no-margin'}>Consulte términos y condiciones</h5>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                    : null
                            }
                            {
                                this.state.areTopProductsErrored ?
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column'
                                    }} onClick={() => {
                                        this.loadContent(1000)
                                    }}>
                                        <IonNote>¡Algo salió mal!</IonNote>
                                        <IonButton fill={'outline'} size={'small'}>
                                            <IonIcon icon={refreshOutline}></IonIcon>
                                            Reintentar
                                        </IonButton>
                                    </div>
                                    : null
                            }


                            {/*Category list Start*/}
                            <CategoryList categories={this.state.mainCategories}
                                          title={this.state.areTopProductsErrored ? "" : 'Categorías'}
                                          onClick={(c: any) => this.goToCategory(c)}>
                            </CategoryList>
                            {/*Category list Ends*/}


                            {/*Recommended Products Start*/}
                            <ProductListHomeWeb
                                lists={[{name: 'Recomendados', products: this.state.productList, _id: '1'}]}
                                selectedList={{name: 'Recomendados', products: this.state.productList}}
                                onClick={(p: any) => this.goToProduct(p)}
                            />
                            <div className={'ion-hide-lg-up'}>
                                <ProductList products={this.state.productList.slice(0, 4)}
                                             title={this.state.areTopProductsErrored ? "" : 'Recomendados'}
                                             onClick={(p: any) => this.goToProduct(p)}
                                             isInfiniteContentDisabled={true}>
                                </ProductList>
                            </div>
                            {/*Recommended Products Ends*/}
                        </div>

                        <WebFooter/>
                    </IonContent>
                </IonPage>
            </>

        )
    }
}


export default withIonLifeCycle(TabHome)
