/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */
import React from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    withIonLifeCycle,
    IonButtons,
    IonButton,
    IonFooter,
    IonCard,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonBackButton,
    IonInput,
    IonDatetime,
    IonProgressBar, IonToast, IonChip, IonNote,
    IonModal, IonText, IonPopover, IonThumbnail,
} from '@ionic/react';
import {Camera, CameraResultType} from '@capacitor/camera';
import {RouteComponentProps} from "react-router";
import {camera, close, time, helpCircle} from "ionicons/icons";
import API from "../../lib/api/NbioApi";
import {CartItemType} from "../../lib/data_types/dataTypes";
import Cart from "../../lib/cart/Cart";
import dayjs from "dayjs";
import utc   from "dayjs/plugin/utc";
import 'dayjs/locale/es'

//style
import "../../css/pages/prescription.css"


//components
import GradientDivider from "../../components/ui/GradientDivider";
import WebHeader from "../../components/ui/WebHeader";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ImageSelector from "../../components/ui/ImageSelector";
import IdUpload from "../../components/checkout/IdUpload";
import axios from "axios";
import {Capacitor} from "@capacitor/core";
import {Keyboard} from "@capacitor/keyboard";
import NPDatePicker from "../../components/ui/NPDatePicker";

//dates
dayjs.extend(utc);

interface PrescriptionProps extends RouteComponentProps {
};

interface PrescriptionState {
    documentId: string;
    issueDate: any;
    imageUrl: string;
    isLoading: boolean;
    loadSavedImage: boolean;
    toastMsg: string;
    showToast: boolean;
    savedCartItem?: CartItemType;
    isEditable:boolean;
    showModal: boolean;
    isEdited: boolean;
    productImage: string;
    productName: string;
    productSKU: string;
    showPrescriptionSelector: boolean;
    prescriptionImages: any;
    copyOfPrescriptionId: any;
    frontImage:any,
    backImage:any,
    documentType:string,
    copyOfDocumentId: any;
    isKeyboardVisible:boolean;
    loaded:boolean;

}

interface CartItem {
    cartItem: CartItemType;
    orderId?:string;
}

class Prescription extends React.Component<PrescriptionProps, PrescriptionState> {
    constructor(props: PrescriptionProps) {
        super(props);
        this.state = {
            documentId: '',
            issueDate: null,
            imageUrl: '',
            isLoading: false,
            loadSavedImage: false,
            toastMsg: '',
            showToast: false,
            savedCartItem: undefined,
            isEditable:false,
            showModal:false,
            isEdited:false,
            productImage:'',
            productName:'',
            productSKU:'',
            showPrescriptionSelector: false,
            prescriptionImages: [
            ],
            copyOfPrescriptionId: null,
            frontImage: '',
            backImage: '',
            documentType: '',
            copyOfDocumentId:null,
            isKeyboardVisible:false,
            loaded: false

        }

        this.onSelectedPrescription = this.onSelectedPrescription.bind(this);
        this.onClickAddPrescription = this.onClickAddPrescription.bind(this);
    }
    init(){

    }
    ionViewDidEnter() {
        this.init()
        let i = this.props.location.state as CartItem;
        let cartItem = i.cartItem;
        let isEditable = false;

        if(cartItem){
            this.setState({productImage:cartItem.imageUrlThumbnail,
                productName:cartItem.description,
                productSKU: cartItem.SKU
            });
        }

        if (cartItem.prescription) {
            if(cartItem.isPrescriptionValidated){
                if(!cartItem.isPrescriptionValid){
                    isEditable = true;
                }
            }
            this.setState({
                imageUrl: cartItem.prescription.imageUrl, loadSavedImage: true,
                documentId: cartItem.prescription.documentId,
                issueDate: cartItem.prescription.issueDate,
                savedCartItem:cartItem,
                isEditable: isEditable,
                isEdited:true,
                loaded:true
            })
        }else{
            this.setState({
                isEditable:true,
                isEdited:false,
                loaded:true

            })
        }
        this.loadPrescriptions();
        //    Keyboard events
        const isKeyboardAvailable = Capacitor.isPluginAvailable('Keyboard');

        if(isKeyboardAvailable){
            Keyboard.addListener('keyboardDidShow', info => {
                this.setState({isKeyboardVisible:true});
            });
            Keyboard.addListener('keyboardDidHide', () => {
                this.setState({isKeyboardVisible:false});
            });
        }
    }
    showIntoView = () =>{
        if(this.state.isKeyboardVisible){
            try{
                // @ts-ignore
                document.activeElement.parentElement.parentElement.scrollIntoView();
            }catch(ex){
                console.log('ex',ex);
            }
        }
    }
    validatePhotoId = () =>{
        let i = this.props.location.state as CartItem;
        let cartItem = i.cartItem;
        // IF THE DOCUMENT IS NOT RI, isValid = true
        if(cartItem.medicationGroup !== 'R1'){
            return {
                isValid:true,
                error:''
            }
        }
        if(!this.state.documentType){
            const isValid = false;
            const error = 'Selecciona una identificación';
            if(!isValid){
                return {
                    isValid,
                    error
                }
            }
        }
        if(this.state.documentType === 'INE'){
            const isValid = this.state.frontImage && this.state.backImage;
            const error = 'Selecciona una imagen frontal y trasera para tu identificación';
            if(!isValid){
                return {
                    isValid,
                    error
                }
            }
        }else{
            const isValid = !!this.state.frontImage;
            const error = 'Selecciona una imagen frontal para tu identificación';
            if(!isValid){
                return {
                    isValid,
                    error
                }
            }
        }
        return {
            isValid:true,
            error:''
        }
    }
    savePrescription = async () => {
        if (!this.state.isEditable) {
            return false;
        }
        this.setState({isLoading: true});
        let i = this.props.location.state as CartItem;
        let cartItem = i.cartItem;
        const {isValid,error} = this.validatePhotoId();
        if(!isValid){
            return this.setState({
                toastMsg: error,
                showToast: true,
                isLoading:false
            })
        }
        // Validate date
        const dayjsIssueDate = dayjs(this.state.issueDate);
        const now = dayjs();
        if(dayjsIssueDate.isValid()){
            if(dayjsIssueDate.isAfter(now)){
                return this.setState({
                    toastMsg: 'No puedes seleccionar fechas posteriores al día de hoy',
                    showToast: true,
                    isLoading:false
                })
            }
        }
        if (this.state.issueDate && this.state.documentId && this.state.imageUrl) {
            //----------------------------------------------------------------------------------------------------------
            // UPLOAD PHOTO ID                                                                                         |
            //----------------------------------------------------------------------------------------------------------
            const cartId = Cart.getCartId() || '';
            const photoIdObj = {
                imageFrontUrl: this.state.frontImage,
                imageBackUrl: this.state.backImage,
                documentType: this.state.documentType
            }
            // ONLY UPLOAD IMAGE WHEN medicationGroup === R1
            if(cartItem.medicationGroup === 'R1'){
                try{
                    if (i.orderId) {
                        await API.orders.uploadID(i.orderId, photoIdObj, i.cartItem.cartItemId, this.state.copyOfDocumentId);
                    }else{
                        await API.cart.uploadID(cartId, photoIdObj, i.cartItem.cartItemId, this.state.copyOfDocumentId);
                    }
                }catch(ex){
                    return this.setState({
                        toastMsg: 'Hubo un error subiendo tu imagen',
                        showToast: true,
                        isLoading:false
                    })
                }
            }

            API.prescriptions.create(
                this.state.documentId,
                this.state.imageUrl,
                this.state.issueDate,
                this.state.copyOfPrescriptionId
            ).then((res) => {
                const prescriptionId = res.data.prescription._id;
                if (i.orderId) {
                    // update order prescription
                    API.orders.updatePrescription(i.orderId, cartItem._id, prescriptionId).then((res) => {
                        this.setState({isLoading: false}, () => {
                            this.props.history.goBack();
                        });
                    }).catch((ex) => {
                        let errorMessage = 'Hubo un error subiendo tu imagen';
                        try{
                            errorMessage = ex.response.data.error_es;
                        }catch(ex){
                        }
                        this.setState({
                            toastMsg: errorMessage,
                            showToast: true,
                            isLoading:false
                        })
                    })
                } else {
                    // update cart prescription
                    // NOW UPDATE CART ITEM
                    Cart.updateProduct(
                        cartItem.cartItemId,
                        cartItem.quantity,
                        res.data.prescription._id
                    ).then((res) => {
                        this.setState({isLoading: false}, () => {
                            this.props.history.goBack();
                        });
                    })
                }

            }).catch((ex) => {
                // TODO: HANDLE
                this.setState({isLoading: false}, () => {
                });
            })
        } else {
            this.setState({
                toastMsg: 'Debes de ingresar la receta, cédula y fecha',
                showToast: true,
                isLoading:false
            })

        }
    }

    _takePicture = async () => {
        try{
            const image = await Camera.getPhoto({
                quality: 90,
                resultType: CameraResultType.Base64,
                promptLabelHeader:'Agregar receta',
                promptLabelPhoto:'Seleccionar de la galería',
                promptLabelPicture:'Tomar una fotografía'
            });
            // image.webPath will contain a path that can be set as an image src.
            // You can access the original file using image.path, which can be
            // passed to the Filesystem API to read the raw data of the image,
            // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
            const imageBase64 = image.base64String;
            if (imageBase64) {
                //todo upload the pic
                this.setState({
                    imageUrl: imageBase64
                })
            }
        }catch(ex){
            this.setState({
                toastMsg: 'No pudimos abrir la cámara. Por favor, vuelve a intentarlo',
                showToast: true,
                isLoading:false
            })
        }

    }

    removePicture = () => {
        this.setState({imageUrl: '', loadSavedImage: false});
    }

    renderAddButton() {
        if (this.state.isLoading) {
            return (
                <IonProgressBar type="indeterminate"></IonProgressBar>
            )
        } else {
            return (
                <IonButton className="ion-margin-horizontal w-100" onClick={this.savePrescription}
                           color="primary"
                           expand="block"
                           disabled={this.state.loadSavedImage}
                           fill='solid'>GUARDAR RECETA</IonButton>
            )
        }
    }

    onClickAddPrescription = () => {
        if(this.state.prescriptionImages.length > 0){
            return this.setState({showPrescriptionSelector:true});
        }else{
            return this._takePicture();
        }
    }

    renderPrescription = (imgUrl: string) => {
        if (imgUrl) {
            let imgSrc = this.state.loadSavedImage ? this.state.imageUrl : `data:image/jpeg;base64,${imgUrl}`;
            return (
                <div className={'wrapper-image-prescription'}>
                    <img className="w-100" src={imgSrc} alt={'prescription-image'}>
                    </img>
                    <IonButton className="fab-camera-prescription-btn"
                               fill="clear"
                               shape="round"
                               size="large"
                               color={"danger"}
                               strong
                               onClick={() => this.removePicture()}>
                        <IonIcon slot={"icon-only"} icon={close}></IonIcon>
                    </IonButton>
                </div>
            )
        } else if(! this.state.showPrescriptionSelector) {
            return (
                <div>
                    <IonButton expand={'block'}
                               fill={"outline"}
                               className={'upload-image-btn ion-margin-top ion-margin-bottom'}
                               onClick={() => this.onClickAddPrescription()}
                    >
                        <IonIcon slot={"icon-only"} icon={camera}></IonIcon>
                        Agregar
                    </IonButton>
                    <IonChip color={'dark'} onClick={() => this.setShowModal(true)}  className={'ion-margin-top'}>
                        <IonIcon icon={helpCircle}></IonIcon>
                        <IonLabel>Receta de ejemplo</IonLabel>
                    </IonChip>
                </div>
            )
        }else{
            return null
        }
    }

    handleInput = ({id, value}: any) => {
        this.setState({[id]: value} as Pick<PrescriptionState, keyof PrescriptionState>);
        if(id === 'issueDate'){
            this.setState({'isEdited':true});
        }
    }

    renderValidatorComment(){
        let locationState = this.props.location.state as CartItem;
        let orderId = locationState ? locationState.orderId : null;
        // let orderId = 1;

        if(!this.state.savedCartItem  || !orderId){
            return null;
        }else{
            if(!this.state.savedCartItem.isPrescriptionValidated){


                return (
                    <IonChip color={'success'} style={{minHeight:50,width:'100%'}}>
                    <IonIcon icon={time}></IonIcon>
                        <IonNote className='ion-text-wrap padding-half'>
                            Tu receta está esperando a ser validada.
                        </IonNote>
                    </IonChip>
                )
            }
        }

        return(
            <IonChip color={'danger'} style={{minHeight:100,width:'100%'}}>
                <IonIcon icon={close}></IonIcon>
                <IonNote className='ion-text-wrap padding-half'>
                    Tu receta fue rechazada por la siguiente razón: <b>{this.state.savedCartItem.prescriptionValidatorComment}.</b>
                    <br></br>
                    Remueve tu receta y vuelve a subirla con los datos correctos.
                </IonNote>
            </IonChip>
        )
    }

    setShowModal = (show: boolean) => {
        this.setState({showModal:show});
    }

    // setSelectedDate = (v: any) =>{
    //     const dateFormat = dayjs(v).format('DD MMMM YYYY');
    //     this.setState({
    //         issueDate:dateFormat
    //     })
    // }

    renderDate = (date: any) => {
        // if the user hasn't picked a date, show local timezone instead of utc
        let dateValue = null;
        if(this.state.isEdited){
            dateValue = dayjs(date).locale('es').utc().format('DD MMMM YYYY');
        }else{
            dateValue = dayjs().locale('es').format('DD MMMM YYYY');

        }
        return  <IonText>{dateValue}</IonText>
    }

    onSelectedPrescription = async (imageSelected: any) => {
        // create a new prescription
        try{
            let image = await axios.get(imageSelected.imageThumbnailUrl, {responseType: 'arraybuffer'});
            const image64 = Buffer.from(image.data,'binary').toString('base64');
            this.setState({
                showPrescriptionSelector: false,
                imageUrl: image64,
                copyOfPrescriptionId: imageSelected._id,
                issueDate:imageSelected.issueDate,
                documentId:imageSelected.documentId
            });
        }catch(ex){

        }



    }
    loadPrescriptions = () =>{
        const cartId = Cart.getCartId() || '';
        API.cart.getPrescriptions(cartId).then((res) =>{
            this.setState({
                prescriptionImages: res.data.prescriptions
            })
        }).catch((ex) =>{

        })
    }
    renderPrescriptionSelector = () => {
        if (this.state.showPrescriptionSelector){
                return(
                    <ImageSelector images={this.state.prescriptionImages}
                                   title={'Selecciona una receta'}
                                   onSelect={(image: any) => this.onSelectedPrescription(image)}
                                   onSelectUploadImage={() => {
                                       this.setState({showPrescriptionSelector:false,copyOfPrescriptionId:null});
                                       return this._takePicture()}
                                   }>
                    </ImageSelector>
                )
        }else{
            return null
        }
    }
    setIssueDate(date: Date){
        this.setState({issueDate:date});
    }
    render() {
        let i = this.props.location.state as CartItem;
        let cartItem = i ? i.cartItem : {medicationGroup:''};
        return (
            <IonPage>
                <IonToast
                    isOpen={this.state.showToast}
                    onDidDismiss={() => this.setState({showToast: false})}
                    message={this.state.toastMsg}
                    duration={1000}/>
                {/*Modal start*/}
                <IonModal isOpen={this.state.showModal} className="fullscreen-modal">
                    <IonHeader className={"ion-hide-lg-up"}>
                        <IonToolbar className={"toolbar-nbio"}>
                            <IonTitle>Receta de ejemplo</IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={() => this.setShowModal(false)}><IonIcon icon={close}></IonIcon></IonButton>
                            </IonButtons>
                        </IonToolbar>
                        <GradientDivider></GradientDivider>
                    </IonHeader>
                    <IonContent fullscreen className="ion-padding-vertical">
                        <IonGrid>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size-md="12">
                                    {/*Prescription image example*/}
                                    <TransformWrapper>
                                        <TransformComponent>
                                            <img src={'https://nbio.blob.core.windows.net/cdn/prescription_example.jpeg'} alt="test" />
                                        </TransformComponent>
                                    </TransformWrapper>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonContent>
                </IonModal>
                {/*Modal end*/}

                <WebHeader title={'Sube tu Receta'} showSearchButton={false} showMenuButton={false} showCartButton={false}></WebHeader>
                <IonContent className="ion-padding-vertical">
                    <IonGrid>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size-md="6">
                                <IonCard className={'ion-padding ion-no-margin ion-margin-bottom'}>
                                    <IonCardSubtitle>
                                        Tu producto controlado
                                    </IonCardSubtitle>
                                    <IonItem lines={'none'} className={'ion-no-padding bg-transparent'}>
                                        <IonThumbnail slot={'start'}>
                                            <img src={this.state.productImage} alt="product_image" />
                                        </IonThumbnail>
                                        <IonLabel className={'ion-text-wrap'}>{this.state.productName}</IonLabel>
                                    </IonItem>
                                </IonCard>

                                {this.renderValidatorComment()}

                                <IonCard className={'ion-padding ion-no-margin ion-margin-bottom'}>
                                    <IonCardSubtitle>Agrega la imagen de la receta</IonCardSubtitle>
                                    {this.renderPrescription(this.state.imageUrl)}

                                    {this.renderPrescriptionSelector()}
                                </IonCard>

                                <IonCard className={"ion-no-margin  ion-margin-bottom"}>
                                    <IonCardContent>
                                        <IonCardSubtitle>
                                            Agrega los datos de la receta
                                        </IonCardSubtitle>
                                        <IonItem className={'ion-no-padding bg-transparent'}>
                                            <IonLabel position="floating">Cédula profesional</IonLabel>
                                            <IonInput id="documentId"
                                                      value={this.state.documentId}
                                                      placeholder={'Cédula profesional'}
                                                      onIonChange={(e: any) => this.handleInput(e.target)}
                                                      onFocus={() => this.showIntoView()}
                                            >
                                            </IonInput>
                                        </IonItem>
                                        {/*<IonItem button={true} id="prescription-datetime" className={'ion-no-padding bg-transparent'}>*/}
                                        {/*    <IonLabel position="stacked">Fecha de emisión</IonLabel>*/}
                                        {/*    {this.renderDate(this.state.issueDate)}*/}
                                        {/*    <IonPopover trigger="prescription-datetime" showBackdrop={false}>*/}
                                        {/*        <IonDatetime id="issueDate"*/}
                                        {/*                     presentation={'date'}*/}
                                        {/*                     locale={'es-MX'}*/}
                                        {/*                     value={*/}
                                        {/*                         this.state.isEdited ?*/}
                                        {/*                             dayjs(this.state.issueDate).utc().format('YYYY-MM-DD') :*/}
                                        {/*                             dayjs(this.state.issueDate).format('YYYY-MM-DD')*/}
                                        {/*                     }*/}
                                        {/*                     max={dayjs().format('YYYY-MM-DD')}*/}
                                        {/*                     onIonChange={(e: any) => {*/}
                                        {/*                             this.handleInput(e.target)*/}
                                        {/*                     }}*/}
                                        {/*                     >*/}
                                        {/*        </IonDatetime>*/}
                                        {/*    </IonPopover>*/}
                                        {/*</IonItem>*/}
                                        <div className={'ion-margin-top'}>
                                            <IonLabel position={'stacked'}>Fecha de emisión:</IonLabel>
                                            {
                                                this.state.loaded ?
                                                    <NPDatePicker onDateChanged={(date:any) => {
                                                        this.setIssueDate(date.toDate());
                                                    }}
                                                                  defaultValue={
                                                                      this.state.isEdited ?
                                                                          dayjs(this.state.issueDate).format('MM-DD-YYYY')
                                                                          : dayjs().format('MM-DD-YYYY')
                                                                  }
                                                    ></NPDatePicker>
                                                     : null
                                            }

                                        </div>
                                        <IonLabel  className={'ion-margin-top'}>
                                            <IonText color={"danger"}>
                                                <b>NOTA: </b>Asegúrate de que coincidan los datos de la receta con la imagen que tomes. Tu receta será previamente validada por nuestro equipo y tendrás que entregar la receta original al operador.
                                            </IonText>
                                        </IonLabel>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            {/*Upload ID component*/}
                            {
                                cartItem.medicationGroup === 'R1' ?
                                    <IdUpload
                                        onBackImageSelected={(data:any) => {this.onBackImageSelected(data)}}
                                        onDocumentTypeSelected={(data:any) => {this.onDocumentTypeSelected(data)}}
                                        onFrontImageSelected={(data:any) => {this.onFrontImageSelected(data)}}
                                        cartItem={cartItem}
                                    ></IdUpload>
                                    : null
                            }
                        </IonRow>
                    </IonGrid>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButtons>
                            {this.renderAddButton()}
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        )
    }

    private onBackImageSelected(data: any) {
        const sourcePhotoId = data.sourcePhotoId || {};

        this.setState({
            backImage: data.image,
            documentType: data.documentType,
            copyOfDocumentId:  sourcePhotoId._id
        });
    }

    private onDocumentTypeSelected(data: any) {
        this.setState({
            documentType: data.documentType
        });
    }

    private onFrontImageSelected(data: any) {
        const sourcePhotoId = data.sourcePhotoId || {};
        this.setState({
            frontImage: data.image,
            documentType: data.documentType,
            copyOfDocumentId: sourcePhotoId._id
        });
    }
}

export default withIonLifeCycle(Prescription);
