/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2022
 */

import {IonButton, IonSpinner, isPlatform, useIonAlert} from "@ionic/react";
import {ProductType} from "../../lib/data_types/dataTypes";
import Cart from "../../lib/cart/Cart";
import {useHistory} from "react-router-dom";
import React, {useContext, useState} from "react";
import ReactResizeDetector from 'react-resize-detector';
import {AppContext} from "../../App";

//style
import "../../css/components/BuyItNowButton.css";
// Buy now forwarder
import buyNowForwarder from "../../lib/buy-now-forwarder/buy-now-forwarder";
interface BuyItNowButtonProps{
    product: ProductType;
    onButtonClick?: any;
    adId?:string
    classBtn?: string;
}

const BuyItNowButton: React.FC<BuyItNowButtonProps> = ({
                                                           product,onButtonClick = () =>{},
                                                           adId='',
                                                           classBtn=''
                                                           },) => {
    let history = useHistory();
    let [extraPadding,setExtraPadding] = useState(false);
    const {user, setUser, isAuth, setIsAuth} = useContext(AppContext);
    const [isLoading,setIsLoading] = useState(false);
    const [presentAlert] = useIonAlert();
    const goToStore = () =>{
        const isIos = isPlatform('ios');
        window.open(isIos ? 'https://apps.apple.com/us/app/nbio/id1605823846' : 'https://play.google.com/store/apps/details?id=mx.nbio&hl=es_MX&gl=US');
    }
    const askDownloadApp = () =>{
        const isIos = isPlatform('ios');
        presentAlert({
            header: 'Descarga nuestra app',
            message:
                `Para una mejor experiencia, descarga nuestra app`,
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                },
                {
                    text: isIos ? 'Ir a la App Store' : 'Ir a Google Play',
                    role: 'confirm',
                    handler: () => {
                        goToStore();
                    },
                },
            ],
        })
    }
    const GoToCart = () =>{
        history.push('/carrito');
    }
    const GoToCheckout = () =>{
        history.push('/confirmar-pedido');
    }
    const goToBuyNowFwd = async () =>{
        const buyNowUrl = await buyNowForwarder.buyNowForwarder();
        if(buyNowUrl){
            history.push(buyNowUrl);
        }else{
            // TODO: Handle error
        }
    }
    const onClick = (e : any) => {
        e.stopPropagation();
        if(product.isAd){
            // GO TO AD
            onButtonClick(product)
        }else{
            setIsLoading(true);
            Cart.addProduct(product._id,1,true,adId).then(res => {
                try{
                    // @ts-ignore
                    fbq('track', 'AddToCart');
                }catch(ex){

                }
                //google tracking
                try{
                    // @ts-ignore
                    gtag("event", "add_to_cart", {
                        currency: "MXN",
                        value: product.price,
                        items: [
                            {
                                item_id: product._id,
                                item_name: product.name,
                                item_brand: product.brandName || '',
                                item_category: product.categories ? product.categories[0] : '',
                                price: product.price,
                                quantity: 1
                            }
                        ]
                    });
                }catch(ex){

                }
                //Redirect to checkout, if the cart doesn't contain a controlled product
                goToBuyNowFwd();
                // Cart.getCart().then(res => {
                //     setIsLoading(false);
                //     const cartItems = res.data.cart.items;
                //     let isPrescriptionRequired = true;
                //
                //     isPrescriptionRequired = cartItems.some( (item: any) => {
                //         if(item.isControlled){
                //             return !item.prescription;
                //         }
                //         return false
                //     })
                //
                //     if(!isAuth){
                //         GoToCart();
                //     }
                //     else if(isPrescriptionRequired){
                //         GoToCart();
                //     }else{
                //         GoToCheckout();
                //     }
                // })
            }).catch((ex) =>{
                if(ex === 'Unsupported web view'){
                    askDownloadApp();
                }
                setIsLoading(false);
            })
        }

    }
    const handleWidth = (w:any) =>{
            setExtraPadding(w < 142.5);
    }
    return (
        <IonButton className={`${classBtn} ion-text-uppercase ion-text-wrap ${extraPadding ? 'buy-it-now-btn' : ''}`}
                   size="small"
                   expand={"block"}
                   color={product.isAd ? "primary" : "danger"}
                   onClick={(e) =>{onClick(e)}}
                   disabled={!product._id || isLoading}
        >
            <ReactResizeDetector handleWidth  onResize={(w) => handleWidth(w)}>
            </ReactResizeDetector>
            {
                isLoading ?
                    <IonSpinner name={'dots'}></IonSpinner>
                :
                product.isAd ? 'VER MÁS' : 'COMPRAR AHORA'
            }
        </IonButton>
    );
};

export default BuyItNowButton;
