/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * February 2021
 */


import React from "react";
import {IonText} from "@ionic/react";

interface OrderPaymentMethodProps {
    paymentMethod: string;
}

const OrderPaymentMethod: React.FC<OrderPaymentMethodProps> = ({paymentMethod}) => {
    let method = '';

    switch (paymentMethod) {
        case 'card': {
            method = 'Tarjeta de crédito/debito';
            break;
        }
        case 'cash': {
            method = 'Efectivo';
            break;
        }
        case 'pos': {
            method = 'Terminal';
            break;
        }
        case 'mercado-pago': {
            method = 'Mercado pago';
            break;
        }
        default: {
            method = '---';
            break;
        }
    }

    return <IonText>{method}</IonText>;
};

export default OrderPaymentMethod;
