/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import React from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    withIonLifeCycle,
    IonButton,
    IonIcon, IonMenu, IonList, IonItem, IonChip, IonCol, IonRow, IonNote
} from '@ionic/react';
import { RouteComponentProps} from "react-router";
import {filter, home, hourglass, key, receipt, refreshOutline, search} from "ionicons/icons";

//components
import ProductList from "../../components/products/ProductList";
import {
    ActiveFilterType, BrandType,
    filterTagDataType,
    filterTagObjectType,
    ProductType
} from "../../lib/data_types/dataTypes";
import NbioApi from "../../lib/api/NbioApi";
import WebHeader from "../../components/ui/WebHeader";
import {setAppTitle} from "../../lib/app-utils/app-utils";

interface BrandProps
    extends RouteComponentProps<{
        id: string;

    }> {
};

interface BrandState  {
    products:ProductType[],
    brand:BrandType,
    activeFilters:ActiveFilterType,
    isLoading:boolean,
    currentPage:number,
    endReached:boolean,
    userNavigatedForward:boolean,
    brandNotFound:boolean;
    brandErrored:boolean;
}
class Brand extends React.Component<BrandProps,BrandState>{
    private searchMenu: any;
    constructor(props: BrandProps) {
        super(props);
        this.state = {
            products: [],
            brand:{
                _id:'-1',
                handleUrl:'',
                name:'',
                filterTags:{

                }
            },
            activeFilters:{},
            isLoading:true,
            currentPage:1,
            endReached:false,
            userNavigatedForward:false,
            brandNotFound:false,
            brandErrored:false
        }
    }
    goToProduct(p:any){
        this.setState({
            userNavigatedForward:true
        },() =>{
            // this.props.history.push(`/productos/${p.sku}`);
        });
    }
    ionViewDidEnter(){
        const handleUrl = this.props.match.params.id;
        const stateHandleUrl = this.state.brand.handleUrl
        const handleUrlChanged = handleUrl !== stateHandleUrl;
        if(!this.state.userNavigatedForward || handleUrlChanged ){
            this.setState({products:[]}, () =>{
                this.loadBrand();
            });
        }

    }
    isSelected(filterName:string, filter:filterTagDataType){
        if(this.state.activeFilters.hasOwnProperty(filterName)){
            const isInArray = this.state.activeFilters[filterName].find((f) =>{
                return f.label === filter.label && f.value === filter.value
            })
            return !!isInArray;
        }else{
            return false;
        }
    }
    onFilterSelected(filterName:string, filterTag:filterTagObjectType, filter:filterTagDataType){
        let newFilters = {...this.state.activeFilters}
        if(this.state.activeFilters.hasOwnProperty(filterName)){
            // append it or remove it
            const foundFilter = this.state.activeFilters[filterName]
                .find((f) => f.label === filter.label && f.value === filter.value);
            if(foundFilter){
                    // remove it
                    newFilters[filterName] = this.state.activeFilters[filterName]
                        .filter((f) => f.label !== filter.label && f.value !== filter.value);
            }else{
                // append it
                newFilters[filterName].push(filter);
            }
        }else{
            // create it
            newFilters[filterName] = [
                filter
            ]
        }

        this.setState({activeFilters:newFilters}, () =>{
            this.loadWithFilters()
        })

    }
    loadWithFilters(){
        this.setState({
            isLoading:true,
            products:[]
        }, () =>{
            this.onLoadNewPage(1).then((res) =>{
               this.setState({
                   isLoading:false
               })
            })
        })
    }
    renderLoading() {
        if (this.state.isLoading ) {
            return (
                <div className={'ion-padding ion-text-center'}>
                    {'Cargando...'}
                    <br/>
                    <br/>
                    <IonIcon icon={hourglass } color={'medium'} size={'large'} slot={'icon-only'}></IonIcon>
                </div>
            )
        } else {
            return null;
        }

    }
    renderFilterType(filter: filterTagObjectType,filterName:string){
        if(filter.type === 'string'){
            // return a list of strings
            return (
                <IonRow>
                    <IonCol>
                        {
                            filter.data.length === 0 ?
                                <IonChip color={'primary'}>Todo</IonChip> : null
                        }
                        {
                            filter.data.map((d,i) =>{
                                return (
                                        <IonChip key={`${i}_${d.label}`} color={this.isSelected(filterName,d) ? 'primary' : ''}  onClick={() =>{
                                            this.onFilterSelected(filterName,filter,d);
                                        }}
                                        className={'ion-text-wrap ion-text-capitalize'} >{d.label}</IonChip>
                                )
                            })
                        }

                    </IonCol>
                </IonRow>
            )
        }
    }
    renderMenu(){
        const brand = this.state.brand;
        const filterTags = brand.filterTags || {};
        const keys = Object.keys(filterTags!);

        return(
            <IonMenu side="start" contentId={'category-page'} ref={(ref) => this.searchMenu = ref}>
                <IonHeader>
                    <IonToolbar className={'toolbar-nbio'}>
                        <h3 className={'ion-margin'}>Filtra tu búsqueda</h3>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        {
                            keys.map((k,i) =>{
                                return (
                                    <div key={`${i}_${k}`}>
                                        <IonItem  lines={'none'}>{filterTags[k].locale_es}</IonItem>
                                        {
                                            this.renderFilterType(filterTags[k],k)
                                        }
                                    </div>
                                )
                            })
                        }

                    </IonList>
                </IonContent>
            </IonMenu>

        )
    }
    renderbrandNotFound(){
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }} >
                <IonNote>Categoría no encontrada</IonNote>
                <IonButton fill={'outline'} size={'small'} routerLink={'/inicio'}>
                    <IonIcon icon={home}></IonIcon>
                    &nbsp;Volver al inicio
                </IonButton>
            </div>
        )
    }
    renderbrandErrored(){
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }} onClick={() => {
                this.loadBrand();
            }}>
                <IonNote>¡Algo salió mal!</IonNote>
                <IonButton fill={'outline'} size={'small'}>
                    <IonIcon icon={refreshOutline}></IonIcon>
                    Reintentar
                </IonButton>
            </div>
        )
    }
    render(){
        return (
            <IonPage id={'category-page'}>
                {this.renderMenu()}
                <WebHeader
                           customButton={
                               <IonButton onClick={() => this.openMenu()}>
                                   <IonIcon slot="icon-only" icon={filter}></IonIcon>
                               </IonButton>
                           }
                ></WebHeader>
                <IonContent className="ion-padding-top">
                    <IonHeader className={"ion-margin-bottom ion-no-border"}>
                        <IonTitle className="ion-text-capitalize">{this.state.brand.name}</IonTitle>
                    </IonHeader>
                    {
                        this.state.isLoading ? this.renderLoading() :
                            <ProductList products={this.state.products}
                                         onClick={(product:any) =>{
                                             this.goToProduct(product)
                                         }}
                                         isInfiniteContentDisabled={this.state.endReached}
                                         loadOnEnter={false}
                                         onLoadNewPage={ async (pageNumber:number) => {
                                             if(!this.state.userNavigatedForward){
                                                 return this.onLoadNewPage(pageNumber)
                                             }
                                         }}
                                        >
                            </ProductList>
                    }
                    {
                        this.state.brandErrored ?
                        this.renderbrandErrored() : null
                    }
                    {
                        this.state.brandNotFound ?
                        this.renderbrandNotFound() : null
                    }
                </IonContent>
            </IonPage>
        )
    }
    async loadBrand(){ // initial call
        return new Promise((resolve,reject) =>{
            let brandId = this.props.match.params.id;
            return NbioApi.brands.getBrand(brandId,1,20,this.state.activeFilters).then((res) =>{
                this.setState({
                    products:res.data.products,
                    brand:res.data.brand,
                    isLoading:false,
                    endReached: res.data.pages === 1
                }, () =>{
                    resolve(res.data);
                });
                // Set title
                const title = `Farmacia Nbio | ${this.state.brand.name} | Envíos a todo México`;
                setAppTitle(title);
            }).catch((ex) =>{
                this.setState({
                    isLoading:false
                });
                const errorMsg = ex.response?.data?.error_es;
                const errorCode = ex.response?.data?.error_code;
                if(errorCode == 'CATEGORIES_404'){
                    // show error
                    this.setState({brandNotFound:true})
                }else{
                    this.setState({brandErrored:true})
                }
            });
        })
    }
    async onLoadNewPage(pageNumber: number) {
        return new Promise((resolve,reject) =>{
            let brandId = this.props.match.params.id;
            return NbioApi.brands.getBrand(brandId,pageNumber,20,this.state.activeFilters).then((res) =>{
                let newProducts = [...this.state.products,...res.data.products]
                this.setState({
                    products:newProducts,
                    brand:res.data.brand,
                    isLoading:false,
                    currentPage:pageNumber,
                    endReached:pageNumber === res.data.pages
                }, () =>{
                    resolve(res.data);
                });

            }).catch((ex) =>{
               // TODO: HANDLE
            });
        })
    }
    private openMenu() {
        this.searchMenu.toggle();
    }

}

export default withIonLifeCycle(Brand);
