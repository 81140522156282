/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import React from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    withIonLifeCycle,
    IonItem
} from '@ionic/react';
import { RouteComponentProps} from "react-router";
import { Virtuoso } from 'react-virtuoso';
import NbioApi from "../../lib/api/NbioApi";
import {CategoryType} from "../../lib/data_types/dataTypes";
//components
import WebHeader from "../../components/ui/WebHeader";
import WebFooter from "../../components/ui/WebFooter";

interface AllBrandsProps extends RouteComponentProps{}

interface AllBrandsState{
    brands: CategoryType[] ;
    page:number,
    endReached:boolean
}

class AllBrands extends React.Component<AllBrandsProps,AllBrandsState>{
    constructor(props: AllBrandsProps) {
        super(props);
        this.state = {
            brands: [],
            page:1,
            endReached:false
        }
    }
    ionViewDidEnter() {
        this.loadPage();
    }
    loadPage(){
        if(this.state.endReached){
            return null;
        }
        NbioApi.brands.get(this.state.page,10).then(res => {
            const newBrands = [...this.state.brands,...res.data.brands];
            this.setState(
                {
                    brands:newBrands,
                    page:this.state.page+1,
                    endReached: res.data.brands.length === 0
                });
        }).catch((ex) =>{
        })
    }
    loadMoreBrands = () => {
        this.loadPage();
    }
    goToBrand(category:any){
        this.props.history.push(`/marcas/${category.handleUrl}`);
    }
    render(){
        const brandsList = this.state.brands;
        return (
            <IonPage>
                <WebHeader title={'Marcas'}></WebHeader>
                <IonContent className={"ion-padding-top"}>
                    <IonHeader className={"ion-margin-bottom ion-no-border"}>
                        <IonTitle></IonTitle>
                    </IonHeader>
                    <Virtuoso
                        style={{height:'100%'}}
                        data={brandsList}
                        totalCount={brandsList.length}
                        endReached={this.loadMoreBrands}
                        itemContent={(index,brand) =>
                            <IonItem button key={brand._id} detail onClick={() => {this.goToBrand(brand)}}>
                                {brand.name}
                            </IonItem>
                        }
                    />
                    <WebFooter></WebFooter>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(AllBrands);
