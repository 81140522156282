/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2023
 */

import {
    IonButton,
    IonIcon,
    IonLabel, IonRippleEffect, IonText,
    IonToast,
} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router";
import {
    closeOutline
} from "ionicons/icons";
import {AppContext} from "../../App";

//style
import "../../css/components/profilePopup.css";
import {Link} from "react-router-dom";
import {User} from "../../lib/data_types/dataTypes";
import NbioApi from "../../lib/api/NbioApi";
import Cart from "../../lib/cart/Cart";


interface ProfilePopupProps {
    showPopup: boolean;
    onDismiss?: any;
    referenceElement?: string;
    onClickClosePopup?: any;
    isLoading?: boolean
}

const CartPopup: React.FC<ProfilePopupProps> = ({
                                                    showPopup = false,
                                                    onDismiss = () => {
                                                    },
                                                    referenceElement = 'icon-profile',
                                                    onClickClosePopup = () => {
                                                    },
                                                    isLoading = false

                                                }) => {
    const {user, setUser, isAuth, setIsAuth} = useContext(AppContext);
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState('');
    const [left, setLeft] = useState(0);

    useEffect(() => {
        if (showPopup) {
            positionProfilePopup();
        }
    }, [showPopup]);

    useEffect(() => {
        positionProfilePopup();
    }, []);
    const onResize = (ev: any) => {
        // 991 hide
        if (window.innerWidth <= 991) {
            onClickClosePopup();
        }
        positionProfilePopup();
    }
    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);


    const positionProfilePopup = () => {
        const profileIcons = document.getElementsByClassName(referenceElement);
        if(profileIcons.length > 0){
            // @ts-ignore
            const bBox = profileIcons[profileIcons.length - 1].getBoundingClientRect();
            if (bBox) {
                // @ts-ignore
                const bLeft = bBox.right - 304;
                // console.log('cartIcon', cartIcon);
                setLeft(bLeft);
            }
        }

    }
    const logout = async () => {
        const dummyUser: User = {
            name: '',
            last_name: '',
            source: '',
            uid: '',
            email: '',
            roles: ['user'],
            isDisabled: false,
            profileUrl: '',
            profileUrlThumbnail: ''
        };
        NbioApi.users.logout().then((res) => {
            // forget cart
            Cart.forgetCart();
            setUser(dummyUser);
            // Reset localStorage
            localStorage.removeItem('nbio-zipcode-verified');

            window.location.replace(`/inicio`);
            setIsAuth(false);
        }).catch((ex) => {
            window.location.replace(`/inicio`);
        })
    }
    const renderProfile = () => {
        if (!isAuth) {
            return (
                <div className={'nbio-menu-profile'}>
                    <Link to={'/perfil'} onClick={() => {
                        onClickClosePopup();
                    }}>
                        <div
                            className={'ion-activatable ripple-parent border-radius-100 nbio-menu-thumbnail-wrapper cursor-pointer'}
                        >
                            <div className={'nbio-menu-thumbnail'}
                                 style={{
                                     backgroundImage: `url(https://nbio.blob.core.windows.net/cdn/nbio-default-profile.png)`,
                                     backgroundSize: 'cover',
                                     backgroundColor: 'white'
                                 }}>

                            </div>
                            <IonRippleEffect></IonRippleEffect>
                        </div>
                    </Link>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: '10px',
                        marginLeft: '1rem'
                    }}>
                        <IonButton fill={'outline'} size={'small'} routerLink={'/iniciar-sesion'} onClick={() => {
                            onClickClosePopup();
                        }}>
                            &nbsp;INICIAR SESIÓN
                        </IonButton>
                    </div>
                </div>
            )
        }
        const profileUrl = user.profileUrl || 'https://nbio.blob.core.windows.net/cdn/nbio-default-profile.png';
        return (
            <div className={'nbio-menu-profile'}>
                <div
                    className={'ion-activatable ripple-parent border-radius-100 nbio-menu-thumbnail-wrapper cursor-pointer'}
                    onClick={() => {
                        onClickClosePopup();
                    }}
                >
                    <div className={'nbio-menu-thumbnail'}
                         style={{
                             backgroundImage: `url(${profileUrl})`,
                             backgroundSize: 'cover'
                         }}>

                    </div>
                    <IonRippleEffect></IonRippleEffect>
                </div>
                <div className={'nbio-menu-profile-content'}>
                    <div className={'ion-text-capitalize ion-text-wrap'}>
                        <b>{user.name} {user.last_name}</b>
                    </div>
                    <div>
                            <span className={'cursor-pointer ion-activatable ripple-parent'}>
                                <Link to={'/perfil'} onClick={() => {
                                    onClickClosePopup();
                                }}>
                                    <IonRippleEffect>
                                    </IonRippleEffect>
                                    Perfil
                                </Link>
                            </span>
                        &nbsp;|&nbsp;
                        <span className={'cursor-pointer ion-activatable ripple-parent'}>
                            <Link to={'/ordenes'} onClick={() => {
                                onClickClosePopup();
                            }}>
                                <IonRippleEffect>
                                </IonRippleEffect>
                                Órdenes
                            </Link>
                            </span>
                    </div>

                </div>
            </div>

        )
    }
    const onClickAddress = () => {
        history.push({
            pathname: '/direcciones',
            state: {
                goBackTo: '/direcciones',
            }
        });
    }
    const onClickInvoicing = () => {
        history.push({
            pathname: '/datos-de-facturacion',
            state: {
                onSavePath: '/datos-de-facturacion',
            }
        });
    }
    const renderOptions = () => {
        if(!isAuth){
            return null;
        }
        return (
            <div>
                <Link to={`/perfil/editar`}>
                    <div className={'profile-popup-item ion-activatable ripple-parent'}
                         onClick={() => onClickClosePopup()}>
                        <IonRippleEffect>
                        </IonRippleEffect>
                        <IonText>
                            Editar perfil
                        </IonText>
                    </div>
                </Link>
                <Link to={`/perfil/actualizar-contrasena`}>
                    <div className={'profile-popup-item ion-activatable ripple-parent'}
                         onClick={() => onClickClosePopup()}>
                        <IonRippleEffect>
                        </IonRippleEffect>
                        <IonText>
                            Cambiar contraseña
                        </IonText>
                    </div>
                </Link>
                <div className={'profile-popup-item ion-activatable ripple-parent'}
                     onClick={() => {
                         onClickClosePopup();
                         onClickAddress();
                     }}>
                    <IonRippleEffect>
                    </IonRippleEffect>
                    <IonText>
                        Direcciones
                    </IonText>
                </div>
                <Link to={`/metodos-de-pago`}>
                    <div className={'profile-popup-item ion-activatable ripple-parent'}
                         onClick={() => onClickClosePopup()}>
                        <IonRippleEffect>
                        </IonRippleEffect>
                        <IonText>
                            Métodos de pago
                        </IonText>
                    </div>
                </Link>
                    <div className={'profile-popup-item ion-activatable ripple-parent'}
                         onClick={() => {
                             onClickClosePopup();
                             onClickInvoicing();
                         }}>
                        <IonRippleEffect>
                        </IonRippleEffect>
                        <IonText>
                            Datos de facturación
                        </IonText>
                    </div>
                <Link to={`/cupones`}>
                    <div className={'profile-popup-item ion-activatable ripple-parent'}
                         onClick={() => onClickClosePopup()}>
                        <IonRippleEffect>
                        </IonRippleEffect>
                        <IonText>
                            Cupones
                        </IonText>
                    </div>
                </Link>
                <div className={'profile-popup-item ion-activatable ripple-parent'}
                     onClick={() => {
                         onClickClosePopup();
                         logout();
                     }}>
                    <IonRippleEffect>
                    </IonRippleEffect>
                    <IonText color={'danger'}>
                        Cerrar sesión
                    </IonText>
                </div>
            </div>

        );
    }
    return (
        <div className={showPopup ? 'profile-popup-wrapper-open' : 'profile-popup-wrapper-closed'}
             onClick={() => onClickClosePopup()}>
            <IonToast isOpen={showToast}
                      onDidDismiss={() => {
                          setShowToast(false);
                          setToastMsg('');
                      }}
                      message={toastMsg}
                      duration={2000}/>
            {
                showPopup ?
                    <div className={"profile-popup ion-padding"} style={{left: left}}
                         onClick={(ev) => (ev.stopPropagation())}>
                        <div className={'profile-popup-header'}>
                            <IonLabel></IonLabel>
                            <IonButton fill={'clear'}
                                       color={'medium'}
                                       size={'small'}
                                       onClick={() => onClickClosePopup()}>
                                <IonIcon icon={closeOutline}></IonIcon>
                            </IonButton>
                        </div>
                        <div className={"profile-content"}>
                            {renderProfile()}
                            {renderOptions()}
                            <Link to={`/informacion`}>
                                <div className={'profile-popup-item ion-activatable ripple-parent'}
                                     onClick={() => onClickClosePopup()}>
                                    <IonRippleEffect>
                                    </IonRippleEffect>
                                    <IonText>
                                        Acerca de Nbio
                                    </IonText>
                                </div>
                            </Link>
                        </div>
                        <div>
                            {/*    Need Help?*/}
                            <IonText className={'nbio-menu-subtitle'}>
                                <h5 className={'ion-text-capitalize'}>
                                    ¿Necesitas Ayuda?
                                </h5>
                            </IonText>
                            <IonText className={'display-block nbio-menu-need-help-text'}>
                                <a href={'tel:+525641674170'} target={'_blank'}>
                                    ¿No encontraste algún producto? ¿Tuviste algún problema? Llama a nuestra línea de
                                    atención a
                                    clientes <b>Aquí.</b>
                                </a>

                            </IonText>
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}
export default CartPopup;

