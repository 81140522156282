/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2023
 */


import React from "react";
import {
    IonButton,
    IonButtons, IonCard, IonCardContent, IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon, IonModal,
    IonPage,
    IonRow, IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import {CouponType} from "../../lib/data_types/dataTypes";
import {chevronBack} from "ionicons/icons";
import dayjs from "dayjs";
import utc   from "dayjs/plugin/utc";
import "dayjs/locale/es";

//style
import "../../css/components/coupon.css";

//components
import GradientDivider from "../../components/ui/GradientDivider";
import WebHeader from "../../components/ui/WebHeader";

//dates
dayjs.extend(utc);

interface CouponTermsAndConditionsModalProps{
    coupon: CouponType;
    isModalOpen: boolean;
    onClickCloseModal: any
}



const CouponTermsAndConditionsModal:React.FC<CouponTermsAndConditionsModalProps> = ({
                                                                                        coupon,
                                                                                        isModalOpen =false,
                                                                                        onClickCloseModal = () =>{}
                                                                                    }) => {

    const renderTitle = () => {
        if(coupon.type === 'free_shipping'){
            return 'Envio gratis'
        }

        return `${coupon.value} ${coupon.discountType === 'fixed_amount' ? 'MXN' : '%'} OFF`;
    }

    const renderTxt = () => {
        if(coupon.type === 'free_shipping'){
            return 'El descuento equivalente al valor del envio'
        }

        if(coupon.type === 'item'){
            return `Descuento de ${coupon.value} ${coupon.discountType === 'fixed_amount' ? 'MXN' : '%'} en productos seleccionados`
        }

        if(coupon.type === 'total'){
            return `Descuento en el total de la compra de ${coupon.value} ${coupon.discountType === 'fixed_amount' ? 'MXN' : '%'}`
        }
    }

    const renderAppliesProductos = () => {
        let txt = 'Aplica a todos los productos';

        if(coupon.products.length > 0 || coupon.categories.length > 0){
            return 'Aplica a productos seleccionados'
        }
        if(coupon.appliesToAllProducts || coupon.appliesToAllCategories){
            return txt
        }

        return txt;
    }

    const renderMinPurchase = () => {
        let txt = 'No hay mínimo de compra'
        if(coupon.hasMinimumPurchase){
            return `Mínimo de compra ${coupon.minimumPurchase} mxn`
        }
        return txt
    }

    const renderMaxPurchase = () => {
        let txt = 'No hay límite de compra';
        if(coupon.hasMaximumPurchase){
            return  `Límite de compra de ${coupon.maximumPurchase} mxn`
        }
        return txt
    }

    const renderRedeemsPerUser = () => {
        let txt = 'Limitado a un cupón por usuario. Se canjea por orden de llegada';
        if(coupon.maxRedeemsPerUser !== 1){
            return `Limitado a ${coupon.maxRedeemsPerUser} cupones por usuario. Se canjea por orden de llegada`;
        }
        return txt
    }

    const renderMaxRedeems = () => {
        let txt = 'Cupones limitados. Se canjea por orden de llegada';
        if(coupon.hasMaxRedeems){
            return `Cupones limitados a ${coupon.maxRedeems}. Se canjea por orden de llegada`;
        }
        return txt
    }

    const expirationDate = coupon.endDate ? dayjs(coupon.endDate).format('DD/MM/YYYY') : 'agotar existencias';
    const startDate = coupon.startDate ? dayjs(coupon.startDate).format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY');

    return (
        <IonModal isOpen={isModalOpen}>
            <IonPage>
                <IonHeader>
                    <IonToolbar className={"toolbar-nbio"}>
                        <IonButtons slot={'start'}>
                            <IonButton onClick={(e) => {
                                e.stopPropagation();
                                onClickCloseModal()
                            }}>
                                <IonIcon icon={chevronBack}></IonIcon>
                            </IonButton>
                        </IonButtons>
                        <IonTitle >T&C</IonTitle>
                    </IonToolbar>
                    <GradientDivider></GradientDivider>
                </IonHeader>
                <IonContent fullscreen>
                    <IonGrid className={''}>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size-md="6">
                                <div className={'coupon small ion-margin-bottom'}>
                                    <div className={'coupon-body'}>
                                        <div className={'coupon-title'}>{renderTitle()}</div>
                                    </div>
                                    <div className={'coupon-footer'}>
                                        <div><small>Código del cupón: {coupon.code}</small></div>
                                    </div>
                                    <div className="circle1"></div>
                                    <div className="circle2"></div>
                                </div>

                                <IonCard className={'ion-no-margin ion-margin-bottom'}>
                                    <IonCardContent>
                                        <IonCardTitle>Recompensa</IonCardTitle>
                                        <IonText>
                                            {renderTxt()}
                                        </IonText>
                                    </IonCardContent>
                                </IonCard>

                                <IonCard className={'ion-no-margin ion-margin-bottom'}>
                                    <IonCardContent>
                                        <IonCardTitle>Plazo válido</IonCardTitle>
                                        <IonText>
                                            Válido desde {startDate} hasta {expirationDate}
                                        </IonText>
                                    </IonCardContent>
                                </IonCard>

                                <IonCard className={'ion-no-margin ion-margin-bottom'}>
                                    <IonCardContent>
                                        <IonCardTitle>Categorías y productos</IonCardTitle>
                                        <IonText>
                                            {renderAppliesProductos()}
                                        </IonText>
                                    </IonCardContent>
                                </IonCard>

                                <IonCard className={'ion-no-margin'}>
                                    <IonCardContent>
                                        <IonCardTitle>Restricciones</IonCardTitle>
                                        <IonText className={'ion-text-wrap'}>
                                            <ul style={{paddingRight:'1rem'}}>
                                                <li>{renderMinPurchase()}</li>
                                                <li>{renderMaxPurchase()}</li>
                                                <li>{renderRedeemsPerUser()}</li>
                                                <li>{renderMaxRedeems()}</li>
                                                {coupon.canBeUsedWithDiscounts ? <li>Aplica con otros descuentos</li> : null}
                                                {coupon.canBeUsedWithOtherCoupons ? <li>Aplica con otros cupones</li> : null}
                                                <li>No es acumulable</li>
                                                <li>Hasta agotar existencias</li>
                                            </ul>
                                        </IonText>
                                    </IonCardContent>
                                </IonCard>

                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        </IonModal>
    );
}

export default CouponTermsAndConditionsModal;
