/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import React, {useContext, useEffect, useState} from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonRefresher,
    IonRefresherContent,
    IonLabel,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonSkeletonText
} from '@ionic/react';
import NbioApi from "../../lib/api/NbioApi";
import {Virtuoso} from "react-virtuoso";
import {OrderType} from "../../lib/data_types/dataTypes";
import {AppContext} from "../../App";


//components
import BtnToolbarLogo from "../../components/ui/BtnToolbarLogo";
import GradientDivider from "../../components/ui/GradientDivider";
import OrderListItem from "../../components/orders/OrderListItem";
import WebHeader from "../../components/ui/WebHeader";
import UserLoginInvitation from "../../components/ui/UserLoginInvitation";


const TabOrders: React.FC = () => {
    const {user, setUser, isAuth, setIsAuth} = useContext(AppContext);
    const [page, setPage] = useState(1);
    const [pageFinished, setPageFinished] = useState(1);
    const [orders, setOrders] = useState([]);
    const [finishedOrders, setFinishedOrders] = useState([]);
    const [renderComponent, setRenderComponent] = useState('LOADING');
    const [selectedTab, setSelectedTab] = useState('ORDERS');
    const [endOrdersReached, setEndOrdersReached] = useState(false);
    const [endFinishedOrdersReached, setEndFinishedOrdersReached] = useState(false);

    // on isAuth changed
    useEffect(() => {
        if (isAuth) {
            setRenderComponent('LOADING');
            setPage(1);
            setPageFinished(1);
            setEndFinishedOrdersReached(false);
            setEndOrdersReached(false);
            setOrders([]);
            setFinishedOrders([]);
            loadMoreOrders();
            loadMoreFinishedOrders();
        } else {
            setRenderComponent('LOGIN_INVITATION');
        }
    }, [isAuth]);
    // IN=PROGRESS======================================================================================================
    // on Page Changed (When page changes)
    useEffect(() => {
        if (page === -1) {
            setPage(1);
        } else {
            if(isAuth){
                loadMoreOrders(false);
            }
        }
    }, [page])

    // on orders changed  (When data changes)
    useEffect(() => {
        if (orders.length === 0 && !endOrdersReached) {
            setPage(-1);
            setEndOrdersReached(false);
        }
    }, [orders])
    // IN=PROGRESS=ENDS=================================================================================================
    // =================================================================================================================

    // =================================================================================================================
    // FINISHED=========================================================================================================

    // on Page Finished Changed (When page changes)
    useEffect(() => {
        if (pageFinished === -1) {
            setPageFinished(1);
        } else {
            if(isAuth){
                loadMoreFinishedOrders(false);
            }

        }
    }, [pageFinished])

    // on orders changed  (When data changes)
    useEffect(() => {
        if (finishedOrders.length === 0 && !endFinishedOrdersReached) {
            setPageFinished(-1);
        }
    }, [finishedOrders])
    // FINISHED=ENDS====================================================================================================

    const loadMoreOrders = (showLoader = true) => {
        const renderC = showLoader ? 'LOADING' : 'ORDERS';
        const _orders = showLoader ? [] : orders;

        return new Promise((resolve, reject) => {
            if (endOrdersReached) {
                return resolve(null);
            }
            setOrders(_orders);
            setRenderComponent(renderC);
            NbioApi.orders.getMyOrders(page, 10, 'pending').then(res => {
                const newOrders: any = [...orders, ...res.data.orders];
                setOrders(newOrders);
                setRenderComponent(selectedTab);
                setEndOrdersReached(res.data.orders.length === 0);
                resolve(null);
            }).catch((ex) => {
                reject(null);
            });
        })
    }

    const loadMoreFinishedOrders = (showLoader = true) => {
        const renderC = showLoader ? 'LOADING' : 'FINISHED';
        const _finishedOrders = showLoader ? [] : finishedOrders;

        return new Promise((resolve, reject) => {
            if (endFinishedOrdersReached) {
                return resolve(null);
            }
            setRenderComponent(renderC);
            setFinishedOrders(_finishedOrders);
            NbioApi.orders.getMyOrders(pageFinished, 10, 'finished').then(res => {
                const newOrders: any = [...finishedOrders, ...res.data.orders];
                setFinishedOrders(newOrders);
                setRenderComponent(selectedTab);
                setEndFinishedOrdersReached(res.data.orders.length === 0);
                resolve(null);
            }).catch((ex) => {
                reject(null)
            })
        })
    }

    const renderEmptyList = (orderComponent: string, ordersLenght: number) => {
        if (orderComponent === 'ORDERS' && ordersLenght === 0) {
            return (
                <div className={'ion-padding ion-text-center'}>
                    <div className={'ion-padding'}>Aún no tienes órdenes. Da un vistazo a nuestros productos.</div>
                    <br/>
                    <IonButton className={'ion-text-uppercase'}
                               routerDirection="forward"
                               routerLink='/inicio'>VER PRODUCTOS</IonButton>
                </div>
            )
        } else if (orderComponent === 'FINISHED' && ordersLenght === 0) {
            return (
                <div className={'ion-padding ion-text-center'}>
                    <div className={'ion-padding'}>Aún no tienes órdenes finalizadas</div>
                </div>
            )
        } else {
            return null
        }
    }

    const renderComponentContent = () => {
        switch (renderComponent) {
            case 'ORDERS': {
                return renderOrders();
            }
            case 'FINISHED': {
                return renderFinishedOrders();
            }
            case 'LOADING': {
                return renderLoader();
            }
            case 'LOGIN_INVITATION': {
                return <UserLoginInvitation></UserLoginInvitation>
            }
            default: {
                return renderLoader();
            }
        }
    }

    const renderOrders = () => {
        return (
            <>
                <IonRefresher slot="fixed" onIonRefresh={(ev: any) => {
                    setOrders([]);
                    setEndOrdersReached(false);
                    ev.detail.complete();
                }}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                {renderEmptyList('ORDERS', orders.length)}
                <Virtuoso
                    style={{height: '100%'}}
                    data={orders}
                    totalCount={orders.length}
                    endReached={() => setPage(page + 1)}
                    footer={() => {
                        return (
                            <div
                                style={{
                                    padding: '1rem',
                                    textAlign: 'center',
                                }}
                            >
                                {
                                    endOrdersReached ?
                                        orders.length === 0 ? '' : 'No tienes más órdenes'
                                        :
                                        'Cargando...'
                                }
                            </div>
                        )
                    }
                    }
                    itemContent={(index, order: OrderType) => {
                        return (<OrderListItem order={order}></OrderListItem>)
                    }
                    }>
                </Virtuoso>
            </>
        )
    }

    const renderFinishedOrders = () => {
        return (
            <>
                <IonRefresher slot="fixed" onIonRefresh={(ev: any) => {
                    setFinishedOrders([]);
                    setEndFinishedOrdersReached(false);
                    ev.detail.complete();
                }}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                {renderEmptyList('FINISHED', finishedOrders.length)}
                <Virtuoso
                    style={{height: '100%'}}
                    data={finishedOrders}
                    totalCount={finishedOrders.length}
                    endReached={() => setPageFinished(pageFinished + 1)}
                    footer={() => {
                        return (
                            <div
                                style={{
                                    padding: '1rem',
                                    textAlign: 'center',
                                }}
                            >
                                {
                                    endFinishedOrdersReached ?
                                        finishedOrders.length === 0 ? '' : 'No tienes más órdenes'
                                        :
                                        'Cargando...'
                                }
                            </div>
                        )
                    }
                    }
                    itemContent={(index, order: OrderType) => {
                        return (<OrderListItem order={order}></OrderListItem>)
                    }
                    }>
                </Virtuoso>
            </>
        )
    }

    const renderLoader = () => {
        return (
            <IonSkeletonText animated className={'h-100'}/>
        )
    }

    return (
        <IonPage>
            <WebHeader title={'Órdenes'}></WebHeader>
            <IonHeader>
                {
                    isAuth ?
                        <IonSegment onIonChange={(e) => {
                            setRenderComponent(e.detail.value!);
                            setSelectedTab(e.detail.value!)
                        }}
                                    value={renderComponent}>
                            <IonSegmentButton value="ORDERS">
                                <IonLabel>En proceso</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="FINISHED">
                                <IonLabel>Finalizadas</IonLabel>
                            </IonSegmentButton>
                        </IonSegment> : null
                }
            </IonHeader>
            <IonContent>
                {renderComponentContent()}
            </IonContent>
        </IonPage>
    );

};

export default TabOrders;
