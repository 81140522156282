/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2022
 */


import React, {useState} from "react";
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    IonInput, IonToast, IonSpinner, IonLabel, IonText,
} from "@ionic/react";
import {CouponType} from "../../lib/data_types/dataTypes";
import NbioApi from "../../lib/api/NbioApi";
import {ticket, chevronBack} from "ionicons/icons";
import Coupon from "./Coupon";

interface CouponsComponentProps{
    coupons: CouponType[];
    onClickClose?: any;
    onCouponClicked?: any;
}


const CouponsComponent: React.FC<CouponsComponentProps> = ({coupons=[],
                                                               onClickClose = () => {},
                                                               onCouponClicked =() =>{}  }) => {
    const [validatingCoupon, setValidatingCoupon] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState('');
    const [couponCode, setCouponCode] = useState('');


    const validateCoupon = () => {
        //validating coupon code
        if(couponCode.trim() === ''){
            setToastMsg('Ingresa el código del cupón');
            setShowToast(true);
            setValidatingCoupon(false);
        }else{
            NbioApi.coupons.validateCoupon(couponCode).then(res => {
                setValidatingCoupon(false);
                onCouponClicked(res.data.coupon._id);
            }).catch(ex => {
                setValidatingCoupon(false);
                let errorMessage = 'No podemos canjear tu cupón en este momento.';
                try {
                    errorMessage = ex.response.data.error_es;
                } catch (ex) {
                }
                setToastMsg(errorMessage);
                setShowToast(true);
            })
        }
    }

    const renderCoupons = () => {
        if(coupons.length > 0){
            return(
                coupons.map((coupon) => {
                    return <Coupon key={coupon._id} coupon={coupon}
                                   onClickCoupon={(couponId: string) => onCouponClicked(couponId)} />
                })
            )
        }else{
            return (
                <div className={'ion-text-center h-100 flex-col ion-justify-content-center ion-align-items-center'}>
                    <div className={'ion-margin-bottom'}> No tienes cupones en este momento. <br/>
                        ¡Descubre cupones en farmacias participantes!
                    </div>
                    <IonIcon icon={ticket} size={'large'} slot={'icon-only'}></IonIcon>
                </div>
            )
        }
    }

    return (
        <IonPage>
            <IonToast isOpen={showToast}
                      onDidDismiss={() => {setShowToast(false);setToastMsg('')}}
                      message={toastMsg}
                      duration={4000}/>
            <IonHeader translucent>
                <IonToolbar>
                    <IonButtons slot={'start'}>
                        <IonButton onClick={() => onClickClose()}>
                            <IonIcon icon={chevronBack}></IonIcon>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>
                        Cupones
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className={'ion-padding'}>
                {renderCoupons()}
            </IonContent>
            <IonFooter className={'ion-no-border'}>
                <IonToolbar  className={'ion-no-padding'}>
                    <IonGrid className={'ion-no-padding'}>
                        <IonRow className={'ion-justify-content-center ion-no-padding'}>
                            <IonCol className={"flex-row padding-bottom-half"}>
                                <IonToolbar className={'ion-no-padding'}>
                                    <IonInput slot={''}
                                              value={couponCode}
                                              placeholder={'¿Tienes un código? Escríbelo aquí'}
                                              onIonChange={(e) => setCouponCode(e.detail.value!)}
                                    >
                                    </IonInput>
                                    <IonButtons slot={'end'}>
                                        <IonButton fill={'outline'}
                                                   color={'primary'}
                                                   disabled={validatingCoupon}
                                                   onClick={() => {
                                                       setValidatingCoupon(true);
                                                       validateCoupon()
                                                    }}>
                                            {
                                                validatingCoupon ? <IonSpinner/> : 'Canjear'
                                            }
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    );
};

export default CouponsComponent;
