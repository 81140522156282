/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * May 2022
 */

import {IonCard,IonCardContent, IonLabel} from "@ionic/react";
import React from "react";
import {useHistory} from "react-router";


//style
import '../../css/style.css';
import '../../css/components/webBreadcrumbs.css';


interface WebBreadcrumbsProps{
    links?: link[];
}
type link={
    name: string;
    link: string;
}
const WebBreadcrumbs: React.FC<WebBreadcrumbsProps> = ({links = []}) => {
    const history = useHistory();

    const renderLinks = () => {
        return links.map((l:link) => {
            return(
                <IonLabel className={'w-breadcrumb'} onClick={() => history.push(l.link)}>
                    {l.name}
                </IonLabel>
            )
        })
    }

    return (
        <IonCard className={"ion-hide-lg-down no-margin-horizontal"}>
            <IonCardContent>
                <IonLabel className={'w-breadcrumb'} onClick={() => history.push('/inicio')}>
                    Inicio
                </IonLabel>
                {
                    renderLinks()
                }
            </IonCardContent>
        </IonCard>
  );
};

export default WebBreadcrumbs;
