/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2021
 */

import React from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonProgressBar, IonText, IonThumbnail,
    IonTitle, IonToast,
    IonToolbar,
    withIonLifeCycle
} from '@ionic/react';
import {RouteComponentProps} from "react-router";
import {camera, close, cloudUpload, reload} from "ionicons/icons";
import API from "../../lib/api/NbioApi";


//components
import GradientDivider from "../../components/ui/GradientDivider";
import {Camera, CameraResultType, CameraSource} from "@capacitor/camera";
//Style
import "../../css/components/UploadTicketOperator.css";
//Filesize
import {filesize} from "filesize";
import NPLightBox from "../../components/ui/NPLightBox";
interface UploadTicketOperatorProps extends RouteComponentProps<{ id: string }> {
};

interface UploadTicketOperatorState {
    imageUrl: string;
    isUploading: boolean;
    loaded:number;
    total:number;
    progress:number;
    showLightBox:boolean;
    showErrorMessage:boolean;
}

class UploadTicketOperator extends React.Component<UploadTicketOperatorProps, UploadTicketOperatorState> {
    constructor(props: UploadTicketOperatorProps) {
        super(props);
        this.state = {
            imageUrl: '',
            isUploading: false,
            loaded:0,
            total:0,
            progress:0,
            showLightBox:false,
            showErrorMessage:false
        }
    }

    ionViewDidEnter() {
        // this._takePicture();
    }

    _takePicture = async () => {
        const image = await Camera.getPhoto({
            quality: 90,
            resultType: CameraResultType.Base64,
            promptLabelHeader:'Agregar recibo',
            promptLabelPhoto:'Seleccionar de la galería',
            promptLabelPicture:'Tomar una fotografía',
            source:CameraSource.Camera
        });
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        const imageBase64 = image.base64String;
        if (imageBase64) {
            //todo upload the pic
            this.setState({
                imageUrl: imageBase64
            })
        }
    }

    onBack() {
        this.setState({
            imageUrl: ''
        });
        this.props.history.goBack();
    }
    parseFileSize(fileSize:number){
        try{
            return filesize(fileSize, {standard: "jedec"});
        }catch (ex){
            return '-';
        }
    }
    render() {
        let imgSrc = `data:image/jpeg;base64,${this.state.imageUrl}`;
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar className={"toolbar-nbio"}>
                        <IonButtons slot="start" >
                            <IonButton onClick={() => this.onBack()} disabled={this.state.isUploading}>
                                <IonIcon icon={close}></IonIcon>
                            </IonButton>
                        </IonButtons>
                        <IonTitle>Agregar recibo</IonTitle>
                        {/*<IonButtons slot="end">*/}
                        {/*    <IonButton*/}
                        {/*        onClick={*/}
                        {/*            () => this.onAddTicket()*/}
                        {/*        }*/}
                        {/*        disabled={this.state.isUploading}*/}
                        {/*    >Agregar</IonButton>*/}
                        {/*</IonButtons>*/}
                    </IonToolbar>
                    <GradientDivider></GradientDivider>
                </IonHeader>
                <IonContent className={'ion-padding'}>

                    <div className={'ticket-upload-wrapper'}>
                        {/*<img className="ticket-upload-preview" src={imgSrc} alt={''}></img>*/}
                        {
                            this.state.imageUrl ?
                                <div className={`thumbnail-wrapper`}>
                                    <img
                                        src={imgSrc}
                                        onClick={() => {
                                            this.setState({
                                                // selectedImage: ticket.imageUrl
                                            })
                                            this.toggleLightBox();
                                        }}
                                    >
                                    </img>
                                </div>
                                : null
                        }
                        <IonButton
                            color={''}
                            fill={"outline"}
                            onClick={() => this._takePicture()}
                            disabled={this.state.isUploading}
                        >
                            <IonIcon icon={camera}>
                            </IonIcon>
                            {this.state.imageUrl ? " Volver a tomar" : " Tomar fotografía"}
                        </IonButton>
                        {
                            this.state.imageUrl ?
                                <IonButton
                                    color={'secondary'}
                                    fill={"outline"}
                                    onClick={() => this.onAddTicket()}
                                    disabled={this.state.isUploading}
                                >
                                        <IonIcon icon={cloudUpload}>
                                        </IonIcon>
                                        &nbsp;Subir recibo
                                </IonButton>
                                :null
                        }
                        {
                            this.state.isUploading ?
                                <div  className={'ion-text-center ion-margin-top'}>
                                    <IonText className={'ion-margin-top'}>
                                        Subiendo recibo&nbsp;
                                        {
                                            this.parseFileSize(this.state.loaded)
                                        } / {
                                        this.parseFileSize(this.state.total)}
                                    </IonText>
                                    <IonProgressBar type={'determinate'} value={this.state.progress}>

                                    </IonProgressBar>
                                </div>

                                :
                                null
                        }
                    </div>
                    {this.renderLightBox()}
                    <IonToast
                        message={'Hubo un error al subir tu recibo. Verifica tu conexión a Internet y vuelve a intentar.'}
                        duration={5000}
                        onDidDismiss={() => this.setState({showErrorMessage:false})}
                        isOpen={this.state.showErrorMessage}

                    />
                </IonContent>
            </IonPage>
        )
    }
    toggleLightBox() {
        this.setState({showLightBox: !this.state.showLightBox})
    }
    renderLightBox() {
        let imgSrc = `data:image/jpeg;base64,${this.state.imageUrl}`;
        return (
            <NPLightBox
                url={imgSrc}
                isOpen={this.state.showLightBox}
                onClose={() => this.setState({showLightBox: false})}>

            </NPLightBox>)
    }

    private onAddTicket() {
        if (this.state.isUploading) {
            return;
        }
        this.setState({
            isUploading: true
        })
        const orderId = this.props.match.params.id;
        const onUploadProgress = (pEvent:any) =>{
            this.setState({
                total:pEvent.total,
                loaded:pEvent.loaded,
                progress: (pEvent.loaded * 100 / pEvent.total) / 100
        })
        }
        API.operator.tickets.create(this.state.imageUrl,onUploadProgress).then((res) => {
            const ticketId = res.data.ticket._id;
            API.operator.orders.uploadTicket(orderId, ticketId).then((res) => {
                this.setState({
                    isUploading: false
                }, () =>{
                    this.onBack();
                })
            }).catch((ex) => {
                this.setState({
                    isUploading: false,
                    showErrorMessage:true
                })
            })
        }).catch((ex) => {
            this.setState({
                isUploading: false,
                showErrorMessage:true
            })
        })
    }
}

export default withIonLifeCycle(UploadTicketOperator);
