/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2023
 */

import {
    IonSearchbar
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import API from "../../lib/api/NbioApi";

//style
import "../../css/components/searchbarWeb.css";
import {useHistory} from "react-router";


interface searchbarWebProps {
    title? :string,
    onSearchFinished : any,
    onUpdateSearchStep: any,
    onQueryChanged?: any
}

const SearchbarWeb: React.FC<searchbarWebProps > = ({
                                                 title='',
                                                 onSearchFinished = () => {},
                                                 onUpdateSearchStep = () => {},
                                                        onQueryChanged = () =>{}

}) => {
    const [query, setQuery] = useState('');
    const [searchStep, setSearchStep] = useState('');
    const history = useHistory();

    const search = (query: string) => {
        onUpdateSearchStep('SEARCHING');

        API.products.search(query,1,10).then(res => {
            onSearchFinished(res.data)
            onUpdateSearchStep('SEARCH_FINISHED');

            //facebook tracking
            try{
                // @ts-ignore
                fbq('track', 'Search');
                // @ts-ignore
            }catch (e) {

            }
            //google tracking
            try{
                // @ts-ignore
                gtag("event", "search", {search_term: query});
            }catch (e) {

            }
        }).catch((ex) => {
            onSearchFinished([]);
            onUpdateSearchStep('SEARCH_FINISHED');
        });
    }

    const handleEnter = (ev:any) => {
        if (ev.keyCode === 13 || ev.key === 'Enter') {
            if (query.trim() !== '') {
                // search(query);
                viewAllResults();
            }
        }
    }
    useEffect(() =>{
        onQueryChanged(query);
        if(query.trim()){
            search(query);
        }else{
            onUpdateSearchStep('');
        }

    },[query]);
    const viewAllResults = () =>{
        history.push({
            pathname:'/busqueda',
            search:`?q=${query}`

        })
    }
    return (
        <div slot={""} className={"ion-hide-lg-down"}>
            <IonSearchbar placeholder={'¿Qué estás buscando?'}
                          className={'searchbar-web'}
                          animated
                          showClearButton="focus"
                          value={query}
                          onIonInput={(e:any) => {
                              setQuery(e.target.value);
                              onUpdateSearchStep('QUERY');
                          }}
                          enterkeyhint={"search"}
                          id={'search_bar_web'}
                          onKeyDown={ (e:any) =>{
                              if(e.code === 'Escape'){
                                onUpdateSearchStep('');
                              }
                              handleEnter(e);
                          }}
            >
            </IonSearchbar>
        </div>
  )
}
export default SearchbarWeb;
