/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * May 2023
 */

import {IonCard} from "@ionic/react";
import {AppLauncher} from "@capacitor/app-launcher";
import {useHistory} from "react-router";

//style
import '../../css/components/bannerItem.css';
import {useEffect, useState} from "react";
import {star} from "ionicons/icons";

interface BannerItemProps{
    item ?: any,
    height?:number,
    width? :number
}

const validUrl = require('valid-url');

const BannerItem: React.FC<BannerItemProps> = ({item, width=0,height=0}) => {
    const history = useHistory();
    const LAPTOP_SIZE = 880;
    const onClickCard = () => {
        const link = item.goTo;
        if(link){
            if(validUrl.isWebUri(link)){
                AppLauncher.openUrl({url: link!});
            }else{
                history.push({
                    pathname:`${link!}`,
                });

            }
        }

    }
    useEffect(() =>{
    },[]);
    let imgUrl = null;
    if(width < LAPTOP_SIZE){
        imgUrl =  item.imageUrl
    }else{
        imgUrl = item.imageUrlMobile ;
    }

    const dominantColor = width < LAPTOP_SIZE ? item.dominantColor : item.dominantColorMobile || item.dominantColor;

    return (
        <div className={'w-100 h-100'} style={{height:height}} id={`banner_${item._id}`}  onClick={() => onClickCard()}>
            <div className="banner-img" style={{
                backgroundColor: imgUrl ? '' : dominantColor,
                backgroundImage: `url("${imgUrl}")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition:'center center',
                backgroundSize:`${width}px ${height}px`,
                height: `${height}px`,
                width: `${width}px`,
                filter: imgUrl ? '' : `blur(160px)`
            }} >
            </div>
        </div>

  );
};

export default BannerItem;
