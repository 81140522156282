/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * Nov 2024
 */
import React from 'react';
import {
    IonRow,
    IonCol,
    IonCard,
    IonCardContent, IonHeader, IonTitle, IonAccordionGroup, IonAccordion, IonItem, IonLabel, IonText
} from '@ionic/react';
const FAQContent: React.FC<{ size: string,sizeLg:string,sizeMd:string }> = ({ size = '12',sizeLg = '6' ,sizeMd='6'}) =>{
    return (
        <>

            <IonRow className={"h-100 ion-justify-content-center"}>
                <IonCol size-md={sizeMd} className={"flex-col ion-justify-content-between"}>
                    <IonCard className={'ion-no-margin'}>
                        <IonAccordionGroup>
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q0">
                                <IonItem slot="header">
                                    <IonLabel>
                                        Necesito ayuda, ¿Cómo me pongo en contacto con atención a clientes?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Ponemos a disposición de nuestros usuarios los teléfonos siguientes
                                        para atención a clientes: 56 4167 4170 además;
                                        te puedes comunicar mediante correo electrónico a la dirección
                                        <a href="soporte.clientes@nbio.mx">soporte.clientes@nbio.mx</a>
                                        por medio de la aplicación WhatsApp a los números antes mencionados.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q1">
                                <IonItem slot="header">
                                    <IonLabel>
                                        ¿Puedo comprar medicamentos con receta médica?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        ¡Sí!, nuestra plataforma te solicitará tu receta médica y/o
                                        información adicional, según el tipo de medicamento que solicites
                                        (antibióticos/controlados), por esto es requisito fundamental de las
                                        autoridades sanitarias contar con la receta médica, la cual es validada
                                        por personal calificado de acuerdo con la legislación en materia de salud.
                                        No olvides que deberás presentar tu receta médica original y/o información
                                        adicional al momento de la entrega.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q2">
                                <IonItem slot="header">
                                    <IonLabel>¿Por qué te pedimos la receta?</IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Según el tipo de medicamento que solicites (antibióticos/controlados), por
                                        esto es requisito fundamental de las autoridades sanitarias contar
                                        con la receta médica, la cual es validada por personal calificado de
                                        acuerdo con la legislación en materia de salud.
                                        No olvides que deberás presentar tu receta médica original y/o información
                                        adicional al momento de la entrega.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q3">
                                <IonItem slot="header">
                                    <IonLabel>
                                        ¿Qué métodos de pago aceptan, y, cómo se realiza el pago?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Aceptamos tarjetas de crédito o débito para pagos realizados dentro
                                        de nuestro sitio web y APP, también mediante terminales móviles
                                        para tarjetas de crédito y/o débito, con los proveedores
                                        Mercado Pago® y CLIP®.
                                        Adicionalmente puedes realizar tus pagos en efectivo cuando el
                                        repartidor te haga entrega de tu pedido, siempre que el monto total
                                        de tu pedido sea menor a: $2,000.00 MXN (dos mil Moneda Nacional).
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q4">
                                <IonItem slot="header">
                                    <IonLabel>
                                        ¿Aceptan American Express?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Sí, aceptamos American Express, VISA, MasterdCard, Mercado Pago y
                                        también pagos en efectivo.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q5">
                                <IonItem slot="header">
                                    <IonLabel>
                                        ¿Venden medicamento refrigerado?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        ¡Claro que sí, garantizamos la integridad de todos tus medicamentos!,
                                        en nuestra APP podrás visualizar la temperatura y humedad en tiempo
                                        real de tus productos. Recuerda que nuestras unidades de transporte
                                        están calificadas y son exclusivas para el transporte de medicamentos.
                                        Bajo ninguna circunstancia arriesgaremos tu salud transportando tus
                                        medicamentos junto a verduras, comida o cualquier otro tipo de producto
                                        PROHIBIDO POR LA LEY. Nuestras unidades se especializan en cadena fría
                                        con rangos de temperatura de 2 a 8°C y nuestro personal está calificado
                                        y certificado para gestionarlo debidamente.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q6">
                                <IonItem slot="header">
                                    <IonLabel>
                                        Ya pagué y no tienen uno de mis productos. ¿Qué procede?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        En ocasiones, no tenemos disponibilidad de alguno de tus productos,
                                        cuando sea el caso te notificaremos mediante la APP y si así lo deseas,
                                        haremos lo posible para conseguirlo con terceros, si no; automáticamente
                                        procesaremos la devolución correspondiente al monto de tu producto
                                        en un plazo no mayor a 10 días hábiles, si tienes dudas sobre este proceso,
                                        comunícate a nuestras vías de contacto. Vía telefónica
                                        al (564) 167-4170, al correo <a href="soporte.clientes@nbio.mx">soporte.clientes@nbio.mx</a> o
                                        vía WhatsApp a los números antes mencionados.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q7">
                                <IonItem slot="header">
                                    <IonLabel >
                                        ¿Por qué su delivery es especializado en medicamentos?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Creemos en un nuevo paradigma de salud y equidad, por ellos utilizamos
                                        tecnología de punta para asegurar la integridad de tus medicamentos
                                        y así, garantizar que cuando lleguen a tus manos funcionen
                                        correctamente. Nuestra prioridad es tu salud.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q8">
                                <IonItem slot="header">
                                    <IonLabel>
                                        ¿Manejan envíos express?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        ¡Por supuesto, podemos entregarte tus productos de 20 a 90 min!
                                        a la hora de finalizar tu pedido, marca la opción de entrega
                                        inmediata. Por el momento la cobertura de este servicio se limita a la CDMX.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q9">
                                <IonItem slot="header">
                                    <IonLabel>
                                        ¿Por qué no tienen disponibilidad de mi producto y la tienda indica que sí hay?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Nuestro sistema de gestión de inventarios realiza actualizaciones y
                                        verificaciones de forma periódica, es probable que en el transcurso
                                        de tu solicitud de productos se hayan agotado las existencias,
                                        haremos todo lo posible para que esto no suceda con frecuencia.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q10">
                                <IonItem slot="header">
                                    <IonLabel>
                                        No encuentro un medicamento ¿Cómo puedo solicitarlo?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Nuestro catálogo se encuentra en constante actualización, seguramente
                                        contamos con el producto, pero aún no está habilitado en nuestro inventario
                                        digital, nos puedes dejar tus datos y los del medicamento en cuestión
                                        para priorizarlo en nuestras actualizaciones; así, te notificaremos
                                        tan pronto lo habilitemos en nuestro catálogo. Puedes hacernos llegar
                                        esta información llamando a la línea de atención a clientes (564) 167-4170, a
                                        nuestro correo <a href="soporte.clientes@nbio.mx">soporte.clientes@nbio.mx</a> o
                                        vía WhatsApp a los números antes mencionados.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q11">
                                <IonItem slot="header">
                                    <IonLabel>
                                        ¿Por qué me salen dos indicadores de temperatura en la APP?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        TODOS los medicamentos requieren condiciones especiales de
                                        temperatura. Por ello, nuestro personal calificado y certificado, los
                                        acomodará en el contenedor exclusivo para medicamentos según sus
                                        necesidades. Desde nuestra APP podrás darle seguimiento a las
                                        condiciones de temperatura de tus medicamentos. Si requieres información
                                        adicional, puedes solicitarla comunicándote vía correo electrónico a
                                        la dirección <a href="soporte.clientes@nbio.mx">soporte.clientes@nbio.mx</a>
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q12">
                                <IonItem slot="header">
                                    <IonLabel>
                                        ¿En cuánto tiempo me llega mi pedido?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Contamos con dos modalidades de entrega, los envíos programados que
                                        llegan a partir del día siguiente a tu pedido en la fecha y horario
                                        que elijas, y, los envíos inmediatos que tienen un tiempo de entrega
                                        de 20 a 90 minutos el mismo día, puedes elegir el que más se adecue
                                        a tus necesidades.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q13">
                                <IonItem slot="header">
                                    <IonLabel>
                                        No puedo recibir mi pedido, ¿qué puedo hacer?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        No te preocupes, cuando sea el caso puedes comunicarte a nuestras líneas
                                        de atención a clientes a los teléfonos 56 4167 4170
                                        y vía WhatsApp a los mismos números, nuestro personal te
                                        solicitará el ID de tu orden, no olvides tenerlo a la mano.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q14">
                                <IonItem slot="header">
                                    <IonLabel>
                                        No estaba en el domicilio cuando realizaron la entrega, ¿qué procede?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Si tu entrega fue de modalidad inmediata, la programaremos para el
                                        día siguiente en uno de los horarios de entrega programada o si tu
                                        entrega fue programada, la enviaremos en el siguiente horario
                                        disponible. Si tienes dudas al respecto te puedes comunicar a nuestras
                                        líneas de atención a clientes a los teléfonos 56 4167 4170
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q15">
                                <IonItem slot="header">
                                    <IonLabel>
                                        ¿Cómo puedo saber cual es el ID de mi orden?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Después de solicitar tu pedido, dentro de la APP podrás ir a la
                                        pestaña de ÓRDENES donde encontrarás un historial de todos tus
                                        pedidos, selecciona la orden respectiva a tu búsqueda y dentro de
                                        la misma podrás encontrar toda la información relacionada además de
                                        su ID. Si tienes dudas al respecto te puedes comunicar a nuestras
                                        líneas de atención a clientes a los teléfonos 56 4167 4170.
                                        {/*TODO: Insertar ejemplo gráfico aquí */}
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q16">
                                <IonItem slot="header">
                                    <IonLabel>
                                        ¿Cómo puedo cancelar un pedido?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Podrás cancelar tu pedido sin costo, siempre que no haya dado inicio
                                        la entrega del mismo, (Estado: “EN CAMINO”) de lo contrario generará
                                        un cargo equivalente al costo de envío. Para realizar la cancelación
                                        puedes ubicarte en la pestaña de ÓRDENES y seleccionar la que desees
                                        cancelar.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q17">
                                <IonItem slot="header">
                                    <IonLabel>
                                        ¿Requiero factura, cómo la solicito?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Puedes solicitar tus facturas a través de <a href="facturacion@nbio.mx">facturacion@nbio.mx</a> o
                                        en nuestras líneas de atención a clientes 56 4167 4170, se te solicitará esta información:
                                        <br/>
                                        <ul>
                                            <li>ID DE TU ORDEN</li>
                                            <li>RAZÓN SOCIAL</li>
                                            <li>RFC</li>
                                            <li>DOMICILIO FISCAL (OPCIONAL)</li>
                                            <li>CORREO ELECTRÓNICO</li>
                                        </ul>
                                        <br/>
                                        Te las haremos llegar en un plazo máximo de 7 días.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q18">
                                <IonItem slot="header">
                                    <IonLabel>
                                        ¿Cómo procede mi reembolso?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Los plazos de devolución y el lugar donde se acreditará el reembolso
                                        dependen del medio que hayas elegido para hacer el pago.
                                        <br/>
                                        Puede revisar en el siguiente link &nbsp;
                                        <a target={'_blank'}
                                           href={'https://www.mercadopago.com.mx/ayuda/devolver-dinero-compra_1601'}>
                                            https://www.mercadopago.com.mx/ayuda/devolver-dinero-compra_1601
                                        </a>
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                            <IonAccordion value="q19">
                                <IonItem slot="header">
                                    <IonLabel>
                                        La APP me notificó que me devolvieron dinero ¿a qué se debe?
                                    </IonLabel>
                                </IonItem>
                                <IonItem slot="content">
                                    <IonText className={'ion-text-wrap'}>
                                        Nos esforzamos al máximo para crear un nuevo paradigma de salud y equidad,
                                        si encontramos tus productos más baratos que el precio de lista, ¡te
                                        haremos la devolución de manera inmediata! Siempre intentaremos
                                        conseguir productos más económicos para ti.
                                    </IonText>
                                </IonItem>
                            </IonAccordion>
                            {/*-------------------------------------------------------------*/}
                            {/*-------------------------------------------------------------*/}
                        </IonAccordionGroup>
                    </IonCard>
                </IonCol>
            </IonRow>
        </>
    )

}
export default FAQContent;
