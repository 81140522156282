/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */
import React from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonContent,
    IonPage,
    IonTitle,
    IonToolbar,
    withIonLifeCycle,
    IonButtons,
    IonFooter,
    IonButton,
    IonToast,
    IonCard,
    IonCardContent,
    IonLabel,
    IonCardSubtitle,
    IonCardHeader,
} from '@ionic/react';
import Cart from "../../lib/cart/Cart";
import {CartItemType} from "../../lib/data_types/dataTypes";
import {RouteComponentProps} from "react-router";
import { money } from '../../lib/money/money';
import API from "../../lib/api/NbioApi";

//components
import CartList from "../../components/cart/CartList";
import WebHeader from "../../components/ui/WebHeader";
import FreeDeliveryProgressBar from "../../components/checkout/FreeDeliveryProgressBar";
import {EVENTS} from "../../lib/events/events";
import buyNowForwarder from "../../lib/buy-now-forwarder/buy-now-forwarder";

interface CartProps extends RouteComponentProps{};

interface CartState{
    cart:{
        id: string;
        photoId: any;
        items: CartItemType[]
    },
    parsedTotalItems: string,
    totalItems:number,
    countItems: number,
    showToast: boolean,
    toastMsg: string,
    showLoginInvitationModal: boolean,
    isAuth: boolean | null,
    isLoading: boolean
};

class TabCart extends React.Component<CartProps,CartState> {
    constructor(props: CartProps) {
        super(props);
        this.state = {
            cart: {
                id: '',
                photoId:{},
                items: [],
            },
            parsedTotalItems: '0.00',
            totalItems:0,
            countItems: 0,
            showToast: false,
            toastMsg: '',
            showLoginInvitationModal: false,
            isAuth: null,
            isLoading:true
        }
        this.onClickBuy     = this.onClickBuy.bind(this);
        this.getMe          = this.getMe.bind(this);
    }

    loadCart = (showIsLoading = false) =>{
        if(showIsLoading){
            this.setState({isLoading:true});
        }
        Cart.getCart().then(res => {
            this.setState({
                cart: res.data.cart,
                parsedTotalItems:res.data.parsedTotalItems,
                totalItems:res.data.totalItems,
                countItems:res.data.countItems
            })
        }).catch((ex) => {
            this.setState({
                cart:{
                    id:'',
                    photoId:{},
                    items:[]
                },
                parsedTotalItems:'0.00',
                countItems:0,
                showToast:false,
                toastMsg:''
            })
        }).finally(() =>{
            if(showIsLoading){
                this.setState({isLoading:false});
            }
        })
    }

    getMe = () => {
        API.users.getMe().then(res => {
            this.setState({isAuth:true});
        }).catch((ex) => {
            this.setState({isAuth:false});
        })
    }

    ionViewDidEnter() {
       this.loadCart(true);
       this.getMe();
        window.addEventListener(EVENTS.UPDATE_CART, this.updateCartItem);
        window.addEventListener(EVENTS.ADD_TO_CART, this.updateCartItem);

    }

    ionViewDidLeave(){
        window.removeEventListener(EVENTS.UPDATE_CART, this.updateCartItem);
        window.removeEventListener(EVENTS.ADD_TO_CART, this.updateCartItem);
    }
    updateCartItem = () => {
        Cart.getCart().then((res) => {
            this.setState({cart:res.data.cart});
        }).catch((ex) => {

        });
    }
    onDeleteProduct = (product:CartItemType) =>{
        Cart.removeProduct(product.cartItemId).then((res) =>{
            this.loadCart();
            this.setState({showToast:true,toastMsg:'Producto eliminado'});
        }).catch((ex) =>{

        })

    }

    onDecreaseQty = (product:CartItemType) =>{
        let qty = product.quantity - 1 <= 1 ? 1 : product.quantity - 1;
        Cart.updateProduct(product.cartItemId,qty).then((res) =>{
            this.loadCart();
            this.setState({showToast:true, toastMsg:'Producto eliminado'});
        }).catch((ex) =>{

        })
    }

    onIncreaseQty = (product:CartItemType) => {
        let qty = product.quantity + 1 >= 20 ? 20 : product.quantity + 1;
        Cart.updateProduct(product.cartItemId,qty).then((res) =>{
            this.loadCart();
            this.setState({showToast:true, toastMsg:'Producto agregado'});
        }).catch((ex) =>{

        })
    }

    onAddPrescription = (product:CartItemType) =>{
        this.props.history.push({
            pathname:'/receta',
            state:{cartItem:product}
        })
    }

    onClickBuy = async () => {
        const countItems = this.state.countItems;
        const cartItems = this.state.cart.items;
        let isPrescriptionRequired = true;

        isPrescriptionRequired = cartItems.some( item => {
            if(item.isControlled){
                return !item.prescription;
            }
            return false
        });
        if(countItems === 0) {
            this.setState({showToast: true, toastMsg: 'Agrega productos al carrito para continuar.'});
            return;
        }else if(isPrescriptionRequired){
            this.setState({showToast:true,toastMsg:'Agrega las recetas de los medicamentos controlados.'});
            return;
        }

        const buyNowUrl = await buyNowForwarder.buyNowForwarder();
        if (buyNowUrl) {
            this.props.history.push(buyNowUrl);
        } else {
            // TODO: Handle error
            this.setState({showToast:true,toastMsg:'Hubo un problema al procesar la compra'});
        }


        //
        // if(! this.state.isAuth){
        //     // Suggest to redirect after the user logins / creates an account
        //     Cart.saveRedirectAfterLoginSuggestion();
        //     // Now redirect to login
        //     this.props.history.push('/iniciar-sesion',{
        //         showWelcomeMessage:true
        //     });
        //     // this.setState({showLoginInvitationModal:true});
        // }
        // else if(countItems === 0){
        //     this.setState({showToast:true,toastMsg:'Agrega productos al carrito para continuar.'});
        // }else if(isPrescriptionRequired){
        // }else{
        //     this.props.history.push('/confirmar-pedido');
        // }
    }

    renderUploadId = () => {
        return(
            <IonGrid>
                <IonRow className={'ion-justify-content-center'}>
                    <IonCol size-md={"6"}>
                        <IonCard className="ion-no-margin"
                                 color={'warning'}
                                 button
                                 onClick={() => {
                                     this.props.history.push({
                                        pathname:'/identificacion',
                                        state:{cartId:this.state.cart.id}}
                                        )}
                                 }
                                 routerDirection={'forward'}>
                            <IonCardContent className={'padding-half'}>
                                <IonLabel className="ion-text-wrap">
                                    ¡Tu compra contiene un medicamento especial!
                                    El nombre de la identificación de coincidir con el de la receta.
                                    Toca aquí para agregar tu identificación oficial.
                                </IonLabel>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    }

    renderPhotoId = () => {
        const photoId = this.state.cart ? this.state.cart.photoId : {};

        if(photoId){
            return(
                <IonGrid>
                    <IonRow className={'ion-justify-content-center'}>
                        <IonCol size-md={"6"}>
                            <IonCard className="ion-no-margin"
                                     button
                                     onClick={() => {
                                         this.props.history.push({
                                             pathname:'/identificacion',
                                             state:{cartId:this.state.cart.id}}
                                         )}
                                     }
                                     routerDirection={'forward'}>
                                <IonCardHeader>
                                    <IonCardSubtitle>Identificación:
                                        <span className={'ion-text-uppercase'}> {photoId.documentType}</span>
                                    </IonCardSubtitle>
                                </IonCardHeader>
                                <div className={'w-100 flex-row ion-justify-content-evenly'}>
                                    <img className={'flex-1 img-contain'} src={photoId.imageFrontUrl}
                                         alt={'id-front'}></img>
                                    {
                                        photoId.documentType === 'INE' ?
                                            <img className={'flex-1 ml-1 img-contain'} src={photoId.imageBackUrl}
                                                 alt={'id-back'}></img> : null
                                    }
                                </div>

                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            )
        }
    }


    render() {
        return (
            <>
                <IonPage>
                <WebHeader></WebHeader>
                <IonContent>
                    <IonToast         isOpen={this.state.showToast}
                                      onDidDismiss={() => this.setState({showToast: false})}
                                      message={this.state.toastMsg}
                                      duration={2000} />
                    <FreeDeliveryProgressBar total={this.state.totalItems}/>
                    <CartList products={this.state.cart.items} onDelete={this.onDeleteProduct}
                              onDecreaseQty={this.onDecreaseQty} onIncreaseQty={this.onIncreaseQty}
                              onAddPrescription={this.onAddPrescription} isLoading={this.state.isLoading}
                    >
                    </CartList>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonGrid>
                            <IonRow className={'ion-justify-content-center'}>
                                <IonCol size-md={"6"} size-lg={"6"}>
                                    <IonToolbar>
                                    <IonTitle slot="" className="padding-vertical-half" color="dark" size="small"><b>SUBTOTAL:
                                            &nbsp;{money(this.state.parsedTotalItems)}</b>
                                        </IonTitle>
                                        <IonButtons slot={""}>
                                            <IonButton className="w-100"
                                                       color="primary"
                                                       expand="block"
                                                       fill='solid'
                                                       onClick={this.onClickBuy}>
                                                Comprar ({this.state.countItems})</IonButton>
                                        </IonButtons>
                                    </IonToolbar>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
            </>

        )
    }
}

export default withIonLifeCycle(TabCart);
