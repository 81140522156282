/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2022
 */

import {IonThumbnail, IonBadge, IonSkeletonText} from "@ionic/react";
import React from "react";
import {CartItemType} from "../../lib/data_types/dataTypes";

//style
import "../../css/components/OrderProductThumbnail.css";

//interface
interface OrderProductThumbnailProps {
    items: CartItemType[]
}

const OrderProductThumbnail: React.FC<OrderProductThumbnailProps> = ({ items }) => {
    if(items.length === 0){
        return(
            <IonSkeletonText animated={true} className={'opt-skeleton'} />
        )
    }else{
        return (
            <div className={'opt-wrapper'}>
                {items.map((item,index) => {
                    return(
                        <div className={'opt-item'} key={item._id}>
                            <IonBadge className={'opt-badge'}><small>{item.quantity}</small></IonBadge>
                            <IonThumbnail className={'opt-thumbnail'}>
                                <img src={item.imageUrl}/>
                            </IonThumbnail>
                        </div>
                    )
                })}
            </div>
        )

    }

};

export default OrderProductThumbnail;
