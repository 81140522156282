import Cart from "../cart/Cart";
import API from "../api/NbioApi";

const buyNowForwarderObj = {
    buyNowForwarder: async () => {
        // CAN_BUY_WITHOUT_ACCOUNT	isAuth	isPrescriptionRequired
        const CAN_BUY_WITHOUT_ACCOUNT = process.env.REACT_APP_CAN_BUY_WITHOUT_ACCOUNT === 'true';
        const res = await Cart.getCart();
        const cartItems = res.data.cart.items;
        let isPrescriptionRequired = true;
        isPrescriptionRequired = cartItems.some( (item) => {
            if(item.isControlled){
                return !item.prescription;
            }
            return false
        });
        let isAuth = false;
        try{
            const me = await API.users.getMe();
            isAuth = true;
        }catch(ex){

        }
        // 0 0 0
        if(!CAN_BUY_WITHOUT_ACCOUNT && !isAuth && !isPrescriptionRequired){
            return '/iniciar-sesion';
        }
        // 0 0 1
        if(!CAN_BUY_WITHOUT_ACCOUNT && !isAuth && isPrescriptionRequired){
            return '/iniciar-sesion';
        }
        // 0 1 1
        if(!CAN_BUY_WITHOUT_ACCOUNT && isAuth && isPrescriptionRequired){
            return '/carrito';
        }
        // 1 0 0
        if(CAN_BUY_WITHOUT_ACCOUNT && !isAuth && !isPrescriptionRequired){
            return '/confirmar-pedido';
        }
        // 1 0 1
        if(CAN_BUY_WITHOUT_ACCOUNT && !isAuth && isPrescriptionRequired){
            return '/carrito';
        }
        // 1 1 1
        if(CAN_BUY_WITHOUT_ACCOUNT && isAuth && isPrescriptionRequired){
            return '/carrito';
        }
        // 0 1 0
        if(!CAN_BUY_WITHOUT_ACCOUNT && isAuth && !isPrescriptionRequired){
            return '/confirmar-pedido';
        }
        // 1 1 0
        if(CAN_BUY_WITHOUT_ACCOUNT && isAuth && !isPrescriptionRequired){
            return '/confirmar-pedido';
        }
    }
}
export default buyNowForwarderObj;