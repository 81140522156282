/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */
import React from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    withIonLifeCycle,
    IonButtons,
    IonBackButton,
    IonButton,
    IonIcon,
    IonCard,
    IonCardSubtitle,
    IonGrid,
    IonRow,
    IonCol,
    IonCardHeader,
    IonCardContent,
    IonFooter,
    IonLabel,
    IonToast, IonNote
} from '@ionic/react';
import { RouteComponentProps} from "react-router";
import {
    addCircle,
    chevronBack, home,
    refreshOutline,
    removeCircle,
    shareOutline,
    shareSocialOutline,
    shareSocialSharp
} from "ionicons/icons";
import NbioApi from "../../lib/api/NbioApi";
import Cart from "../../lib/cart/Cart";
import {ProductType} from "../../lib/data_types/dataTypes";

//style
import 'swiper/swiper-bundle.min.css';
import '@ionic/react/css/ionic-swiper.css';

//components
import Product from "../../components/products/Product";
import RecommendedProducts from "../../components/products/RecommendedProducts";
import WebHeader from "../../components/ui/WebHeader";
import WebFooter from "../../components/ui/WebFooter";

// Share
import { Share } from '@capacitor/share';
// app utils
const {setAppTitle} = require('../../lib/app-utils/app-utils');


class Page404 extends React.Component{
    constructor(props:any) {
        super(props);
        this.state = {
        }
    }


    renderProductNotFound(){
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }} >
                <h2>Lo sentimos, no encontramos la página.</h2>
                <a href={'/inicio'}>
                    <IonButton fill={'outline'} size={'small'} >
                        <IonIcon icon={home}></IonIcon>
                        &nbsp;Volver al inicio
                    </IonButton>
                </a>

            </div>
        )
    }
    render(){
        return (
            <IonPage>
                <IonContent className="ion-padding-top" >
                    {this.renderProductNotFound()}
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(Page404);
