/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2022
 */


import React from "react";
import {
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonItem,
    IonLabel, IonNote, IonRadio, IonRadioGroup,
    IonRow,
    IonSkeletonText
} from "@ionic/react";
import API from "../../lib/api/NbioApi";
import {PaymentMethod} from "../../lib/data_types/dataTypes";
import {RouteComponentProps} from "react-router";
import NbioApi from "../../lib/api/NbioApi";


interface CardPaymentProps extends RouteComponentProps{
    onConfirmPayment:any;
    onFailedPayment:any;
    checkoutId:string;
    onChangePaymentType?:any;
}
interface CardPaymentState{
    paymentMethodsLength:number;
    isLoaded:boolean;
    isErrored:boolean;
    defaultPaymentMethod:PaymentMethod | null;
    isCardLoaded: boolean;
    isCardErrored:boolean;
}
class CardPayment extends React.Component<CardPaymentProps,CardPaymentState> {
    constructor(s:any) {
        super(s);
        this.state = {
            paymentMethodsLength:0,
            isLoaded:false,
            isErrored:false,
            defaultPaymentMethod:null,
            isCardLoaded: false,
            isCardErrored:false
        }
    }
    componentDidMount() {
        this.loadCards();
    }

    pay(){
        const data = {
            chargeDefault:true
        }
        NbioApi.checkout.generatePaymentIntent(this.props.checkoutId,data).then((res) => {
            this.props.onConfirmPayment(res.data.orderId);
        }).catch((ex) =>{
            this.props.onFailedPayment();
        })
    }
    renderDefaultCard = (payment: PaymentMethod | null) => {
        if (!payment) {
            return <div></div>
        } else {
            return (
                <IonCard key={payment.last4} className={'ion-margin-top'}>
                    <IonItem lines="none">
                        <IonRadioGroup value={'1'}>
                            <IonRadio value={'1'}></IonRadio>
                        </IonRadioGroup>
                        <IonLabel className={"ion-text-capitalize"} >
                            <b>&nbsp; {payment.brand} **** **** **** {payment.last4}</b>
                        </IonLabel>
                    </IonItem>
                    <IonCardContent className={"no-padding-vertical"}>
                        <IonLabel>
                            {payment.name}
                        </IonLabel>
                        <br/>
                        <IonNote>
                            Vence {payment.exp_month}/{payment.exp_year}
                        </IonNote>
                    </IonCardContent>
                </IonCard>
            )
        }

    }
    loadCards(){
        API.paymentMethods.get().then((res) => {
            const paymentMethods = res.data.paymentMethods;
            const paymentMethodsLength = paymentMethods.length;
            this.setState({
                paymentMethodsLength:paymentMethodsLength,
                isLoaded:true
            })

        }).catch((ex) => {
            this.setState({
                isErrored:true,
                isLoaded:true
            })
        });
        API.paymentMethods.getDefault().then((res) => {
            this.setState({
                defaultPaymentMethod:res.data.defaultMethod,
                isCardLoaded:true
            })
        }).catch((ex) => {
            this.setState({
                isCardErrored:true
            })
        })
    }
    renderContent(){
        if (!this.state.isLoaded) {
            return (
                <IonSkeletonText animated className={'h-100'}/>
            )
        } else if (this.state.paymentMethodsLength === 0 && !this.state.isErrored) {
            return (
                <div>
                    <div>
                        AGREGA NUEVA
                    </div>
                    <div>
                        AGREGAR
                    </div>
                </div>
            )
        } else if (this.state.paymentMethodsLength > 0 && !this.state.isErrored) {
            return (
                <IonCol>
                    {/*TIENES TARJETAS, CARGAR DEFAULT*/}
                    {
                        this.renderDefaultCard(
                            this.state.defaultPaymentMethod
                        )
                    }
                    <IonCard button={true} routerLink={'/seleccionar-tarjeta'}>
                        <IonItem lines="none" detail>
                            <IonLabel className={"ion-text-capitalize"}>
                                Seleccionar otra tarjeta
                            </IonLabel>
                        </IonItem>
                    </IonCard>
                    <IonCard button={true} onClick={()=> this.goToSelectCard()}>
                        <IonItem lines="none" detail>
                            <IonLabel className={"ion-text-capitalize"}>
                                Agregar una tarjeta
                            </IonLabel>
                        </IonItem>
                    </IonCard>
                </IonCol>
            )
        } else {
            return (
                <div>No pudimos cargar tus metodos de pago</div>
            )
        }
    }
    goToSelectCard(){
        this.props.onChangePaymentType('force-card');
    }
    render(){
        return(
            <IonContent fullscreen>
                <IonGrid className={'ion-no-padding h-100'}>
                    <IonRow className="ion-justify-content-center h-100">
                        <IonCol className={'h-100'}>
                            {this.renderContent()}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        )
    }
}
export default CardPayment;
