/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import {
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonImg,
    IonButton, IonIcon
} from "@ionic/react";
import {CategoryType} from '../../lib/data_types/dataTypes';
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';

//style
import '../../css/components/CategoryList.css';
import {useRef} from "react";
import {
    arrowBack,
    arrowBackCircleOutline,
    arrowForward,
    chevronBack,
    chevronBackCircleOutline, chevronForward,
    chevronForwardCircleOutline
} from "ionicons/icons";

interface CategoryListProps{
    categories: CategoryType[],
    title ?: string,
    onClick?: any
}

const CategoryList: React.FC<CategoryListProps> = ({ categories,title ,onClick}) => {
    return (
    <IonGrid className={'margin-top-lg'}>
        {
            title !== "" ?
                <IonRow>
                    <IonCol>
                        <IonText>
                            <h5 className={'ion-hide-lg-up'}>
                                {title}
                            </h5>
                            <h3 className={'ion-hide-lg-down'}>
                                {title}
                            </h3>
                        </IonText>
                    </IonCol>
                </IonRow>
            : null
        }
        <IonRow>
            <Swiper modules={[Pagination,Navigation]}
                    slidesPerView={1}
                    breakpoints={
                        {
                            768: {
                                slidesPerView: 2,
                            },
                            992: {
                                slidesPerView: 4,
                            },
                            1200: {
                                slidesPerView:5,
                            },
                            2600:{
                                slidesPerView:6,
                            }
                        }
                    }
                    autoplay={true}
                    navigation={{
                        prevEl: '.nbio-swiper-prev',
                        nextEl: '.nbio-swiper-next',
                    }}
                    pagination={true}
            >
                {
                    categories.map(category => {
                        return(
                            <SwiperSlide key={`${category._id}`}>
                                <IonCard className={'category-card ion-no-margin ion-no-border'}>
                                    <IonImg src={category.image} />
                                    <div className={'category-content'}>
                                        <IonText className={'ion-text-wrap ion-text-capitalize ion-margin-bottom'}>
                                            <h5>{category.name}</h5>
                                        </IonText>
                                        <IonButton className={'btn-true-dark'}
                                                   size={'small'}
                                                   routerLink={`/categorias/${category.handleUrl}`}
                                                   onClick={() => onClick(category)}>Ver todo
                                        </IonButton>
                                    </div>
                                </IonCard>
                            </SwiperSlide>
                        )
                    })
                }
                <div className={'nbio-swiper-navigation'}>
                    <IonButton className={'nbio-swiper-prev'} fill={'clear'} color={'tertiary'}>
                        <IonIcon icon={chevronBack} color={'white'} style={{backgroundColor:'white',borderRadius:'100%',fontSize:'2rem'}}>
                        </IonIcon>
                    </IonButton>
                    <IonButton className={'nbio-swiper-next'}  fill={'clear'} color={'tertiary'}>
                        <IonIcon icon={chevronForward} color={'white'} style={{backgroundColor:'white',borderRadius:'100%',fontSize:'30px'}}>
                        </IonIcon>
                    </IonButton>
                </div>
            </Swiper>

        </IonRow>
    </IonGrid>
  );
};

export default CategoryList;
