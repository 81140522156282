/* Copyright (C) Nodeport SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */
const bills = [50,100,500,1000];
function _ratios(x){
    return bills.map((b) => {return {bill:b,ratio:x/b} }).filter((b) => b.ratio >1);
}
function optimalChange(x){
    let change = 0;
    let ratios = _ratios(x);
    let y = parseFloat(x);
    while(y > 0){
        ratios = _ratios(y);
        if(ratios.length > 0){
            let _r = ratios[ratios.length - 1];
            y = y - Math.floor(_r.ratio) *  _r.bill;
            change += Math.floor(_r.ratio) *  _r.bill;
        }else{
            change += bills[0]
            y = 0;
        }
    }
    return change;
}
module.exports ={
    optimalChange:optimalChange
}
