/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */
import React, {useContext, useState} from 'react';
import {
    IonContent,
    IonButton,
    IonCard,
    IonCardSubtitle,
    IonItem,
    IonLabel,
    IonIcon,
    IonAvatar,
    IonGrid,
    IonRow,
    IonCol, useIonViewDidEnter, IonAlert, IonToast, IonSpinner,
} from '@ionic/react';
import {useHistory} from "react-router";
import {Camera, CameraResultType} from '@capacitor/camera';
import NbioApi from "../lib/api/NbioApi";
import {User} from "../lib/data_types/dataTypes";
import {
    camera,
    lockClosed,
    person,
    logOut,
    ticket,
    location,
    card,
    trash,
    reader,
    personCircle,
    car, wifi, map, informationCircleOutline
} from "ionicons/icons";
import {AppContext} from "../App";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import 'dayjs/locale/es';

//libs
import Cart from "../lib/cart/Cart";
import API from "../lib/api/NbioApi";

//style
import "../css/components/profile.css"

//dates
dayjs.extend(utc);

const Profile: React.FC = () => {
    const history = useHistory();
    const {user, setUser, isAuth, setIsAuth} = useContext(AppContext);
    const [showDeleteAccountAlert, setShowDeleteAccountAlert] = useState(false);
    const [showDeleteAccountAlert2, setShowDeleteAccountAlert2] = useState(false);
    const [showOperatorAlert, setShowOperatorAlert] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [vehiclesInfo, setVehicles] = useState([]);
    const [loadingVehiclesInfo, setLoadingVehiclesInfo] = useState(true);
    const [showCameraErrorMessage, setShowCameraErrorMessage] = useState(false);
    const [isUploadingPhoto,setIsUploadingPhoto] = useState(false);
    const logout = async () => {
        const dummyUser: User = {
            name: '',
            last_name: '',
            source: '',
            uid: '',
            email: '',
            roles: ['user'],
            isDisabled: false,
            profileUrl: '',
            profileUrlThumbnail: ''
        };
        NbioApi.users.logout().then((res) => {
            // forget cart
            Cart.forgetCart();
            setUser(dummyUser);
            // Reset localStorage
            localStorage.removeItem('nbio-zipcode-verified');

            window.location.replace(`/inicio`);
            setIsAuth(false);
        }).catch((ex) => {
            window.location.replace(`/inicio`);
        })
    }
    useIonViewDidEnter(() => {
        NbioApi.users.getMe().then((res) => {
            setUser(res.data.user);
        }).catch((ex) => {

        });
        setLoadingVehiclesInfo(true);
        NbioApi.vehicles.getOperatorInfo().then((res) => {
            setVehicles(res.data.vehicles || []);
            setLoadingVehiclesInfo(false);
        }).catch((ex) => {
            setLoadingVehiclesInfo(false);
        });
    });
    const _takePicture = async () => {
        try{
            const image = await Camera.getPhoto({
                quality: 90,
                resultType: CameraResultType.Base64,
                promptLabelHeader: 'Agregar foto de perfil',
                promptLabelPhoto: 'Seleccionar de la galería',
                promptLabelPicture: 'Tomar una fotografía'
            });
            // image.webPath will contain a path that can be set as an image src.
            // You can access the original file using image.path, which can be
            // passed to the Filesystem API to read the raw data of the image,
            // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
            const imageBase64 = image.base64String;
            setIsUploadingPhoto(true);
            if (imageBase64) {
                try{
                    await NbioApi.users.updateProfilePicture(imageBase64);
                    const userRes = await NbioApi.users.getMe();
                    if (userRes) {
                        setUser(userRes.data.user);
                    }
                    setIsUploadingPhoto(false);
                    return null;
                }catch (e) {
                    setIsUploadingPhoto(false);

                }
            }
        }catch(ex){
            setShowCameraErrorMessage(true);
            setIsUploadingPhoto(false);
        }



    }

    const renderProfileImage = (imgUrl: string) => {
        if(isUploadingPhoto){
            return (<IonSpinner/>);
        }
        if (imgUrl) {
            return (
                <img src={imgUrl} alt={'user-image'}></img>
            )
        } else {
            return (
                <IonIcon className={"w-100 h-100"} icon={person}></IonIcon>
            )
        }
    }

    const startAccountDeletion = () => {
        if (user.roles.includes('operator')) {
            setShowOperatorAlert(true);
        } else {
            setShowDeleteAccountAlert(true);
        }
    }

    const deleteAccount = () => {
        API.users.deleteOwnAccount().then((res) => {
            window.location.replace(`/inicio`);
        }).catch((ex) => {
            setShowMessage(true);
        })
    }

    const onClickAddress = () => {
        history.push({
            pathname: '/direcciones',
            state: {
                goBackTo: '/direcciones',
            }
        });
    }
    const onClickInvoicing = () => {
        history.push({
            pathname: '/datos-de-facturacion',
            state: {
                onSavePath: '/datos-de-facturacion',
            }
        });
    }
    const renderVehicles = () => {
        if (loadingVehiclesInfo) {
            return (
                <IonCard>
                    <IonItem>
                        <IonSpinner name={'dots'}></IonSpinner>
                    </IonItem>
                </IonCard>

            );
        }
        return vehiclesInfo.map((v: any) => {
            return (
                <IonCard className={'ion-no-margin ion-margin-top'} key={v._id}>
                    <IonItem className={'bg-transparent'} lines={'none'}>
                        <IonIcon slot={'start'} icon={car} color={'medium'}></IonIcon>
                        <IonLabel>{v?.name} ({v?.plate || 'Sin placas registradas'})</IonLabel>
                    </IonItem>
                    {
                        v?.devices.map((d: any) => {
                            return (
                                <IonItem className={'bg-transparent'} lines={'none'} key={d._id}>
                                    <IonIcon slot={'start'} icon={wifi} color={'medium'}></IonIcon>
                                    <IonLabel>{d?.name} ({d?.id})</IonLabel>
                                </IonItem>
                            )
                        })
                    }

                </IonCard>
            )
        });
    }
    return (
        <>
            <IonContent className="ion-padding-vertical">
                <IonToast
                    isOpen={showMessage}
                    onDidDismiss={() => {
                        setShowMessage(false);
                    }}
                    message={'Hubo un problema al eliminar tu cuenta. Intenta de nuevo más tarde'}
                    duration={5000}
                />
                <IonToast
                    isOpen={showCameraErrorMessage}
                    onDidDismiss={() => {
                        setShowCameraErrorMessage(false);
                    }}
                    message={'No pudimos abrir la cámara. Por favor, vuelve a intentarlo.'}
                    duration={5000}
                />
                <IonAlert isOpen={showOperatorAlert}
                          header={'Eliminación de cuenta'}
                          message={'Para borrar tu cuenta de operador contacta al equipo de Nbio.'}
                          onDidDismiss={() => setShowOperatorAlert(false)}
                          buttons={[
                              {
                                  text: 'Aceptar',
                                  handler: () => {
                                      setShowOperatorAlert(false);
                                  },
                              },
                          ]}/>
                <IonAlert isOpen={showDeleteAccountAlert}
                          onDidDismiss={() => setShowDeleteAccountAlert(false)}
                          header={'¿Estás seguro/a de eliminar tu cuenta?'}
                          message="Al confirmar se eliminará también tu historial de compras, historial de navegación y
                     cerraremos permantentemente tu cuenta. Para volver a usar Nbio necesitarás crear una nueva cuenta.
                     Esta opción no se puede deshacer. ¿Estás seguro/a?"
                          buttons={[
                              {
                                  text: 'No',
                                  role: 'cancel',
                                  handler: () => setShowDeleteAccountAlert(false)
                              },
                              {
                                  text: 'Si',
                                  handler: () => {
                                      setShowDeleteAccountAlert(false);
                                      setShowDeleteAccountAlert2(true);
                                  },
                              },
                          ]}/>
                <IonAlert isOpen={showDeleteAccountAlert2}
                          onDidDismiss={() => setShowDeleteAccountAlert2(false)}
                          header={'¿Estás seguro/a de eliminar tu cuenta?'}
                          message="Para eliminar tu cuenta, vuelve a confirmar. Esta opción no se puede deshacer. ¿Estás seguro/a?"
                          buttons={[
                              {
                                  text: 'No, mantener mi cuenta',
                                  role: 'cancel',
                                  handler: () => setShowDeleteAccountAlert2(false)
                              },
                              {
                                  text: 'Si, eliminar mi cuenta',
                                  handler: () => {
                                      deleteAccount()
                                  },
                              },
                          ]}/>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size-md="6">
                            <IonCard className={"flex-col ion-align-items-center ion-padding ion-no-margin"}>
                                <IonAvatar className={'p-relative'}>
                                    {renderProfileImage(user.profileUrl)}
                                    <IonButton className={"fab-camera-btn"}
                                               size="small" fill={'clear'}
                                               onClick={_takePicture}>
                                        <IonIcon slot={"icon-only"} icon={camera}></IonIcon>
                                    </IonButton>
                                </IonAvatar>

                                <IonCardSubtitle
                                    className={'ion-margin-top ion-text-uppercase'}>{user.name} {user.last_name}
                                </IonCardSubtitle>
                                <IonCardSubtitle
                                    className={'ion-text-uppercase'}>{user.email}
                                </IonCardSubtitle>
                            </IonCard>

                            <IonCard className={'ion-no-margin ion-margin-top'}>
                                <IonItem detail className={'bg-transparent'} lines={'full'}
                                         routerLink={'/perfil/editar'}
                                         routerDirection={'forward'}>
                                    <IonIcon slot={'start'} icon={personCircle} color={'medium'}></IonIcon>
                                    <IonLabel>Editar datos de la cuenta</IonLabel>
                                </IonItem>
                                <IonItem detail className={'bg-transparent'} lines={'none'}
                                         routerLink={'/perfil/actualizar-contrasena'}
                                         routerDirection={'forward'}>
                                    <IonIcon slot={'start'} icon={lockClosed} color={'medium'}></IonIcon>
                                    <IonLabel>Cambiar contraseña</IonLabel>
                                </IonItem>
                            </IonCard>
                            {
                                user.roles.includes('operator') ?
                                    // Operator Info
                                    renderVehicles()
                                    :
                                    // User  options
                                    <IonCard className={'ion-no-margin ion-margin-top cursor-pointer'}>
                                        <IonItem detail className={'bg-transparent'} lines={'full'}
                                                 onClick={() => onClickAddress()}
                                                 routerDirection={'forward'}>
                                            <IonIcon slot={'start'} icon={location} color={'medium'}></IonIcon>
                                            <IonLabel>Direcciones</IonLabel>
                                        </IonItem>
                                        <IonItem detail className={'bg-transparent'} lines={'full'}
                                                 routerLink={'/metodos-de-pago'}
                                                 routerDirection={'forward'}>
                                            <IonIcon slot={'start'} icon={card} color={'medium'}></IonIcon>
                                            <IonLabel>Métodos de pago</IonLabel>
                                        </IonItem>
                                        <IonItem detail className={'bg-transparent'} lines={'full'}
                                                 onClick={() => onClickInvoicing()}
                                                 routerDirection={'forward'}>
                                            <IonIcon slot={'start'} icon={reader} color={'medium'}></IonIcon>
                                            <IonLabel>Datos de facturación</IonLabel>
                                        </IonItem>
                                        <IonItem detail className={'bg-transparent'} lines={'none'}
                                                 routerLink={'/cupones'}
                                                 routerDirection={'forward'}>
                                            <IonIcon slot={'start'} icon={ticket} color={'medium'}></IonIcon>
                                            <IonLabel>Cupones</IonLabel>
                                        </IonItem>
                                    </IonCard>

                            }

                            <IonCard className={'ion-no-margin ion-margin-top'}>
                                <IonItem detail button className={'bg-transparent'} lines={'full'}
                                         onClick={() => startAccountDeletion()}>
                                    <IonIcon slot={'start'} icon={trash} color={'danger'}></IonIcon>
                                    <IonLabel color={'danger'}>Eliminar mi cuenta</IonLabel>
                                </IonItem>
                                <IonItem detail button className={'bg-transparent'} lines={'none'}
                                         onClick={() => logout()}>
                                    <IonIcon slot={'start'} icon={logOut} color={'danger'}></IonIcon>
                                    <IonLabel color={'danger'}>Cerrar sesión</IonLabel>
                                </IonItem>
                            </IonCard>
                            <IonCard className={'ion-no-margin ion-margin-top'}>
                                <IonItem detail button className={'bg-transparent'} lines={'none'}
                                         routerLink={'/informacion'}
                                         routerDirection={'forward'}>
                                    <IonIcon slot={'start'} icon={informationCircleOutline}></IonIcon>
                                    <IonLabel>Acerca de Nbio</IonLabel>
                                </IonItem>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </>
    )
}

export default Profile;
